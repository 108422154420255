import {
    ValidationRequestProps,
    ValidationResponseProps,
    ValidationRuleProps
} from "../types/ValidationProps";
import ValidationRuleType from "../types/ValidationRuleType";

const Validations = (props: ValidationRequestProps) => {
    //eslint-disable-next-line
    let result: ValidationResponseProps = {} as ValidationResponseProps
    //eslint-disable-next-line
    // debugger
    if (props.required) {
        if (props.rules) {
            props.rules.map((r: ValidationRuleProps) => {
                //eslint-disable-next-line
                //debugger
                switch (r.Type) {
                    case ValidationRuleType.REGEX: {
                        const pattern = new RegExp(r.Rule)
                        if(props.fieldType == 'password') {
                            console.log('pattern 1', pattern)
                        }
                        if(props.value) {
                            console.log('props.value 2', props.value)
                            if (!pattern.test(props.value)) {
                                if(props.fieldType == 'password') {
                                    console.log('props.value 1', props.value)
                                }
                                result.Valid = false
                            }
                        } else {
                            console.log('props.value 3', props.value)
                            result.Valid = false
                        }
                        break;
                    }
                    case ValidationRuleType.COMPARE:
                        if (props.value != r.Rule) {
                            if(props.fieldType == 'password') {
                                console.log('props.value 1', props.value)
                            }
                            result.Valid = false
                            result.Msg = `${props.fieldName} value not match`
                        }
                        break;
                    case ValidationRuleType.MAXLENTH:
                        if (props.value.length > parseInt(r.Rule)) {
                            if(props.fieldType == 'password') {
                                console.log('props.value 1', props.value)
                            }
                            result.Valid = false
                        }
                        break;
                    case ValidationRuleType.CUSTOM:
                        if (!eval(r.Rule)) {
                            if(props.fieldType == 'password') {
                                console.log('props.value 1', props.value)
                            }
                            result.Valid = false
                        }
                        break;
                    case ValidationRuleType.EXTERNAL:
                        break;
                }
            })
            if (result.Valid == false) {
                result.Msg = result.Msg == undefined || result.Msg == '' ? `${props.fieldName} value is invalid` : result.Msg
            } else {
                result.Valid = true
                result.Msg = ''
            }
        } else {
            if (props.value && props.value.length > 0) {
                result.Valid = true
                result.Msg = ''
            } else {
                result.Valid = false
                result.Msg = `${props.fieldName} is required`
            }
        }
    } else {
        result.Valid = true
        result.Msg = ''
    }
    return result;
}

export default Validations