import React, {useEffect} from "react";
import Header from "../../../framework/layouts/Header";
import Footer from "../../../framework/layouts/Footer";
import {getSessionCookie} from "../../../framework/common/SessionHandler";
import DashboardContainer from "./DashboardContainer";
import WelcomePage from "./WelcomePage";

const AuditorWelcome = () => {
    const session = getSessionCookie();
    useEffect(()=>{
        if(session.userRoleId == 3 && session.status != 3 && session.status != 4 &&  session.status != 1){
            window.location.href = '/profile';
        }else if(session.userRoleId == 1 || session.userRoleId == 4 ){
            window.location.href = '/dashboard';
        }
    },[])

    return (
        <>
            <Header>
                {session.status == 1 ? (
                    <WelcomePage
                        session={session}
                    />
                    ) :(
                    <DashboardContainer
                    session={session}
                />
                    )}
            </Header>
            <Footer/>
        </>

    );
}

export default AuditorWelcome;