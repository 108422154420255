import React, {useEffect} from "react";
import Header from "../../../../framework/layouts/Header";
import Footer from "../../../../framework/layouts/Footer";
import AssesmentStatusContainer from "./AssesmentStatusContainer";


const AssesmentStatus = () => {
    return(
        <React.Fragment>
            <Header>
                <AssesmentStatusContainer/>
            </Header>
            <Footer/>
        </React.Fragment>
    );
}

export default AssesmentStatus