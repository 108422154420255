import React from "react";
import Header from "../../../../framework/layouts/Header";
import Footer from "../../../../framework/layouts/Footer";
import EditAssessorContainer from "./EditAssessorContainer";


const EditAssessor = () => {
    const mainUrl = window.location.pathname
    console.log(mainUrl)
    return(
        <React.Fragment>
            <Header>
                <EditAssessorContainer/>
            </Header>
            <Footer/>
        </React.Fragment>
    );
}

export default EditAssessor