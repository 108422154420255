import React from "react";
import Header from "../../../../framework/layouts/Header";
import Footer from "../../../../framework/layouts/Footer";
import AdminContainer from "./AdminContainer";


const Admin = () => {
    const mainUrl = window.location.pathname
    console.log(mainUrl)
    return(
        <React.Fragment>
            <Header>
                <AdminContainer/>
            </Header>
            <Footer/>
        </React.Fragment>
    );
}

export default Admin