import React, {useEffect, useState} from 'react'
import {Grid, Popup, Radio} from "semantic-ui-react";
import TableWithBackground from "../../../framework/widgets/TableWithBackgrounds";
import RSATable from "../../../framework/widgets/TableWithBackgrounds/RSATable";
import AddNewCourse from "./AddNewCourse";
import TableHeader from "../../../framework/types/TableHeader";
import TableRow from "../../../framework/types/TableRow";
import tags from "../../../models/common/tag";
import {RoadSafetyCoursesProps} from "./DataModal";
import {
    Auditor7YrsExpExceptionSave,
    fetchAuditor,
    fetchAuditor7YrsExpException,
    RemoveMefromAudit,
    RemoveMefromCourse,
    UpdateMsgFromAdmin
} from "./ProfileController";
import {getSessionCookie} from "../../../framework/common/SessionHandler";
import InfoToggle from "../../../framework/widgets/InfoToggle";
import MsgFromAdmin from "../../../models/apiResponce/Auditor/MsgFromAdmin";
import {PaginationProps} from "antd";
import {fetchFiles} from "../../../framework/components/FileUpload/FileUploadController";
import Modal from "../../../framework/components/Modals";
import FileUploadPop from "../../../framework/components/FileUpload/FileUploadPop";
import waringImage from "../../../assets/images/icons/warning.png";
import OutLineButton from "../../../framework/components/Buttons/OutlIneButton";
import DefaultButton from "../../../framework/components/Buttons/DefaultButton";
import {Session} from "inspector";
import Position from "../../../framework/types/Position";
import NameWithMultiline from "../../../framework/widgets/NameWithText/NameWithMuliLine";
import NameWithCheckBox from "../../../framework/widgets/NameWithCheckBox/NameWithCheckBox";
import moment from "moment";



const SuperAdminNotes = () => {
    const [auditorId, setAuditorId] = useState<any>();
    const session = getSessionCookie();
    const encid = new URLSearchParams(location.search).get('get')
    const [auditorStatus, SetAuditorStatus] = useState(0)
    const [logNotes, setLogNotes] = useState('')
    const [isExcp, setIsExcp] = useState(false)
    const [createDate, setCreateDate] = useState('')
    const [lastUpdateDate, setLastUpdateDate] = useState('')

    useEffect(() => {
        const data = fetchAuditor7YrsExpException(parseInt(atob(encid ? encid : '')))
        Promise.resolve(data).then((r) => {
            setLogNotes(r.note ? r.note : '')
            setIsExcp(r.sevenYearsExp ? r.sevenYearsExp : false)
            setCreateDate(moment(r.createDate).format("DD/MM/YYYY hh:mm:ss A"))
            setLastUpdateDate(moment(r.lastUpdateDate).format("DD/MM/YYYY hh:mm:ss A"))
        })
    },[])

    useEffect(() => {
        if(encid){
            Auditor7YrsExpExceptionSave(parseInt(atob(encid ? encid : '')),session.userId, logNotes, isExcp)
        }
    },[logNotes,isExcp])

    return (
        <React.Fragment>
            <div className="ui center aligned icon header red-title" id={'courses'}>
                <h1 className="ui center aligned icon header red-title">Super Admin Notes</h1>
            </div>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column>
                         <span style={{display: 'inline-flex'}}>Enter any notes that is relevant to this profile (maximum of 300 chracters)</span>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <NameWithMultiline id={'txtSNotes'}
                                           labelText={''}
                                           type={'text'}
                                           className={'other_exp'}
                                           wordCount={true}
                                           labelPosition={Position.Top}
                                           required={false}
                                           fluid={false}
                                           value={logNotes}
                                           onChange={(e:any)=>{
                                               setLogNotes(e.target.value)
                                           }}
                                           disabled={!(session.userRoleId == 4)}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Grid.Row columns={1}>
                            <Grid.Column>
                                <NameWithCheckBox id={'chkExemption '} value={isExcp} onChange={()=>{setIsExcp(!isExcp)}} name={'Exemption'} label={' Exemption '} disabled={!(session.userRoleId == 4)} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={1}>
                            <Grid.Column>
                                <p style={{marginTop:'10px'}}>TfNSW will recognise existing Level 3 road safety auditors with 5 years experience and who meet all other Level 3 criteria, during the transition period.</p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <span> Date created: {createDate} </span>
                        <p> Date updated: {lastUpdateDate} </p>
                    </Grid.Column>
                    <Grid.Column>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>

                    </Grid.Column>
                </Grid.Row>

            </Grid>
            <br/>
            <hr/>
        </React.Fragment>
    )
}

export default SuperAdminNotes




