

//Import url from config file
import ApiCallAxio from "../../../../framework/common/apiCallAxio";
import APICallMethods from "../../../../framework/types/APICallMethods";

import moment from "moment";
import { AssessmentProcessStatsRes, AssessmentProcessStatsperAssessorRes } from "../../../../models/apiRequest/mis/AssesmentProcess";

const baseUrl = process.env.REACT_APP_API_BASEURL;
const baseUrl2 = process.env.REACT_APP_API_BASEURL2;
const sublink = process.env.REACT_APP_API_SUBLINK;
const version = process.env.REACT_APP_API_VERSION;

//to get the list of all the contact logs.
const fetchAssessmentProcessStats = async (fromDate?:string,toDate?:string) => {
    const from = fromDate == "Invalid date" ? '' : `StartDate=${moment(fromDate).format("MM-DD-YYYY")}`
    const to = toDate == "Invalid date" ? '' : `EndDate=${moment(toDate).format("MM-DD-YYYY")}`
    const params = from.length > 1 && to.length > 1 ? `?${from}&${to}`:from.length > 1 ? `?${from}`:to.length > 1 ? `?${to}` : ''
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/reports/assessmentprocessstats${params}`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objresult: result = result; //store the result in userTitles array
    return objresult;//return the array
}

interface result {
    result: AssessmentProcessStatsRes[]
}

const fetchAssessmentProcessStatsDownload = async (fromDate?:string,toDate?:string) => {
    const from = fromDate == "Invalid date" ? '' : `StartDate=${moment(fromDate).format("MM-DD-YYYY")}`
    const to = toDate == "Invalid date" ? '' : `EndDate=${moment(toDate).format("MM-DD-YYYY")}`
    const params = from.length > 1 && to.length > 1 ? `?${from}&${to}`:from.length > 1 ? `?${from}`:to.length > 1 ? `?${to}` : ''
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/reports/assessmentprocessstats/download${params}`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objresult: string = result; //store the result in userTitles array
    return objresult;//return the array
}

const fetchAssessmentProcessStatsPerAssessor = async (fromDate?:string,toDate?:string) => {
    const from = fromDate == "Invalid date" ? '' : `StartDate=${moment(fromDate).format("MM-DD-YYYY")}`
    const to = toDate == "Invalid date" ? '' : `EndDate=${moment(toDate).format("MM-DD-YYYY")}`
    const params = from.length > 1 && to.length > 1 ? `?${from}&${to}`:from.length > 1 ? `?${from}`:to.length > 1 ? `?${to}` : ''
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/reports/assessmentprocessstatsperassessor${params}`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objresult: resultAssesor = result; //store the result in userTitles array
    return objresult;//return the array
}

interface resultAssesor {
    result: AssessmentProcessStatsperAssessorRes[]
}

const fetchAssessmentProcessStatsPerAssessorDownload = async (fromDate?:string,toDate?:string) => {
    const from = fromDate == "Invalid date" ? '' : `StartDate=${moment(fromDate).format("MM-DD-YYYY")}`
    const to = toDate == "Invalid date" ? '' : `EndDate=${moment(toDate).format("MM-DD-YYYY")}`
    const params = from.length > 1 && to.length > 1 ? `?${from}&${to}`:from.length > 1 ? `?${from}`:to.length > 1 ? `?${to}` : ''
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/reports/assessmentprocessstatsperassessor/download${params}`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objresult: string = result; //store the result in userTitles array
    return objresult;//return the array
}







export {fetchAssessmentProcessStats, fetchAssessmentProcessStatsDownload, fetchAssessmentProcessStatsPerAssessor, fetchAssessmentProcessStatsPerAssessorDownload};