import React, {useEffect} from "react";
import Header from "../../framework/layouts/Header";
import Footer from "../../framework/layouts/Footer";
import TrainingContainer from "./TrainingContainer";
import {
    GoogleReCaptchaProvider,
    useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

const Training = () => {
    return(
        <React.Fragment>
            <>
            <GoogleReCaptchaProvider
                reCaptchaKey={
                    process.env.REACT_APP_RECAPTCHA_KEY
                        ? process.env.REACT_APP_RECAPTCHA_KEY
                        : ""
                }
            >
                <Header>
                    <TrainingContainer/>
                </Header>
                <Footer/>
            </GoogleReCaptchaProvider>
            </>
        </React.Fragment>
    );
}

export default Training