import React, {useEffect, useState} from "react";
import {Container, Grid, Table} from "semantic-ui-react";
import InnerTitleBar from "../../../../framework/layouts/InnerTitleBar";
import {userRoleSelector} from "../../../../common/roleSelector";
import {getSessionCookie} from "../../../../framework/common/SessionHandler";
import OutLineButton from "../../../../framework/components/Buttons/OutlIneButton";
import { UpdateApiCallForAssessor, SaveApiCallForAssessor } from "../../../search/SearchController";
import SuccessToast from "../../../../framework/components/Toast/SuccessToast";
import ErrorToast from "../../../../framework/components/Toast/ErrorToast";
import NameWithDefault from "../../../../framework/widgets/NameWithText/NameWithDefault";
import Position from "../../../../framework/types/Position";
import NameWithDropDown from "../../../../framework/widgets/NameWithDropDown";
import { fetchCountries, fetchStates, fetchTitle } from "../../../auditor/profile/ProfileController";
import NameWithEmail from "../../../../framework/widgets/NameWithText/NameWithEmail";
import NameWithPassword from "../../../../framework/widgets/NameWithText/NameWithPassword";
import NameWithNumber from "../../../../framework/widgets/NameWithText/NameWithNumber";
import NameWithRadioButton from "../../../../framework/widgets/NameWithCheckBox/NameWithRadioButton";
import Header from "../../../../framework/layouts/Header";
import Footer from "../../../../framework/layouts/Footer";
import { AssessorProfileRes } from "../../../../models/apiResponce/mis/AssessorProfileRes";


const EditAssessorProfile = () => {
    const mainUrl = window.location.pathname
    console.log(mainUrl)
    return(
        <React.Fragment>
            <Header>
                <EditAssessorProfileContainer/>
            </Header>
            <Footer/>
        </React.Fragment>
    );
}

export default EditAssessorProfile

const EditAssessorProfileContainer = () =>{
    const AssessorId = new URLSearchParams(location.search).get('id');
    const Type = new URLSearchParams(location.search).get('type');
    const [state, setState] = React.useState<AssessorProfileRes>();
    const session = getSessionCookie();
    const [emailError, setEmailError] = React.useState('')
    const [passwordError, setPasswordError] = React.useState('')
    const [buttonText, setButtoText] = useState('Add')
    const [headerText, setHeaderText] = useState('Add Assessor')
    const [titles, setTitles] = useState<any>()
    const [countries, setCountries] = useState<any>()
    const [states, setStates] = useState<any>()
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth < 768);
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    useEffect(() => {
        if(AssessorId == '0'){
            setButtoText('Add Assessor')
            setHeaderText('Add Assessor profile')
        }else{
            setButtoText('Update Assessor')
            setHeaderText('Edit Assessor profile')
        }
    },[AssessorId])

    useEffect(() => {
        const countryArray = fetchCountries();
        Promise.resolve(countryArray).then((value) => {
            setCountries(value)
        })

        const titleArray = fetchTitle();
        Promise.resolve(titleArray).then((value) => {
            setTitles(value)
        })

        const stateArray = fetchStates();
        Promise.resolve(stateArray).then((value) => {
            setStates(value)
        })
    }, []);

    useEffect(() => {
        if (AssessorId && AssessorId !== '0') {
            UpdateApiCallForAssessor(AssessorId).then((value) => {
                if (value) {
                    setState(value);
                    console.log("New state value:", value);
                    console.log(state)
                }
            });
        }
    }, [AssessorId]);

    useEffect(() => {
        console.log("Updated state:", state);
    }, [state]);

    const onSaveClick = () => {
        try {
            if (!Validating()) {
                return;
            }

            if (AssessorId && AssessorId !== '0') {
                const searchData = SaveApiCallForAssessor(state)
                Promise.resolve(searchData).then((value)=>{
                    if(value){
                        SuccessToast('Assessor updated successfully')
                        setTimeout(() => {
                            // Reload the page
                            window.location.href = '/profile/assessor'
                        }, 2000); // 2000 milliseconds delay (2 seconds)
                    }
                })
            } else {
                const searchData = SaveApiCallForAssessor(state)
                Promise.resolve(searchData).then((value)=>{
                    if(value){
                        SuccessToast('Assessor added successfully')
                        setTimeout(() => {
                            // Reload the page
                            window.location.href = '/profile/assessor'
                        }, 2000); // 2000 milliseconds delay (2 seconds)
                    }
                })
            }
            
        } catch (error) {
            // Handle the error here
            ErrorToast('Error saving loginname change');
        }
    }

    const Validating = () => {
        if (state?.newEmail !== state?.confirmEmail) {
            // Show an error message or handle the validation failure
            setEmailError('New email and Confirm new email must match');
            return false;
        }
        if(state?.password !== state?.confirmPassword) {
            setPasswordError('New password and Confirm password must match');
            return false;
        }
        setEmailError('');
        setPasswordError('');
        return true;
    };

    const [disableSave, setDisableSave] = React.useState(false);

useEffect(() => {
        DisableValidation();
    }, [state, emailError, passwordError]);

    const DisableValidation = () =>{
        const isFormInvalid = (
            !state?.newEmail ||
            !state?.confirmEmail ||
            state.newEmail !== state.confirmEmail ||
            emailError !== '' ||
    
            !state?.password ||
            !state?.confirmPassword ||
            state.password !== state.confirmPassword ||
            passwordError !== '' ||
    
            !state?.firstName ||
            !state?.lastName ||
            !state?.title ||
            !state?.orgName ||
            !state?.position ||
            !state?.workAddress ||
            !state?.suburb ||
            !state?.postalCode ||
            !state?.workMobNumber ||
            !state.workEmail
        );
    
        setDisableSave(isFormInvalid);
    }

    return(
        <Container fluid={true} style={{marginTop: '14px', marginBottom: '20px'}}>
            <InnerTitleBar
                title={"Update Assessors"}
                usreName={session.userDisplayName != null ? session.userDisplayName : "  "}
                userRole={userRoleSelector(session.userRoleId.toString())}
                lastLogin={session.lastLogin.toString()}
                status={userRoleSelector(session.userRoleId.toString())}
            />
            {
                isMobile ? (
                    <Grid>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
    
                        </Grid.Column>
                        <Grid.Column width={12}>
                                <hr/>
                        </Grid.Column>
                        <Grid.Column width={2}>
    
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
    
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <b><span style={{display:'inline-flex', fontSize:'20px'}}>{headerText}</span></b>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                        <Grid.Column width={2}>
    
                        </Grid.Column>
                    </Grid.Row>
                    { Type == 'update' ? (
                        <Grid.Row columns={3}>
                            <Grid.Column width={2}>
    
                            </Grid.Column>
                            <Grid.Column width={12}>
                                <Grid>
                                    <Grid.Row columns={8}>
                                        <Grid.Column width={3}>
                                            <span style={{display:'inline-flex'}}>Assessor ID:</span>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <span style={{display:'inline-flex'}}>{AssessorId}</span>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>     
                            </Grid.Column>
                            <Grid.Column width={2}>
    
                            </Grid.Column>
                        </Grid.Row>
                    ):null}
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithEmail
                                                labelText="Email address:"
                                                labelPosition={Position.Top}
                                                id={'txtemail'} 
                                                fluid={true} 
                                                placeholder={'Enter email address'}
                                                value={state?.newEmail}
                                                required={true}
                                                type={'email'}
                                                onChange={(e: any) => {
                                                    setState({...state,newEmail:e.target.value})
                                                }}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithEmail
                                            labelText="Confirm email address:"
                                            labelPosition={Position.Top}
                                            type="email"
                                            id={'txtConfirmEmail'} 
                                            fluid={true} 
                                            placeholder={'Enter confirm email address'}
                                            required={true}
                                            onError={emailError}
                                            value={state?.confirmEmail}
                                            onChange={(e: any) => {
                                                setState({...state,confirmEmail:e.target.value})
                                            }}
                                            onPaste={(e:any) => {
                                                e.preventDefault();
                                                return
                                            }}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithPassword
                                                id={'txtPassword'}
                                                fluid={true}
                                                required={true}
                                                placeholder={'Enter password'}
                                                value={state?.password}
                                                onChange={(e: any) => {
                                                    setState({ ...state, password: e.target.value });
                                                } } 
                                                labelText={"Password:"} 
                                                labelPosition={Position.Top} 
                                                type={"password"}                                    />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithPassword
                                            id={'txtConfirmPassword'} 
                                            fluid={true} 
                                            placeholder={'Enter confirm password'}
                                            onError={passwordError}
                                            value={state?.confirmPassword}
                                            onChange={(e: any) => {
                                                setState({...state,confirmPassword:e.target.value})
                                            }}
                                            onPaste={(e:any) => {
                                                e.preventDefault();
                                                return
                                            }}
                                            labelText={"Confirm password:"} 
                                            labelPosition={Position.Top} 
                                            type={"password"}           
                                    />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
    
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <b><span style={{display:'inline-flex', fontSize:'20px'}}>Personal details:</span></b>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                        <Grid.Column width={2}>
    
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDropDown 
                                            clearable={false} 
                                            selection={true} 
                                            labelText={'Title:'}
                                            labelPosition={Position.Top} id={'ddTitle'}
                                            placeholder={'Select title'}
                                            required={true}
                                            options={titles}
                                            value={state?.title}
                                            onChange={(e: any, data: any) => {
                                                setState({...state, title: data.value})
                                        }}/>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtFirstName'} 
                                            fluid={true} 
                                            placeholder={'Enter first name'}
                                            value={state?.firstName}
                                            onChange={(e: any) => {
                                                setState({...state,firstName:e.target.value})
                                            }}
                                            labelText={"First name:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtMiddleName'} 
                                            fluid={true} 
                                            placeholder={'Enter middle name'}
                                            value={state?.middleName}
                                            onChange={(e: any) => {
                                                setState({...state,middleName:e.target.value})
                                            }}
                                            labelText={"Middle name:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={false}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtLastName'} 
                                            fluid={true} 
                                            placeholder={'Enter last name'}
                                            value={state?.lastName}
                                            onChange={(e: any) => {
                                                setState({...state,lastName:e.target.value})
                                            }}
                                            labelText={"Last name:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtOrgName'} 
                                            fluid={true} 
                                            placeholder={'Enter company / organisation'}
                                            value={state?.orgName}
                                            onChange={(e: any) => {
                                                setState({...state,orgName:e.target.value})
                                            }}
                                            labelText={"Company / Organisation:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtBranch'} 
                                            fluid={true} 
                                            placeholder={'Enter branch / section'}
                                            value={state?.branchName}
                                            onChange={(e: any) => {
                                                setState({...state,branchName:e.target.value})
                                            }}
                                            labelText={"Branch / Section:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={false}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtPosition'} 
                                            fluid={true} 
                                            placeholder={'Enter position'}
                                            value={state?.position}
                                            onChange={(e: any) => {
                                                setState({...state,position:e.target.value})
                                            }}
                                            labelText={"Position:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtWordAddress'} 
                                            fluid={true} 
                                            placeholder={'Enter work (postal) address'}
                                            value={state?.workAddress}
                                            onChange={(e: any) => {
                                                setState({...state,workAddress:e.target.value})
                                            }}
                                            labelText={"Work 'Postal' address:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtSuburb'} 
                                            fluid={true} 
                                            placeholder={'Enter suburb'}
                                            value={state?.suburb}
                                            onChange={(e: any) => {
                                                setState({...state,suburb:e.target.value})
                                            }}
                                            labelText={"Suburb:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDropDown 
                                            clearable={false} 
                                            selection={true} 
                                            labelText={'State:'}
                                            labelPosition={Position.Top} id={'ddState'}
                                            placeholder={'Select state'}
                                            required={false}
                                            options={states}
                                            value={state?.state}
                                            onChange={(e: any, data: any) => {
                                                setState({...state, state: data.value})
                                        }}/>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithNumber
                                            id={'txtPostalCode'} 
                                            fluid={true} 
                                            placeholder={'Enter postal code'}
                                            value={state?.postalCode}
                                            onChange={(e: any) => {
                                                setState({...state,postalCode:e.target.value})
                                            }}
                                            labelText={"Postalcode:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDropDown 
                                            clearable={false} 
                                            selection={true} 
                                            labelText={'Country:'}
                                            labelPosition={Position.Top} id={'ddCountry'}
                                            placeholder={'Select country'}
                                            required={false}
                                            options={countries}
                                            value={state?.country}
                                            onChange={(e: any, data: any) => {
                                                setState({...state, country: data.value})
                                        }}/>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtWorkMobNo'} 
                                            fluid={true} 
                                            placeholder={'Enter work mobile number'}
                                            value={state?.workMobNumber}
                                            onChange={(e: any) => {
                                                setState({...state,workMobNumber:e.target.value})
                                            }}
                                            labelText={"Work mobile no.:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtLandPhoneNo'} 
                                            fluid={true} 
                                            placeholder={'Enter land phone number'}
                                            value={state?.landLinePhone}
                                            onChange={(e: any) => {
                                                setState({...state,landLinePhone:e.target.value})
                                            }}
                                            labelText={"Landline phone no.:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={false}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithEmail
                                            labelText="Work email:"
                                            labelPosition={Position.Top}
                                            id={'txtWorkEmail'} 
                                            fluid={true} 
                                            placeholder={'Enter work email address'}
                                            value={state?.workEmail}
                                            required={true}
                                            type={'email'}
                                            onChange={(e: any) => {
                                                setState({...state,workEmail:e.target.value})
                                            }}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={1} mobile={4}>
                                    <span style={{display:'inline-flex'}}>Activate:</span><br/>
                                        <NameWithRadioButton
                                            id={'rbYes'}
                                            label={'Active'}
                                            name={'rbActivate'}
                                            value={state?.isActivate}
                                            onChange={() => {
                                                setState({...state, isActivate: true})
                                            }}
                                            // disabled={isEditCourse}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={1} mobile={4}>
                                        <span>&nbsp;</span><br/>
                                        <NameWithRadioButton
                                            id={'rbNo'}
                                            label={'Inactive'}
                                            name={'rbActivate'}
                                            value={!state?.isActivate}
                                            onChange={() => {
                                                setState({...state, isActivate : false})
                                            }}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
    
                        </Grid.Column>
                        <Grid.Column width={12} className={'button-flex'} style={{display:'flex', justifyContent:'right'}}>
                            <OutLineButton id={'btnCancel'} style={{width: '150px'}} onClick={() => {
                                    window.location.href = '/profile/editAssessor';
                                }} 
                                color={'red'} 
                                className={"background-transaparent-red"}
                                text={'Back'}/>
                            <OutLineButton id={'btnSave'} 
                                style={{width: '150px'}} 
                                onClick={() => {
                                    onSaveClick()
                                }} 
                                color='blue' 
                                className={"background-transaparent"}
                                text={buttonText}
                                disabled={disableSave}
                            />
                        </Grid.Column>
                        <Grid.Column width={2}>
    
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                ) : (
                    <Grid>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
    
                        </Grid.Column>
                        <Grid.Column width={12}>
                                <hr/>
                        </Grid.Column>
                        <Grid.Column width={2}>
    
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
    
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <b><span style={{display:'inline-flex', fontSize:'20px'}}>{headerText}</span></b>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                        <Grid.Column width={2}>
    
                        </Grid.Column>
                    </Grid.Row>
                    { Type == 'update' ? (
                        <Grid.Row columns={3}>
                            <Grid.Column width={2}>
    
                            </Grid.Column>
                            <Grid.Column width={12}>
                                <Grid>
                                    <Grid.Row columns={8}>
                                        <Grid.Column width={3}>
                                            <span style={{display:'inline-flex'}}>Assessor ID:</span>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <span style={{display:'inline-flex'}}>{AssessorId}</span>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>     
                            </Grid.Column>
                            <Grid.Column width={2}>
    
                            </Grid.Column>
                        </Grid.Row>
                    ):null}
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithEmail
                                                labelText="Email address:"
                                                labelPosition={Position.Top}
                                                id={'txtemail'} 
                                                fluid={true} 
                                                placeholder={'Enter email address'}
                                                value={state?.newEmail}
                                                required={true}
                                                type={'email'}
                                                onChange={(e: any) => {
                                                    setState({...state,newEmail:e.target.value})
                                                }}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithEmail
                                            labelText="Confirm email address:"
                                            labelPosition={Position.Top}
                                            type="email"
                                            id={'txtConfirmEmail'} 
                                            fluid={true} 
                                            placeholder={'Enter confirm email address'}
                                            required={true}
                                            onError={emailError}
                                            value={state?.confirmEmail}
                                            onChange={(e: any) => {
                                                setState({...state,confirmEmail:e.target.value})
                                            }}
                                            onPaste={(e:any) => {
                                                e.preventDefault();
                                                return
                                            }}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithPassword
                                                id={'txtPassword'}
                                                fluid={true}
                                                required={true}
                                                placeholder={'Enter password'}
                                                value={state?.password}
                                                onChange={(e: any) => {
                                                    setState({ ...state, password: e.target.value });
                                                } } 
                                                labelText={"Password:"} 
                                                labelPosition={Position.Top} 
                                                type={"password"}                                    />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithPassword
                                            id={'txtConfirmPassword'} 
                                            fluid={true} 
                                            placeholder={'Enter confirm password'}
                                            onError={passwordError}
                                            value={state?.confirmPassword}
                                            onChange={(e: any) => {
                                                setState({...state,confirmPassword:e.target.value})
                                            }}
                                            onPaste={(e:any) => {
                                                e.preventDefault();
                                                return
                                            }}
                                            labelText={"Confirm password:"} 
                                            labelPosition={Position.Top} 
                                            type={"password"}           
                                    />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
    
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row columns={3}>
                                    <Grid.Column>
                                        <b><span style={{display:'inline-flex', fontSize:'20px'}}>Personal details:</span></b>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                        <Grid.Column width={2}>
    
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDropDown 
                                            clearable={false} 
                                            selection={true} 
                                            labelText={'Title:'}
                                            labelPosition={Position.Top} id={'ddTitle'}
                                            placeholder={'Select title'}
                                            required={true}
                                            options={titles}
                                            value={state?.title}
                                            onChange={(e: any, data: any) => {
                                                setState({...state, title: data.value})
                                        }}/>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtFirstName'} 
                                            fluid={true} 
                                            placeholder={'Enter first name'}
                                            value={state?.firstName}
                                            onChange={(e: any) => {
                                                setState({...state,firstName:e.target.value})
                                            }}
                                            labelText={"First name:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtMiddleName'} 
                                            fluid={true} 
                                            placeholder={'Enter middle name'}
                                            value={state?.middleName}
                                            onChange={(e: any) => {
                                                setState({...state,middleName:e.target.value})
                                            }}
                                            labelText={"Middle name:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={false}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtLastName'} 
                                            fluid={true} 
                                            placeholder={'Enter last name'}
                                            value={state?.lastName}
                                            onChange={(e: any) => {
                                                setState({...state,lastName:e.target.value})
                                            }}
                                            labelText={"Last name:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtOrgName'} 
                                            fluid={true} 
                                            placeholder={'Enter company / organisation'}
                                            value={state?.orgName}
                                            onChange={(e: any) => {
                                                setState({...state,orgName:e.target.value})
                                            }}
                                            labelText={"Company / Organisation:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtBranch'} 
                                            fluid={true} 
                                            placeholder={'Enter branch / section'}
                                            value={state?.branchName}
                                            onChange={(e: any) => {
                                                setState({...state,branchName:e.target.value})
                                            }}
                                            labelText={"Branch / Section:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={false}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtPosition'} 
                                            fluid={true} 
                                            placeholder={'Enter position'}
                                            value={state?.position}
                                            onChange={(e: any) => {
                                                setState({...state,position:e.target.value})
                                            }}
                                            labelText={"Position:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtWordAddress'} 
                                            fluid={true} 
                                            placeholder={'Enter work (postal) address'}
                                            value={state?.workAddress}
                                            onChange={(e: any) => {
                                                setState({...state,workAddress:e.target.value})
                                            }}
                                            labelText={"Work 'Postal' address:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtSuburb'} 
                                            fluid={true} 
                                            placeholder={'Enter suburb'}
                                            value={state?.suburb}
                                            onChange={(e: any) => {
                                                setState({...state,suburb:e.target.value})
                                            }}
                                            labelText={"Suburb:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDropDown 
                                            clearable={false} 
                                            selection={true} 
                                            labelText={'State:'}
                                            labelPosition={Position.Top} id={'ddState'}
                                            placeholder={'Select state'}
                                            required={false}
                                            options={states}
                                            value={state?.state}
                                            onChange={(e: any, data: any) => {
                                                setState({...state, state: data.value})
                                        }}/>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithNumber
                                            id={'txtPostalCode'} 
                                            fluid={true} 
                                            placeholder={'Enter postal code'}
                                            value={state?.postalCode}
                                            onChange={(e: any) => {
                                                setState({...state,postalCode:e.target.value})
                                            }}
                                            labelText={"Postalcode:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDropDown 
                                            clearable={false} 
                                            selection={true} 
                                            labelText={'Country:'}
                                            labelPosition={Position.Top} id={'ddCountry'}
                                            placeholder={'Select country'}
                                            required={false}
                                            options={countries}
                                            value={state?.country}
                                            onChange={(e: any, data: any) => {
                                                setState({...state, country: data.value})
                                        }}/>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtWorkMobNo'} 
                                            fluid={true} 
                                            placeholder={'Enter work mobile number'}
                                            value={state?.workMobNumber}
                                            onChange={(e: any) => {
                                                setState({...state,workMobNumber:e.target.value})
                                            }}
                                            labelText={"Work mobile no.:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtLandPhoneNo'} 
                                            fluid={true} 
                                            placeholder={'Enter land phone number'}
                                            value={state?.landLinePhone}
                                            onChange={(e: any) => {
                                                setState({...state,landLinePhone:e.target.value})
                                            }}
                                            labelText={"Landline phone no.:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={false}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithEmail
                                            labelText="Work email:"
                                            labelPosition={Position.Top}
                                            id={'txtWorkEmail'} 
                                            fluid={true} 
                                            placeholder={'Enter work email address'}
                                            value={state?.workEmail}
                                            required={true}
                                            type={'email'}
                                            onChange={(e: any) => {
                                                setState({...state,workEmail:e.target.value})
                                            }}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={1} mobile={4}>
                                    <span style={{display:'inline-flex'}}>Activate:</span><br/>
                                        <NameWithRadioButton
                                            id={'rbYes'}
                                            label={'Active'}
                                            name={'rbActivate'}
                                            value={state?.isActivate}
                                            onChange={() => {
                                                setState({...state, isActivate: true})
                                            }}
                                            // disabled={isEditCourse}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={1} mobile={4}>
                                        <span>&nbsp;</span><br/>
                                        <NameWithRadioButton
                                            id={'rbNo'}
                                            label={'Inactive'}
                                            name={'rbActivate'}
                                            value={!state?.isActivate}
                                            onChange={() => {
                                                setState({...state, isActivate : false})
                                            }}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
    
                        </Grid.Column>
                        <Grid.Column width={12} className={'button-flex'} style={{display:'flex', justifyContent:'right'}}>
                            <OutLineButton id={'btnCancel'} style={{width: '150px'}} onClick={() => {
                                    window.location.href = '/profile/editAssessor';
                                }} 
                                color={'red'} 
                                className={"background-transaparent-red"}
                                text={'Back'}/>
                            <OutLineButton id={'btnSave'} 
                                style={{width: '150px'}} 
                                onClick={() => {
                                    onSaveClick()
                                }} 
                                color='blue' 
                                className={"background-transaparent"}
                                text={buttonText}
                                disabled={disableSave}
                            />
                        </Grid.Column>
                        <Grid.Column width={2}>
    
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                )
            }

        </Container>
    );
}
