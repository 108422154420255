import React, {useEffect, useState} from "react";
import {Container, Grid, Table} from "semantic-ui-react";
import InnerTitleBar from "../../../../framework/layouts/InnerTitleBar";
import {userRoleSelector} from "../../../../common/roleSelector";
import {getSessionCookie} from "../../../../framework/common/SessionHandler";
import Position from "../../../../framework/types/Position";
import NameWithDefault from "../../../../framework/widgets/NameWithText/NameWithDefault";
import OutLineButton from "../../../../framework/components/Buttons/OutlIneButton";
import DateRangeBox from "../../../../framework/components/TextBoxes/DateRangeBox";
import moment from "moment";
import {fetchAuditorsContactLog} from "./ContactLogController";

const ContactLogContainer = () =>{
    const session = getSessionCookie();
    const baseUrl = process.env.REACT_APP_API_BASEURL;
    const baseUrl2 = process.env.REACT_APP_API_BASEURL2;
    const [data, setData] = useState<string>();
    const [recoord, setRecord] = useState<string>()
    const [dateRange, setDateRange] = useState('')

    const handleExportClick = async () =>{
        const dates = dateRange.split('-')
        const AuditorsCount = await fetchAuditorsContactLog(moment(dates[0], "DD/MM/YYYY").toString(),moment(dates[1], "DD/MM/YYYY").toString());
        setRecord(AuditorsCount.totalRecords.toString())
        setData(`${baseUrl2 ? baseUrl2 : baseUrl}/${AuditorsCount.logUrl}`)
    }

    return(
        <Container fluid={true} style={{marginTop: '14px', marginBottom: '20px'}}>
            <InnerTitleBar
                title={"Auditor Contact Log"}
                usreName={session.userDisplayName != null ? session.userDisplayName : "  "}
                userRole={userRoleSelector(session.userRoleId.toString())}
                lastLogin={session.lastLogin.toString()}
                status={userRoleSelector(session.userRoleId.toString())}
            />
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <p><b>Export a log file of the contact submissions that auditors have received.</b></p>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={12} mobile={16} computer={12} tablet={8}>
                                    <>
                                        <DateRangeBox
                                            id={'txtFromDate'}
                                            fluid={true}
                                            placeholder={'From Date - To Date'}
                                            onChange={(e:any,data:any) => {setDateRange(data.value)}}
                                            value={dateRange}
                                        />
                                    </>
                                </Grid.Column>
                                <Grid.Column width={4} mobile={16} computer={4} tablet={8}>
                                        <OutLineButton 
                                        id={'btnExport'} 
                                        style={{ width: '150px' }} 
                                        className={"background-transaparent"} 
                                        onClick={handleExportClick} color={'blue'} text={'Export'}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>

                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                {(() => {
                    // Check if `recoord` exists and is not '0'
                    if (recoord && recoord !== '0') {
                        return (
                            <Grid.Row columns={3}>
                                <Grid.Column width={2} />
                                <Grid.Column width={12}>
                                    <p><b>{recoord} record(s) extracted, click the link below to download the file</b></p>
                                    <p><b><a href={data}>Download log file here</a></b></p>
                                </Grid.Column>
                                <Grid.Column width={2} />
                            </Grid.Row>
                        );
                    } 
                    // Else if `recoord` exists and is '0'
                    else if (recoord === '0') {
                        return (
                            <Grid.Row columns={3}>
                                <Grid.Column width={2} />
                                <Grid.Column width={12}>
                                    <p><b>{recoord} record(s) extracted</b></p>
                                </Grid.Column>
                                <Grid.Column width={2} />
                            </Grid.Row>
                        );
                    } 
                    // Else (fallback case if `recoord` doesn't exist)
                    else {
                        return (
                            null
                        );
                    }
                })()}
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>

                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>

                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
}

export default ContactLogContainer
