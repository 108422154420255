//Import url from config file
import ApiCallAxio from "../../../../framework/common/apiCallAxio";
import APICallMethods from "../../../../framework/types/APICallMethods";
import {AuditorByCondition} from "../../../../models/apiResponce/Auditor/AuditorByCondition";
import moment from "moment";
import { CourseManagementRes, CourseManagementResSearchRes } from "../../../../models/apiResponce/Courses/CourseManagementRes";
import { AuditorCourseRess } from "../../../../models/apiResponce/Auditor/AuditorCourseRess";

const baseUrl = process.env.REACT_APP_API_BASEURL;
const baseUrl2 = process.env.REACT_APP_API_BASEURL2;
const sublink = process.env.REACT_APP_API_SUBLINK;
const version = process.env.REACT_APP_API_VERSION;

//to get the list of all the titles.
const fetchAllCourses = async (courseId:number, providerId:number, pageNumber:number) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/Courses/coursemanagement?courseID=${courseId}&providerID=${providerId}&pageNumber=${pageNumber}`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    console.log(result)
    const objCoursesRes: CourseManagementResSearchRes = result; //store the result in userTitles array
    return objCoursesRes;//return the array
}

//to get the list of all the titles.
const fetchAllAuditorCourses = async (courseId: number) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorAccreditation/GetAuditorCoursesByCourseId?courseID=${courseId}`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objCoursesRes: AuditorCourseRess[] = result.auditorCourseRess;
    return objCoursesRes;
}

const formatDate = (dateStr:any) => {
    const momentDate = moment(dateStr, "DD/MM/YYYY");
    return momentDate.isValid() ? momentDate.format("YYYY-MM-DD") : null;
}

const addCourse = async (props:CourseManagementRes) => {
    console.log(props)
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/Courses`; //complete url
    const Data = {
        courseId: props.courseId,
        courseName: props.courseName,
        courseProviderId: props.courseProviderId,
        providerName: props.providerName,
        courseLocation: props.courseLocation,
        courseDate: formatDate(props.courseDate),
        isActivated: props.isActivated != null ? props.isActivated : false,
        flag: props.flag,
        courseReferenceId: props.courseReferenceId,
        courseStartDate: formatDate(props.courseStartDate),
        courseEndDate: formatDate(props.courseEndDate),
        courseGroup: props.courseGroup,
        refCourseCount: props.refCourseCount,
        courseClassification: props.courseClassifications,
        courseLink: props.courseLink,
        courseDescription: props.courseShortDesc,
        removedCourseClassifications: props.removedCourseClassifications
    }
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.POST, data:Data}); //call the api using ApiCall method
    const blSendStatus: boolean = result.courseRes; //store the result in userTitles array

    return blSendStatus;//return the array
}

export {fetchAllCourses, addCourse, fetchAllAuditorCourses}