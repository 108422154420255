import React from 'react';

interface UpgradeNoticeModalProps {
    show: boolean;
    onClose: () => void;
}

const UpgradeNoticeModal = (props: UpgradeNoticeModalProps) => {
    if (!props.show) return null;

    const modalOverlayStyle: React.CSSProperties = {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.7)', // Dark semi-transparent background
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000,
    };

    const modalContentStyle: React.CSSProperties = {
        backgroundColor: '#fff',
        padding: '30px',
        borderRadius: '12px',
        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)', // Modern shadow effect
        width: '450px',
        maxWidth: '90%',
        textAlign: 'center',
        color: '#333',
    };

    const titleStyle: React.CSSProperties = {
        color: '#29599e', // Blue theme color
        fontSize: '24px',
        marginBottom: '20px',
        fontWeight: 'bold',
    };

    const paragraphStyle: React.CSSProperties = {
        fontSize: '16px',
        marginBottom: '15px',
        lineHeight: '1.6',
    };

    const buttonStyle: React.CSSProperties = {
        backgroundColor: '#29599e', // Blue theme color
        color: '#fff',
        border: 'none',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
    };

    const buttonHoverStyle: React.CSSProperties = {
        backgroundColor: '#1c3f6e', // Darker blue on hover
    };

    return (
        <div style={modalOverlayStyle}>
            <div style={modalContentStyle}>
                <h2 style={titleStyle}>System Upgrade Notice</h2>
                <p style={paragraphStyle}>
                    A scheduled system upgrade is set to commence on Wednesday, 18th September, 2024, at 4 PM (AEST).
                </p>
                <p style={paragraphStyle}>
                    During this period, the system will be temporarily unavailable.
                </p>
                <p style={paragraphStyle}>
                    We anticipate the system to be fully accessible again at 8:30 AM (AEST) on Thursday.
                </p>
                <button
                    style={buttonStyle}
                    onMouseOver={(e) => (e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor as string)}
                    onMouseOut={(e) => (e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor as string)}
                    onClick={props.onClose}
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default UpgradeNoticeModal;
