
import {processreportReq} from '../../../../models/apiRequest/mis/ProcessReportReq'

//Import url from config file
import ApiCallAxio from "../../../../framework/common/apiCallAxio";
import APICallMethods from "../../../../framework/types/APICallMethods";

import moment from "moment";

const baseUrl = process.env.REACT_APP_API_BASEURL;
const baseUrl2 = process.env.REACT_APP_API_BASEURL2;
const sublink = process.env.REACT_APP_API_SUBLINK;
const version = process.env.REACT_APP_API_VERSION;

//to get the list of all the contact logs.
const RMSAuditorsperRegionReportPdf = async () => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/reports/rmsauditorsperregionreportpdf`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objresult:string = result; //store the result in userTitles array
    return objresult;//return the array
}

const RMSAuditorsperRegionReportExcel = async () => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/reports/rmsauditorsperregionreportexcel`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objresult:string = result; //store the result in userTitles array
    return objresult;//return the array
}

const OtherAuditorsperRegionReportPdf = async () => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/reports/otherauditorsperregionreportpdf`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objresult:string = result; //store the result in userTitles array
    return objresult;//return the array
}

const OtherAuditorsperRegionReportExcel = async () => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/reports/otherauditorsperregionreportexcel`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objresult:string = result; //store the result in userTitles array
    return objresult;//return the array
}


export {RMSAuditorsperRegionReportPdf, RMSAuditorsperRegionReportExcel, OtherAuditorsperRegionReportPdf, OtherAuditorsperRegionReportExcel };