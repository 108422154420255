import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import 'fomantic-ui-css/semantic.css';
import './assets/scss/theme.scss';
import { authRoutes, guestRoutes } from "./routes/RouteList";
import { getSessionCookie } from "./framework/common/SessionHandler";
import { SessionContext } from './models/context/SessionContext';
import axios from 'axios';

function App() {
    const navigate = useNavigate();
    const [session, setSession] = useState(getSessionCookie());
    const [clientIP, setClientIP] = useState<string | null>(null);
    const [maintenanceCheckDone, setMaintenanceCheckDone] = useState(false); // Track when the maintenance check is complete
    const config = window.config;

    // Function to fetch the client's IP address
    // const fetchClientIP = async () => {
    //     try {
    //         const response = await axios.get('https://api64.ipify.org?format=json'); // Fetch client IP
    //         setClientIP(response.data.ip);
    //     } catch (error) {
    //         console.error('Failed to fetch IP address', error);
    //     }
    // };

    // useEffect(() => {
    //     fetchClientIP(); // Fetch IP address on component mount
    // }, []);

    useEffect(() => {
        // if (!clientIP) {
        //     return; // Wait until the clientIP is fetched
        // }

        // const allowedIPs = process.env.REACT_APP_ALLOWED_IPS?.split(',') || [];
        // const allowedIPsFromConfig = config.AllowedIpsUnderMaintenance?.split(',') || [];

        // console.log('allowedIPsFromConfig', allowedIPsFromConfig);
        // console.log('clientIP', clientIP);

        // // If in maintenance mode and IP is not in the allowed list
        // if ((process.env.REACT_APP_MAINTENANCE === '1' || config.MaintenanceMode === true)) {
        //     if (!allowedIPs.includes(clientIP) && !allowedIPsFromConfig.includes(clientIP)) {
        //         navigate('/maintenance'); // Redirect to maintenance
        //     }
        // }

        setSession(getSessionCookie());
        setMaintenanceCheckDone(true); // Mark the maintenance check as complete
    }, []);

    // Only render the main content when maintenance check is complete
    // if (!maintenanceCheckDone) {
    //     return null; // Return nothing until the check is done
    // }

    return (
        <SessionContext.Provider value={{ session }}>
            <React.Fragment>
                <Routes>
                    {authRoutes.map((route: { url: string, widget: any }, index: number) => (
                        <Route key={index} path={route.url}
                               element={session ? <route.widget/> :
                                   <Navigate key={index} to={'/'}/>}/>
                    ))}
                    {guestRoutes.map((route: { url: string, widget: any }, index: number) => (
                        <Route key={index} path={route.url} element={<route.widget/>}/>
                    ))}
                </Routes>
            </React.Fragment>
        </SessionContext.Provider>
    );
}

export default App;
