import React, {useCallback, useEffect, useState} from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import {Button, Grid, GridColumn} from "semantic-ui-react";
import Recaptcha from "../../framework/common/Recaptch";
import SuccessToast from "../../framework/components/Toast/SuccessToast";
import {SendMessageState} from "../../framework/types/SendMessageState";
import DefaultTextBox from "../../framework/components/TextBoxes/DefaultTextBox";
import EmailInputBox from "../../framework/components/TextBoxes/EmailInputBox";
import MultilineTextBox from "../../framework/components/TextBoxes/MultilineTextBox";
import DefaultButton from "../../framework/components/Buttons/DefaultButton";
import {SendEnquiryUs} from "./ContactUsController";
import OutLineButton from "../../framework/components/Buttons/OutlIneButton";



const ContactUsForm = () => {
  const [formData, setFormData] = useState<Record<string, string>>({});
    const [state, setState] = useState<SendMessageState>()
    const [errorState, setErrorState] = useState<SendMessageState>()
    const [disablebtn, setDisableBtn] = useState(false)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

    // useEffect(()=>{
    //     initMap();
    // },[])

  const Cancel = () =>{
    setFormData({})
  }

  const {executeRecaptcha} = useGoogleReCaptcha();

     //Google Recaptcha
     const reCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }
        const token = await executeRecaptcha('Login');
        console.log(token); // Will print the token
        sessionStorage.setItem('token', token);
    }, [executeRecaptcha]);


    const handleSubmit = async(formData: any) => {
        await reCaptchaVerify();
        const verified = await Recaptcha();
        console.log(verified)
        console.log(formData);
    };

    const validator = () =>{
        let result = true
        let errorMsg = {};
        if(state === undefined){
            setErrorState({...errorState,fullName:"Please enter full name", email:"Please enter email", message:"Please enter message", phone:"Please enter phone number"})
            return false;
        }
        if(state?.fullName === undefined || state.fullName.length < 2){
            errorMsg = {...errorMsg,fullName:"Please enter valid full name"}
            result =  false;
        }
        let regx = '^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$'
        let pattern = new RegExp(regx)

        if(state?.email === undefined || !pattern.test(state.email)){
            errorMsg = {...errorMsg,email:"Please enter valid email"}
            result =  false;
        }

        if(state?.message === undefined || state.message.length < 10){
            errorMsg = {...errorMsg, message:"Please enter valid message"}
            result =  false;
        }

        regx = "^\\({0,1}((0|\\+61|61)(\\ |-){0,1}(2|4|3|7|8)){0,1}\\){0,1}(\\ |-){0,1}[0-9]{2}(\\ |-){0,1}[0-9]{2}(\\ |-){0,1}[0-9]{1}(\\ |-){0,1}[0-9]{3}$"
        pattern = new RegExp(regx)
        if(state?.phone  === undefined || !pattern.test(state.phone) ){
            errorMsg = {...errorMsg, phone:"Please enter valid phone"}
            result =  false;
        }
        setErrorState(errorMsg)
        return result
    }
    const SendMessage = () => {
        setDisableBtn(true)
        if(!validator()){
            setDisableBtn(false)
            return

        }
        if(state){
            const sendMsg = SendEnquiryUs(state)
            Promise.resolve(sendMsg).then((value)=>{
                if(value){
                    SuccessToast('The message has been successfully sent')
                    setDisableBtn(false)
                    setState({message:'',phone:'',company:'',email:'',position:'',fullName:''})
                    setErrorState({message:'',phone:'',company:'',email:'',position:'',fullName:''})
                }
            })
        }

    }

    ////const [formdata, setFormData] = useState({});
    
    const handleCancel = () => {setFormData({}) };

    let map: google.maps.Map;
    const center: google.maps.LatLngLiteral = {lat: 30, lng: -110};

    function initMap(): void {
        map = new window.google.maps.Map(document.getElementById("map") as HTMLElement, {
            center,
            zoom: 8
        });
    }

  return (
    
      <Grid columns={2}>
          <Grid.Column width={8} widescreen={8} computer={8} tablet={16} mobile={16}>
              <Grid>
                  <Grid.Row columns={1} centered>
                      <Grid.Column>
                          <DefaultTextBox
                              id={'txtName'}
                              fluid
                              placeholder={'Full Name'}
                              value={state?.fullName}
                              onChange={(e: any) => {
                                  setState({...state, fullName: e.target.value});
                              }}
                              onerror={errorState?.fullName}
                              disabled={disablebtn}
                          />
                      </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={1} centered>
                      <Grid.Column>
                          <EmailInputBox
                              id={'txtEmailAddress'}
                              fluid
                              placeholder={'Email Address'}
                              value={state?.email}
                              onChange={(e: any) => {
                                  setState({...state, email: e.target.value});
                              }}
                              onerror={errorState?.email}
                              disabled={disablebtn}
                          />
                      </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={1} centered>
                      <Grid.Column>
                          <DefaultTextBox
                              id={'txtPhone'}
                              fluid
                              placeholder={'Phone Number'}
                              value={state?.phone}
                              onChange={(e: any) => {
                                  setState({...state, phone: e.target.value});
                              }}
                              onerror={errorState?.phone}
                              disabled={disablebtn}
                          />
                      </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={1} centered>
                      <Grid.Column>
                          <MultilineTextBox
                              id={'txtMsg'}
                              fluid
                              placeholder={'Message'}
                              wordCount={false}
                              value={state?.message}
                              onChange={(e: any) => {
                                  setState({...state, message: e.target.value});
                              }}
                              onerror={errorState?.message}
                              disabled={disablebtn}
                          />
                      </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={3} >
                      <Grid.Column width={11} mobile={4} computer={11} widescreen={11} largeScreen={11} tablet={8}/>

                      <Grid.Column width={4} mobile={12} computer={4} widescreen={4} largeScreen={4} tablet={8} className={'button-flex'}>
                          <Button basic outline={1} style={{width:'150px'}} content='blue' id={'btnSend'} onClick={SendMessage} color='blue' className={"background-transaparent"} disabled={disablebtn}>{disablebtn ? 'Wait': 'Send'}</Button>
                          <Button basic outline={1} style={{width:'150px'}} content='red' id={'btnCancel'} onClick={()=>{
                              setDisableBtn(false)
                              setState({message:'',phone:'',company:'',email:'',position:'',fullName:''})
                              setErrorState({message:'',phone:'',company:'',email:'',position:'',fullName:''})
                          }} color='red' className={"background-transaparent-red"} disabled={disablebtn}>Clear</Button>
                      </Grid.Column>
                      <Grid.Column width={3} mobile={6} computer={3} widescreen={3} largeScreen={3} tablet={4}  className={'button-flex'}>

                      </Grid.Column>
                  </Grid.Row>
              </Grid>
          </Grid.Column>
        <Grid.Column width={8} mobile={16} computer={8} widescreen={8} largeScreen={8} tablet={16}>
          <div className="contact-us-form-info">
            <p>Our contact details are as follows:<br />
                <br />
              Managed Training Services<br />
              <a href="https://goo.gl/maps/qCet14VUXHXw1rYM7" target="_blank" rel="noopener noreferrer">Level 13, 345 George Street<br />
                Sydney NSW 2000<br /> </a>
              <a href="tel:1300361787"> Phone: 1300 361 787<br /> </a>
              <a href="mailto:register@roadsafetyregister.com.au"> Email: register@roadsafetyregister.com.au<br /> </a>
            </p>
              <div id="map">
                  <iframe style={{border:'none'}}
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d828.2112924938937!2d151.20613242855958!3d-33.86788088476704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12ae40837640cb%3A0xbb29e95a259293a1!2sLevel%2013%2F345%20George%20St%2C%20Sydney%20NSW%202000%2C%20Australia!5e0!3m2!1sen!2slk!4v1690189835667!5m2!1sen!2slk"
                      width="100%" height="220"  loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"></iframe>
              </div>
          </div>
        </Grid.Column>
      </Grid>
    
  );
};

export default ContactUsForm;