import React, {useEffect, useState} from 'react'
import {Grid} from "semantic-ui-react";
import RSATable from "../../framework/widgets/TableWithBackgrounds/RSATable";
import TableHeader from "../../framework/types/TableHeader";
import {getSessionCookie} from "../../framework/common/SessionHandler";
import OutLineButton from "../../framework/components/Buttons/OutlIneButton";
import Position from "../../framework/types/Position";
import NameWithMultiline from "../../framework/widgets/NameWithText/NameWithMuliLine";
import moment from "moment/moment";
import {IAdminNotifications, IAuditLogs} from "../../models/apiResponce/Notification/GetNotifications";
import {AddAuditLogs, AddLogfromAdmin, GetAdminNotifications, GetAuditLogs} from "../auditor/profile/ProfileController";

interface Props {
    temporaryLogs: IAuditLogs[],
    setTemporaryLogs: any

    // any props that come into the component
}

const AuditAdminLog = ({ temporaryLogs, setTemporaryLogs }: Props) => {
    const [auditorId, setAuditorId] = useState<any>();
    const session = getSessionCookie();
    const encid = new URLSearchParams(location.search).get('id')
    const [auditorStatus, SetAuditorStatus] = useState(0)
    const [HistoryDet, setHistoryDet] = useState<object[]>([])
    const [totalPages,setTotalPages] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [logDesc, setLogDesc] = useState('')
    const HistoryHead: TableHeader[] = [
        {
            text: "Date/Time"
        },
        {
            text: "Note"
        }
    ];
    useEffect(()=>{
        fetchAdminLogs()
    },[])

    const fetchAdminLogs = () => {
        if(encid) {
            const getAuditLogHistry = GetAuditLogs(parseInt(encid))
            Promise.resolve(getAuditLogHistry).then((r) => {
                SetValuesHistory(r)
            })
        }
    }

    useEffect(() => {
        console.log(temporaryLogs);
    }, [temporaryLogs]);

    const SetValuesHistory = (Assesment: IAuditLogs[]) => {
        const TableRows: object[] = [];
        Assesment.map((raw: IAuditLogs) => {
            TableRows.push([
                {
                    tag: "text",
                    text: moment(raw.addedDate).format("DD-MM-YYYY hh:mm:ss A"),
                    icon: "",
                    onclick: null
                },
                {
                    tag: "text",
                    text: raw.description,
                    icon: "",
                    onclick: null
                }
            ])})
        setHistoryDet(TableRows);
    }

    const SaveLogComment = () => {
        if(logDesc !== "") {
            if(encid) {
                const getAssesmentHistry = AddAuditLogs(parseInt(encid), logDesc)
                Promise.resolve(getAssesmentHistry).then((r) => {
                    SetValuesHistory(r)
                    setLogDesc('')
                })
            } else {
                const newLogEntry: IAuditLogs = {
                    description: logDesc,
                    addedDate: (new Date()).toString(),
                    // Initialize other properties as needed
                };
        
                const newLogs: IAuditLogs[] = [...temporaryLogs, newLogEntry];
        
                // Update temporary logs
                setTemporaryLogs(newLogs);
        
                // Update the display with the new logs
                SetValuesHistory(newLogs);
                setLogDesc('');
            }
        }
    }

    return (
        <React.Fragment>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <h1 className="ui center aligned icon header red-title">Admin Notes Log </h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <NameWithMultiline id={'txtAddNotes'}
                                           labelText={'Add Notes'}
                                           type={'text'}
                                           className={'other_exp'}
                                           wordCount={false}
                                           labelPosition={Position.Top}
                                           required={true}
                                           fluid={false}
                                           value={logDesc}
                                           onChange={(e:any)=>{
                                               setLogDesc(e.target.value)
                                           }}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column>
                        <div className={'button-flex'} style={{justifyContent:'start'}}>
                            <OutLineButton id={'btnAddLog'} style={{width: '150px'}} onClick={() => {
                                SaveLogComment()
                            }} color='blue' className={"background-transaparent"} text={'Add Log'}  disabled={false}/>
                        </div>
                    </Grid.Column>
                    <Grid.Column>

                    </Grid.Column>
                    <Grid.Column>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <h2 style={{display: 'inline-flex'}}>Notes History</h2>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        {HistoryDet.length > 0 ?  (<div style={{overflow: 'auto'}}>
                            <RSATable
                                head={HistoryHead}
                                body={HistoryDet}
                                //pageCount={totalPages/5}
                                //onChange={onChange}
                                //current={currentPage}
                            />
                        </div>): "No Notes"}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <br/>
            <hr/>
        </React.Fragment>
    )
}

export default AuditAdminLog
