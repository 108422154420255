import React, {useEffect, useState} from "react";
import {Button, Container, Grid, Icon} from "semantic-ui-react";
import DefaultCheckBox from "../../components/CheckBox/DefaultCheckBox";
import NameWithCheckBox from "../NameWithCheckBox/NameWithCheckBox";
import parse from 'html-react-parser'
import MultilineTextBox from "../../components/TextBoxes/MultilineTextBox";
import {getSessionCookie} from "../../common/SessionHandler";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import Modal from "../../../framework/components/Modals";
import waringImage from "../../../assets/images/icons/warning.png";
import OutLineButton from "../../components/Buttons/OutlIneButton";
import DefaultButton from "../../components/Buttons/DefaultButton";

interface InfoToggleProps {
    type: string,
    msg: string,
    update:string,
    update2:string,
    msg2: string,
    id: string,
    id2: string,
    value?: any,
    value2?: any,
    onChange?: any
    onChange2?: any,
    createDate?:any
    createDate2?:any
    responceDate?:any
    responceDate2?:any
    onMsgBoxChange?:any,
    onMsgBoxChange2?:any,
    isConfirmedByAdminOnDate?:any,
    createdBy?:any,
    createdBy2?:any,
}

const InfoToggle = (props: InfoToggleProps) => {
    const adminColor = "#FFE852";
    const assesorColor = "#91A2B4";
    const [bgColor, setBgColor] = useState('AdminInfoToggle')
    const [msg, setMsg] = useState('')
    const [msg1, setMsg1] = useState('')
    const [msg2, setMsg2] = useState('')
    const [adminBoxEnable, SetAdminBoxEnable] = useState(false)
    const [checkboxEnable, SetCheckboxEnable] = useState(false)
    const Session = getSessionCookie()
    const encid = new URLSearchParams(location.search).get('get')
    const [editOrAddClick, SetEditOrAddClick] = useState(false)
    const [inputValue, setInputValue] = useState(props.msg);
    const [open2, setOpen2] = useState(false);
    const [assessor1, setAssessor1] = useState('')
    const [assessor2, setAssessor2] = useState('')
    const handleInputChange = (event:any) => {
        setInputValue(event.target.value); // Update local state only
        props.onMsgBoxChange(event.target.value)
        // Optionally call a debounced props.onMsgBoxChange to reduce frequency of updates
    };

    useEffect(() => {
        setInputValue(props.msg); // Only update from props when actually changed
    }, [props.msg, props.createDate]);

    useEffect(() => {
        console.log('props.msg ', props.msg); // Only update from props when actually changed
    }, [props.msg]);

    useEffect(() => {
        SetAdminBoxEnable((Session.userRoleId == 1 || Session.userRoleId == 4) && ! props.msg)
        console.log('adminBoxEnable ', adminBoxEnable);
        SetCheckboxEnable(Session.userRoleId != 1 && Session.userRoleId != 4)
    }, []);

    useEffect(()=>{
        // SetAdminBoxEnable((Session.userRoleId == 1 || Session.userRoleId == 4) && ! props.value)

        //eslint-disable-next-line
        let tempMsg = props.msg

        //eslint-disable-next-line
        let tempMsg2 = props.msg2

        //eslint-disable-next-line
        let tempAssessor = props.createdBy

        //eslint-disable-next-line
        let tempAssessor2 = props.createdBy2

        /* if(tempMsg2.includes('\r\n')){
            tempMsg2 = tempMsg2.replace('\r\n','<br/>')
        }
        if(tempMsg.includes('\r\n')){
            tempMsg = tempMsg.replace('\r\n','<br/>')
        }*/


        setMsg1(tempMsg)
        setMsg2(tempMsg2)


      if (Session.userRoleId === 1) {
          setAssessor1(tempAssessor ? tempAssessor : 'Assessor 1 Request');
          setAssessor2(tempAssessor2 ? tempAssessor2 : 'Assessor 2 Request');
      } else {
          setAssessor1('Assessor 1 Request');
          setAssessor2('Assessor 2 Request');
      }
        //console.log("Infotoglle Props: ", props)

    },[props])

    const handleEditClick = () => {
        SetEditOrAddClick(true)
        SetAdminBoxEnable(true);
        if(props.createDate && props.value){
            props.onChange(false);
        }
    };

    const handleAddClick = () => {
        setOpen2(false)
        SetEditOrAddClick(true)
        SetAdminBoxEnable(true);
        if(props.createDate && props.value){
            props.onChange(false);
        }
        props.onMsgBoxChange('');
    };

    return (
        <React.Fragment>
            <Container fluid style={{backgroundColor: props.type == 'AdminInfoToggle' ? adminColor : assesorColor}}>
                {props.type == 'AdminInfoToggle' ? (
                    <Grid style={{padding: '30px'}}>
                        <Grid.Row columns={2}>
                            {!checkboxEnable ? (
                                <Grid.Column width={8} mobile={16} tablet={16} widescreen={8} largeScreen={8} computer={8}>
                                    <h3 style={{width: '90%'}}>Message to Auditor:</h3>
                                    <p>Enter details of the information you need from the Auditor here.</p>
                                    {((props.createDate && props.createDate != 'Invalid date') || (props.isConfirmedByAdminOnDate && props.isConfirmedByAdminOnDate != 'Invalid date')) ? (<p style={{lineHeight:'0.1rem'}}>Date requested : {props.isConfirmedByAdminOnDate && props.isConfirmedByAdminOnDate != 'Invalid date' ? props.isConfirmedByAdminOnDate : props.createDate} </p>) : null }
                                    {props.responceDate && props.responceDate != 'Invalid date' ? (<p style={{lineHeight:'0.1rem'}}>Date confirmed by Auditor : {props.responceDate} </p>) : null }
                                </Grid.Column>
                            ) : (
                                <Grid.Column width={8} mobile={16} tablet={16} widescreen={8} largeScreen={8} computer={8}>
                                    <h3 style={{width: '90%'}}>Message from the Administrator</h3>
                                    <br/>

                                    <p>Please review the comments made from the Administrator regarding your registration. More
                                    information may be required from you to meet the eligibility criteria for your selected
                                    level of accreditation.</p>
                                    <p style={{width: '90%', color: '#CD123D'}}><i>Please tick to confirm that you have provided the
                                    requested information required for your auditor profile assessment.</i></p>
                                </Grid.Column>
                            ) }
                        <Grid.Column width={8} mobile={16} tablet={16} widescreen={8} largeScreen={8} computer={8}>
                                {
                                    ((Session.userRoleId == 1 || Session.userRoleId == 4) && (editOrAddClick || props.msg)) ? (
                                        <>
                                            <div style={{ float: 'right' }}>
                                            <div style={{ float: 'right' }}>
                                                <Button onClick={handleEditClick} style={{ padding: '0', backgroundColor: 'transparent', border: 'none' }}>
                                                    <FontAwesomeIcon
                                                        icon={faPen}
                                                        onClick={handleEditClick}
                                                        title="Edit message"  // Tooltip text
                                                        style={{
                                                            cursor: 'pointer',
                                                            margin: '0 5px',
                                                            color: 'rgb(72, 151, 211)',
                                                            fontSize: '20px'
                                                        }}
                                                    />
                                                </Button>
                                                <Button onClick={() => {
                                                    setOpen2(true)
                                                }} style={{ padding: '0', backgroundColor: 'transparent', border: 'none' }}>
                                                    <FontAwesomeIcon
                                                        icon={faPlus}
                                                        onClick={() => {
                                                            setOpen2(true)
                                                            }
                                                        }
                                                        title="Clear and add a new message"  // Tooltip text
                                                        style={{
                                                            cursor: 'pointer',
                                                            margin: '0 5px',
                                                            color: 'rgb(72, 151, 211)',
                                                            fontSize: '20px'
                                                        }}
                                                    />
                                                </Button>
                                            </div>
                                            </div><div>&nbsp;</div>
                                        </>
                                    ):null
                                }
                                <MultilineTextBox
                                    id={props.id+'1'}
                                    disabled={!adminBoxEnable}
                                    value={props.msg}
                                    fluid={true}
                                    wordCount={false}
                                    style={{
                                        width: '100%',
                                        /*lineHeight: '3',*/
                                        backgroundColor: adminBoxEnable ? '#fff':'#e5e5e5',
                                        paddingLeft: '20px',
                                        paddingRight: '20px',
                                        paddingTop: '20px',
                                        height: '100px',
                                        borderRadius:'0px'
                                    }}
                                    onChange={props.onMsgBoxChange}
                                />
                            {(encid != null &&  (Session.userRoleId == 1 || Session.userRoleId == 4)) ?
                                (
                                    <NameWithCheckBox id={props.id} style={{width: '90%', marginTop:'10px'}} type={'checkbox'}
                                                label={!checkboxEnable ? 'Auditor has updated profile as requested.' : 'I confirm that I have updated my profile with the requested information.'}
                                                value={props.value} disabled={!checkboxEnable} isAddDisableStyle={Session.userRoleId == 1 || Session.userRoleId == 4} />):null}

                            {(props.msg.length > 0 &&  Session.userRoleId != 1 && Session.userRoleId != 4) ?
                                (
                                    <NameWithCheckBox id={props.id} style={{width: '90%', marginTop:'10px'}} type={'checkbox'}
                                                label={!checkboxEnable ? 'Auditor has updated profile as requested.' : 'I confirm that I have updated my profile with the requested information.'}
                                                value={props.value} onChange={props.onChange} disabled={!checkboxEnable} isAddDisableStyle={Session.userRoleId == 1 || Session.userRoleId == 4} />):null}
                        </Grid.Column>
                        </Grid.Row>
                    </Grid>) :
                    (
                        <Grid style={{padding: '20px'}}>
                            <Grid.Row>
                                <Grid.Column width={8} mobile={16} tablet={16} widescreen={8} largeScreen={8} computer={8}>
                                    <h3 style={{width: '90%'}}>Message from the Assessor</h3>
                                    <br/>

                                    <p>Please review the comments made from the Assessor regarding your
                                        registration. More information may be required from you to meet the eligibility
                                        criteria for your selected level of accreditation.</p>
                                    <p style={{width: '90%', color: '#CD123D'}}><i>Please tick to confirm that you have
                                        provided the requested information required for your auditor profile
                                        assessment.</i></p>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2}>
                                <Grid.Column width={8} widescreen={8} mobile={16} tablet={16} computer={8}>
                                    <><h3 style={{width: '90%'}}>{assessor1}</h3>
                                    <p  style={{
                                        width: '100%',
                                        backgroundColor:'#ffffff',
                                        paddingTop: '8px',
                                        paddingBottom: '8px',
                                        paddingLeft: '5px',
                                        paddingRight: '5px',
                                        minHeight: '40px',
                                        marginBottom:'-10px',
                                    }}>Last Updated : {props.update} </p>
                                        <MultilineTextBox
                                            id={props.id+'1'}
                                            disabled={true}
                                            fluid={true}
                                            value={msg1}
                                            wordCount={false}
                                            style={{
                                                width: '100%',
                                                /*lineHeight: '3',*/
                                                backgroundColor: '#e5e5e5',
                                                paddingLeft: '10px',
                                                paddingRight: '10px',
                                                paddingTop: '20px',
                                                height: '100px',
                                                borderRadius:'0px'
                                            }}
                                        />
                                    {props.msg.length > 0 && (props.msg != "Please note the assessor has received information and is working on the assessment." && props.msg != "Please note the assessor has completed the assessment.") ? (<NameWithCheckBox id={props.id} style={{width: '90%', marginTop:'10px'}} type={'checkbox'}
                                                      label={'I confirm that I have updated my profile with the requested information'}
                                                      value={props.value} onChange={props.onChange}/>) : null}</>
                                </Grid.Column>
                                <Grid.Column width={8} widescreen={8} mobile={16} tablet={16} computer={8}>
                                    <><h3 style={{width: '90%'}}>{assessor2}</h3>
                                    <p style={{
                                        width: '100%',
                                        backgroundColor:'#ffffff',
                                        paddingTop: '8px',
                                        paddingBottom: '8px',
                                        paddingLeft: '5px',
                                        paddingRight: '5px',
                                        minHeight: '40px',
                                        marginBottom:'-10px'
                                    }}>Last Updated : {props.update2} </p>
                                        <MultilineTextBox
                                            id={props.id+'2'}
                                            disabled={true}
                                            fluid={true}
                                            value={msg2}
                                            wordCount={false}
                                            style={{
                                                width: '100%',
                                                /*lineHeight: '3',*/
                                                backgroundColor: '#e5e5e5',
                                                paddingLeft: '10px',
                                                paddingRight: '10px',
                                                paddingTop: '20px',
                                                height: '100px',
                                                borderRadius:'0px'
                                            }}
                                        />
                                    {props.msg2.length > 0 && (props.msg2 != "Please note the assessor has received information and is working on the assessment." && props.msg2 != "Please note the assessor has completed the assessment.") ? (<NameWithCheckBox id={props.id2+ props.msg2} style={{width: '90%', marginTop:'10px'}} type={'checkbox'}
                                                      label={'I confirm that I have updated my profile with the requested information'}
                                                      value={props.value2} onChange={props.onChange2}/>) : null }</>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    )}
            </Container>
            <Modal
                    open={open2 ? open2 : false}
                    setOpen={setOpen2}
                    title={"Warning !"}
                    modalDescription={true}
                    descriptionHeader={'Are you sure you want to clear message and add new message?'}
                    descriptionBody={'This action cannot be undone.'}
                    imageUrl={waringImage}
                    imageSize='small'
                    modalAction={true}
                    ActionButtons={() => (
                        <ControllButtonReg
                            NoClick={() => {
                                setOpen2(false)
                            }}
                            YesClick={()=>handleAddClick()}
                        />
                    )}
                />
        </React.Fragment>
    );
}

export default InfoToggle;

interface ControllButtonProps {
    //eslint-disable-next-line
    NoClick: any;
    //eslint-disable-next-line
    YesClick: any;
    //eslint-disable-next-line
}

const ControllButtonReg = (props: ControllButtonProps) => {
    return (
        <React.Fragment>
            <OutLineButton id={'btnCancel'} style={{width: '150px'}} onClick={() => {
                props.NoClick()
            }} color={'red'} text={'No'}/>
            <DefaultButton id={'btnSave'} style={{width: '150px'}} onClick={() => {
                props.YesClick()
            }} color={'blue'}
                           text={'Yes'}/>
        </React.Fragment>
    )
}
