import React, {useEffect, useState} from "react";
import {Accordion, Container, Grid, Icon, Table} from "semantic-ui-react";
import InnerTitleBar from "../../../../framework/layouts/InnerTitleBar";
import {userRoleSelector} from "../../../../common/roleSelector";
import {getSessionCookie} from "../../../../framework/common/SessionHandler";
import OutLineButton from "../../../../framework/components/Buttons/OutlIneButton";
import DateRangeBox from "../../../../framework/components/TextBoxes/DateRangeBox";
import { fetchAssessorSummary} from "./AssessorActivityController";
import RSATable from "../../../../framework/widgets/TableWithBackgrounds/RSATable";
import TableHeader from "../../../../framework/types/TableHeader";
import { AssessorActivitySummersyres, AssessorResultRes} from "../../../../models/apiRequest/mis/AssesmentProcess";
import tags from "../../../../models/common/tag";
import Dropdowns from "../../../../framework/components/DropDowns";
import DropDownOptions from "../../../../framework/types/DropDownOptions";
import { fetchAssessors } from "../../../auditor/profile/ProfileController";

const AssessorActivityContainer = () =>{
    const session = getSessionCookie();
    const [recoord, setRecord] = useState<string>()
    const [activeIndex, setActiveIndex] = useState<number>(0)
    const [assessor, setAssessors] = React.useState<DropDownOptions[]>([]);
    const [assessorValue, setAssessorValue] = React.useState('');
    const [inprogressCount, setInProgressCount] = useState(0)
    const [completeCount, setCompleteCount] = useState(0)
    const [errorMessage, setErrorMessage] = useState('');
    const AssesStatHead: TableHeader[] = [
        {
            text: "ID"
        },
        {
            text: "Auditor"
        },
        {
            text: "Time"
        }
    ];

    const [tableDetinPro, setTableDetinPro] = useState<object[]>([])
    const [tableDetCom, setTableDetCom] = useState<object[]>([])
    
    const SetValues = (data:AssessorResultRes[]) => {
        const TableRows: object[] = [];
        data.map(r => {
            TableRows.push([{
                    tag: tags.text,
                    text: r.id,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.auditorName,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.timeTaken,
                    icon: "",
                    onclick: null
                }])
        })
        return TableRows;
    }

    
    // useEffect(()=>{
    //     handleProcess()
    // },[assessorValue])

    const handleProcess = async () => {
        if (!assessorValue) {
            setErrorMessage('Please select an assessor.');
            return;
        }

        setErrorMessage(''); // Clear any previous error message
        console.log('assessorValue', assessorValue);

        try {
            const result = await fetchAssessorSummary(parseInt(assessorValue));
            if (result) {
                setInProgressCount(result.inProgressCount);
                setTableDetinPro(SetValues(result.inProgressResult));
                setCompleteCount(result.complate);
                setTableDetCom(SetValues(result.completeResult));
            }
        } catch (error) {
            console.error('Error fetching assessor summary:', error);
            //setErrorMessage('An error occurred while processing. Please try again.');
        }
    };


    useEffect(()=>{
        fetchAssessorsFromDb();
    },[])
    const fetchAssessorsFromDb = () => {
        const getAssessors = fetchAssessors();
        Promise.resolve(getAssessors).then((getAssessorsvalue)=>{
            if(getAssessorsvalue){
                setAssessors(getAssessorsvalue)
            }
        })
    }

    return(
        <Container fluid={true} style={{marginTop: '14px', marginBottom: '20px'}}>
            <InnerTitleBar
                title={"Assessor Activity Report"}
                usreName={session.userDisplayName != null ? session.userDisplayName : "  "}
                userRole={userRoleSelector(session.userRoleId.toString())}
                lastLogin={session.lastLogin.toString()}
                status={userRoleSelector(session.userRoleId.toString())}
            />
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <p>Please specify the Assessor using following dropdown list and click process.</p>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={12} mobile={16} computer={12}>
                                <Dropdowns   selection={true}
                                                fluid={true}
                                                clearable={true}
                                                    id={'ddAssessor'}
                                                    options={assessor}
                                                    value={assessorValue}
                                                    onChange={(e: any, data: any) => {
                                                        setAssessorValue(data.value)
                                                    }}
                                                    placeholder={'Select Assessor'}                                             
                                />
                                </Grid.Column>
                                <Grid.Column width={4} mobile={16} computer={4} className='button-flex'>
                                    <OutLineButton id={'btnDownloadAssessmentProcessStatsReport'} className={"background-transaparent"}
                                                    style={{width: '250px', height:'36px'}} onClick={(e:any) => {
                                                        handleProcess()
                                        }} color={'blue'} text={'Process'}/>
                                        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                                    </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                    
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Accordion fluid>
                            {inprogressCount > 0 ? (<><Accordion.Title 
                                            active={activeIndex==1}
                                            index={1}
                                            onClick={() => {
                                                if(activeIndex == 1){
                                                    setActiveIndex(0)
                                                }else{
                                                    setActiveIndex(1)
                                                }
                                            }}
                                            id={'1'}
                            >
                                <Grid>
                                    <Grid.Row columns={2}>
                                        <Grid.Column className={'accrdian-title'} width={15} mobile={12} tablet={13} style={{fontSize:'larger'}}>
                                            {`In Progress - ${inprogressCount}`}
                                        </Grid.Column>
                                        <Grid.Column width={1}>
                                            <Icon name='angle down'/>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>                               
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex == 1} >
                                <RSATable
                                    head={AssesStatHead}
                                    body={tableDetinPro}
                                />
                            </Accordion.Content></>) : null}
                            {completeCount > 0 ? (<><Accordion.Title 
                                            active={activeIndex == 2}
                                            index={2}
                                            onClick={() => {
                                                if(activeIndex == 2){
                                                    setActiveIndex(0)
                                                }else{
                                                    setActiveIndex(2)
                                                }
                                                
                                            }}
                                            id={'2'}
                            >
                                <Grid>
                                    <Grid.Row columns={2}>
                                        <Grid.Column className={'accrdian-title'} width={15} mobile={12} tablet={13} style={{fontSize:'larger'}}>
                                            {`Completed - ${completeCount}`}
                                        </Grid.Column>
                                        <Grid.Column width={1}>
                                            <Icon name='angle down'/>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>                               
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex == 2} >
                                <RSATable
                                    head={AssesStatHead}
                                    body={tableDetCom}
                                />
                            </Accordion.Content></>):null}
                        </Accordion>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>

                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>

                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
}

export default AssessorActivityContainer
