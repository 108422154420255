
import {processreportReq} from '../../../../models/apiRequest/mis/ProcessReportReq'

//Import url from config file
import ApiCallAxio from "../../../../framework/common/apiCallAxio";
import APICallMethods from "../../../../framework/types/APICallMethods";

import moment from "moment";

const baseUrl = process.env.REACT_APP_API_BASEURL;
const baseUrl2 = process.env.REACT_APP_API_BASEURL2;
const sublink = process.env.REACT_APP_API_SUBLINK;
const version = process.env.REACT_APP_API_VERSION;

//to get the list of all the contact logs.
const fetchAdminReport = async (fromDate : string, toDate : string) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/reports/adminreport?fromDate=${fromDate}&toDate=${toDate}`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.POST}); //call the api using ApiCall method
    const objresult:string = result; //store the result in userTitles array
    return objresult;//return the array
}

const fetchAssessorReport = async (fromDate : string, toDate : string) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/reports/assessorreport?fromDate=${fromDate}&toDate=${toDate}`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.POST }); //call the api using ApiCall method
    const objresult:string = result; //store the result in userTitles array
    return objresult;//return the array
}

const fetchAuditorReport = async (fromDate : string, toDate : string) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/reports/auditorreport?fromDate=${fromDate}&toDate=${toDate}`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.POST }); //call the api using ApiCall method
    const objresult:string = result; //store the result in userTitles array
    return objresult;//return the array
}


export {fetchAdminReport, fetchAssessorReport, fetchAuditorReport };