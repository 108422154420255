import React, {useEffect, useState} from "react";
import {Container, Grid, Table} from "semantic-ui-react";
import InnerTitleBar from "../../../../framework/layouts/InnerTitleBar";
import {userRoleSelector} from "../../../../common/roleSelector";
import {getSessionCookie} from "../../../../framework/common/SessionHandler";
import OutLineButton from "../../../../framework/components/Buttons/OutlIneButton";
import DateRangeBox from "../../../../framework/components/TextBoxes/DateRangeBox";
import moment from "moment";
import { fetchAdminReport, fetchAssessorReport, fetchAuditorReport } from "./LoginActivityReportController";

const LoginActivityReportContainer = () =>{
    const session = getSessionCookie();
    const AuditorId = new URLSearchParams(location.search).get('AuditorId');
    const name = new URLSearchParams(location.search).get('name');
    const[txtName, setTxtName] = useState('')
    const [current, setCurrent] = useState(1);
    const [data, setData] = useState<any>([]);
    const [recoord, setRecord] = useState(0)
    const [dateRange, setDateRange] = useState('')

    const generateAdminReport = async() => {

        const dates = dateRange.split('-')
        const fromDate = moment(moment(dates[0], "DD/MM/YYYY").toString()).format("YYYY-MM-DD")
        const toDate = moment(moment(dates[1], "DD/MM/YYYY").toString()).format("YYYY-MM-DD")
    
        const report = await fetchAdminReport(fromDate, toDate)
        const linkSource = `data:application/pdf;base64,${report}`;
        const downloadLink = document.createElement("a");
        const fileName = "AdminLoginReport.pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    const generateAssessorReport = async() => {

        const dates = dateRange.split('-')
        const fromDate = moment(moment(dates[0], "DD/MM/YYYY").toString()).format("YYYY-MM-DD")
        const toDate = moment(moment(dates[1], "DD/MM/YYYY").toString()).format("YYYY-MM-DD")
    
        const report = await fetchAssessorReport(fromDate, toDate)
        const linkSource = `data:application/pdf;base64,${report}`;
        const downloadLink = document.createElement("a");
        const fileName = "AssessorLoginReport.pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    const generateAuditorReport = async() => {

        const dates = dateRange.split('-')
        const fromDate = moment(moment(dates[0], "DD/MM/YYYY").toString()).format("YYYY-MM-DD")
        const toDate = moment(moment(dates[1], "DD/MM/YYYY").toString()).format("YYYY-MM-DD")
    
        const report = await fetchAuditorReport(fromDate, toDate)
        const linkSource = `data:application/pdf;base64,${report}`;
        const downloadLink = document.createElement("a");
        const fileName = "AuditorLoginReport.pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    return(
        <Container fluid={true} style={{marginTop: '14px', marginBottom: '20px'}}>
            <InnerTitleBar
                title={"Login Activity Report"}
                usreName={session.userDisplayName != null ? session.userDisplayName : "  "}
                userRole={userRoleSelector(session.userRoleId.toString())}
                lastLogin={session.lastLogin.toString()}
                status={userRoleSelector(session.userRoleId.toString())}
            />
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <p><b>Login Activity Report:</b></p>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={12} mobile={16} computer={12}>
                                    <>
                                        <DateRangeBox
                                            id={'txtFromDate'}
                                            fluid={true}
                                            placeholder={'From Date - To Date'}
                                            onChange={(e:any,data:any) => {setDateRange(data.value)}}
                                            value={dateRange}
                                        />
                                    </>
                                </Grid.Column>
                                <Grid.Column width={4} mobile={16} computer={4} className='button-flex'>

                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <hr/>
                        <span style={{fontSize:'14px'}}><em>NOTE: System only counts one login per day</em></span>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={12} mobile={16} computer={12}>
                                    <p>Admin Login Activity Report</p>
                                </Grid.Column>
                                <Grid.Column width={4} mobile={16} computer={4} className='button-flex'>
                                    <OutLineButton id={'btnDownloadTallyReport'} className={"background-transaparent"}
                                                   style={{width: '250px'}} onClick={(e:any) => {
                                                    generateAdminReport()
                                    }} color={'blue'} text={'Download Admin Report'}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={12} mobile={16} computer={12}>
                                    <p>Assessor Login Activity Report</p>
                                </Grid.Column>
                                <Grid.Column width={4} mobile={16} computer={4} className='button-flex'>
                                    <OutLineButton id={'btnDownloadTallyReport'} className={"background-transaparent"}
                                                   style={{width: '250px'}} onClick={(e:any) => {
                                                    generateAssessorReport()
                                    }} color={'blue'} text={'Download Assessor Report'}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={12} mobile={16} computer={12}>
                                    <p>Auditor Login Activity Report</p>
                                </Grid.Column>
                                <Grid.Column width={4} mobile={16} computer={4} className='button-flex'>
                                    <OutLineButton id={'btnDownloadTallyReport'} className={"background-transaparent"}
                                                   style={{width: '250px'}} onClick={(e:any) => {
                                                    generateAuditorReport()
                                    }} color={'blue'} text={'Download Auditor Report'}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>

                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>

                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
}

export default LoginActivityReportContainer
