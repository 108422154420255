import React, {useEffect, useState} from "react";
import InnerTitleBar from "../../../framework/layouts/InnerTitleBar";
import {Container, Grid, Input} from "semantic-ui-react";
import AdminNotification from "./AdminNotification";
import AdminMessage from "./AdminMessage";
import GeneralMsg from "./GeneralMessage";
import {userRoleSelector} from "../../../common/roleSelector";
import {fetchAdminNotification, fetchAdminNotificationAudits} from "../common/SearchController";
import {AdminNotificationCount, AdminNotificationCountAudits} from "../../../framework/types/AdminNotificationCount";
import OutLineButton from "../../../framework/components/Buttons/OutlIneButton";
import NameWithRadioButton from "../../../framework/widgets/NameWithCheckBox/NameWithRadioButton";
import {SearchApiCall} from "../../search/SearchController";
import Modals from "../../../framework/components/Modals";

const WelcomePage = ({session}: any) => {

    const [AuditorCounts, SetAuditorCounts] = useState<AdminNotificationCount>({
        newApplicationsReadyForAdmin:0,
        activeAuditors:0,
        activeAuditorsInLevelReview:0,
        activeAuditorsWithAllUnverifiedCourses:0,
        activeAuditorsWithUnconfirmedAudits:0,
        auditorsWithUnverifiedAudits:0,
        auditorUnreadComments:0,
        newApplicationsinAssessment:0,
        newApplicationsInReview:0,
        newApplicationsWithIncompleteReg:0,
    } as AdminNotificationCount)
    const [AuditsCounts, SetAuditsCounts] = useState<AdminNotificationCountAudits>({
        pendingRoadSafetyAuditsinreviewprocess:0,
        pendingRoadSafetyAuditstobeverified:0,
        verifiedAuditswithunconfirmedAuditors:0,
        verifiedRoadSafetyAudits:0,
        notrecognisedRoadSafetyAudits:0
    })

    const [loading, setLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth < 768);
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const searchApi = await fetchAdminNotification();
                const searchApiAudits = await fetchAdminNotificationAudits();
                console.log(searchApi)
                if (searchApi && searchApiAudits) {
                    SetAuditorCounts(searchApi);
                    SetAuditsCounts(searchApiAudits);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                // Set loading to false once data is fetched (either successfully or with an error)
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <Container fluid={true} style={{/*height: '750vh',*/ marginTop: '14px', marginBottom: '20px'}}>
            <InnerTitleBar
                title={"Welcome"}
                usreName={session.userDisplayName != null ? session.userDisplayName : "  "}
                userRole={userRoleSelector(session.userRoleId.toString())}
                lastLogin={session.lastLogin.toString()}
                status={userRoleSelector(session.userRoleId.toString())}
            />
            <Grid id={'thank-page'}  style={{marginBottom: '40px', marginTop: '-50px'}}>
                <Grid.Row>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>

                    </Grid.Column>
                </Grid.Row>
                {
                    isMobile ? (
                        <>
                        <Grid.Row columns={3}>
                            <Grid.Column width={2}>

                            </Grid.Column>
                            <Grid.Column width={12}>

                                <Grid>
                                    <Grid.Row columns={1}>
                                        <Grid.Column>
                                            <h1 style={{display: 'inline-flex', color: "#1559A3"}}>Notifications</h1>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>

                            </Grid.Column>
                            <Grid.Column width={2}>

                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={3}>
                        <Grid.Column width={2}>

                        </Grid.Column>
                        <Grid.Column width={12}>

                            <Grid>
                                <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <SimpleSearch/>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                        </Grid.Column>
                        <Grid.Column width={2}>

                        </Grid.Column>
                    </Grid.Row>
                    </>
                    ) : (
                        <Grid.Row columns={3}>
                            <Grid.Column width={2}>

                            </Grid.Column>
                            <Grid.Column width={12}>

                                <Grid>
                                    <Grid.Row columns={2}>
                                        <Grid.Column width={6}>
                                            <h1 style={{display: 'inline-flex', color: "#1559A3"}}>Notifications</h1>
                                        </Grid.Column>
                                        <Grid.Column width={10}>
                                            <SimpleSearch/>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>

                            </Grid.Column>
                            <Grid.Column width={2}>

                            </Grid.Column>
                        </Grid.Row>
                    )
                }
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                       {!loading && (
                            <AdminNotification
                                AuditorCounts={AuditorCounts}
                                AuditCounts={AuditsCounts}
                            />
                        )}
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <hr/>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <AdminMessage
                            AuditorCounts={AuditorCounts}
                        />
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <hr/>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <GeneralMsg/>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Modals
                  open={loading}
                  basicModal={true}
                  modalSize={'small'}
                  imageSize={'tiny'}
                  imageUrl={'https://images.emojiterra.com/google/noto-emoji/v2.034/128px/23f3.png'}
                  setOpen={setLoading}
                  modalDescription={true}
                  descriptionHeader={'Please wait ...!'}
                  descriptionBody={'Your data is loading ...'}
                  modalDimmer={"blurring"}
                  modalAction={false}
              />
        </Container>
    );
}

export default WelcomePage;


const SimpleSearch = () => {
    const [searchOption, setSearchOption] = useState('Auditors')
    const [txtName, setTxtName] = useState('')

    return(
        <Grid>
            <Grid.Row columns={1}>
                <Grid.Column>
                    <Grid stackable>
                        <Grid.Row>
                        <Grid.Column tablet={2} computer={4}></Grid.Column>
                            <Grid.Column width={16} mobile={16} tablet={6} computer={4}>
                                <NameWithRadioButton
                                    id={'rbtAuditors'}
                                    label={`Auditors`}
                                    name={'Search'}
                                    value={searchOption === 'Auditors'}
                                    onChange={() => setSearchOption('Auditors')}
                                />
                                <NameWithRadioButton
                                    id={'rbtAudits'}
                                    label={`Audits`}
                                    name={'Search'}
                                    value={searchOption === 'Audits'}
                                    onChange={() => setSearchOption('Audits')}
                                />
                            </Grid.Column>
                            <Grid.Column width={16} mobile={16} tablet={8} computer={6}>
                                <div className="ui action input button-flex" style={{ width: '100%' }}>
                                    <input type="text" placeholder={`${searchOption} Search...`} value={txtName} onChange={(e) => setTxtName(e.target.value)} />
                                    <OutLineButton
                                        id={'cmbApplyLevelReview'}
                                        onClick={() => window.location.href = `/search/auditors?status=${searchOption === 'Auditors' ? 999 : 99}&name=${txtName}`}
                                        color='blue'
                                        className={"background-transaparent"}
                                        text={'Search'}
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}