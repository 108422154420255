import React, { useState, useEffect } from 'react';
import { Grid } from "semantic-ui-react";
import OutLineButton from "../../components/Buttons/OutlIneButton";
import { ArrNotificationMenuProps, NotificationMenuProps } from "../../types/ArrNotificationMenuProps";

const NotificationMenu = (props: ArrNotificationMenuProps) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const isMobile = windowWidth < 768;

    return (
        <Grid>
            {props.Data.map((r: NotificationMenuProps, index) => (
                <Grid.Row key={index} columns={1}>
                    <Grid.Column>
                        <div style={{
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                            alignItems: isMobile ? 'center' : 'inherit',
                            justifyContent: 'space-between',
                            margin: '8px 0'
                        }}>
                            <div style={{ flex: isMobile ? '0 0 100%' : '10', textAlign: isMobile ? 'center' : 'left' }}>
                                {r.text}
                            </div>
                            <div style={{ flex: isMobile ? '0 0 100%' : '2', textAlign: isMobile ? 'center' : 'right', marginTop: isMobile ? '10px' : '0' }}>
                                {r.value}
                            </div>
                            <div style={{ flex: isMobile ? '0 0 100%' : '3', marginTop: isMobile ? '10px' : '0' }}>
                                <OutLineButton id={'cmbApplyLevelReview'} onClick={r.onClick} style={{width: '150px', height:'36px', marginLeft:'10px'}}
                                           color='blue' className={"background-transaparent"} text={'View'}
                            />
                            </div>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            ))}
        </Grid>
    );
}

export default NotificationMenu;
