import React, {useEffect} from "react";
import TextBoxes from "./index";
import DefaultTextBoxProps from "../../types/DefaultTextBoxProps";

const PasswordBox = (props: DefaultTextBoxProps) => {
    const [text, setText] = React.useState<string>('');
    useEffect(() => {
        //console.log('EmailInputBox', props.onerror);
        setText(props.onerror ? props.onerror : '');
    }, [props.onerror]);

    useEffect(() => {
        const element = document.getElementById(props.id) as HTMLInputElement;
        console.log(element.value)
        if(element){
            if(text.length > 0){
                element.style.borderColor = '#CD123D'
            }else{
                element.style.borderColor = '#e0e0e0'
            }
        }
    }, [text])

    return (
        <>
            <TextBoxes
                id={props.id}
                type={'password'}
                placeholder={props.placeholder}
                style={props.style}
                className={props.className}
                disabled={props.disabled}
                onChange={props.onChange}
                onClick={props.onClick}
                onLostFocus={props.onLostFocus}
                onKeydown={props.onKeydown}
                value={props.value}
                fluid={props.fluid}
                onPaste={(e:any)=>{e.preventDefault()}}
            />
            <span className='email-validation'>{text}</span>
        </>
    )
}

export default PasswordBox