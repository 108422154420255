import React, {useEffect} from "react";
import Header from "../../../../framework/layouts/Header";
import Footer from "../../../../framework/layouts/Footer";
import AssessorActivityContainer from "./AssessorActivityContainer";


const AssessorActivity = () => {
    return(
        <React.Fragment>
            <Header>
                <AssessorActivityContainer/>
            </Header>
            <Footer/>
        </React.Fragment>
    );
}

export default AssessorActivity