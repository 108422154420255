import React, {useEffect, useState} from "react";
import {fetchAuditStages, fetchStates} from "../auditor/profile/ProfileController";
import Header from "../../framework/layouts/Header";
import InnerTitleBar from "../../framework/layouts/InnerTitleBar";
import AboutUsText from "../aboutus/AboutUsText";
import Footer from "../../framework/layouts/Footer";
import userRoleSelector from "../../framework/common/UserSelector";
import {Container, Grid} from "semantic-ui-react";
import AuditAdminLog from "./AuditAdminLog";
import AuditParticipationConfirmation from "./AuditParticipationConfirmation";

const PgAuditParticipationConfirm = () => {
    const [token, setToken] = useState<string|null>(null)

    useEffect(() => {
        const cUrl = window.location.href
        if (cUrl.includes('?token')) {
            const urlParams = new URLSearchParams(window.location.search);
            setToken(urlParams.get('token'));
        }
    }, []);

    return (
        <>
            <Header>
                <Container fluid={true} style={{/*height: '750vh',*/ marginTop: '14px', marginBottom: '20px'}}>
                    <InnerTitleBar
                        title={'Auditor Participation Confirmation'}
                        usreName={''}
                        userRole={""}
                        lastLogin={""}
                        status={""}
                        level={""}
                        certExpDate={""}
                        newId={""}
                    />
                    <Grid>
                        <Grid.Row></Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <AuditParticipationConfirmation
                                    token={token}
                                    isEdit={true}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row></Grid.Row>
                        <Grid.Row>
                            <Grid.Column>

                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </Header>

            <Footer />

        </>
    )
}

export default PgAuditParticipationConfirm