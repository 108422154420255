import React, {useEffect} from "react";
import Header from "../../../../framework/layouts/Header";
import Footer from "../../../../framework/layouts/Footer";
import RegionReportContainer from "./RegionReportContainer";



const RegionReport = () => {
    return(
        <React.Fragment>
            <Header>
                <RegionReportContainer/>
            </Header>
            <Footer/>
        </React.Fragment>
    );
}

export default RegionReport