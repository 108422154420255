

//Import url from config file
import ApiCallAxio from "../../../../framework/common/apiCallAxio";
import APICallMethods from "../../../../framework/types/APICallMethods";

import moment from "moment";

const baseUrl = process.env.REACT_APP_API_BASEURL;
const baseUrl2 = process.env.REACT_APP_API_BASEURL2;
const sublink = process.env.REACT_APP_API_SUBLINK;
const version = process.env.REACT_APP_API_VERSION;

//to get the list of all the contact logs.
const fetchAuditorsContactLog = async (fromDate?:string,toDate?:string) => {
    const from = fromDate == "Invalid date" ? '' : `startDate=${moment(fromDate).format("MM-DD-YYYY")}`
    const to = toDate == "Invalid date" ? '' : `endDate=${moment(toDate).format("MM-DD-YYYY")}`
    const params = from.length > 1 && to.length > 1 ? `?${from}&${to}`:from.length > 1 ? `?${from}`:to.length > 1 ? `?${to}` : ''
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorProfile/GetContactLogs${params}`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objresult: result = result; //store the result in userTitles array
    return objresult;//return the array
}


interface result {
    totalRecords: number,
    logUrl: string
}




export {fetchAuditorsContactLog};