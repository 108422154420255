import React, {useEffect, useState} from "react";
import {Container, Grid, Table} from "semantic-ui-react";
import InnerTitleBar from "../../../../framework/layouts/InnerTitleBar";
import {userRoleSelector} from "../../../../common/roleSelector";
import {getSessionCookie} from "../../../../framework/common/SessionHandler";
import OutLineButton from "../../../../framework/components/Buttons/OutlIneButton";
import DateRangeBox from "../../../../framework/components/TextBoxes/DateRangeBox";
import NameWithDefault from "../../../../framework/widgets/NameWithText/NameWithDefault";
import NameWithNumber from "../../../../framework/widgets/NameWithText/NameWithNumber";
import NumberTextBox from "../../../../framework/components/TextBoxes/NumberTextBox";
import { paraProps, paraProps2, paraPropsSingle, processreportReq } from "../../../../models/apiRequest/mis/ProcessReportReq";
import { fetchProcessReport } from "./ProcessReportController";
import moment from "moment";

const ProcessReportContainer = () =>{
    const session = getSessionCookie();
    const [dateRange, setDateRange] = useState('')

    //Auditor 1
    const [auditor1Less, SetAuditor1Less] = useState("4")
    const [auditor1Between1, SetAuditor1Between1] = useState("4")
    const [auditor1Between2, SetAuditor1Between2] = useState("5")
    const [auditor1More, SetAuditor1More] = useState("5")

    //Auditor 2
    const [auditor2Less, SetAuditor2Less] = useState("2")
    const [auditor2Between1, SetAuditor2Between1] = useState("2")
    const [auditor2Between2, SetAuditor2Between2] = useState("5")
    const [auditor2More, SetAuditor2More] = useState("5")

    //Auditor 3
    const [auditor3Less, SetAuditor3Less] = useState("11")
    const [auditor3Between1, SetAuditor3Between1] = useState("11")
    const [auditor3Between2, SetAuditor3Between2] = useState("30")
    const [auditor3More, SetAuditor3More] = useState("30")

    //Auditor 4
    const [auditor4Less, SetAuditor4Less] = useState("11")
    const [auditor4Between1, SetAuditor4Between1] = useState("11")
    const [auditor4Between2, SetAuditor4Between2] = useState("30")
    const [auditor4More, SetAuditor4More] = useState("30")

    //Courses
    const [courseLess, SetCourseLess] = useState("2")
    const [courseBetween1, SetCourseBetween1] = useState("2")
    const [courseBetween2, SetCourseBetween2] = useState("10")
    const [courseMore, SetCourseMore] = useState("10")

    //Audits
    const [auditsLess, SetAuditsLess] = useState("6")
    const [auditsBetween1, SetAuditsBetween1] = useState("6")
    const [auditsBetween2, SetAuditsBetween2] = useState("15")
    const [audits2Between1, SetAudits2Between1] = useState("15")
    const [audits2Between2, SetAudits2Between2] = useState("30")
    const [auditsMore, SetAuditsMore] = useState("30")

    //AuditorLink
    const [auditorLinkLess, SetAuditorLinkLess] = useState("6")
    const [auditorLinkBetween1, SetAuditorLinkBetween1] = useState("6")
    const [auditorLinkBetween2, SetAuditorLinkBetween2] = useState("15")
    const [auditorLink2Between1, SetAuditorLink2Between1] = useState("15")
    const [auditorLink2Between2, SetAuditorLink2Between2] = useState("30")
    const [auditorLinkMore, SetAuditorLinkMore] = useState("30")

    //Assessors
    const [assessorsLess, SetAssessorsLess] = useState("4")
    const [assessorsEqual, SetAssessorsEqual] = useState("4")
    const [assessorsMore, SetAssessorsMore] = useState("4")

    const generateReport = async() => {

        const dates = dateRange.split('-')

        const auditor1:paraProps = {
            lessCount : parseInt(auditor1Less),
            btwsCount : parseInt(auditor1Between1),
            btweCount : parseInt(auditor1Between2),
            moreCount : parseInt(auditor1More)
        }

        const auditor2:paraProps = {
            lessCount : parseInt(auditor2Less),
            btwsCount : parseInt(auditor2Between1),
            btweCount : parseInt(auditor2Between2),
            moreCount : parseInt(auditor2More)
        }

        const auditor3:paraProps = {
            lessCount : parseInt(auditor3Less),
            btwsCount : parseInt(auditor3Between1),
            btweCount : parseInt(auditor3Between2),
            moreCount : parseInt(auditor3More)
        }

        const auditor4:paraProps = {
            lessCount : parseInt(auditor4Less),
            btwsCount : parseInt(auditor4Between1),
            btweCount : parseInt(auditor4Between2),
            moreCount : parseInt(auditor4More)
        }

        const course:paraProps = {
            lessCount : parseInt(courseLess),
            btwsCount : parseInt(courseBetween1),
            btweCount : parseInt(courseBetween2),
            moreCount : parseInt(courseMore)
        }

        const audit:paraProps2 = {
            lessCount : parseInt(auditsLess),
            btwsCount : parseInt(auditsBetween1),
            btweCount : parseInt(auditsBetween2),
            sbtweCount : parseInt(audits2Between2),
            sbtwsCount : parseInt(audits2Between1),
            moreCount : parseInt(auditsMore)
        }

        const auditorLink:paraProps2 = {
            lessCount : parseInt(auditorLinkLess),
            btwsCount : parseInt(auditorLinkBetween1),
            btweCount : parseInt(auditorLinkBetween2),
            sbtweCount : parseInt(auditorLink2Between2),
            sbtwsCount : parseInt(auditorLink2Between1),
            moreCount : parseInt(auditorLinkMore)
        }

        const assessor:paraPropsSingle = {
            lessCount : parseInt(assessorsLess),
            equalCount : parseInt(assessorsEqual),
            moreCount : parseInt(assessorsMore)
        }

        const processreportPara:processreportReq = {
            fromDate : moment(moment(dates[0], "DD/MM/YYYY").toString()).format("YYYY-MM-DD"),
            toDate : moment(moment(dates[1], "DD/MM/YYYY").toString()).format("YYYY-MM-DD"),
            auditorsPhase1 : auditor1,
            auditorsPhase2 : auditor2,
            auditorsPhase3 : auditor3,
            auditorsPhase4 : auditor4,
            courses : course,
            assessors: assessor,
            auditorLink : auditorLink,
            audits: audit,
        }

        const report = await fetchProcessReport(processreportPara)
        const linkSource = `data:application/pdf;base64,${report}`;
        const downloadLink = document.createElement("a");
        const fileName = "process_report.pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }


    return(
        <Container fluid={true} style={{marginTop: '14px', marginBottom: '20px'}}>
            <InnerTitleBar
                title={"Process Flow State Report"}
                usreName={session.userDisplayName != null ? session.userDisplayName : "  "}
                userRole={userRoleSelector(session.userRoleId.toString())}
                lastLogin={session.lastLogin.toString()}
                status={userRoleSelector(session.userRoleId.toString())}
            />
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <p><b>Process Flow State Report</b></p>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={12} mobile={16} computer={12}>
                                    <>
                                        <DateRangeBox
                                            id={'txtFromDate'}
                                            fluid={true}
                                            placeholder={'From Date - To Date'}
                                            onChange={(e:any,data:any) => {setDateRange(data.value)}}
                                            value={dateRange}
                                        />
                                    </>
                                </Grid.Column>
                                <Grid.Column width={4} mobile={16} computer={4} className='button-flex'>

                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <ProcessReportParaWidget
                            lessDays={auditor1Less}
                            moreDays={auditor1More}
                            between1Days={auditor1Between1}
                            between2Days={auditor1Between2}
                            title="Auditors Phase 1 - Number of days between New Registrations & Auditor records in review process:"
                            id="auditor1"
                            lessDaysOnChange={(e:any) => {
                               SetAuditor1Less(e.target.value)
                            }}
                            moreDaysOnChange={(e:any) => {
                                SetAuditor1More(e.target.value)
                            }}
                            between1OnChange={(e:any) => {
                                SetAuditor1Between1(e.target.value)
                            }}
                            between2OnChange={(e:any) => {
                                SetAuditor1Between2(e.target.value)
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <ProcessReportParaWidget
                            lessDays={auditor2Less}
                            moreDays={auditor2More}
                            between1Days={auditor2Between1}
                            between2Days={auditor2Between2}
                            title="Auditors Phase 2 - Number of days between “Pending in review process” and first Assessor allocation:"
                            id="auditor2"
                            lessDaysOnChange={(e:any) => {
                                SetAuditor2Less(e.target.value)
                             }}
                             moreDaysOnChange={(e:any) => {
                                 SetAuditor2More(e.target.value)
                             }}
                             between1OnChange={(e:any) => {
                                 SetAuditor2Between1(e.target.value)
                             }}
                             between2OnChange={(e:any) => {
                                 SetAuditor2Between2(e.target.value)
                             }}
                        />
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                    <ProcessReportParaWidget
                            lessDays={auditor3Less}
                            moreDays={auditor3More}
                            between1Days={auditor3Between1}
                            between2Days={auditor3Between2}
                            title="Auditors Phase 3 - Number of days between first Assessor allocation and Auditor status change to “Verified”:"
                            id="auditor3"
                            lessDaysOnChange={(e:any) => {
                                SetAuditor3Less(e.target.value)
                             }}
                             moreDaysOnChange={(e:any) => {
                                 SetAuditor3More(e.target.value)
                             }}
                             between1OnChange={(e:any) => {
                                 SetAuditor3Between1(e.target.value)
                             }}
                             between2OnChange={(e:any) => {
                                 SetAuditor3Between2(e.target.value)
                             }}
                        />
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                    <ProcessReportParaWidget
                            lessDays={auditor4Less}
                            moreDays={auditor4More}
                            between1Days={auditor4Between1}
                            between2Days={auditor4Between2}
                            title="Auditors Phase 4 - Number of days between first Assessor allocation and Auditor status change to “Inactive”:"
                            id="auditor4"
                            lessDaysOnChange={(e:any) => {
                                SetAuditor4Less(e.target.value)
                             }}
                             moreDaysOnChange={(e:any) => {
                                 SetAuditor4More(e.target.value)
                             }}
                             between1OnChange={(e:any) => {
                                 SetAuditor4Between1(e.target.value)
                             }}
                             between2OnChange={(e:any) => {
                                 SetAuditor4Between2(e.target.value)
                             }}
                        />
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                    <ProcessReportParaWidget
                            lessDays={courseLess}
                            moreDays={courseMore}
                            between1Days={courseBetween1}
                            between2Days={courseBetween2}
                            title="Courses - The system will record a log entry when an Auditor adds a course and the course is confirmed:"
                            id="course"
                            lessDaysOnChange={(e:any) => {
                                SetCourseLess(e.target.value)
                             }}
                             moreDaysOnChange={(e:any) => {
                                 SetCourseMore(e.target.value)
                             }}
                             between1OnChange={(e:any) => {
                                 SetCourseBetween1(e.target.value)
                             }}
                             between2OnChange={(e:any) => {
                                 SetCourseBetween2(e.target.value)
                             }}
                        />
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                    <ProcessReportParaWidgetTowBetweens
                            lessDays={auditsLess}
                            moreDays={auditsMore}
                            between1Days={auditsBetween1}
                            between2Days={auditsBetween2}
                            sbetween1Days={audits2Between1}
                            sbetween2Days={audits2Between2}
                            title="Audits - The system will record a log entry when a new audit is entered and when the audit gets verified:"
                            id="audit"
                            lessDaysOnChange={(e:any) => {
                                SetAuditsLess(e.target.value)
                            }}
                            moreDaysOnChange={(e:any) => {
                                SetAuditsMore(e.target.value)
                            }}
                            between1OnChange={(e:any) => {
                                SetAuditsBetween1(e.target.value)
                            }}
                            between2OnChange={(e:any) => {
                                SetAuditsBetween2(e.target.value)
                            }}
                            sbetween1OnChange={(e:any) => {
                                SetAudits2Between1(e.target.value)
                            }}
                            sbetween2OnChange={(e:any) => {
                                SetAudits2Between2(e.target.value)
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                    <ProcessReportParaWidgetTowBetweens
                            lessDays={auditorLinkLess}
                            moreDays={auditorLinkMore}
                            between1Days={auditorLinkBetween1}
                            between2Days={auditorLinkBetween2}
                            sbetween1Days={auditorLink2Between1}
                            sbetween2Days={auditorLink2Between2}
                            title="Auditors link to Audits - The system will record a log entry when an Auditor adds himself/herself to an audit and the link is verified:"
                            id="audit2"
                            lessDaysOnChange={(e:any) => {
                                SetAuditorLinkLess(e.target.value)
                            }}
                            moreDaysOnChange={(e:any) => {
                                SetAuditorLinkMore(e.target.value)
                            }}
                            between1OnChange={(e:any) => {
                                SetAuditorLinkBetween1(e.target.value)
                            }}
                            between2OnChange={(e:any) => {
                                SetAuditorLinkBetween2(e.target.value)
                            }}
                            sbetween1OnChange={(e:any) => {
                                SetAuditorLink2Between1(e.target.value)
                            }}
                            sbetween2OnChange={(e:any) => {
                                SetAuditorLink2Between2(e.target.value)
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <ProcessReportParaWidgetSingle
                            lessDays={assessorsLess}
                            moreDays={assessorsMore}
                            between1Days={assessorsEqual}
                            between2Days="0"
                            title="Assessors - Number of days between first Assessor allocation to assessment provided:"
                            id="assessors"
                            lessDaysOnChange={(e:any) => {
                                SetAssessorsLess(e.target.value)
                            }}
                            moreDaysOnChange={(e:any) => {
                                SetAssessorsMore(e.target.value)
                            }}
                            between1OnChange={(e:any) => {
                                SetAssessorsLess(e.target.value)
                            }}
                            between2OnChange={(e:any) => {
                                console.log('')
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={12} mobile={16} computer={12}>

                                </Grid.Column>
                                <Grid.Column width={4} mobile={16} computer={4} className='button-flex'>
                                    <OutLineButton id={'btnDownloadTallyReport'} className={"background-transaparent"}
                                                   style={{width: '250px'}} onClick={(e:any) => {
                                                    generateReport();
                                    }} color={'blue'} text={'Download the Report'}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>

                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
}

export default ProcessReportContainer

interface ProcessReportParaWidgetProps {
    title:string,
    lessDays:string,
    lessDaysOnChange:any,
    between1Days:string,
    between1OnChange:any,
    between2Days?:string,
    between2OnChange?:any,
    sbetween1Days?:string,
    sbetween1OnChange?:any,
    sbetween2Days?:string,
    sbetween2OnChange?:any,
    moreDays:string,
    moreDaysOnChange:any,
    id:string,
    disabled?:boolean

}

const ProcessReportParaWidgetSingle = (props:ProcessReportParaWidgetProps) => {

    return(
        <Grid>
            <Grid.Row columns={1}>
                    <Grid.Column width={16}>
                        <p><b>{props.title}</b></p>
                    </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={4}>
                    <Grid.Column width={5}>
                        <p>Number of days less than</p>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <NumberTextBox
                            id={`${props.id}less`}
                            disabled={props.disabled}
                            value={props.lessDays}
                            onChange={props.lessDaysOnChange}
                            />
                    </Grid.Column>
                    <Grid.Column width={1}>

                    </Grid.Column>
                    <Grid.Column width={3}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column width={5}>
                        <p>Number of days equal to</p>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <NumberTextBox
                            id={`${props.id}between1`}
                            disabled={props.disabled}
                            value={props.between1Days}
                            onChange={props.between1OnChange}
                            />
                    </Grid.Column>
                    <Grid.Column width={1}>

                    </Grid.Column>
                    <Grid.Column width={3}>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column width={5}>
                        <p>Number of days more than</p>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <NumberTextBox
                            id={`${props.id}more`}
                            disabled={props.disabled}
                            value={props.lessDays}
                            onChange={props.lessDaysOnChange}
                            />
                    </Grid.Column>
                    <Grid.Column width={1}>

                    </Grid.Column>
                    <Grid.Column width={5}>

                    </Grid.Column>
                </Grid.Row>
        </Grid>
    );
}

const ProcessReportParaWidget = (props:ProcessReportParaWidgetProps) => {

    return(
        <Grid>
            <Grid.Row columns={1}>
                    <Grid.Column width={16}>
                        <p><b>{props.title}</b></p>
                    </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={4}>
                    <Grid.Column width={5}>
                        <p>Number of days less than</p>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <NumberTextBox
                            id={`${props.id}less`}
                            disabled={props.disabled}
                            value={props.lessDays}
                            onChange={props.lessDaysOnChange}
                            //onerror={props.onErrorYrs}
                            //onLostFocus={props.onLostFocusYrs}
                            />
                    </Grid.Column>
                    <Grid.Column width={1}>

                    </Grid.Column>
                    <Grid.Column width={3}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column width={5}>
                        <p>Number of days between</p>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <NumberTextBox
                            className="process-report-number-input"
                            id={`${props.id}between1`}
                            disabled={props.disabled}
                            value={props.between1Days}
                            onChange={props.between1OnChange}
                            //onerror={props.onErrorYrs}
                            //onLostFocus={props.onLostFocusYrs}
                            />
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={3}>
                    <NumberTextBox
                            className="process-report-number-input"
                            id={`${props.id}between2`}
                            disabled={props.disabled}
                            value={props.between2Days}
                            onChange={props.between2OnChange}
                            //onerror={props.onErrorYrs}
                            //onLostFocus={props.onLostFocusYrs}
                            />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column width={5}>
                        <p>Number of days more than</p>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <NumberTextBox
                            id={`${props.id}more`}
                            disabled={props.disabled}
                            value={props.moreDays}
                            onChange={props.moreDaysOnChange}
                            //onerror={props.onErrorYrs}
                            //onLostFocus={props.onLostFocusYrs}
                            />
                    </Grid.Column>
                    <Grid.Column width={1}>

                    </Grid.Column>
                    <Grid.Column width={5}>

                    </Grid.Column>
                </Grid.Row>
        </Grid>
    );
}

const ProcessReportParaWidgetTowBetweens = (props:ProcessReportParaWidgetProps) => {

    return(
        <Grid>
            <Grid.Row columns={1}>
                    <Grid.Column width={16}>
                        <p><b>{props.title}</b></p>
                    </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={4}>
                    <Grid.Column width={5}>
                        <p>Number of days less than</p>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <NumberTextBox
                            id={`${props.id}less`}
                            disabled={props.disabled}
                            value={props.lessDays}
                            onChange={props.lessDaysOnChange}
                            //onerror={props.onErrorYrs}
                            //onLostFocus={props.onLostFocusYrs}
                            />
                    </Grid.Column>
                    <Grid.Column width={1}>

                    </Grid.Column>
                    <Grid.Column width={3}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column width={5}>
                        <p>Number of days between</p>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <NumberTextBox
                            className="process-report-number-input"
                            id={`${props.id}between1`}
                            disabled={props.disabled}
                            value={props.between1Days}
                            onChange={props.between1OnChange}
                            //onerror={props.onErrorYrs}
                            //onLostFocus={props.onLostFocusYrs}
                            />
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={3}>
                    <NumberTextBox
                            className="process-report-number-input"
                            id={`${props.id}between2`}
                            disabled={props.disabled}
                            value={props.between2Days}
                            onChange={props.between2OnChange}
                            //onerror={props.onErrorYrs}
                            //onLostFocus={props.onLostFocusYrs}
                            />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column width={5}>
                        <p>Number of days between</p>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <NumberTextBox
                            className="process-report-number-input-2"
                            id={`${props.id}sbetween1`}
                            disabled={props.disabled}
                            value={props.sbetween1Days}
                            onChange={props.sbetween1OnChange}
                            //onerror={props.onErrorYrs}
                            //onLostFocus={props.onLostFocusYrs}
                            />
                    </Grid.Column>
                    <Grid.Column width={2}>
                        {/* <p>and</p> */}
                    </Grid.Column>
                    <Grid.Column width={3}>
                    <NumberTextBox
                            className="process-report-number-input-2"
                            id={`${props.id}sbetween2`}
                            disabled={props.disabled}
                            value={props.sbetween2Days}
                            onChange={props.sbetween2OnChange}
                            //onerror={props.onErrorYrs}
                            //onLostFocus={props.onLostFocusYrs}
                            />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column width={5}>
                        <p>Number of days more than</p>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <NumberTextBox
                            id={`${props.id}more`}
                            disabled={props.disabled}
                            value={props.moreDays}
                            onChange={props.moreDaysOnChange}
                            //onerror={props.onErrorYrs}
                            //onLostFocus={props.onLostFocusYrs}
                            />
                    </Grid.Column>
                    <Grid.Column width={1}>

                    </Grid.Column>
                    <Grid.Column width={5}>

                    </Grid.Column>
                </Grid.Row>
        </Grid>
    );
}