import React, {useEffect, useState} from "react";
import {Container, Grid, Table} from "semantic-ui-react";
import {AuditorSearchResRow, AuditSearchResRow} from "../../../models/apiResponce/Auditor/SearchRes";
import {SearchReq} from "../../../models/apiRequest/Auditor/SearchReq";
import { Oval } from "react-loader-spinner";
import { SearchApiCall } from "../../search/SearchController";
import OutLineButton from "../../../framework/components/Buttons/OutlIneButton";
import InnerTitleBar from "../../../framework/layouts/InnerTitleBar";
import {Pagination, PaginationProps} from "antd";
import {userRoleSelector} from "../../../common/roleSelector";
import {getSessionCookie} from "../../../framework/common/SessionHandler";
import NotFound from "../../Warring/404";
import Header from "../../../framework/layouts/Header";
import WelcomePage from "../welcome/WelcomePage";
import Footer from "../../../framework/layouts/Footer";
import {fetchAdminNotification, SearchApiCallAdmin, SearchApiCallAdminAudits} from "./SearchController";
import { useNavigate } from "react-router-dom";


interface Props {
    //eslint-disable-next-line
    // states: any,
    // focusMode: string,
    //eslint-disable-next-line
    // setFocusMode: any,
    //eslint-disable-next-line
    // setStateRegion: any;
    searchRes:AuditorSearchResRow[];
    record:number
}

const SearchTable = () => {
    const session = getSessionCookie();
    const [isSearch, setIsSearch] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [record, setRecord] = useState<number>(0);
    const [searchRes, setSearchres] = useState<AuditorSearchResRow[]>([])
    const [searchAuditRes, setSearchAuditres] = useState<AuditSearchResRow[]>([])
    const [state, setState] = React.useState<SearchReq>({pageSize: 10, pageNumStartingZero: 0, countryStateId: 1});
    const [title, setTitle] = React.useState('Search for Auditors')
    const statCode = new URLSearchParams(location.search).get('status')
    const searchText = new URLSearchParams(location.search).get('name')

    useEffect(()=>{
        switch (statCode){
            case '200':
                setTitle('New Applications ready for Admin');
                break;
            case '210':
                setTitle('New Applications not meeting minimum criteria');
                break;
            case '220':
                setTitle('New Applications in Review');
                break;
            case '230':
                setTitle('New Applications in Assessment');
                break;
            case '240':
                setTitle('Active Auditors in Level Review');
                break;
            case '250':
                setTitle('Active Auditors');
                break;
            case '260':
                setTitle('Active Auditors with unverified audits ');
                break;
            case '270':
                setTitle('Active Auditors with no verified courses');
                break;
            case '280':
                setTitle('Active Auditors with unconfirmed audits');
                break;
            case '100':
                setTitle('Auditors with messages');
                break;
            case '99':
                setTitle('Search for Audits');
                break;
            case '0':
                setTitle('Verified audits with unlinked auditors');
                break;
            case '1':
                setTitle('Pending Road Safety Audits to be verified');
                break;
            case '2':
                setTitle('Pending Road Safety Audits in review process');
                break;
            case '3':
                setTitle('Verified Road Safety Audits');
                break;
            case '4':
                setTitle('Audit List');
                break;
            case '5':
                setTitle('List of not recognised Road Safety Audits');
                break;
        }
    },[statCode])

    useEffect(()=>{
        setIsLoading(true)
        if(parseInt(statCode ? statCode : '0') >= 100){
            if(statCode == '999'){
                const name =  new URLSearchParams(location.search).get('name');
                const SearchApi = SearchApiCall({pageSize: 10, pageNumStartingZero: 0, countryStateId: 1, name : name ? name : ''} )
                Promise.resolve(SearchApi).then((value)=>{
                    if(value){
                        setSearchres(value.auditorSearchRess)
                        setRecord(value.totalRows)
                        setIsLoading(false)
                    }
                })
            }else{
                const SearchApi = SearchApiCallAdmin(parseInt(statCode ? statCode : '0'),1,0, false);
                Promise.resolve(SearchApi).then((value)=>{
                    if(value){
                        console.log(value)
                        setSearchres(value.auditorSearchRess)
                        setRecord(value.totalRows)
                        setIsLoading(false)
                    }
                })
            }
        }else{

            const SearchApi = SearchApiCallAdminAudits(parseInt(statCode ? statCode : '0'),1, searchText ? searchText : '');
            Promise.resolve(SearchApi).then((value)=>{
                if(value){
                    setSearchAuditres(value.auditGroup)
                    setRecord(value.totalPages)
                    setIsLoading(false)
                }
            })
        }

    },[])

    return (
        <>
            {session.userRoleId != 4 && session.userRoleId != 1 ? (<NotFound/>) : (
                <>
                    <Header>
                        <Container fluid={true} style={{marginTop: '14px', marginBottom: '65px'}}>
                            <InnerTitleBar
                                title={title}
                                usreName={session.userDisplayName != null ? session.userDisplayName : "  "}
                                userRole={userRoleSelector(session.userRoleId.toString())}
                                lastLogin={session.lastLogin.toString()}
                                status={userRoleSelector(session.userRoleId.toString())}
                            />
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={1}>

                                    </Grid.Column>
                                    <Grid.Column widescreen={2} computer={2} largeScreen={2} only='computer wideScreen, largeScreen'>
                                    </Grid.Column>
                                    <Grid.Column width={11} computer={11} tablet={11} widescreen={11} largeScreen={11} mobile={14}>
                                        {isLoading ? (<div style={{height: '40vh', marginTop: '30vh', display:'flex', justifyContent:'center'}}>
                                            <Oval
                                                height={80}
                                                width={80}
                                                color="#4fa94d"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                visible={true}
                                                ariaLabel='oval-loading'
                                                secondaryColor="#4fa94d"
                                                strokeWidth={2}
                                                strokeWidthSecondary={2}

                                            /></div>) : parseInt(statCode ? statCode : '1000') >= 100 ? (
                                            <SearchResult
                                                searchResults={searchRes}
                                                recordCount={record}
                                                setIsSearch={setIsSearch}
                                                state={state}
                                            />
                                        ) : (
                                            <SearchResultAudits
                                                searchResults={searchAuditRes}
                                                recordCount={record}
                                                setIsSearch={setIsSearch}
                                                state={state}
                                            />
                                        )}
                                    </Grid.Column>
                                    <Grid.Column width={2}>

                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Container>
                    </Header>
                    <Footer/>
                </>)}
        </>

    )
}

export default SearchTable;


interface SearchResultsProps {
    //eslint-disable-next-line
    setIsSearch: any,
    recordCount: number,
    //eslint-disable-next-line
    searchResults: AuditorSearchResRow[],
    state: SearchReq
}

const SearchResult = (props: SearchResultsProps) => {
    const [current, setCurrent] = useState(1);
    const [icon1, setIcon1] = useState('')
    const [icon2, setIcon2] = useState('')
    const [icon3, setIcon3] = useState('')
    const [icon4, setIcon4] = useState('')
    const [icon5, setIcon5] = useState('')
    const [icon6, setIcon6] = useState('')
    const [searchCol, setSearchCol] = useState(0)
    const [searchOrder, setSearchOrder] = useState(false)
    const statCode = new URLSearchParams(location.search).get('status')
    //eslint-disable-next-line
    const [data, setData] = useState<any>([]);


    useEffect(() => {
        if (props.searchResults) {
            SetDataTable(props.searchResults)
        }

    }, [props.searchResults]);

    const SetDataTable = (rawData: AuditorSearchResRow[]) => {
        //eslint-disable-next-line
        let temSearch: { auditorName: string; auditorCompany: string; auditorStatus: string; level: number; auditorId: number; accreditationExpiryDate: string; lastUpdatedOnDate: string;}[] = []
        rawData.map((r) => {
            temSearch.push({
                auditorName: r.fullName,
                auditorCompany: r.orgName,
                level: r.auditorLevel,
                auditorStatus: r.auditorStatus,
                auditorId: r.auditorId,
                accreditationExpiryDate: r.accreditationExpiryDate,
                lastUpdatedOnDate: r.lastUpdatedOnDate
            })
        })
        setData(temSearch);
    }

    // useEffect(() => {
    //     if (data.length == 1) {
    //         window.location.href = `/auditor?get=${btoa(data[0].auditorId)}`
    //     }
    // }, [data])
    const OnSearch = () => {
        props.setIsSearch(false);
    }

    const AuditorNameClick = () => {
        setIcon2('')
        setIcon3('')
        setIcon4('')
        setIcon5('')
        setIcon6('')
        setSearchCol(2)
        if (icon1 == 'sort up') {
            setIcon1('sort down')
            OnSortClick(2, true);
            setSearchOrder(true)
        } else {
            setIcon1('sort up')
            OnSortClick(2, false);
            setSearchOrder(false)
        }
    }

    const AuditorCompanyClick = () => {
        setIcon1('')
        setIcon3('')
        setIcon4('')
        setIcon5('')
        setIcon6('')
        setSearchCol(3)
        if (icon2 == 'sort up') {
            setIcon2('sort down')
            OnSortClick(3, true);
            setSearchOrder(true)
        } else {
            setIcon2('sort up')
            OnSortClick(3, false);
            setSearchOrder(false)
        }
    }

    const AuditorStatusClick = () => {
        setIcon2('')
        setIcon1('')
        setIcon4('')
        setIcon5('')
        setIcon6('')
        setSearchCol(4)
        if (icon3 == 'sort up') {
            setIcon3('sort down')
            OnSortClick(4, true);
            setSearchOrder(true)
        } else {
            setIcon3('sort up')
            OnSortClick(4, false);
            setSearchOrder(false)
        }
    }

    const AuditorLevelClick = () => {
        setIcon2('')
        setIcon1('')
        setIcon3('')
        setIcon5('')
        setIcon6('')
        setSearchCol(5)
        if (icon4 == 'sort up') {
            setIcon4('sort down')
            OnSortClick(5, true);
            setSearchOrder(true)
        } else {
            setIcon4('sort up')
            OnSortClick(5, false);
            setSearchOrder(false)
        }
    }

    const AccreditationExpiryClick = () => {
        setIcon2('')
        setIcon1('')
        setIcon3('')
        setIcon4('')
        setIcon6('')
        setSearchCol(6)
        if (icon5 == 'sort up') {
            setIcon5('sort down')
            OnSortClick(6, true);
            setSearchOrder(true)
        } else {
            setIcon5('sort up')
            OnSortClick(6, false);
            setSearchOrder(false)
        }
    }

    const LastUpdatedClick = () => {
        setIcon2('')
        setIcon1('')
        setIcon3('')
        setIcon4('')
        setIcon5('')
        setSearchCol(7)
        if (icon6 == 'sort up') {
            setIcon6('sort down')
            OnSortClick(7, true);
            setSearchOrder(true)
        } else {
            setIcon6('sort up')
            OnSortClick(7, false);
            setSearchOrder(false)
        }
    }

    const OnSortClick = (sortNumber: number, desc: boolean) => {
        // const searchData = SearchApiCall({...props.state, orderByField: sortNumber, isOrderByDesc: desc})
        // Promise.resolve(searchData).then((value) => {
        //     if (value) {
        //         SetDataTable(value.auditorSearchRess)
        //     }
        //     props.setIsSearch(true);
        // })

        if(parseInt(statCode ? statCode : '0') >= 100){
            if(statCode == '999'){
                const searchData = SearchApiCall({...props.state, orderByField: sortNumber, isOrderByDesc: desc})
                Promise.resolve(searchData).then((value) => {
                    if (value) {
                        SetDataTable(value.auditorSearchRess)
                    }
                    props.setIsSearch(true);
                })
            }else{
                const SearchApi = SearchApiCallAdmin(parseInt(statCode ? statCode : '0'),0, sortNumber, desc);
                Promise.resolve(SearchApi).then((value)=>{
                    if(value){
                        SetDataTable(value.auditorSearchRess)
                    }
                    props.setIsSearch(true);
                })
            }
        }
    }
    
    const OnPageChange: PaginationProps['onChange'] = (Page:any) => {
        // const searchData = SearchApiCall({...props.state, pageNumStartingZero: Page-1})
        // Promise.resolve(searchData).then((value) => {
        //     if (value) {
        //         SetDataTable(value.auditorSearchRess)
        //         setCurrent(Page)
        //     }
        //     props.setIsSearch(true);
        // })
        if(parseInt(statCode ? statCode : '0') >= 100){
            if(statCode == '999'){
                    const searchData = SearchApiCall({...props.state, pageNumStartingZero: Page-1})
                    Promise.resolve(searchData).then((value) => {
                        if (value) {
                            SetDataTable(value.auditorSearchRess)
                            setCurrent(Page)
                        }
                        props.setIsSearch(true);
                    })
            }else{
                const SearchApi = SearchApiCallAdmin(parseInt(statCode ? statCode : '0'),Page-1, searchCol, searchOrder);
                Promise.resolve(SearchApi).then((value)=>{
                    if(value){
                        SetDataTable(value.auditorSearchRess)
                        setCurrent(Page)
                    }
                    props.setIsSearch(true);
                })
            }
        }
    }

    const navigate = useNavigate();

    const handleRefineSearchClick = () => {
        // Redirect to the "/search" path
        navigate('/search');
    };

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
      
        return `${day}-${month}-${year}`;
    };
    
    return (
        <Grid>
            <Grid.Row columns={2}>
                <Grid.Column width={15} widescreen={15} mobile={16} tablet={8} computer={15} largeScreen={15}>
                    <span
                        style={{display: 'inline-flex'}}><b>{`Search results : found ${props.recordCount > 0 ? props.recordCount : props.recordCount} results`}</b></span>
                </Grid.Column>
                <Grid.Column width={1} className='button-flex' widescreen={1} mobile={16} tablet={8} computer={1}
                             largeScreen={1} style={{justifyContent: 'center'}}>
                    {/*<OutLineButton id={'btnSearchTop'} onClick={OnSearch} color='blue'*/}
                    {/*               className={"background-transaparent"} text='Refine Search'/>*/}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={8}>
                    <span style={{display: 'inline-flex'}}><b>Auditor(s) details</b></span>
                </Grid.Column>
                <Grid.Column width={8}>
                    {statCode == '999' ? (
                        <span className="ui action input button-flex" style={{display: 'inline-flex', float: 'right'}}>
                        <OutLineButton id={'cmbRefineYourSearch'} onClick={handleRefineSearchClick}
                                           color='blue' className={"background-transaparent"} text={'Refine Your Search'}
                            />
                        </span>
                    ):(
                        null
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
                {data.length > 0 ? (<Grid.Column width={16} style={{Width: '1000px', overflow: 'auto'}}>
                    <Table celled>
                        {statCode == '999' ? (
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{cursor: 'pointer', color: '#29599e'}}
                                                  onClick={AuditorNameClick}>Auditor&apos;s name<i
                                    className={`icon ${icon1}`}/></Table.HeaderCell>
                                <Table.HeaderCell style={{cursor: 'pointer', color: '#29599e'}}
                                                  onClick={AuditorCompanyClick}>Auditor&apos;s Company/Organisation <i
                                    className={`icon ${icon2}`}/></Table.HeaderCell>
                                <Table.HeaderCell style={{cursor: 'pointer', color: '#29599e'}}
                                                  onClick={AuditorStatusClick}>Auditor Status<i
                                    className={`icon ${icon3}`}/></Table.HeaderCell>
                                
                                <Table.HeaderCell style={{cursor: 'pointer', color: '#29599e'}}
                                                onClick={AuditorLevelClick}>Level<i
                                className={`icon ${icon4}`}/></Table.HeaderCell>
                                <Table.HeaderCell style={{cursor: 'pointer', color: '#29599e'}}
                                                  onClick={AccreditationExpiryClick}>Accreditation Expiry<i
                                    className={`icon ${icon5}`}/></Table.HeaderCell>
                                <Table.HeaderCell style={{cursor: 'pointer', color: '#29599e'}}
                                                  onClick={LastUpdatedClick}>Last updated<i
                                    className={`icon ${icon6}`}/></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        ):(
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{cursor: 'pointer', color: '#29599e'}}
                                                  onClick={AuditorNameClick}>Auditor&apos;s name<i
                                    className={`icon ${icon1}`}/></Table.HeaderCell>
                                <Table.HeaderCell style={{cursor: 'pointer', color: '#29599e'}}
                                                  onClick={AuditorCompanyClick}>Auditor&apos;s Company/Organisation <i
                                    className={`icon ${icon2}`}/></Table.HeaderCell>
                                <Table.HeaderCell style={{cursor: 'pointer', color: '#29599e'}}
                                                onClick={AuditorLevelClick}>Level<i
                                className={`icon ${icon4}`}/></Table.HeaderCell>
                                <Table.HeaderCell style={{cursor: 'pointer', color: '#29599e'}}
                                                  onClick={AccreditationExpiryClick}>Accreditation Expiry<i
                                    className={`icon ${icon5}`}/></Table.HeaderCell>
                                <Table.HeaderCell style={{cursor: 'pointer', color: '#29599e'}}
                                                  onClick={LastUpdatedClick}>Last updated<i
                                    className={`icon ${icon6}`}/></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        )}
                        <Table.Body>
                            {data.map((item: TableRowProps, index: number) => (
                                <TableRow
                                    key={index}
                                    auditorName={item.auditorName}
                                    auditorCompany={item.auditorCompany}
                                    level={item.level}
                                    auditorStatus={item.auditorStatus}
                                    auditorId={item.auditorId}
                                    accreditationExpiryDate={formatDate(item.accreditationExpiryDate)}
                                    lastUpdatedOnDate={formatDate(item.lastUpdatedOnDate)}
                                    statusCode={statCode ? statCode : '0'}
                                />
                            ))}
                        </Table.Body>

                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell colSpan='4' textAlign={'right'}>
                                    <Pagination current={current} onChange={OnPageChange} total={props.recordCount}
                                                pageSize={10} showSizeChanger={false}/>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </Grid.Column>) : (<Grid.Column width={16}>No Records found !</Grid.Column>)}
            </Grid.Row>
            <Grid.Row columns={2} style={{justifyContent: 'end'}}>
                <Grid.Column width={15} widescreen={15} mobile={16} tablet={8} computer={15} largeScreen={15}>
                </Grid.Column>
                <Grid.Column width={1} className='button-flex' widescreen={1} mobile={16} tablet={8} computer={1}
                             largeScreen={1}>
                    {/*<OutLineButton id={'btnSearchBottom'} onClick={OnSearch} color='blue'*/}
                    {/*               className={"background-transaparent"} text='Refine Search'/>*/}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

interface TableRowProps {
    auditorName: string,
    auditorCompany: string,
    level: string,
    auditorId: string,
    accreditationExpiryDate: string,
    lastUpdatedOnDate: string,
    auditorStatus: string,
    key: number,
    statusCode?:string
}

const TableRow = (props: TableRowProps) => {
    if (props.statusCode === '999') {
        return (
            <Table.Row key={props.key}>
                <Table.Cell style={{ cursor: 'pointer', color: '#29599e' }}>
                    <a href={`/profile?get=${btoa(props.auditorId)}`}>{props.auditorName}</a>
                </Table.Cell>
                <Table.Cell>{props.auditorCompany}</Table.Cell>
                <Table.Cell>{props.auditorStatus}</Table.Cell>
                <Table.Cell>{props.level}</Table.Cell>
                <Table.Cell>{props.accreditationExpiryDate}</Table.Cell>
                <Table.Cell>{props.lastUpdatedOnDate}</Table.Cell>
            </Table.Row>
        );
    } else {
        return (
            <Table.Row key={props.key}>
                <Table.Cell style={{ cursor: 'pointer', color: '#29599e' }}>
                    <a href={`/profile?get=${btoa(props.auditorId)}`}>{props.auditorName}</a>
                </Table.Cell>
                <Table.Cell>{props.auditorCompany}</Table.Cell>
                <Table.Cell>{props.level}</Table.Cell>
                <Table.Cell>{props.accreditationExpiryDate}</Table.Cell>
                <Table.Cell>{props.lastUpdatedOnDate}</Table.Cell>
            </Table.Row>
        );
    }
};


//Audits Lists
interface SearchResultsAuditsProps {
    //eslint-disable-next-line
    setIsSearch: any,
    recordCount: number,
    //eslint-disable-next-line
    searchResults: AuditSearchResRow[],
    state: SearchReq
}

const SearchResultAudits = (props: SearchResultsAuditsProps) => {
    const [current, setCurrent] = useState(1);
    const [icon1, setIcon1] = useState('')
    const [icon2, setIcon2] = useState('')
    const [icon3, setIcon3] = useState('')
    const [searchCol, setSearchCol] = useState(0)
    const [searchOrder, setSearchOrder] = useState(false)
    const statCode = new URLSearchParams(location.search).get('status')
    const searchText = new URLSearchParams(location.search).get('name')
    //eslint-disable-next-line
    const [data, setData] = useState<any>([]);


    useEffect(() => {
        if (props.searchResults) {
            SetDataTable(props.searchResults)
        }

    }, [props.searchResults]);

    const SetDataTable = (rawData: AuditSearchResRow[]) => {
        //eslint-disable-next-line
        let temSearch: { auditId: number; signOff: string; auditTitle: string; auditClientCompany: string; AuditorName: string; lastUpdatedOnDate: string;}[] = []
        rawData.map((r) => {
            temSearch.push({
                auditId: r.auditID,
                signOff: r.auditFinalSignoffDate,
                auditTitle: r.auditTitle,
                auditClientCompany: r.auditClientCompany,
                AuditorName: `${r.firstName ? r.firstName + ' ' : ''}  ${r.middleName ? r.middleName + ' ' : ''}  ${r.lastName ? r.lastName : ''}`,
                lastUpdatedOnDate: r.modifyDate
            })
        })
        setData(temSearch);
    }

    // useEffect(() => {
    //     if (data.length == 1) {
    //         window.location.href = `/auditor?get=${btoa(data[0].auditorId)}`
    //     }
    // }, [data])
    const OnSearch = () => {
        props.setIsSearch(false);
    }

    const AuditorNameClick = () => {
        setIcon2('')
        setIcon3('')
        setSearchCol(2)
        if (icon1 == 'sort up') {
            setIcon1('sort down')
            OnSortClick(2, true);
            setSearchOrder(true)
        } else {
            setIcon1('sort up')
            OnSortClick(2, false);
            setSearchOrder(false)
        }
    }

    const AuditorCompanyClick = () => {
        setIcon1('')
        setIcon3('')
        setSearchCol(3)
        if (icon2 == 'sort up') {
            setIcon2('sort down')
            OnSortClick(3, true);
            setSearchOrder(true)
        } else {
            setIcon2('sort up')
            OnSortClick(3, false);
            setSearchOrder(false)
        }
    }

    const AuditorLevelClick = () => {
        setIcon2('')
        setIcon1('')
        setSearchCol(4)
        if (icon3 == 'sort up') {
            setIcon3('sort down')
            OnSortClick(4, true);
            setSearchOrder(true)
        } else {
            setIcon3('sort up')
            OnSortClick(4, false);
            setSearchOrder(false)
        }
    }

    const OnSortClick = (sortNumber: number, desc: boolean) => {
        //const searchData = SearchApiCall({...props.state, orderByField: sortNumber, isOrderByDesc: desc})
        const searchData = SearchApiCallAdminAudits(parseInt(statCode ? statCode : '0'), current, searchText ? searchText : '')
        Promise.resolve(searchData).then((value) => {
            if (value) {
                SetDataTable(value.auditGroup)
            }
            props.setIsSearch(true);
        })
    }

    const OnPageChange: PaginationProps['onChange'] = (Page:any) => {
        const searchData = SearchApiCallAdminAudits(parseInt(statCode ? statCode : '0'), Page, searchText ? searchText : '')
        Promise.resolve(searchData).then((value) => {
            if (value) {
                SetDataTable(value.auditGroup)
                setCurrent(Page)
            }
            props.setIsSearch(true);
        })
    }

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
      
        return `${day}-${month}-${year}`;
    };
    
    return (
        <Grid>
            <Grid.Row columns={2}>
                <Grid.Column width={15} widescreen={15} mobile={16} tablet={8} computer={15} largeScreen={15}>
                    <span
                        style={{display: 'inline-flex'}}><b>{`Search results : found ${props.recordCount > 0 ? props.recordCount : props.recordCount} results`}</b></span>
                </Grid.Column>
                <Grid.Column width={1} className='button-flex' widescreen={1} mobile={16} tablet={8} computer={1}
                             largeScreen={1} style={{justifyContent: 'center'}}>
                    {/*<OutLineButton id={'btnSearchTop'} onClick={OnSearch} color='blue'*/}
                    {/*               className={"background-transaparent"} text='Refine Search'/>*/}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={16}>
                    <span style={{display: 'inline-flex'}}><b>Audit(s) details</b></span>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
                {data.length > 0 ? (<Grid.Column width={16} style={{Width: '1000px', overflow: 'auto'}}>
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{cursor: 'pointer', color: '#29599e', width: '150px'}}
                                                  onClick={AuditorNameClick}>Audit ID<i
                                    className={`icon ${icon1}`}/></Table.HeaderCell>
                                <Table.HeaderCell style={{cursor: 'pointer', color: '#29599e'}}
                                                  onClick={AuditorCompanyClick}>Sign-off<i
                                    className={`icon ${icon2}`}/></Table.HeaderCell>
                                <Table.HeaderCell style={{cursor: 'pointer', color: '#29599e'}}
                                                  onClick={AuditorLevelClick}>Audit&apos;s Title<i
                                    className={`icon ${icon3}`}/></Table.HeaderCell>
                                <Table.HeaderCell style={{cursor: 'pointer', color: '#29599e'}}
                                                  onClick={AuditorLevelClick}>Audit&apos;s Company/Organisation<i
                                    className={`icon ${icon3}`}/></Table.HeaderCell>
                                <Table.HeaderCell style={{cursor: 'pointer', color: '#29599e', width: '200px', verticalAlign: 'bottom'}}
                                                  onClick={AuditorLevelClick}>Auditor&apos;s name<i
                                    className={`icon ${icon3}`}/></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {data.map((item: AuditsTableRowProps, index: number) => (
                                <AuditsTableRow
                                    key={index}
                                    auditId={item.auditId}
                                    AuditorName={item.AuditorName}
                                    auditClientCompany={item.auditClientCompany}
                                    auditTitle={item.auditTitle}
                                    signOff={formatDate(item.signOff)}
                                />
                            ))}
                        </Table.Body>

                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell colSpan='4' textAlign={'right'}>
                                    <Pagination current={current} onChange={OnPageChange} total={props.recordCount}
                                                pageSize={10} showSizeChanger={false}/>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </Grid.Column>) : (<Grid.Column width={16}>No Records found !</Grid.Column>)}
            </Grid.Row>
            <Grid.Row columns={2} style={{justifyContent: 'end'}}>
                <Grid.Column width={15} widescreen={15} mobile={16} tablet={8} computer={15} largeScreen={15}>
                </Grid.Column>
                <Grid.Column width={1} className='button-flex' widescreen={1} mobile={16} tablet={8} computer={1}
                             largeScreen={1}>
                    {/*<OutLineButton id={'btnSearchBottom'} onClick={OnSearch} color='blue'*/}
                    {/*               className={"background-transaparent"} text='Refine Search'/>*/}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

interface AuditsTableRowProps {
    auditId: number,
    signOff: string,
    auditTitle: string,
    auditClientCompany: string,
    AuditorName: string,
    key: number,
}

const AuditsTableRow = (props: AuditsTableRowProps) => {
    return (
        <Table.Row key={props.key}>
            <Table.Cell style={{cursor: 'pointer', color: '#29599e'}}><a
                href={`/audits?id=${props.auditId}`}>{props.auditId}</a></Table.Cell>
            <Table.Cell>{props.signOff}</Table.Cell>
            <Table.Cell style={{cursor: 'pointer', color: '#29599e'}}><a 
                href={`/audits?id=${props.auditId}`}>{props.auditTitle}</a></Table.Cell>
            <Table.Cell>{props.auditClientCompany}</Table.Cell>
            <Table.Cell style={{cursor: 'pointer', color: '#29599e'}}><a 
                href={`/audits?id=${props.auditId}`}>{props.AuditorName}</a></Table.Cell>
        </Table.Row>
    );
}