import React, {useEffect} from "react";
import Header from "../../../../framework/layouts/Header";
import Footer from "../../../../framework/layouts/Footer";
import ActivityLogContainer from "./ActivityLogContainer";


const ActivityLog = () => {
    return(
        <React.Fragment>
            <Header>
                <ActivityLogContainer/>
            </Header>
            <Footer/>
        </React.Fragment>
    );
}

export default ActivityLog