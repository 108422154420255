import React, {useEffect, useState} from "react";
import {Container, Grid, Table} from "semantic-ui-react";
import InnerTitleBar from "../../../../framework/layouts/InnerTitleBar";
import {userRoleSelector} from "../../../../common/roleSelector";
import {getSessionCookie} from "../../../../framework/common/SessionHandler";
import OutLineButton from "../../../../framework/components/Buttons/OutlIneButton";
import DateRangeBox from "../../../../framework/components/TextBoxes/DateRangeBox";
import { GetAEDRLevelOne, GetAEDRLevelThree, GetAEDRLevelTwo, WhoCanApplyForLevelReview } from "./AccreditaionReportController";
import moment from "moment";

const AccreditationReportContainer = () =>{
    const session = getSessionCookie();
    const[txtName, setTxtName] = useState('')
    const AEDDate = moment(window.config.AEDDate,"DD-MM-YYYY").toString()

    const WhoCanApply = async() => {
    
        const report = await WhoCanApplyForLevelReview()
        //const linkSource = `data:application/vnd.ms-excel,${report}`;
        const today = new Date();
        const url = window.URL.createObjectURL(new Blob([report])) 
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `MIS Report Auditors who can apply for Level Review as at(${moment(today).format("DD-MM-YYYY")}).csv`)
        document.body.appendChild(link)
        link.click()
        link.remove()

    }

    const Level1Auditors = async() => {
    
        const report = await GetAEDRLevelOne()
        //const linkSource = `data:application/vnd.ms-excel,${report}`;
        const today = new Date();
        const url = window.URL.createObjectURL(new Blob([report])) 
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `MIS Report AED Level One as at (${moment(today).format("DD-MM-YYYY")}).csv`)
        document.body.appendChild(link)
        link.click()
        link.remove()

    }

    const Level1AuditorsAEDDate = async() => {
    
        const report = await GetAEDRLevelOne(AEDDate)
        //const linkSource = `data:application/vnd.ms-excel,${report}`;
        const url = window.URL.createObjectURL(new Blob([report])) 
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `MIS Report Level 1 auditors elapsed on (${moment(AEDDate).format("DD-MM-YYYY")}).csv`)
        document.body.appendChild(link)
        link.click()
        link.remove()

    }

    const Level2Auditors = async() => {
    
        const report = await GetAEDRLevelTwo()
        //const linkSource = `data:application/vnd.ms-excel,${report}`;
        const today = new Date();
        const url = window.URL.createObjectURL(new Blob([report])) 
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `MIS Report AED Level Two as at (${moment(today).format("DD-MM-YYYY")}).csv`)
        document.body.appendChild(link)
        link.click()
        link.remove()

    }

    const Level2AuditorsAEDDate = async() => {
    
        const report = await GetAEDRLevelTwo(AEDDate)
        const url = window.URL.createObjectURL(new Blob([report])) 
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `MIS Report Level 2 auditors elapsed on (${moment(AEDDate).format("DD-MM-YYYY")}).csv`)
        document.body.appendChild(link)
        link.click()
        link.remove()

    }

    const Level3Auditors = async() => {
    
        const report = await GetAEDRLevelThree()
        //const linkSource = `data:application/vnd.ms-excel,${report}`;
        const today = new Date();
        const url = window.URL.createObjectURL(new Blob([report])) 
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `MIS Report AED Level Three as at (${moment(today).format("DD-MM-YYYY")}).csv`)
        document.body.appendChild(link)
        link.click()
        link.remove()

    }

    const Level3AuditorsAEDDate = async() => {
    
        const report = await GetAEDRLevelThree(AEDDate)
        const url = window.URL.createObjectURL(new Blob([report])) 
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `MIS Report Level 3 auditors elapsed on (${moment(AEDDate).format("DD-MM-YYYY")}).csv`)
        document.body.appendChild(link)
        link.click()
        link.remove()

    }

    return(
        <Container fluid={true} style={{marginTop: '14px', marginBottom: '20px'}}>
            <InnerTitleBar
                title={"Accreditation Related Reports"}
                usreName={session.userDisplayName != null ? session.userDisplayName : "  "}
                userRole={userRoleSelector(session.userRoleId.toString())}
                lastLogin={session.lastLogin.toString()}
                status={userRoleSelector(session.userRoleId.toString())}
            />
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <p><b></b></p>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={12} mobile={16} computer={12}>
                                    <p>Level 1 Auditors with “Active” or “Level Review” as at today who fail criteria.</p>
                                </Grid.Column>
                                <Grid.Column width={4} mobile={16} computer={4} className='button-flex'>
                                    <OutLineButton id={'btnDownloadTallyReport'} className={"background-transaparent"}
                                                   style={{width: '250px'}} onClick={(e:any) => {
                                                    Level1Auditors()
                                    }} color={'blue'} text={'Download to Excel'}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={12} mobile={16} computer={12}>
                                    <p>Level 2 Auditors with “Active” or “Level Review” as at today who fail criteria.</p>
                                </Grid.Column>
                                <Grid.Column width={4} mobile={16} computer={4} className='button-flex'>
                                    <OutLineButton id={'btnDownloadTallyReport'} className={"background-transaparent"}
                                                   style={{width: '250px'}} onClick={(e:any) => {
                                                    Level2Auditors();
                                    }} color={'blue'} text={'Download to Excel'}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={12} mobile={16} computer={12}>
                                    <p>Level 3 Auditors with “Active” or “Level Review” as at today who fail criteria.</p>
                                </Grid.Column>
                                <Grid.Column width={4} mobile={16} computer={4} className='button-flex'>
                                    <OutLineButton id={'btnDownloadTallyReport'} className={"background-transaparent"}
                                                   style={{width: '250px'}} onClick={(e:any) => {
                                                    Level3Auditors()
                                    }} color={'blue'} text={'Download to Excel'}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <hr/>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={12} mobile={16} computer={12}>
                                    <p>The list of Level 1 Auditors who will be elapsed {moment(AEDDate).format("DD-MM-YYYY")}.</p>
                                </Grid.Column>
                                <Grid.Column width={4} mobile={16} computer={4} className='button-flex'>
                                    <OutLineButton id={'btnDownloadTallyReport'} className={"background-transaparent"}
                                                   style={{width: '250px'}} onClick={(e:any) => {
                                                    Level1AuditorsAEDDate()
                                    }} color={'blue'} text={'Download to Excel'}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={12} mobile={16} computer={12}>
                                    <p>The list of Level 2 Auditors who will be elapsed {moment(AEDDate).format("DD-MM-YYYY")}.</p>
                                </Grid.Column>
                                <Grid.Column width={4} mobile={16} computer={4} className='button-flex'>
                                    <OutLineButton id={'btnDownloadTallyReport'} className={"background-transaparent"}
                                                   style={{width: '250px'}} onClick={(e:any) => {
                                                    Level2AuditorsAEDDate()
                                    }} color={'blue'} text={'Download to Excel'}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={12} mobile={16} computer={12}>
                                    <p>The list of Level 3 Auditors who will be elapsed {moment(AEDDate).format("DD-MM-YYYY")}.</p>
                                </Grid.Column>
                                <Grid.Column width={4} mobile={16} computer={4} className='button-flex'>
                                    <OutLineButton id={'btnDownloadTallyReport'} className={"background-transaparent"}
                                                   style={{width: '250px'}} onClick={(e:any) => {
                                                    Level3AuditorsAEDDate()
                                    }} color={'blue'} text={'Download to Excel'}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <hr/>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={12} mobile={16} computer={12}>
                                    <p>The list of Auditors who can apply for &quot;Level Review&quot;</p>
                                </Grid.Column>
                                <Grid.Column width={4} mobile={16} computer={4} className='button-flex'>
                                    <OutLineButton id={'btnDownloadTallyReport'} className={"background-transaparent"}
                                                   style={{width: '250px'}} onClick={(e:any) => {
                                                    WhoCanApply()
                                    }} color={'blue'} text={'Download to Excel'}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>

                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>

                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
}

export default AccreditationReportContainer
