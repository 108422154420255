import React, {useEffect, useState} from "react";
import {Container, Grid} from "semantic-ui-react";
import InnerTitleBar from "../../../../framework/layouts/InnerTitleBar";
import {userRoleSelector} from "../../../../common/roleSelector";
import {getSessionCookie} from "../../../../framework/common/SessionHandler";
import OutLineButton from "../../../../framework/components/Buttons/OutlIneButton";
import RSATable from "../../../../framework/widgets/TableWithBackgrounds/RSATable";
import TableHeader from "../../../../framework/types/TableHeader";
import tags from "../../../../models/common/tag";
import DefaultTextBox from "../../../../framework/components/TextBoxes/DefaultTextBox";
import EmailInputBox from "../../../../framework/components/TextBoxes/EmailInputBox";
import { LoginNameChangeReq } from "../../../../models/apiRequest/mis/LoginNameChangeReq";
import { SearchApiCallForLoginNameChange, SaveApiCallForLoginNameChange } from "../../../search/SearchController";
import { LoginNameChangeSearchResRow } from "../../../../models/apiResponce/Auditor/SearchRes";
import SuccessToast from "../../../../framework/components/Toast/SuccessToast";
import ErrorToast from "../../../../framework/components/Toast/ErrorToast";
import { PaginationProps } from "antd/es/pagination";

const UserLoginNameChangeContainer = () =>{

    const [state, setState] = React.useState<LoginNameChangeReq>();
    const session = getSessionCookie();
    const [current, setCurrent] = useState(1);
    const [newEmail, setnewEmail] = useState('')
    const [confirmEmail, setConfirmEmail] = useState('')
    const [selectEmail, setSelectEmail] = useState('')
    const [selectUserType, setSelectUserType] = useState(0)
    const [emailError, setEmailError] = React.useState('')
    const [totalRecords, setTotalRecords] = useState(0)
    const rsaEmailChangeHead: TableHeader[] = [
        {
            text: "Name"
        },
        {
            text: "Login email"
        },
        {
            text: "Password"
        }
        ,
        {
            text: ""
        }
    ];
    const [rsaEmailChange, setRSAEmailChange] = useState<object[]>([
    ])



    const SetValues = (data:LoginNameChangeSearchResRow[]) => {
        const TableRows: object[] = [];
        data.map((r:LoginNameChangeSearchResRow) => {
            TableRows.push([{
                    tag: tags.text,
                    text: `${r.firstName} ${r.middleName} ${r.lastName}`,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: `${r.loginName}`,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.password,
                    icon: "",
                    onclick: null
                },
                {
                    tag: "icon-fa",
                    text: "Select",
                    icon: "edit",
                    onclick:() => {
                        setSelectEmail(r.loginName)
                        setSelectUserType(r.userType)
                    }
                }])
        })
        setRSAEmailChange(TableRows);
    }

    const onSearchClick = () => {
        const searchData = SearchApiCallForLoginNameChange(state, 1)
        Promise.resolve(searchData).then((value) => {
            if (value) {
                setTotalRecords(value.totalRows)
                SetValues(value.userInfoSearchRess)
                setSelectEmail('')
            }
        })
    }

    const onChange: PaginationProps['onChange'] = (page) => {
        setCurrent(page);
        const fetchCourses = SearchApiCallForLoginNameChange(state, page)
        Promise.resolve(fetchCourses).then((value) => {
            if(value){
                setTotalRecords(value.totalRows)
                SetValues(value.userInfoSearchRess)
            }
        });
    };

    const onSaveClick = () => {
        try {
            if (!Validating()) {
                return;
            }
    
            // Add new
            const newData: LoginNameChangeReq = {
                currentEmail: selectEmail || '',
                newEmail: newEmail || '',
                userType: selectUserType
            };
    
            const searchData = SaveApiCallForLoginNameChange(newData)
            Promise.resolve(searchData).then((value)=>{
                if(value){
                    SuccessToast('Login name changed successfully')
                    setTimeout(() => {
                        // Reload the page
                        window.location.reload();
                    }, 2000); // 2000 milliseconds delay (2 seconds)
                }
            })
        } catch (error) {
            // Handle the error here
            ErrorToast('Error saving loginname change');
        }
    }

    const Validating = () => {
        if (newEmail !== confirmEmail) {
            // Show an error message or handle the validation failure
            setEmailError('New email and Confirm new email must match');
            return false;
        }
        setEmailError('');
        return true;
    };

    const [disableSave, setDisableSave] = React.useState(false);

    useEffect(() => {
        DisableValidation();
    }, [newEmail, confirmEmail]);

    const DisableValidation = () =>{
        const isFormInvalid = (
            newEmail !== confirmEmail ||
            !newEmail ||
            !confirmEmail
        );
    
        setDisableSave(isFormInvalid);
    }


    return(
        <Container fluid={true} style={{marginTop: '14px', marginBottom: '20px'}}>
            <InnerTitleBar
                title={"User Login Name Change"}
                usreName={session.userDisplayName != null ? session.userDisplayName : "  "}
                userRole={userRoleSelector(session.userRoleId.toString())}
                lastLogin={session.lastLogin.toString()}
                status={userRoleSelector(session.userRoleId.toString())}
            />
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>  
                        <Grid>
                            <Grid.Row columns={1}>
                                <Grid.Column width={16} style={{verticleAlign:'middle'}}>
                                    <span style={{display:'inline-flex'}}>Email address</span><br></br>
                                    <EmailInputBox
                                            id={'txtEmail'} 
                                            fluid={true} 
                                            placeholder={'Enter Email address'}
                                            value={state?.email}
                                            onChange={(e: any) => {
                                                setState({...state,email:e.target.value})
                                            }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>                       
                    </Grid.Column>
                    <Grid.Column width={2}>
                        
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={1}>
                                <Grid.Column width={16}>
                                    <span style={{display:'inline-flex'}}>Name</span><br></br>
                                    <DefaultTextBox
                                            id={'txtName'} 
                                            fluid={true} 
                                            placeholder={'Enter Name'}
                                            value={state?.name}
                                            onChange={(e: any) => {
                                                setState({...state,name:e.target.value})
                                            }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>                       
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12} className={'button-flex'} style={{display:'flex', justifyContent:'right'}}>
                        <OutLineButton id={'btnSave'} 
                            style={{width: '150px'}} 
                            onClick={() => {
                                onSearchClick()
                            }} 
                            color='blue' 
                            className={"background-transaparent"}
                            text={'Search'}
                            disabled={false}
                        />
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                {rsaEmailChange.length > 0 ? (<Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <div style={{ overflow: 'auto' }}>
                            <RSATable
                                head={rsaEmailChangeHead}
                                body={rsaEmailChange}
                                current={current}
                                pageCount={totalRecords/10}
                                onChange={onChange}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>):
                (
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>

                        </Grid.Column>
                        <Grid.Column width={12}>
                            No Record Found.
                        </Grid.Column>
                        <Grid.Column width={2}>

                        </Grid.Column>
                    </Grid.Row>
                )
                }
                {selectEmail.length > 0 ? (<>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                            <hr/>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={1}>
                                <Grid.Column width={10}>
                                    <b><span style={{display:'inline-flex'}}>Change Panel</span></b>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>     
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={1}>
                                <Grid.Column width={16}>
                                    <span style={{display:'inline-flex'}}>Current email address</span><br></br>
                                    <span style={{display:'inline-flex'}}>{selectEmail}</span>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>     
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={1}>
                                <Grid.Column width={16}>
                                    <span style={{display:'inline-flex'}}>New email address</span><br></br>
                                    <EmailInputBox
                                            id={'txtNewEmail'} 
                                            fluid={true} 
                                            required={true}
                                            placeholder={'Enter New email address'}
                                            value={newEmail}
                                            onChange={(e: any) => {
                                                setnewEmail(e.target.value)
                                            }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>     
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={1}>
                                <Grid.Column width={16}>
                                    <span style={{display:'inline-flex'}}>Confirm new email address</span><br></br>
                                    <EmailInputBox
                                            id={'txtConfirmEmail'} 
                                            fluid={true} 
                                            placeholder={'Enter Confirm new email address'}
                                            value={confirmEmail}
                                            required={true}
                                            onerror={emailError}
                                            onChange={(e: any) => {
                                               
                                                setConfirmEmail(e.target.value)
                                            }}
                                            onpaste={(e:any) => {
                                                e.preventDefault();
                                                return
                                            }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>     
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12} className={'button-flex'} style={{display:'flex', justifyContent:'right'}}>
                        <OutLineButton id={'btnSave'} 
                            style={{width: '150px'}} 
                            onClick={() => {
                                onSaveClick()
                            }} 
                            color='blue' 
                            className={"background-transaparent"}
                            text={'Save'}
                            disabled={disableSave}
                        />
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                </>):null}
            </Grid>
        </Container>
    );
}

export default UserLoginNameChangeContainer
