import React, {useEffect, useState} from "react";
import Header from "../../framework/layouts/Header";
import Footer from "../../framework/layouts/Footer";
import TitleBar from "../../framework/layouts/TitleBar";
import HomeSlider from "./HomeSlider";
import HomeSliderBottom from "./HomeSliderBottom";
import MiddleLeftTripo from "./MiddleLeftTripo";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import UpgradeNoticeModal from "../upgradeNoticeModal";

const Home = () => {

    const [showModal, setShowModal] = useState(false); // State to control modal visibility
    const config = window.config;

    useEffect(() => {
        sessionStorage.clear();
        // Show the modal when the component mounts
        if(process.env.REACT_APP_SYSTEM_UPGRADE_NOTICE === '1' || config.ShowSystemUpgradeNotice === true) {
            setShowModal(true);
        }
    }, []);

    const handleCloseModal = () => {
        setShowModal(false); // Close the modal when the user clicks the close button
    };
    
    useEffect(()=>{
        sessionStorage.clear();
    },[])
    return (
        <>
            <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY ? process.env.REACT_APP_RECAPTCHA_KEY : ""}>
                <Header>
                    <HomeSlider/>
                    <MiddleLeftTripo/>
                    <HomeSliderBottom/>
                </Header>
                <Footer/>
                {/* Render the modal component and pass the props */}
                <UpgradeNoticeModal show={showModal} onClose={handleCloseModal} />
            </GoogleReCaptchaProvider>
        </>
    )
}

export default Home