import React, { useEffect, useState } from "react";
import { Container, Grid } from "semantic-ui-react";
import RSATable from "../../framework/widgets/TableWithBackgrounds/RSATable";
import { lstAuditParticipantsReq, RoadSafetyAuditProps } from "../auditor/profile/DataModal";
import {
    AddNewAudittoDBByToken,
    fetchSingleAuditTokenBase,
    fetchTokenDetails
} from "../auditor/profile/ProfileController";
import TableHeader from "../../framework/types/TableHeader";
import moment from "moment/moment";
import tags from "../../models/common/tag";
import { toast } from "react-toastify";
import OutLineButton from "../../framework/components/Buttons/OutlIneButton";
import DefaultButton from "../../framework/components/Buttons/DefaultButton";
import NameWithCheckBox from "../../framework/widgets/NameWithCheckBox/NameWithCheckBox";
import Modal from "../../framework/components/Modals";
import waringImage from "../../../../assets/images/icons/warning.png";


interface Props {
    //eslint-disable-next-line
    token: any;
    //eslint-disable-next-line
    isEdit: any;
}

const AuditParticipationConfirmation = ({token, isEdit}: Props) => {
    const [loading, setLoading] = useState(false);
    const [isTokenValid, setIsTokenValid] = useState(true); 
    const [state, setState] = React.useState<RoadSafetyAuditProps>()
    const [rbtVal, setRbtVal] = useState('no')
    const [auditId, setAuditId] = React.useState<number>(0);
    const [isVerifyAudit, setIsVerifyAudit] = useState(false);
    const [open2, setOpen2] = useState(false);

    useEffect(() => {
        setState({ ...state })
        if (token != null) {
            //setAddNewAudit(false);
            setLoading(true);
            Promise.resolve(fetchTokenDetails(token)).then((ress) => {
                if(ress != null) {
                    Promise.resolve(fetchSingleAuditTokenBase(ress.auditId)).then((res) => {
                        setAuditId(ress.auditId)
                        setState({ ...res, finalSignoffDate: moment(res.finalSignoffDate).format("DD-MM-YYYY"), 
                        tokenId: ress.id, confirmedBy: ress.email });
                        setRbtVal(res.isTfNSW ? 'yes' : 'no');
                        if (res?.lstAuditParticipantsReq != undefined && res?.lstAuditParticipantsReq?.length > 0) {
                            const objAddAuditors: object[] = []//rsaAddAuditorDet
                            res?.lstAuditParticipantsReq.map((r) => {
                                const row = {
                                    auditorId: r.auditorId,
                                    name: r.auditorFullName,
                                    company: r.auditorCompany,
                                    status: r.auditorId == 0 ? 'Unregistered Auditor' : 'Auditor',
                                    isTeamLeader: r.isLeadAuditor,
                                    isConfirmedByAuditor: r.isConfirmed
                                }
                                objAddAuditors.push(assignAddAuditortoAuditRaw(row, true));
                            })
                            setRSAAddAuditorDet(objAddAuditors);
                        }
                    });
                } else {
                    setIsTokenValid(false);
                }
            });

        }
    }, [token]);

    const rsaAddAuditorHead: TableHeader[] = [
        // {
        //     text: "Auditor ID"
        // },
        {
            text: "Name"
        },
        {
            text: "Auditor's Company"
        },
        // {
        //     text: "Auditor's Status"
        // },
        {
            text: "Lead Auditor"
        },
        {
            text: "Confirmed"
        },
        {
            text: " "
        }
    ];

    const makeAuditorConfirm = (id: string, name: string, isConfirm: boolean) => {
        setRSAAddAuditorDet(prevData => prevData.map(item => {
            if (Array.isArray(item)) {
                // Create a shallow copy of the item array
                const newItem = [...item];
                if (newItem[0] && newItem[1] && newItem[0].text === id && newItem[1].text === name) {
                    // Toggle the isConfirmedByAuditor property
                    newItem[5] = { ...newItem[5], text: !newItem[5].text };
                }
                return newItem;
            }
            return item;
        }));
    };

    const assignAddAuditortoAuditRaw = (raw: any, edit: boolean) => {
        return [
            {
                tag: "hidden",
                text: raw.auditorId,
                icon: "",
                onclick: null
            },
            {
                tag: "text",
                text: raw.name,
                icon: "",
                onclick: null
            },
            {
                tag: "text",
                text: raw.company,
                icon: "",
                onclick: null
            },
            {
                tag: "hidden",
                text: raw.status,
                icon: "",
                onclick: null
            },
            {
                tag: tags.icon,
                text: raw.isTeamLeader,
                icon: raw.isTeamLeader == true ? "check" : "times",
                onclick: null
            },
            {
                tag: 'checkbox',
                text: raw.isConfirmedByAuditor,
                icon: raw.isConfirmedByAuditor == true ? "check" : "minus",
                onclick: () => {
                    raw.isConfirmedByAuditor == false ?
                    makeAuditorConfirm(raw.auditorId, raw.name, raw.isConfirmedByAuditor) : null
                }
            }
        ]
    }

    const SaveAdmin = () => {
        //eslint-disable-next-line
        let allData: lstAuditParticipantsReq[] = []
        let toastMsg = ''
        if (rsaAddAuditorDet.length > 0) {
            const dataState = rsaAddAuditorDet
            dataState.forEach(x => {
                if (Array.isArray(x)) {
                    const y: lstAuditParticipantsReq = {
                        auditorFullName: x[1].text,
                        auditorId: x[0].text > 0 ? x[0].text : 999999999,
                        isConfirmed: x[5].text == 'center' ? x[4].icon == 'check' ? true : false : x[5].text,
                        isLeadAuditor: x[4].text == 'center' ? x[4].icon == 'check' ? true : false : x[4].text,
                        auditId:auditId
                    }
                    allData.push(y)
                }
            })
            if(state?.auditId){
                setState({...state , lstAuditParticipantsReq: allData })
                toastMsg = `Thank you for confirming the details for ${state?.titleOfAudit}(${state?.auditId}).`
            }
        }
        
        if (state !== undefined) {
            let isAuditConfirmed = false
            allData.forEach(x => {
                if(x.isConfirmed) {
                    isAuditConfirmed = true
                    setIsVerifyAudit(true)
                }
            })
            
            const saveResult = AddNewAudittoDBByToken({...state, isAuditConfirmed: isAuditConfirmed, lstAuditParticipantsReq: allData})
            //PromiseToast('Pending...', 'Save Success !', 'Error', saveResult)
            toast.dismiss()
            const id = toast.loading('Pending..');
            Promise.resolve(saveResult).then((value) => {

                if (value.response != undefined && value.response.data.error) {
                    //toast.dismiss(id)
                    toast.update(id, {render: value.response.data.error.detail, type: "error", isLoading: false});
                    setTimeout(() => {
                        toast.dismiss(id)
                    }, 2500)

                } else {
                    toast.update(id, {render: toastMsg, type: "success", isLoading: false});
                        setTimeout(() => {
                        toast.dismiss(id)
                        window.location.reload();
                    }, 1500)
                }

            })
        }
    }
    
    const [rsaAddAuditorDet, setRSAAddAuditorDet] = useState<object[]>([assignAddAuditortoAuditRaw({
        status: 'Auditor',
        isTeamLeader: true,
        isConfirmedByAuditor: false
    }, false)])

    return (
        <React.Fragment>
            <Container>
                { isTokenValid ? (
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Grid>
                                    <Grid.Row columns={2}>
                                        <Grid.Column width={12}>
                                            <span style={{display:'inline-flex'}}>Please verify the Audits and Auditors by ticking the check boxes and click the &apos;Confirm&apos; button.</span>
                                        </Grid.Column>
                                        <Grid.Column width={4}>
                                            <span style={{display:'inline-flex'}}>
                                                <NameWithCheckBox
                                                    id={'cbConfirm'}
                                                    label={'Please verify this audit'}
                                                    value={isVerifyAudit}
                                                    onChange={() => {
                                                        setIsVerifyAudit(!isVerifyAudit)
                                                    }}
                                                />
                                            </span>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>     
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={14}>
                                <Grid>
                                    <Grid.Row columns={2}>
                                        <Grid.Column width={12}>
                                         
                                        </Grid.Column>
                                        <Grid.Column width={2}>
                                        
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>     
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={14}>
                                <Grid>
                                    <Grid.Row columns={2}>
                                        <Grid.Column width={4}>
                                            <span style={{display:'inline-flex'}}>Title of Audit:</span>
                                        </Grid.Column>
                                        <Grid.Column width={10}>
                                            <span style={{display:'inline-flex'}}>{state?.titleOfAudit}</span>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>     
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={14}>
                                <Grid>
                                    <Grid.Row columns={2}>
                                        <Grid.Column width={4}>
                                            <span style={{display:'inline-flex'}}>Stage of Audit:</span>
                                        </Grid.Column>
                                        <Grid.Column width={10}>
                                            <span style={{display:'inline-flex'}}>{state?.stageOfAuditName}</span>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>     
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={14}>
                                <Grid>
                                    <Grid.Row columns={2}>
                                        <Grid.Column width={4}>
                                            <span style={{display:'inline-flex'}}>State Audit Performed in:</span>
                                        </Grid.Column>
                                        <Grid.Column width={10}>
                                            <span style={{display:'inline-flex'}}>{state?.stateAuditPerformedName}</span>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>     
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={14}>
                                <Grid>
                                    <Grid.Row columns={2}>
                                        <Grid.Column width={4}>
                                            <span style={{display:'inline-flex'}}>Location of Audit:</span>
                                        </Grid.Column>
                                        <Grid.Column width={10}>
                                            <span style={{display:'inline-flex'}}>{state?.locationOfAudit}</span>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>     
                            </Grid.Column>
                        </Grid.Row>
                        {rsaAddAuditorDet !== null && rsaAddAuditorDet.length > 0 && (
                            <Grid.Row>
                                <Grid.Column width={16}>                                       
                                    <hr /><br />
                                    <div style={{ overflow: 'auto' }}>
                                        <RSATable
                                            head={rsaAddAuditorHead}
                                            body={rsaAddAuditorDet}
                                        />
                                    </div>
                                    <hr />
                                </Grid.Column>
                            </Grid.Row>
                        )}
                        <Grid.Row centered={true}>
                            <Grid.Column mobile={16} tablet={8} widescreen={12} largeScreen={12} computer={12}>
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={8} computer={5} widescreen={4} largeScreen={4}
                                        style={{justifyContent: 'center', display: 'flex'}}>
                                <ControllButtonReg
                                    YesClick={SaveAdmin}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                ) : (
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={14}>
                                <Grid style={{border:'1px solid red', textAlign:'center', color:'red'}}>
                                    <Grid.Row columns={2}>
                                        <Grid.Column width={14}>
                                            <span style={{display:'inline-flex', fontSize:'22px'}}>The token is expired.</span>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>     
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    )
                }
                {/* <Modal
                    open={open2 ? open2 : false}
                    setOpen={setOpen2}
                    title={"Warning !"}
                    modalDescription={true}
                    descriptionHeader={'Are you sure you want to delete this audit phase?'}
                    descriptionBody={'This action cannot be undone.'}
                    imageUrl={waringImage}
                    imageSize='small'
                    modalAction={true}
                    ActionButtons={() => (
                        <ControllButtonRegModal
                            NoClick={() => {
                                setOpen2(false)
                            }}
                            YesClick={SaveAdmin}
                        />
                    )}
                />
                <br/> */}
            </Container>
        </React.Fragment>
    );
};

export default AuditParticipationConfirmation

interface ControllButtonProps {
    //eslint-disable-next-line
   // NoClick: any;
    //eslint-disable-next-line
    YesClick: any;
    //eslint-disable-next-line
}

const ControllButtonReg = (props: ControllButtonProps) => {
    return (
        <React.Fragment >
            {/* <OutLineButton id={'btnCancel'} style={{width: '150px'}} onClick={() => {
                props.NoClick()
            }} color={'red'} text={'Cancel'}/> */}
            <DefaultButton id={'btnSave'} style={{width: '150px'}} onClick={() => {
                props.YesClick()
            }} color={'blue'}
                           text={'Confirm'}/>
        </React.Fragment>
    )
}