import React, {useEffect} from "react";
import {Grid} from "semantic-ui-react";
import NameWithDropDown from "../../../framework/widgets/NameWithDropDown";
import Position from "../../../framework/types/Position";
import NameWithDefault from "../../../framework/widgets/NameWithText/NameWithDefault";
import DropDownOptions from "../../../framework/types/DropDownOptions";
import {PersonalDetailsValueProps} from "./DataModal";
import {fetchAuditorStatus} from "./ProfileController";
import moment from "moment";

interface AccreditationDetailsProps{
    auditor?:any,
    Status:any
    SetStatus:any
}

const AccreditationDetails = (props:AccreditationDetailsProps) => {
    const [status, setStatus] = React.useState<DropDownOptions[]>([]);
    const [state, setState] = React.useState<PersonalDetailsValueProps>();
    const [AuditorStatus, SetAuditorStatus] = React.useState(0)

    useEffect(() => {
        //eslint-disable-next-line
        //debugger
        Promise.resolve(props.auditor).then((value) => {
            //console.log(value)
            setState(value.personalDetails)
            props.SetStatus(state?.AuditorsStateRes?.auditorStatusId)

        })
    }, [props.auditor])

    useEffect(() => {
        const AuditorsStatus = fetchAuditorStatus()
        Promise.resolve(AuditorsStatus).then((r) => {
            if(r){
                setStatus(r)
            }
        })
    },[])

    return(
        <React.Fragment>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <NameWithDefault labelText={'Auditor ID'} labelPosition={Position.Top}
                                         id={'txtAuditor'} fluid={true} type={'text'}
                                         value={state?.txtNewAuditorId}
                                         disabled={true}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2} >
                    <Grid.Column mobile={16} widescreen={8} tablet={8} computer={8}>
                        <NameWithDropDown  clearable={true} selection={true} labelText={'Auditor level'}
                                           labelPosition={Position.Top} id={'ddApplyLevel'}
                                           placeholder={'Auditor level'}
                                           options={[{key: 1, text: 'Auditor (Level 1)', value: '1'}, {
                                               key: 2,
                                               text: 'Senior Auditor (Level 2)',
                                               value: '2'
                                           }, {key: 3, text: 'Leader (Level 3)', value: '3'}]}
                                           value={state?.txtCurrentLevel}
                                           disabled={state?.AuditorsStateRes?.auditorStatusId == 4 || state?.AuditorsStateRes?.auditorStatusId == 7}
                        />
                    </Grid.Column>
                    <Grid.Column mobile={16} widescreen={8} tablet={8} computer={8}>
                        <NameWithDropDown   selection={true} labelText={'Auditor status'}
                                           labelPosition={Position.Top} id={'ddApplyLevel'}
                                           placeholder={'Auditor status'}
                                           options={status}
                                           value={props.Status?.toString()}
                                           onChange={(e:any, data:any)=>{
                                               props.SetStatus(data.value)
                                           }}
                                            disabled={state?.AuditorsStateRes?.auditorStatusId == 4 || state?.AuditorsStateRes?.auditorStatusId == 7}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column mobile={16} widescreen={8} tablet={8} computer={8}>
                        <NameWithDefault labelText={'Accreditation expiry date'} labelPosition={Position.Top}
                                         id={'txtAED'} fluid={true} type={'text'}
                                         placeholder={'Enter Accreditation expiry date'}
                                         value={moment(state?.AuditorsStateRes?.certExpiryDate).format("DD-MM-YYYY")}
                                         disabled={true}
                        />
                    </Grid.Column>
                    <Grid.Column width={8} mobile={16} widescreen={8} tablet={8} computer={8}>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </React.Fragment>
    )

}

export default AccreditationDetails