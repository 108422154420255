/*
 *################################################################################
 *Module Name                   	: RouteList.ts                				 #
 *Purpose of the Module         	: To Show Route the request        			 #
 *Description of the Module    	    : This module route client request to        #
 *    		                           auth or guest according to url            #
 *Initial Author               	    : T A C Priyashad                            #
 *Date                          	: 11/04/2022                   		         #
 *Copyright © 2022 ITworx Consulting.                                   		 #
 *################################################################################
 */
//Import AuthPages
import Profile from '../pages/auditor/profile';
import AuditorWelcome from '../pages/auditor/welcome';

//Import Admin AuthPages
import AdminWelcome from "../pages/admin/welcome";
import SearchTable from "../pages/admin/common/SearchTable"

//Import GuestPages
import Home from "../pages/home";
import Sample from "../pages/sample";
import Maintenance from "../pages/maintenance";
import Aboutus from "../pages/aboutus";
import Contactus from "../pages/contactus";
import Registration from "../pages/auditor/registration";
import RegiAuthThanks from "../pages/StaticPages/RegiAuthThanks"
import Faqs from '../pages/faqs';
import Faqspop from '../pages/faqs_pop';
import NotFound from "../pages/Warring/404";

//Component
import SampleButtons from "../pages/sample/SampleButtons";
import SampleTextBoxes from "../pages/sample/SampleTextBoxes";
import SampleOptions from "../pages/sample/SampleOptions";
import SampleDropDown from "../pages/sample/SampleDropDown";
import SampleModals from "../pages/sample/SampleModals";
import SampleTosat from "../pages/sample/SampleToast";

//Widgets
import SampleNameWithText from "../pages/sample/SampleNameWithText";
import SampleNameWithDropDown from "../pages/sample/SampleNameWithDropDown";

//Modules
import SampleSupportModule from "../pages/sample/SampleSupportModules";
import Searh from "../pages/search";
import PgsAudits from "../pages/audits";
import AuditorDetails from "../pages/search/AuditorDetails";
import Discaimer from "../pages/StaticPages/Disclaimer";
import SiteMap from "../pages/StaticPages/SiteMap";
import Training from '../pages/training';
import Numberofauditors from "../pages/admin/mis/numberofauditors";
import ProfileLog from "../pages/admin/mis/ProfileLog";
import ContactLog from "../pages/admin/mis/ContactLog";
import ExportAuditors from "../pages/admin/mis/ExportAuditors";
import ProcessReport from "../pages/admin/mis/ProcessReport";
import TallyReport from "../pages/admin/mis/TallyReport";
import LoginActivity from "../pages/admin/mis/LoginReport";
import RegionReport from '../pages/admin/mis/RegionReport';
import VisitOnSearch from '../pages/admin/mis/VisitsOnSearchPage';
import MessageCount from '../pages/admin/mis/MessageCount';
import AccreditationReport from '../pages/admin/mis/AccreditationReports';
import ActivityLog from '../pages/admin/mis/ActivityLog';
import AssesmentStatus from '../pages/admin/mis/AssesmentStatus';
import AssessorActivity from '../pages/admin/mis/AssessorActivityReport';
import Courses from '../pages/admin/misc/Courses';
import Notifications from '../pages/admin/misc/Notification';
import UserLoginNameChange from '../pages/admin/Profile/UserLoginNameChange';
import AuditStages from '../pages/admin/misc/AuditStages';
import AuditPhases from '../pages/admin/misc/AuditPhases';
import AssessorProfile from '../pages/admin/Profile/Assessor/AssessorProfile';
import Assessor from '../pages/admin/Profile/Assessor';
import Admin from '../pages/admin/Profile/Admin';
import AdminProfile from '../pages/admin/Profile/Admin/AdminProfile';
import Company from '../pages/admin/Profile/CompanyProfile';
import AssessorChair from '../pages/admin/Profile/AssessorChair';
import AuditorCompany from '../pages/admin/misc/AuditorCompany';
import PageContent from '../pages/admin/misc/PageContent';
import FaqPageContent from '../pages/admin/misc/FaqPageContent';
import UnlockIpAddress from '../pages/admin/misc/UnlockIpAddress';
import EditAssessor from '../pages/admin/Profile/EditAssessor';
import EditAssessorProfile from '../pages/admin/Profile/EditAssessor/EditAssessorProfile';
import PgAuditParticipationConfirm from '../pages/auditParticipationConfirmation';
import UnlockAuditorProfile from '../pages/admin/misc/UnlockAuditorProfile';



//Define authRoutes which is render when user have permission to view these components.
const authRoutes : {url:string, widget:any}[] = [
    {url:'/profile', widget:Profile},
    {url:'/accreditation', widget:Profile},
    {url:'/welcome', widget:AuditorWelcome},
    {url:'/audits', widget:PgsAudits},
    {url:'/dashboard', widget:AdminWelcome},
    {url:'/search/auditors', widget:SearchTable},
    {url:'/mis/transactionreport', widget:Numberofauditors},
    {url:'/mis/profilelog', widget:ProfileLog},
    {url:'/mis/contactlog', widget:ContactLog},
    {url:'/mis/exportauditors', widget:ExportAuditors},
    {url:'/mis/processreport', widget:ProcessReport},
    {url:'/mis/tallyreport', widget:TallyReport},
    {url:'/mis/regionreport', widget:RegionReport},
    {url:'/mis/visitonsearch', widget:VisitOnSearch},
    {url:'/mis/messagecount', widget:MessageCount},
    {url:'/mis/loginactivityreport', widget:LoginActivity},
    {url:'/mis/accreditationreports', widget:AccreditationReport},
    {url:'/mis/activitylog', widget:ActivityLog},
    {url:'/mis/assesmentstatus', widget:AssesmentStatus},
    {url:'/mis/assessoractivity', widget:AssessorActivity},
    {url:'/misc/coursemanagement', widget:Courses},
    {url:'/misc/courseclassification', widget:Courses},
    {url:'/misc/notifications', widget:Notifications},
    {url:'/profile/userloginnamechange', widget:UserLoginNameChange},
    {url:'/misc/auditStages', widget:AuditStages},
    {url:'/misc/auditPhases', widget:AuditPhases},
    {url:'/profile/assessorChair', widget:AssessorChair},
    {url:'/profile/assessor', widget:Assessor},
    {url:'/profile/assessorProfile', widget:AssessorProfile},
    {url:'/profile/editAssessor', widget:EditAssessor},
    {url:'/profile/editAssessorProfile', widget:EditAssessorProfile},
    {url:'/profile/admin', widget:Admin},
    {url:'/profile/adminProfile', widget:AdminProfile},
    {url:'/profile/company', widget:Company},
    {url:'/misc/auditorCompany', widget:AuditorCompany},
    {url:'/misc/pageContent', widget:PageContent},
    {url:'/misc/faqPageContent', widget:FaqPageContent},
    {url:'/misc/unlockIpAddress', widget:UnlockIpAddress},
    {url:'/misc/unlockAuditorProfile', widget:UnlockAuditorProfile},
    // this route should be at the end of all other routes
];

//Define guestRoutes which is render without checking user permissions.
interface guestRouteProps {
    url:string,
    widget:any
}
const guestRoutes : {url:string, widget:any}[] = [
    {url:"/home", widget:Home},
    {url:"/samples", widget:Sample},
    {url:"/RegiAuthThanks", widget:RegiAuthThanks},
    {url:"/terms", widget:Discaimer},
    {url:"/sitemap", widget:SiteMap},
    //about us, contact us and faqs edited by ShaneW
    {url:"/aboutus", widget:Aboutus},
    {url:"/contactus", widget:Contactus},
    {url:"/faqs", widget:Faqs},
    {url:"/faqspop", widget:Faqspop},
    {url:"/sampleButtons" ,widget:SampleButtons},
    {url:"/sampleTextBoxes" ,widget:SampleTextBoxes},
    {url:"/sampleOptions" ,widget:SampleOptions},
    {url:"/sampleDropDowns" ,widget:SampleDropDown},
    {url:"/sampleModals" ,widget:SampleModals},
    {url:"/sampleToasts" ,widget:SampleTosat},
    {url:"/sampleNameWithText" ,widget:SampleNameWithText},
    {url:"/sampleNameWithDropDown" ,widget:SampleNameWithDropDown},
    {url:"/sampleSupportModule" ,widget:SampleSupportModule},
    {url:"/maintenance" ,widget:Maintenance},
    {url:"/registration" ,widget:Registration},
    {url:"/training" ,widget:Training},
    {url:"/search" ,widget:Searh},
    {url:"/auditor", widget:AuditorDetails},
    {url:"/auditParticipationConfirm", widget:PgAuditParticipationConfirm},
    {url:"/", widget:Home},
    {url:"/404", widget:NotFound}
];

//Export both authRoutes and guestRoutes functions, Which needs to access it from outside this file (Make it accessible to any ts file)
export {authRoutes,guestRoutes}