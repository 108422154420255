import React, {useEffect, useState} from "react";
import {Container, Grid, Table} from "semantic-ui-react";
import InnerTitleBar from "../../../../framework/layouts/InnerTitleBar";
import {userRoleSelector} from "../../../../common/roleSelector";
import {getSessionCookie} from "../../../../framework/common/SessionHandler";
import OutLineButton from "../../../../framework/components/Buttons/OutlIneButton";
import RSATable from "../../../../framework/widgets/TableWithBackgrounds/RSATable";
import TableHeader from "../../../../framework/types/TableHeader";
import tags from "../../../../models/common/tag";
import { ApiCallForUnlockIpAddress, SearchApiCallForUnlockIpAddress } from "../../../search/SearchController";
import {  UnlockIpAddressRow } from "../../../../models/apiResponce/mis/AuditPhaseRes";
import SuccessToast from "../../../../framework/components/Toast/SuccessToast";
import ErrorToast from "../../../../framework/components/Toast/ErrorToast";
import Modal from "../../../../framework/components/Modals";
import 'react-quill/dist/quill.snow.css'; // import styles
import DefaultButton from "../../../../framework/components/Buttons/DefaultButton";
import waringImage from "../../../../assets/images/icons/warning.png";

const UnlockIpAddressContainer = () =>{

    const session = getSessionCookie();
    const [open2, setOpen2] = useState(false);
    const [unlockIpAddress, setUnlockIpAdress] = useState(''); 

    const rsaUnlockIpAddressHead: TableHeader[] = [
        {
            text: ""
        },
        {
            text: "IP address"
        },
        {
            text: "Title"
        },
        {
            text: "First name"
        },
        {
            text: "Last name"
        },
        {
            text: "Locked date"
        }
    ];
    const [rsaUnlockIpAddress, setrsaUnlockIpAddress] = useState<object[]>([
    ])

    const SetValues = (data:UnlockIpAddressRow[]) => {
        const TableRows: object[] = [];
        data.map((r:UnlockIpAddressRow, index:number) => {
            TableRows.push([
                {
                    tag: tags.linkinsametab,
                    text: "Unlock",
                    icon: "",
                    onclick: () => {
                        promptUnlockIpAddress(r.clientAddress || '')
                    }
                },
                {
                    tag: tags.text,
                    text: `${r.clientAddress}`,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: `${r.titleName}`,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: `${r.firstName}`,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: `${r.lastName}`,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: `${r.dateEntered}`,
                    icon: "",
                    onclick: null
                }])
        })
        setrsaUnlockIpAddress(TableRows);
    }

    const promptUnlockIpAddress = (clientAddress: string) => {
        console.log('ip address to unlock', clientAddress)
        setUnlockIpAdress(clientAddress);
        setOpen2(true); // Open the confirmation modal
    };

    const UnlockIpAddress = async () => {
        try {
            //Delete
            const result = await ApiCallForUnlockIpAddress(unlockIpAddress, session.userName);
            if (result.authForRegiRes) {
                SuccessToast('IP Address unlocked successfully');

                // Set a timeout before navigating
                setTimeout(() => {
                    location.reload()
                }, 2000); // 2000 milliseconds delay (2 seconds)
            }
        } catch (error) {
            ErrorToast('Error unlocking IP Address');
        } finally {
            setOpen2(false); // Close the modal
            setUnlockIpAdress(''); // Reset the delete audit ID
        }
    };

    useEffect(() => {
        try {
            const pageContents = SearchApiCallForUnlockIpAddress();
            Promise.resolve(pageContents).then((value) => {
                if (value && value.authForRegiRes) {
                    SetValues(value.authForRegiRes);
                }
            });
        } catch (error) {
            // Handle the error here
            ErrorToast('Error fetching locked IP address results');
        }
        
    }, []);

    return(
        <React.Fragment>
        <Container fluid={true} style={{marginTop: '14px', marginBottom: '20px'}}>
            <InnerTitleBar
                title={"Unlock IP address"}
                usreName={session.userDisplayName != null ? session.userDisplayName : "  "}
                userRole={userRoleSelector(session.userRoleId.toString())}
                lastLogin={session.lastLogin.toString()}
                status={userRoleSelector(session.userRoleId.toString())}
            />
           <Grid>
           <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
           </Grid>
            <Grid>
                {rsaUnlockIpAddress.length > 0 ? (<Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <div style={{ overflow: 'auto' }}>
                            <RSATable
                                head={rsaUnlockIpAddressHead}
                                body={rsaUnlockIpAddress}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>):null}
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
        <Grid>
                <Modal
                        open={open2 ? open2 : false}
                        setOpen={setOpen2}
                        title={"Warning !"}
                        modalDescription={true}
                        descriptionHeader={'Are you sure you want to unlock this IP address?'}
                        descriptionBody={'This action cannot be undone.'}
                        imageUrl={waringImage}
                        imageSize='small'
                        modalAction={true}
                        ActionButtons={() => (
                            <ControllButtonReg
                                NoClick={() => {
                                    setOpen2(false)
                                    setUnlockIpAdress('')
                                }}
                                YesClick={() => UnlockIpAddress()}
                            />
                        )}
                    />
                <br/>
            </Grid>
        </React.Fragment>
    );
}

export default UnlockIpAddressContainer

interface ControllButtonProps {
    //eslint-disable-next-line
    NoClick: any;
    //eslint-disable-next-line
    YesClick: any;
    //eslint-disable-next-line
}

const ControllButtonReg = (props: ControllButtonProps) => {
    return (
        <React.Fragment>
            <OutLineButton id={'btnCancel'} style={{width: '150px'}} onClick={() => {
                props.NoClick()
            }} color={'red'} text={'No'}/>
            <DefaultButton id={'btnSave'} style={{width: '150px'}} onClick={() => {
                props.YesClick()
            }} color={'blue'}
                           text={'Yes'}/>
        </React.Fragment>
    )
}
