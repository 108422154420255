import React, {useEffect} from "react";
import Header from "../../../../framework/layouts/Header";
import Footer from "../../../../framework/layouts/Footer";
import UnlockIpAddressContainer from "./UnlockIpAddressContainer";


const UnlockIpAddress = () => {
    const mainUrl = window.location.pathname
    console.log(mainUrl)
    return(
        <React.Fragment>
            <Header>
                <UnlockIpAddressContainer/>
            </Header>
            <Footer/>
        </React.Fragment>
    );
}

export default UnlockIpAddress