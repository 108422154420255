import React, {useEffect, useState} from "react";
import {Container, Grid} from "semantic-ui-react";
import InnerTitleBar from "../../../../framework/layouts/InnerTitleBar";
import {userRoleSelector} from "../../../../common/roleSelector";
import {getSessionCookie} from "../../../../framework/common/SessionHandler";
import OutLineButton from "../../../../framework/components/Buttons/OutlIneButton";
import { UpdateApiCallForAdmin, SaveApiCallForAdmin, uploadLogoFile, GetAdminCompanyProfile, SaveApiCallForAdminCompany } from "../../../search/SearchController";
import SuccessToast from "../../../../framework/components/Toast/SuccessToast";
import ErrorToast from "../../../../framework/components/Toast/ErrorToast";
import NameWithDefault from "../../../../framework/widgets/NameWithText/NameWithDefault";
import Position from "../../../../framework/types/Position";
import { fetchCountries, fetchStates, fetchTitle } from "../../../auditor/profile/ProfileController";
import NameWithEmail from "../../../../framework/widgets/NameWithText/NameWithEmail";
import Header from "../../../../framework/layouts/Header";
import Footer from "../../../../framework/layouts/Footer";
import { CompanyProfileRes } from "../../../../models/apiResponce/mis/AssessorProfileRes";
import FileUpload from "../../../../framework/components/FileUpload";
import { FileUploadValidator, calculateStorage } from "../../../../framework/common/SupportMobules";
import {  documentValidation, imageValidation, resumeValidation, sizeValidation } from "../../../../framework/validators/fileValidator";
import FileProps from "../../../../framework/types/FileProps";
import FileUploadPop from "../../../../framework/components/FileUpload/FileUploadPop";
import MultilineTextBox from "../../../../framework/components/TextBoxes/MultilineTextBox";


const CompanyProfile = () => {
    const mainUrl = window.location.pathname
    console.log(mainUrl)
    return(
        <React.Fragment>
                <CompanyProfileContainer/>
        </React.Fragment>
    );
}

export default CompanyProfile

const CompanyProfileContainer = () =>{
    const [file, setFile] = useState<FileProps>({pictureAsFile: {} as File, picturePreview: ''})
    const [uploadFile, setUploadFile] = React.useState<any>();
    const AdminId = new URLSearchParams(location.search).get('id');
    const [state, setState] = React.useState<CompanyProfileRes>();
    const session = getSessionCookie();
    const [text, SetText] = useState('')
    const [companyProfileDesc, setDescriptionText] = useState('')
    const [companyDescError, setCompanyDescError] = useState('')
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth < 768);
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        console.log(file);
    })

    useEffect(() => {
        GetAdminCompanyProfile().then((value) => {
            console.log("New state value:", value);
            if (value) {
                setState(value)
                setDescriptionText(value.description ? value.description : '')
                console.log(state)
            }
        });
    }, []);

    useEffect(() => {
        console.log("Updated state:", state);
    }, [state]);

    const onSaveClick = () => {
        try {
            if (!Validating()) {
                return;
            }

            if (state) {
                const updatedData: CompanyProfileRes = {
                    ...state!,
                    description: companyProfileDesc || '',
                };
                const searchData = SaveApiCallForAdminCompany(updatedData)
                Promise.resolve(searchData).then((value)=>{
                    if(value){
                        SuccessToast('Admin company updated successfully')
                        setTimeout(() => {
                            // Reload the page
                            window.location.href = '/welcome'
                        }, 2000); // 2000 milliseconds delay (2 seconds)
                    }
                })
            }
            
        } catch (error) {
            // Handle the error here
            ErrorToast('Error saving company profile');
        }
    }

    const Validating = () => {
        if (companyProfileDesc == '') {
            // Show an error message or handle the validation failure
            setCompanyDescError('Description is required');
            return false;
        }
        setCompanyDescError('');
        return true;
    };

    const [disableSave, setDisableSave] = React.useState(false);

useEffect(() => {
        DisableValidation();
    }, [state, companyProfileDesc]);

    const DisableValidation = () =>{
        const isFormInvalid = (   
            !state?.shortName ||
            !state?.email ||
            !state?.title ||
            !state?.company ||
            !state?.address ||
            !state?.suburb ||
            !state?.postCode ||
            !state?.telephone ||
            !state.fax ||
            !state.link ||
            !companyProfileDesc
        );
    
        setDisableSave(isFormInvalid);
    }

    return(
        <Container fluid={true} style={{marginTop: '14px', marginBottom: '20px'}}>
            <InnerTitleBar
                title={"Edit Admin company profile"}
                usreName={session.userDisplayName != null ? session.userDisplayName : "  "}
                userRole={userRoleSelector(session.userRoleId.toString())}
                lastLogin={session.lastLogin.toString()}
                status={userRoleSelector(session.userRoleId.toString())}
            />
            {
                isMobile ? (
                    <Grid>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
    
                        </Grid.Column>
                        <Grid.Column width={12}>
                                <hr/>
                        </Grid.Column>
                        <Grid.Column width={2}>
    
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
    
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <b><span style={{display:'inline-flex', fontSize:'20px'}}>Edit Admin company profile</span></b>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                        <Grid.Column width={2}>
    
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtCustodian'} 
                                            fluid={true} 
                                            placeholder={'Enter Custodian'}
                                            value={state?.title}
                                            onChange={(e: any) => {
                                                setState({...state,title:e.target.value})
                                            }}
                                            labelText={"Custodian:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtShortName'} 
                                            fluid={true} 
                                            placeholder={'Enter Short Name'}
                                            value={state?.shortName}
                                            onChange={(e: any) => {
                                                setState({...state,shortName:e.target.value})
                                            }}
                                            labelText={"Short Name:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithEmail
                                            labelText="Email:"
                                            labelPosition={Position.Top}
                                            id={'txtemail'} 
                                            fluid={true} 
                                            placeholder={'Enter email address'}
                                            value={state?.email}
                                            required={true}
                                            type={'email'}
                                            onChange={(e: any) => {
                                                setState({...state,email:e.target.value})
                                            }}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <span>Logo:</span><br/>
                                        <Grid>
                                            <Grid.Row columns={1}>
                                                <Grid.Column>
                                                    <FileUploadPop
                                                        fileType={'ADMIN_COMPANY'}
                                                        auditorEmail={session.userName}
                                                        message={""}
                                                        auditorId={session.auditorId}
                                                        editable={true}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column style={{display: 'flex', justifyContent: 'center'}} className={'button-flex'}>
                                                    {text}
                                                </Grid.Column>
                                            </Grid.Row>
                                            {file.pictureAsFile.size > 0 ? (
                                                <Grid.Row columns={1}>
                                                    <Grid.Column>File name - {file.pictureAsFile.name} </Grid.Column>
                                                    <Grid.Column style={{display: 'flex', justifyContent: 'center'}}>File size
                                                        - {calculateStorage(file.pictureAsFile.size)} </Grid.Column>
                                                </Grid.Row>
                                            ) : null}
                                            {/* <Grid.Row>
                                                <Grid.Column>
                                                    <p>Up to 30MB for file types jpg .jpeg .png .webp .tiff .bmp .ai .gif .psd .pdf .raw</p>
                                                </Grid.Column>
                                            </Grid.Row> */}
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <hr/>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <span>Description:<span style={{color:'red'}}>*</span></span><br/>
                                        <MultilineTextBox
                                            wordCount={true}
                                            fluid={true}
                                            className={'other_exp'}
                                            id={'txtDescriptionText'}
                                            placeholder={''}
                                            value={companyProfileDesc}
                                            onChange={(e: any) => {
                                                setDescriptionText(e.target.value)
                                            }}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtCompany'} 
                                            fluid={true} 
                                            placeholder={'Enter Company'}
                                            value={state?.company}
                                            onChange={(e: any) => {
                                                setState({...state,company:e.target.value})
                                            }}
                                            labelText={"Company:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={true}
                                            multiLine={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtAddress'} 
                                            fluid={true} 
                                            placeholder={'Enter Address'}
                                            value={state?.address}
                                            onChange={(e: any) => {
                                                setState({...state,address:e.target.value})
                                            }}
                                            labelText={"Address:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={true}
                                            multiLine={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtSuburb'} 
                                            fluid={true} 
                                            placeholder={'Enter Suburb'}
                                            value={state?.suburb}
                                            onChange={(e: any) => {
                                                setState({...state,suburb:e.target.value})
                                            }}
                                            labelText={"Suburb"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtState'} 
                                            fluid={true} 
                                            placeholder={'Enter State'}
                                            value={state?.state}
                                            onChange={(e: any) => {
                                                setState({...state,state:e.target.value})
                                            }}
                                            labelText={"State:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={false}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtPostCode'} 
                                            fluid={true} 
                                            placeholder={'Enter Post Code'}
                                            value={state?.postCode}
                                            onChange={(e: any) => {
                                                setState({...state,postCode:e.target.value})
                                            }}
                                            labelText={"Post Code:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtTelephone'} 
                                            fluid={true} 
                                            placeholder={'Enter Telephone'}
                                            value={state?.telephone}
                                            onChange={(e: any) => {
                                                setState({...state,telephone:e.target.value})
                                            }}
                                            labelText={"Telephone:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtFax'} 
                                            fluid={true} 
                                            placeholder={'Enter Fax'}
                                            value={state?.fax}
                                            onChange={(e: any) => {
                                                setState({...state,fax:e.target.value})
                                            }}
                                            labelText={"Fax:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtAboutUsLink'} 
                                            fluid={true} 
                                            placeholder={'Enter About Us Link'}
                                            value={state?.link}
                                            onChange={(e: any) => {
                                                setState({...state,link:e.target.value})
                                            }}
                                            labelText={"About Us Link:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={true}
                                            multiLine={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
    
                        </Grid.Column>
                        <Grid.Column width={12} className={'button-flex'} style={{display:'flex', justifyContent:'right'}}>
                            <OutLineButton id={'btnCancel'} style={{width: '150px'}} onClick={() => {
                                ()=>{window.location.href = '/profile/Admin'}
                                }} 
                                color={'red'} 
                                className={"background-transaparent-red"}
                                text={'Cancel'}/>
                            <OutLineButton id={'btnSave'} 
                                style={{width: '150px'}} 
                                onClick={() => {
                                    onSaveClick()
                                }} 
                                color='blue' 
                                className={"background-transaparent"}
                                text={'Update'}
                                disabled={disableSave}
                            />
                        </Grid.Column>
                        <Grid.Column width={2}>
    
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                ) : (
                    <Grid>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
    
                        </Grid.Column>
                        <Grid.Column width={12}>
                                <hr/>
                        </Grid.Column>
                        <Grid.Column width={2}>
    
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
    
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row columns={3}>
                                    <Grid.Column>
                                        <b><span style={{display:'inline-flex', fontSize:'20px'}}>Edit Admin company profile</span></b>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                        <Grid.Column width={2}>
    
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtCustodian'} 
                                            fluid={true} 
                                            placeholder={'Enter Custodian'}
                                            value={state?.title}
                                            onChange={(e: any) => {
                                                setState({...state,title:e.target.value})
                                            }}
                                            labelText={"Custodian:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtShortName'} 
                                            fluid={true} 
                                            placeholder={'Enter Short Name'}
                                            value={state?.shortName}
                                            onChange={(e: any) => {
                                                setState({...state,shortName:e.target.value})
                                            }}
                                            labelText={"Short Name:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithEmail
                                            labelText="Email:"
                                            labelPosition={Position.Top}
                                            id={'txtemail'} 
                                            fluid={true} 
                                            placeholder={'Enter email address'}
                                            value={state?.email}
                                            required={true}
                                            type={'email'}
                                            onChange={(e: any) => {
                                                setState({...state,email:e.target.value})
                                            }}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <span>Logo:</span><br/>
                                        <Grid>
                                            <Grid.Row columns={1}>
                                                <Grid.Column>
                                                    <FileUploadPop
                                                        fileType={'ADMIN_COMPANY'}
                                                        auditorEmail={session.userName}
                                                        message={""}
                                                        auditorId={session.auditorId}
                                                        editable={true}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column style={{display: 'flex', justifyContent: 'center'}} className={'button-flex'}>
                                                    {text}
                                                </Grid.Column>
                                            </Grid.Row>
                                            {file.pictureAsFile.size > 0 ? (
                                                <Grid.Row columns={1}>
                                                    <Grid.Column>File name - {file.pictureAsFile.name} </Grid.Column>
                                                    <Grid.Column style={{display: 'flex', justifyContent: 'center'}}>File size
                                                        - {calculateStorage(file.pictureAsFile.size)} </Grid.Column>
                                                </Grid.Row>
                                            ) : null}
                                            {/* <Grid.Row>
                                                <Grid.Column>
                                                    <p>Up to 30MB for file types jpg .jpeg .png .webp .tiff .bmp .ai .gif .psd .pdf .raw</p>
                                                </Grid.Column>
                                            </Grid.Row> */}
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <hr/>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <span>Description:<span style={{color:'red'}}>*</span></span><br/>
                                        <MultilineTextBox
                                            wordCount={true}
                                            fluid={true}
                                            className={'other_exp'}
                                            id={'txtDescriptionText'}
                                            placeholder={''}
                                            value={companyProfileDesc}
                                            onChange={(e: any) => {
                                                setDescriptionText(e.target.value)
                                            }}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtCompany'} 
                                            fluid={true} 
                                            placeholder={'Enter Company'}
                                            value={state?.company}
                                            onChange={(e: any) => {
                                                setState({...state,company:e.target.value})
                                            }}
                                            labelText={"Company:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={true}
                                            multiLine={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtAddress'} 
                                            fluid={true} 
                                            placeholder={'Enter Address'}
                                            value={state?.address}
                                            onChange={(e: any) => {
                                                setState({...state,address:e.target.value})
                                            }}
                                            labelText={"Address:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={true}
                                            multiLine={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtSuburb'} 
                                            fluid={true} 
                                            placeholder={'Enter Suburb'}
                                            value={state?.suburb}
                                            onChange={(e: any) => {
                                                setState({...state,suburb:e.target.value})
                                            }}
                                            labelText={"Suburb"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtState'} 
                                            fluid={true} 
                                            placeholder={'Enter State'}
                                            value={state?.state}
                                            onChange={(e: any) => {
                                                setState({...state,state:e.target.value})
                                            }}
                                            labelText={"State:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={false}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtPostCode'} 
                                            fluid={true} 
                                            placeholder={'Enter Post Code'}
                                            value={state?.postCode}
                                            onChange={(e: any) => {
                                                setState({...state,postCode:e.target.value})
                                            }}
                                            labelText={"Post Code:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtTelephone'} 
                                            fluid={true} 
                                            placeholder={'Enter Telephone'}
                                            value={state?.telephone}
                                            onChange={(e: any) => {
                                                setState({...state,telephone:e.target.value})
                                            }}
                                            labelText={"Telephone:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtFax'} 
                                            fluid={true} 
                                            placeholder={'Enter Fax'}
                                            value={state?.fax}
                                            onChange={(e: any) => {
                                                setState({...state,fax:e.target.value})
                                            }}
                                            labelText={"Fax:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NameWithDefault
                                            id={'txtAboutUsLink'} 
                                            fluid={true} 
                                            placeholder={'Enter About Us Link'}
                                            value={state?.link}
                                            onChange={(e: any) => {
                                                setState({...state,link:e.target.value})
                                            }}
                                            labelText={"About Us Link:"} 
                                            labelPosition={Position.Top} 
                                            type={"text"}   
                                            required={true}
                                            multiLine={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
    
                        </Grid.Column>
                        <Grid.Column width={12} className={'button-flex'} style={{display:'flex', justifyContent:'right'}}>
                            <OutLineButton id={'btnCancel'} style={{width: '150px'}} onClick={() => {
                                ()=>{window.location.href = '/profile/Admin'}
                                }} 
                                color={'red'} 
                                className={"background-transaparent-red"}
                                text={'Cancel'}/>
                            <OutLineButton id={'btnSave'} 
                                style={{width: '150px'}} 
                                onClick={() => {
                                    onSaveClick()
                                }} 
                                color='blue' 
                                className={"background-transaparent"}
                                text={'Update'}
                                disabled={disableSave}
                            />
                        </Grid.Column>
                        <Grid.Column width={2}>
    
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                )
            }
        </Container>
    );
}
