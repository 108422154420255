//Import url from config file
import ApiCallAxio from "../../../../framework/common/apiCallAxio";
import APICallMethods from "../../../../framework/types/APICallMethods";
import {AuditorByCondition} from "../../../../models/apiResponce/Auditor/AuditorByCondition";
import moment from "moment";
import {ProfileLogRes} from "../../../../models/apiResponce/Auditor/ProfileLogRes";

const baseUrl = process.env.REACT_APP_API_BASEURL;
const baseUrl2 = process.env.REACT_APP_API_BASEURL2;
const sublink = process.env.REACT_APP_API_SUBLINK;
const version = process.env.REACT_APP_API_VERSION;

//to get the list of all the titles.
const fetchAuditorsLog = async (auditorId:number, pageNo:number) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorProfile/GetAuditLogForAuditor?AuditorId=${auditorId}&pageNo=${pageNo}`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objProfileLogRes: ProfileLogRes[] = result.auditLogList; //store the result in userTitles array
    const totalRecords:number = result.totalRecords;
    return {objProfileLogRes,totalRecords};//return the array
}


export {fetchAuditorsLog}