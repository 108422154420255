//Import url from config file
import ApiCall from "../../../../framework/common/apiCall";
import ApiCallAxio from "../../../../framework/common/apiCallAxio";
import APICallMethods from "../../../../framework/types/APICallMethods";

const baseUrl = process.env.REACT_APP_API_BASEURL;
const baseUrl2 = process.env.REACT_APP_API_BASEURL2;
const sublink = process.env.REACT_APP_API_SUBLINK;
const version = process.env.REACT_APP_API_VERSION;

//to get the list of all the titles.
const fetchAllFiles = async () => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/CommonCodes/notificationfile`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objNotificationRes: string[] = result.files; //store the result in userTitles array
    return objNotificationRes;//return the array
}

const uploadNotificationFiles = async (fileName:string,file:any) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/Files/NotificationUploadFile`; //complete url

    const headers = new Headers();

    //eslint-disable-next-line
    const formdata = new FormData();
    formdata.append("file",file.files[0])
    formdata.append('request',fileName)
    const result = await ApiCall({url: `${url}`, method: APICallMethods.POST, data:formdata, headers:headers, credentials:'include'}); //call the api using ApiCall method
    const objfilessArray: string = result.fileName; //store the result in userTitles array

    return objfilessArray;//return the array
}

const deleteNotificationFiles = async (fileName:string) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/Files/NotificationFileDelete?fileName=${fileName}`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.POST});//call the api using ApiCall method
    const objfilessArray: string = result.fileName; //store the result in userTitles array

    return objfilessArray;//return the array
}


export {fetchAllFiles, uploadNotificationFiles, deleteNotificationFiles}