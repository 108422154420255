import React,{useState} from "react";
import {Container, Grid} from "semantic-ui-react";
import InnerTitleBar from "../../../../framework/layouts/InnerTitleBar";
import {userRoleSelector} from "../../../../common/roleSelector";
import {getSessionCookie} from "../../../../framework/common/SessionHandler";
import DateRangeBox from "../../../../framework/components/TextBoxes/DateRangeBox";
import DefaultButton from "../../../../framework/components/Buttons/DefaultButton";
import moment from "moment/moment";
import ErrorToast from "../../../../framework/components/Toast/ErrorToast";
import OutLineButton from "../../../../framework/components/Buttons/OutlIneButton";
import {fetchAuditors, fetchAuditorsByCondition, fetchAudits} from "./NumberOfAuditorsController";


const NumberofAuditsContainer = () => {
    const session = getSessionCookie();
    const baseUrl = process.env.REACT_APP_API_BASEURL;
    const baseUrl2 = process.env.REACT_APP_API_BASEURL2;
    const [dateRange, setDateRange] = useState('')
    const [msgTest, SetMsgTest] = useState('')
    const [count, SetCount] = useState<number>()
    const [total,setTotal]= useState<string>()
    const [level1Count, setLevel1Count] = useState<string>()
    const [level2Count, setLevel2Count] = useState<string>()
    const [level3Count, setLevel3Count] = useState<string>()
    const [level1Url,setLevel1Url] = useState<string>()
    const [level2Url,setLevel2Url] = useState<string>()
    const [level3Url,setLevel3Url] = useState<string>()

    const handleAuditorsClick = async () =>{
        const dates = dateRange.split('-')
        const AuditorsCount = await fetchAuditors(moment(dates[0], "DD/MM/YYYY").toString(),moment(dates[1], "DD/MM/YYYY").toString());
        console.log(AuditorsCount)
        SetCount(parseInt(AuditorsCount))
    }

    const handleAuditsClick = async () =>{
        const dates = dateRange.split('-')
        const AuditsCount = await fetchAudits(moment(dates[0], "DD/MM/YYYY").toString(),moment(dates[1], "DD/MM/YYYY").toString());
        SetCount(parseInt(AuditsCount))
    }

    const handleAuditorsConditionClick = async () =>{
        const AuditorsCon = await fetchAuditorsByCondition();
        setTotal(AuditorsCon.total.toString())
        setLevel1Count(AuditorsCon.totalLevel1.toString())
        setLevel2Count(AuditorsCon.totalLevel2.toString())
        setLevel3Count(AuditorsCon.totalLevel3.toString())
        if(AuditorsCon.fileUrl1 != 'No file is found!'){
            setLevel1Url(`${baseUrl2 ? baseUrl2 : baseUrl}/${AuditorsCon.fileUrl1}`)
        }
        if(AuditorsCon.fileUrl2 != 'No file is found!'){
            setLevel2Url(`${baseUrl2 ? baseUrl2 : baseUrl}/${AuditorsCon.fileUrl2}`)
        }
        if(AuditorsCon.fileUrl3 != 'No file is found!'){
            setLevel3Url(`${baseUrl2 ? baseUrl2 : baseUrl}/${AuditorsCon.fileUrl3}`)
        }
    }

    return(
        <Container fluid={true} style={{marginTop: '14px', marginBottom: '20px'}}>
            <InnerTitleBar
                title={"Number of Auditors & Audits Records"}
                usreName={session.userDisplayName != null ? session.userDisplayName : "  "}
                userRole={userRoleSelector(session.userRoleId.toString())}
                lastLogin={session.lastLogin.toString()}
                status={userRoleSelector(session.userRoleId.toString())}
            />
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                    
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                    
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                    
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                    
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <p><b>Total number of Auditors/Road Safety Audits in the system:</b></p>
                        <p>Please specify date range and click on &lsquo;Number of auditors&lsquo; button to get the total number of auditors added during the specific date range or click on &lsquo;Number of road safety audits&lsquo; to get the total number of road safety audits added during the specific date range.</p>
                        <p>Please leave date range blank and click on &lsquo;Number of auditors&lsquo;, to get the total number of auditors in the system or click on &lsquo;Number of road safety audits&lsquo; to get the total number of road safety audits in the system.</p>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={3}>
                                <Grid.Column width={7} mobile={16} computer={7} tablet={16}>
                                    <DateRangeBox
                                        id={'txtFromDate'}
                                        fluid={true}
                                        placeholder={'From Date - To Date'}
                                        onChange={(e:any,data:any) => {setDateRange(data.value)}}
                                        value={dateRange}
                                    />
                                </Grid.Column>
                                <Grid.Column width={4} mobile={16} computer={4} tablet={8}>
                                    <OutLineButton id={'btnSearch'}
                                                   className={"background-transaparent"}
                                                   style={{width: '250px'}} onClick={() => {
                                        SetMsgTest('road safety auditors')
                                        handleAuditorsClick()

                                    }} color={'blue'} text={'Number of Auditors'}/>
                                </Grid.Column>&nbsp;&nbsp;&nbsp;&nbsp;
                                <Grid.Column width={4} mobile={16} computer={4} tablet={8}>
                                    <OutLineButton id={'btnSearch'}
                                                   className={"background-transaparent"}
                                                   style={{width: '250px'}} onClick={() => {
                                        SetMsgTest('road safety audits')
                                        handleAuditsClick()

                                    }} color={'blue'} text={'Number of Audits'}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                {count !== undefined ? (<Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <p><b>{`Total number of ${msgTest} added between ${dateRange} is ${count}.`}</b></p>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>) : null}
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <hr/>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={3}>
                                <Grid.Column width={7} mobile={16} computer={7} tablet={16}>
                                    <p><b>Total number of Auditors who will require audit inclusion or training in next 3 months:</b></p>
                                </Grid.Column>
                                <Grid.Column width={4}>

                                </Grid.Column>&nbsp;
                                <Grid.Column width={4} mobile={16} computer={4} tablet={16}>
                                    <OutLineButton id={'btnSearch'}
                                                   className={"background-transaparent"}
                                                   style={{width: '250px'}} onClick={handleAuditorsConditionClick} color={'blue'} text={'Number of Auditors'}/>
                                </Grid.Column>
                            </Grid.Row>
                            {total ? (<Grid.Row columns={3}>
                                <Grid.Column width={12}>
                                    <span>The total number of auditors who will require audit inclusion or training in next 3 month is <b>{total}</b>.</span><br/>
                                    <span>The total number of level 1 Auditors is {level1Url ? (<a href={level1Url}><b>{level1Count}</b></a>) : (<b>{level1Count}</b>)}.</span><br/>
                                    <span>The total number of level 2 Auditors is {level2Url ? (<a href={level2Url}><b>{level2Count}</b></a>) : (<b>{level2Count}</b>)}.</span><br/>
                                    <span>The total number of level 3 Auditors is {level3Url ? (<a href={level3Url}><b>{level3Count}</b></a>) : (<b>{level3Count}</b>)}.</span>
                                </Grid.Column>
                                <Grid.Column width={2}>

                                </Grid.Column>
                            </Grid.Row>) : null }
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
}

export default NumberofAuditsContainer