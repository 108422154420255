import React, {useEffect, useRef, useState} from "react";
import InnerTitleBar from "../../framework/layouts/InnerTitleBar";
import {Container, Grid, Table} from "semantic-ui-react";
//import {AuditorRegionValueProps, QualificationValueProps} from "../auditor/profile/DataModal";
import NameWithCheckBox from "../../framework/widgets/NameWithCheckBox/NameWithCheckBox";
import Position from "../../framework/types/Position";
import NameWithDefault from "../../framework/widgets/NameWithText/NameWithDefault";
import NameWithDropDown from "../../framework/widgets/NameWithDropDown";
import DropDownOptions from "../../framework/types/DropDownOptions";
import {SearchReq} from "../../models/apiRequest/Auditor/SearchReq";
import {SearchApiCall} from "./SearchController";
import {AuditorSearchResRow} from "../../models/apiResponce/Auditor/SearchRes";
import {Pagination, PaginationProps} from "antd";
import OutLineButton from "../../framework/components/Buttons/OutlIneButton";
import {Oval} from "react-loader-spinner";
import NameWithEmail from "../../framework/widgets/NameWithText/NameWithEmail";
import { raw } from "body-parser";
import { getSessionCookie } from "../../framework/common/SessionHandler";
import moment from "moment";
import { P } from "@antv/g2plot";

interface Props {
    //eslint-disable-next-line
    states: any,
    focusMode: string,
    //eslint-disable-next-line
    setFocusMode: any,
    //eslint-disable-next-line
    setStateRegion: any;
}

const PublicSearch = (props: Props) => {
    const [isSearch, setIsSearch] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [record, setRecord] = useState<number>(0);
    const [searchRes, setSearchres] = useState<AuditorSearchResRow[]>([])
    const [state, setState] = React.useState<SearchReq>({pageSize: 10, pageNumStartingZero: 0, countryStateId: 1});

    return (
        <Container fluid={true} style={{marginTop: '14px', marginBottom: '14px'}}>
            <InnerTitleBar
                title={'Search for Auditors'}
                usreName={''}
                userRole={""}
                lastLogin={''}
                status={''}
            />
            <Grid>
                <Grid.Row>
                    <Grid.Column width={1}>

                    </Grid.Column>
                    <Grid.Column widescreen={3} computer={3} largeScreen={3} only='computer wideScreen, largeScreen'>
                        {isSearch ? (<SideSearchOptions
                            focusMode={props.focusMode}
                            setFocusMode={props.setFocusMode}
                            setStateRegion={props.setStateRegion}
                            states={props.states}
                            setIsSearch={setIsSearch}
                            setRecord={setRecord}
                            setSearchres={setSearchres}
                            setState={setState}
                            stateSearch={state}
                            setIsLoading={setIsLoading}
                        />) : null}
                    </Grid.Column>
                    <Grid.Column width={11} computer={11} tablet={11} widescreen={11} largeScreen={11} mobile={14}>
                        {isSearch ? isLoading ? (<div style={{height: '40vh', marginTop: '30vh', display:'flex', justifyContent:'center'}}>
                            <Oval
                                height={80}
                                width={80}
                                color="#4fa94d"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="#4fa94d"
                                strokeWidth={2}
                                strokeWidthSecondary={2}

                            /></div>) : (
                            <SearchResult
                                searchResults={searchRes}
                                recordCount={record}
                                setIsSearch={setIsSearch}
                                state={state}
                            />
                        ) : (
                            <SearchOptions
                                focusMode={props.focusMode}
                                setFocusMode={props.setFocusMode}
                                setStateRegion={props.setStateRegion}
                                states={props.states}
                                setIsSearch={setIsSearch}
                                setRecord={setRecord}
                                setSearchres={setSearchres}
                                setState={setState}
                                setIsLoading={setIsLoading}
                            />)}
                    </Grid.Column>
                    <Grid.Column width={1}>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    )
}

export default PublicSearch;

interface AvailabiltyProps {
    //eslint-disable-next-line
    focusMode: any
    //eslint-disable-next-line
    setFocusMode: any
    //eslint-disable-next-line
    setStateRegion: any
    //eslint-disable-next-line
    states: any
    setIsSearch: React.Dispatch<React.SetStateAction<boolean>>
    setRecord:  React.Dispatch<React.SetStateAction<number>>,
    setSearchres:  React.Dispatch<React.SetStateAction<AuditorSearchResRow[]>>
    setState:  React.Dispatch<React.SetStateAction<SearchReq>>
    stateSearch?:SearchReq
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}

// interface StateProps extends AuditorRegionValueProps, QualificationValueProps {
//     AuditorName?: string,
//     CompanyName?: string,
//     level1?: boolean,
//     level2?: boolean,
//     level3?: boolean,
//     states?: any,
//     postcode?: string,
//     proximity?: string,
//     RD?: string,
//     RSE?: string,
//     TE?: string,
//     BS?: string,
//     TTM?: string,
// }

const SearchOptions = (props: AvailabiltyProps) => {

    const Session = getSessionCookie();
    const [state, setState] = React.useState<SearchReq>({pageSize: 10, pageNumStartingZero: 0, countryStateId: 1});
    const [stages, setStages] = useState<DropDownOptions[]>([]);
    const [nsw, setNsw] = React.useState<boolean>(false);
    //eslint-disable-next-line
    const [isEdit, setIsEdit] = React.useState<boolean>(true);
    const experience = [{key: 1, text: 'Any Years', value: '1'},
        {key: 2, text: 'Over 1 year', value: '2'},
        {key: 3, text: 'Over 3 years', value: '3'},
        {key: 4, text: 'Over 5 years', value: '4'},
        {key: 5, text: 'Over 10 years', value: '5'}
    ]

    useEffect(() => {
        props.setStateRegion(state);
    }, [state])

    useEffect(() => {
        Promise.resolve(props.states).then((value) => {
            setStages(value)
        })
    }, [props.states])

    const OnSearch = () => {
        if(Session != null && (Session.userRoleId == 1 || Session.userRoleId == 4)) {
            const searchData = SearchApiCall(state)

            Promise.resolve(searchData).then((value) => {
                if (value) {
                    props.setRecord(value.totalRows)
                    props.setSearchres(value.auditorSearchRess)
                    props.setState(state)
                }
                props.setIsSearch(true);
            })
        } else {
            const searchData = SearchApiCall(state)

            Promise.resolve(searchData).then((value) => {
                if (value) {
                    props.setRecord(value.totalRows)
                    props.setSearchres(value.auditorSearchRess)
                    props.setState(state)
                }
                props.setIsSearch(true);
            })
        }


    }
    const CheckArraySet = (Checked: boolean, value: number, item?: number[]) => {
        let tempVal = item
        if (Checked) {
            tempVal ? tempVal.push(value) : tempVal = [value]
        } else {
            if (tempVal != undefined && tempVal.includes(value)) {
                tempVal = tempVal.filter(r => r != value)
            }
        }

        return tempVal
    }

    const gridRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      const handleScroll = () => {
        if (gridRef.current) {
          gridRef.current.style.overflowY = 'auto';
        }
      };

      const currentRef = gridRef.current;

      if (currentRef) {
        currentRef.addEventListener('mouseover', handleScroll);
      }

      return () => {
        if (currentRef) {
          currentRef.removeEventListener('mouseover', handleScroll);
        }
      };
    }, []);

    return (
        <React.Fragment>
            <Grid>
                <Grid.Row columns={2}>
                    <Grid.Column width={16} className='button-flex' style={{justifyContent: 'end'}}>
                        <OutLineButton id={'btnSearchBottom'} onClick={OnSearch} color='blue'
                                       className={"background-transaparent"} text='Search'
                                       disabled={!isEdit}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <span style={{display: 'inline-flex'}}><b>Auditor(s) details</b></span>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column width={8} widescreen={8} computer={8} tablet={16} mobile={16}>
                        <NameWithDefault labelText={'Name'} labelPosition={Position.Top}
                                         id={'txtName'} fluid={true} type={'text'}
                                         placeholder={'Enter Name'}
                                         required={false}
                                         value={state?.name}
                            //onError={fnameError}
                            //eslint-disable-next-line
                                         onChange={(e: any) => {
                                             setState({...state, name: e.target.value});
                                         }}
                                         disabled={!isEdit}
                        />
                    </Grid.Column>
                    <Grid.Column width={8} widescreen={8} computer={8} tablet={16} mobile={16}>
                        <NameWithDefault labelText={'Company/Organisation'} labelPosition={Position.Top}
                                         id={'txtCompany'} fluid={true} type={'text'}
                                         placeholder={'Enter Company/Organisation'}
                                         required={false}
                                         value={state?.companyOrOrganisation}
                            //onError={fnameError}
                            //eslint-disable-next-line
                                         onChange={(e: any) => {
                                             setState({...state, companyOrOrganisation: e.target.value});
                                         }}
                                         disabled={!isEdit}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column width={8} widescreen={8} computer={8} tablet={16} mobile={16}>
                        <NameWithEmail labelText={'Email'} labelPosition={Position.Top}
                                         id={'txtEmail'} fluid={true} type={'text'}
                                         placeholder={'Enter Email'}
                                         required={false}
                                         value={state?.email}
                                         onChange={(e: any) => {
                                             setState({...state, email: e.target.value});
                                         }}
                                         disabled={!isEdit}
                        />
                    </Grid.Column>
                    <Grid.Column width={8} widescreen={8} computer={8} tablet={16} mobile={16}>
                    </Grid.Column>
                </Grid.Row>
                {/**######################################################################################################*/}
                <Grid.Row>
                    <Grid.Column width={16}>
                        <span style={{display: 'inline-flex'}}><b>Level of accreditation</b></span>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Auditor (Level 1)'} name={'level'} id={'level1'}
                                          value={state?.auditorLevels?.includes(1)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                              setState({
                                                  ...state,
                                                  auditorLevels: CheckArraySet(e.target.checked, 1, state?.auditorLevels)
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Senior Auditor (Level 2)'} name={'level'} id={'level2'}
                                          value={state?.auditorLevels?.includes(2)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                              setState({
                                                  ...state,
                                                  auditorLevels: CheckArraySet(e.target.checked, 2, state?.auditorLevels)
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Lead Auditor (Level 3)'} name={'level'} id={'level3'}
                                          value={state?.auditorLevels?.includes(3)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                              setState({
                                                  ...state,
                                                  auditorLevels: CheckArraySet(e.target.checked, 3, state?.auditorLevels)
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                </Grid.Row>
                {
                    (Session != null && (Session.userRoleId == 1 || Session.userRoleId == 4)) ?(
                            <>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <span style={{display: 'inline-flex'}}><b>Auditor status</b></span>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={3}>
                                    <Grid.Column width={5}>
                                        <NameWithCheckBox label={'New application'} name={'auditorStatusIds'} id={'newApplication'}
                                                        value={state?.auditorStatusIds?.includes(1)}
                                                        //eslint-disable-next-line
                                                        onChange={(e: any, data: any) => {
                                                            const checkedStat = CheckArraySet(e.target.checked, 1, state?.auditorStatusIds)
                                                            setState({
                                                                ...state,
                                                                auditorStatusIds: checkedStat
                                                            });
                                                        }} disabled={!isEdit}/>
                                    </Grid.Column>
                                    <Grid.Column width={5} style={{marginRight: '7px'}}>
                                        <NameWithCheckBox label={'Application in review'} name={'auditorStatusIds'} id={'applicationInReview'}
                                                        value={state?.auditorStatusIds?.includes(2)}
                                                        //eslint-disable-next-line
                                                        onChange={(e: any, data: any) => {
                                                            const checkedStat = CheckArraySet(e.target.checked, 2, state?.auditorStatusIds)
                                                            setState({
                                                                ...state,
                                                                auditorStatusIds: checkedStat
                                                            });
                                                        }} disabled={!isEdit}/>
                                    </Grid.Column>
                                    <Grid.Column width={5}>
                                        <NameWithCheckBox label={'Application assessment'} name={'auditorStatusIds'} id={'applicationAssessment'}
                                                        value={state?.auditorStatusIds?.includes(7)}
                                                        //eslint-disable-next-line
                                                        onChange={(e: any, data: any) => {
                                                            const checkedStat = CheckArraySet(e.target.checked, 7, state?.auditorStatusIds)
                                                            setState({
                                                                ...state,
                                                                auditorStatusIds: checkedStat
                                                            });
                                                        }} disabled={!isEdit}/>
                                    </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                    <Grid.Column width={5}>
                                        <NameWithCheckBox label={'Active'} name={'auditorStatusIds'} id={'active'}
                                                        value={state?.auditorStatusIds?.includes(3)}
                                                        //eslint-disable-next-line
                                                        onChange={(e: any, data: any) => {
                                                            const checkedStat = CheckArraySet(e.target.checked, 3, state?.auditorStatusIds)
                                                            setState({
                                                                ...state,
                                                                auditorStatusIds: checkedStat
                                                            });
                                                        }} disabled={!isEdit}/>
                                    </Grid.Column>
                                    <Grid.Column width={5} style={{marginRight: '7px'}}>
                                        <NameWithCheckBox label={'Level review'} name={'auditorStatusIds'} id={'levelReview'}
                                                        value={state?.auditorStatusIds?.includes(4)}
                                                        //eslint-disable-next-line
                                                        onChange={(e: any, data: any) => {
                                                            const checkedStat = CheckArraySet(e.target.checked, 4, state?.auditorStatusIds)
                                                            setState({
                                                                ...state,
                                                                auditorStatusIds: checkedStat
                                                            });
                                                        }} disabled={!isEdit}/>
                                    </Grid.Column>
                                    <Grid.Column width={5}>
                                        <NameWithCheckBox label={'Elapsed'} name={'auditorStatusIds'} id={'elapsed'}
                                                        value={state?.auditorStatusIds?.includes(5)}
                                                        //eslint-disable-next-line
                                                        onChange={(e: any, data: any) => {
                                                            const checkedStat = CheckArraySet(e.target.checked, 5, state?.auditorStatusIds)
                                                            setState({
                                                                ...state,
                                                                auditorStatusIds: checkedStat
                                                            });
                                                        }} disabled={!isEdit}/>
                                    </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                    <Grid.Column width={16}>
                                        <NameWithCheckBox label={'Inactive'} name={'auditorStatusIds'} id={'inactive'}
                                                        value={state?.auditorStatusIds?.includes(6)}
                                                        //eslint-disable-next-line
                                                        onChange={(e: any, data: any) => {
                                                            const checkedStat = CheckArraySet(e.target.checked, 6, state?.auditorStatusIds)
                                                            setState({
                                                                ...state,
                                                                auditorStatusIds: checkedStat
                                                            });
                                                        }} disabled={!isEdit}/>
                                    </Grid.Column>
                                </Grid.Row>
                            </>
                            ):(null)
                }
                <Grid.Row>
                    <Grid.Column width={16}>
                        <span style={{display: 'inline-flex'}}><b>Location of auditor</b></span>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithDropDown
                            selection={true}
                            labelText={'State'}
                            labelPosition={Position.Top}
                            id={'State'}
                            placeholder={'Enter State'}
                            options={stages}
                            required={false}
                            clearable={true}
                            value={state?.auditorLocationStateId ? state?.auditorLocationStateId.toString() : '0'}
                            //eslint-disable-next-line
                            onChange={(e: any, data: any) => {
                                e.preventDefault();
                                setState({...state, auditorLocationStateId: data.value});
                            }}
                            disabled={!isEdit}

                        />
                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithDefault labelText={'Postcode'} labelPosition={Position.Top}
                                         id={'txtPostcode'} fluid={true} type={'text'}
                                         placeholder={'Enter Postcode'}
                                         required={false}
                                         value={state?.auditorLocationPostCode}
                            //onError={fnameError}
                            //eslint-disable-next-line
                                         onChange={(e: any) => {
                                             setState({...state, auditorLocationPostCode: e.target.value});
                                         }}
                                         disabled={!isEdit}
                        />
                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithDropDown
                            selection={true}
                            labelText={'Proximity'}
                            labelPosition={Position.Top}
                            id={'Proximity'}
                            placeholder={'Enter Proximity'}
                            options={[{key: 1, text: 'Within 0km', value: '1'},
                                {key: 2, text: 'Within 5km', value: '2'},
                                {key: 3, text: 'Within 10km', value: '3'},
                                {key: 4, text: 'Within 20km', value: '4'},
                                {key: 5, text: 'Within 50km', value: '5'},
                                {key: 6, text: 'Within 100km', value: '6'},
                                {key: 7, text: 'Within 250km', value: '7'},
                                {key: 8, text: 'Within 500km', value: '8'},
                            ]}
                            required={false}
                            clearable={true}
                            value={state?.auditorLocationProximityInKm ? state?.auditorLocationProximityInKm.toString() : '0'}
                            //eslint-disable-next-line
                            onChange={(e: any, data: any) => {
                                e.preventDefault();
                                setState({...state, auditorLocationProximityInKm: data.value});
                            }}
                            disabled={!isEdit}

                        />
                    </Grid.Column>
                </Grid.Row>
                {/*#######################################################################################################*/}
                <Grid.Row>
                    <Grid.Column width={16}>
                        <span style={{display: 'inline-flex'}}><b>Region available to work in</b></span>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'New South Wales'} name={'state'} id={'nsw'} value={nsw}
                                          onChange={() => {
                                              setNsw(!nsw);
                                          }} disabled={!isEdit}/>
                        <Grid.Row columns={1}>
                            <Grid.Column style={{paddingLeft: '20px'}}>
                                <NameWithCheckBox label={'Greater Sydney'} name={'state'} id={'grate'}
                                                  value={state?.regionIdsAvailableToWorkIn?.includes(1)}
                                    //eslint-disable-next-line
                                                  onChange={(e: any, data: any) => {
                                                      setState({
                                                          ...state,
                                                          regionIdsAvailableToWorkIn: CheckArraySet(e.target.checked, 1, state?.regionIdsAvailableToWorkIn)
                                                      });
                                                  }} disabled={!isEdit}/>
                            </Grid.Column>
                            <Grid.Column style={{paddingLeft: '20px'}}>
                                <NameWithCheckBox label={'North'} name={'state'} id={'north'}
                                                  value={state?.regionIdsAvailableToWorkIn?.includes(2)}
                                    //eslint-disable-next-line
                                                  onChange={(e: any, data: any) => {
                                                      setState({
                                                          ...state,
                                                          regionIdsAvailableToWorkIn: CheckArraySet(e.target.checked, 2, state?.regionIdsAvailableToWorkIn)
                                                      });
                                                  }} disabled={!isEdit}/>
                            </Grid.Column>
                            <Grid.Column style={{paddingLeft: '20px'}}>
                                <NameWithCheckBox label={'South'} name={'state'} id={'south'}
                                                  value={state?.regionIdsAvailableToWorkIn?.includes(3)}
                                    //eslint-disable-next-line
                                                  onChange={(e: any, data: any) => {
                                                      setState({
                                                          ...state,
                                                          regionIdsAvailableToWorkIn: CheckArraySet(e.target.checked, 3, state?.regionIdsAvailableToWorkIn)
                                                      });
                                                  }} disabled={!isEdit}/>
                            </Grid.Column>
                            <Grid.Column style={{paddingLeft: '20px'}}>
                                <NameWithCheckBox label={'West'} name={'state'} id={'west'}
                                                  value={state?.regionIdsAvailableToWorkIn?.includes(4)}
                                    //eslint-disable-next-line
                                                  onChange={(e: any, data: any) => {
                                                      setState({
                                                          ...state,
                                                          regionIdsAvailableToWorkIn: CheckArraySet(e.target.checked, 4, state?.regionIdsAvailableToWorkIn)
                                                      });
                                                  }} disabled={!isEdit}/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Victoria'} name={'state'} id={'victoria'}
                                          value={state?.regionIdsAvailableToWorkIn?.includes(5)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                              setState({
                                                  ...state,
                                                  regionIdsAvailableToWorkIn: CheckArraySet(e.target.checked, 5, state?.regionIdsAvailableToWorkIn)
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Western Australia'} name={'state'} id={'wa'}
                                          value={state?.regionIdsAvailableToWorkIn?.includes(6)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                              setState({
                                                  ...state,
                                                  regionIdsAvailableToWorkIn: CheckArraySet(e.target.checked, 6, state?.regionIdsAvailableToWorkIn)
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Australia Capital Region'} name={'state'} id={'act'}
                                          value={state?.regionIdsAvailableToWorkIn?.includes(7)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                              setState({
                                                  ...state,
                                                  regionIdsAvailableToWorkIn: CheckArraySet(e.target.checked, 7, state?.regionIdsAvailableToWorkIn)
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Queensland'} name={'state'} id={'qld'}
                                          value={state?.regionIdsAvailableToWorkIn?.includes(8)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                              setState({
                                                  ...state,
                                                  regionIdsAvailableToWorkIn: CheckArraySet(e.target.checked, 8, state?.regionIdsAvailableToWorkIn)
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'South Australia'} name={'state'} id={'sa'}
                                          value={state?.regionIdsAvailableToWorkIn?.includes(9)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                              setState({
                                                  ...state,
                                                  regionIdsAvailableToWorkIn: CheckArraySet(e.target.checked, 9, state?.regionIdsAvailableToWorkIn)
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Tasmania'} name={'state'} id={'tas'}
                                          value={state?.regionIdsAvailableToWorkIn?.includes(10)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                              setState({
                                                  ...state,
                                                  regionIdsAvailableToWorkIn: CheckArraySet(e.target.checked, 10, state?.regionIdsAvailableToWorkIn)
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Northern Territory'} name={'state'} id={'nt'}
                                          value={state?.regionIdsAvailableToWorkIn?.includes(11)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                              setState({
                                                  ...state,
                                                  regionIdsAvailableToWorkIn: CheckArraySet(e.target.checked, 11, state?.regionIdsAvailableToWorkIn)
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Overseas'} name={'state'} id={'os'}
                                          value={state?.regionIdsAvailableToWorkIn?.includes(12)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                              setState({
                                                  ...state,
                                                  regionIdsAvailableToWorkIn: CheckArraySet(e.target.checked, 12, state?.regionIdsAvailableToWorkIn)
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                </Grid.Row>
                {/*#############################################################################################################################*/}
                <Grid.Row>
                    <Grid.Column width={16}>
                        <span style={{display: 'inline-flex'}}><b>Relevant professional experience</b></span>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column width={8} widescreen={8} computer={8} tablet={16} mobile={16}>
                        <NameWithDropDown
                            selection={true}
                            labelText={'Road Design'}
                            labelPosition={Position.Left}
                            id={'RoadDesign'}
                            placeholder={'Enter Road Design'}
                            options={experience}
                            required={false}
                            fluid={true}
                            clearable={true}
                            value={state?.roadDesign ? state?.roadDesign.toString() : '0'}
                            //eslint-disable-next-line
                            onChange={(e: any, data: any) => {
                                e.preventDefault();
                                setState({...state, roadDesign: data.value});
                            }}
                            disabled={!isEdit}

                        />
                    </Grid.Column>
                    <Grid.Column width={8} widescreen={8} computer={8} tablet={16} mobile={16}>
                        <NameWithDropDown
                            selection={true}
                            labelText={'Road Safety Engineering'}
                            labelPosition={Position.Left}
                            id={'RoadSafetyEngineering'}
                            placeholder={'Enter Road Safety Engineering'}
                            options={experience}
                            required={false}
                            fluid={true}
                            clearable={true}
                            value={state?.roadSafetyEngineering ? state?.roadSafetyEngineering.toString() : '0'}
                            //eslint-disable-next-line
                            onChange={(e: any, data: any) => {
                                e.preventDefault();
                                setState({...state, roadSafetyEngineering: data.value});
                            }}
                            disabled={!isEdit}

                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column width={8} widescreen={8} computer={8} tablet={16} mobile={16}>
                        <NameWithDropDown
                            selection={true}
                            labelText={'Traffic Engineering'}
                            labelPosition={Position.Left}
                            id={'TrafficEngineering'}
                            placeholder={'Enter Traffic Engineering'}
                            options={experience}
                            required={false}
                            clearable={true}
                            fluid={true}
                            value={state?.trafficEngineering ? state?.trafficEngineering.toString() : '0'}
                            //eslint-disable-next-line
                            onChange={(e: any, data: any) => {
                                e.preventDefault();
                                setState({...state, trafficEngineering: data.value});
                            }}
                            disabled={!isEdit}

                        />
                    </Grid.Column>
                    <Grid.Column width={8} widescreen={8} computer={8} tablet={16} mobile={16}>
                        <NameWithDropDown
                            selection={true}
                            labelText={'Behavioural Science'}
                            labelPosition={Position.Left}
                            id={'BehaviouralScience'}
                            placeholder={'Enter Behavioural Science'}
                            options={experience}
                            required={false}
                            fluid={true}
                            clearable={true}
                            value={state?.behaviouralScience ? state?.behaviouralScience.toString() : '0'}
                            //eslint-disable-next-line
                            onChange={(e: any, data: any) => {
                                e.preventDefault();
                                setState({...state, behaviouralScience: data.value});
                            }}
                            disabled={!isEdit}

                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column width={8} widescreen={8} computer={8} tablet={16} mobile={16}>
                        <NameWithDropDown
                            selection={true}
                            labelText={'Traffic/Transport Management'}
                            labelPosition={Position.Left}
                            id={'Traffic/TransportManagement'}
                            placeholder={'Enter Traffic/Transport Management'}
                            options={experience}
                            required={false}
                            fluid={true}
                            clearable={true}
                            value={state?.trafficTransportManagement ? state?.trafficTransportManagement.toString() : '0'}
                            //eslint-disable-next-line
                            onChange={(e: any, data: any) => {
                                e.preventDefault();
                                setState({...state, trafficTransportManagement: data.value});
                            }}
                            disabled={!isEdit}

                        />
                    </Grid.Column>
                    <Grid.Column width={8} widescreen={8} computer={8} tablet={16} mobile={16}>

                    </Grid.Column>
                </Grid.Row>
                {/*#############################################################################################################################*/}
                <Grid.Row>
                    <Grid.Column width={16}>
                        <span style={{display: 'inline-flex'}}><b>Specific expertise</b></span>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Construction Projects'} name={'state'} id={'constructionProject'}
                                          value={state?.specificExpertiseIds?.includes(1)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                              setState({
                                                  ...state,
                                                  specificExpertiseIds: CheckArraySet(e.target.checked, 1, state?.specificExpertiseIds)
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Reconstruction Projects'} name={'state'} id={'reconstructionProjects'}
                                          value={state?.specificExpertiseIds?.includes(2)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                              setState({
                                                  ...state,
                                                  specificExpertiseIds: CheckArraySet(e.target.checked, 2, state?.specificExpertiseIds)
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Intersection Projects'} name={'state'} id={'intersectionProjects'}
                                          value={state?.specificExpertiseIds?.includes(3)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                              setState({
                                                  ...state,
                                                  specificExpertiseIds: CheckArraySet(e.target.checked, 3, state?.specificExpertiseIds)
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Local Area Traffic Management Projects'} name={'state'}
                                          id={'localAreaTrafficManagementProjects'}
                                          value={state?.specificExpertiseIds?.includes(4)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                              setState({
                                                  ...state,
                                                  specificExpertiseIds: CheckArraySet(e.target.checked, 4, state?.specificExpertiseIds)
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Traffic Signal Projects'} name={'state'} id={'trafficSignalProjects'}
                                          value={state?.specificExpertiseIds?.includes(6)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                              setState({
                                                  ...state,
                                                  specificExpertiseIds: CheckArraySet(e.target.checked, 6, state?.specificExpertiseIds)
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Temporary Traffic Management'} name={'state'}
                                          id={'temporaryTrafficManagement'}
                                          value={state?.specificExpertiseIds?.includes(7)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                              setState({
                                                  ...state,
                                                  specificExpertiseIds: CheckArraySet(e.target.checked, 7, state?.specificExpertiseIds)
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Development Planning'} name={'state'} id={'developmentPlanning'}
                                          value={state?.specificExpertiseIds?.includes(8)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                              setState({
                                                  ...state,
                                                  specificExpertiseIds: CheckArraySet(e.target.checked, 8, state?.specificExpertiseIds)
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Design & Construction Projects'} name={'state'}
                                          id={'designConstructionProjects'}
                                          value={state?.specificExpertiseIds?.includes(9)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                              setState({
                                                  ...state,
                                                  specificExpertiseIds: CheckArraySet(e.target.checked, 9, state?.specificExpertiseIds)
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Crash Investigation and Analyses'} name={'state'}
                                          id={'crashInvestigation'}
                                          value={state?.specificExpertiseIds?.includes(10)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                              setState({
                                                  ...state,
                                                  specificExpertiseIds: CheckArraySet(e.target.checked, 10, state?.specificExpertiseIds)
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Vulnerable Road User (Pedestrians, cyclists, motorcyclists)'}
                                          name={'state'} id={'vulnerableRoadUser'}
                                          value={state?.specificExpertiseIds?.includes(5)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                              setState({
                                                  ...state,
                                                  specificExpertiseIds: CheckArraySet(e.target.checked, 5, state?.specificExpertiseIds)
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>

                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column width={16} className='button-flex' style={{justifyContent: 'end'}}>
                        <OutLineButton id={'btnSearchBottom'} onClick={OnSearch} color='blue'
                                       className={"background-transaparent"} text='Search'
                                       disabled={!isEdit}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </React.Fragment>
    );
}

const SideSearchOptions = (props: AvailabiltyProps) => {
    const Session = getSessionCookie();
    const [state, setState] = React.useState<SearchReq>(props.stateSearch ? props.stateSearch : {pageSize: 10, pageNumStartingZero: 0, countryStateId: 1} );
    const [stages, setStages] = useState<DropDownOptions[]>([]);
    const [nsw, setNsw] = React.useState<boolean>(false);
    //eslint-disable-next-line
    const [isEdit, setIsEdit] = React.useState<boolean>(true);
    const experience = [{key: 1, text: 'Any Years', value: '1'},
        {key: 2, text: 'Over 1 year', value: '2'},
        {key: 3, text: 'Over 3 years', value: '3'},
        {key: 4, text: 'Over 5 years', value: '4'},
        {key: 5, text: 'Over 10 years', value: '5'}
    ]

    useEffect(() => {
        props.setStateRegion(state);
    }, [state])

    useEffect(() => {
        Promise.resolve(props.states).then((value) => {
            setStages(value)
        })
    }, [props.states])
    useEffect(()=>{
        OnSearch()
    },[state])

    const OnSearch = () => {
        props.setIsLoading(true)
        if(Session != null && (Session.userRoleId == 1 || Session.userRoleId == 4)) {
            const searchData = SearchApiCall(state)
            Promise.resolve(searchData).then((value) => {
                if (value) {
                    props.setRecord(value.totalRows)
                    props.setSearchres(value.auditorSearchRess)
                    props.setState(state)
                }
                props.setIsLoading(false)
                props.setIsSearch(true);
            })
        } else {
            const searchData = SearchApiCall(state)
            Promise.resolve(searchData).then((value) => {
                if (value) {
                    props.setRecord(value.totalRows)
                    props.setSearchres(value.auditorSearchRess)
                    props.setState(state)
                }
                props.setIsLoading(false)
                props.setIsSearch(true);
            })
        }


    }
    const CheckArraySet = (Checked: boolean, value: number, item?: number[]) => {
        let tempVal = item
        if (Checked) {
            tempVal ? tempVal.push(value) : tempVal = [value]
        } else {
            if (tempVal != undefined && tempVal.includes(value)) {
                tempVal = tempVal.filter(r => r != value)
            }
        }

        return tempVal
    }

    // const CheckArrayClear = (Checked: boolean, value: number, item?: number[]) => {
    //     let tempVal = item
    //     if (Checked) {
    //         tempVal ? tempVal.push(value) : tempVal = [value]
    //     } else {
    //         if (tempVal != undefined && tempVal.includes(value)) {
    //             tempVal = tempVal.filter(r => r != value)
    //         }
    //     }
    //
    //     return tempVal
    // }

    return (
        <React.Fragment>
            <Grid >
                <Grid.Row columns={2}>
                    <Grid.Column width={10}>
                        <span style={{display: 'inline-flex'}}><b>Filters</b></span>
                    </Grid.Column>
                    <Grid.Column width={6} className='button-flex' style={{justifyContent: 'end'}}>
                        <OutLineButton id={'btnSearchBottom'} onClick={()=>{
                            setNsw(false)
                            setState({
                                pageSize: 10,
                                pageNumStartingZero: 0,
                                countryStateId: 1,
                                auditorLevels:[],
                                regionIdsAvailableToWorkIn:[],
                                specificExpertiseIds:[]
                            })
                        }} color='blue' className={"background-transaparent"} text='Clear Filters'
                                       disabled={!isEdit}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid className="custom-scrollbar" tabIndex={0}>
                <Grid style={{height: '500px'}}>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <span style={{display: 'inline-flex'}}><b>Auditor details</b></span>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column width={16}>
                            <NameWithDefault labelText={'Name'} labelPosition={Position.Top}
                                             id={'txtName'} fluid={true} type={'text'}
                                             placeholder={'Enter Name'}
                                             required={false}
                                             value={state?.name}
                                //onError={fnameError}
                                //eslint-disable-next-line
                                             onChange={(e: any) => {
                                                 setState({...state, name: e.target.value});
                                             }}
                                             disabled={!isEdit}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column width={16}>
                            <NameWithDefault labelText={'Company/Organisation'} labelPosition={Position.Top}
                                             id={'txtCompany'} fluid={true} type={'text'}
                                             placeholder={'Enter Company/Organisation'}
                                             required={false}
                                             value={state?.companyOrOrganisation}
                                //onError={fnameError}
                                //eslint-disable-next-line
                                             onChange={(e: any) => {
                                                 setState({...state, companyOrOrganisation: e.target.value});
                                             }}
                                             disabled={!isEdit}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column width={16}>
                            <NameWithEmail labelText={'Email'} labelPosition={Position.Top}
                                            id={'txtEmail'} fluid={true} type={'text'}
                                            placeholder={'Enter Email'}
                                            required={false}
                                            value={state?.email}
                                            onChange={(e: any) => {
                                                setState({...state, email: e.target.value});
                                            }}
                                            disabled={!isEdit}
                            />
                        </Grid.Column>
                        <Grid.Column width={8} widescreen={8} computer={8} tablet={16} mobile={16}>
                        </Grid.Column>
                    </Grid.Row>
                    {/**######################################################################################################*/}
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <span style={{display: 'inline-flex'}}><b>Level of accreditation</b></span>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Auditor (Level 1)'} name={'level'} id={'level1'}
                                              value={state?.auditorLevels?.includes(1)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      auditorLevels: CheckArraySet(e.target.checked, 1, state?.auditorLevels)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Senior Auditor (Level 2)'} name={'level'} id={'level2'}
                                              value={state?.auditorLevels?.includes(2)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      auditorLevels: CheckArraySet(e.target.checked, 2, state?.auditorLevels)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Lead Auditor (Level 3)'} name={'level'} id={'level3'}
                                              value={state?.auditorLevels?.includes(3)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      auditorLevels: CheckArraySet(e.target.checked, 3, state?.auditorLevels)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                    </Grid.Row>
                    {/*#######################################################################################################*/}
                     {/**######################################################################################################*/}

                     {
                        (Session != null && (Session.userRoleId == 1 || Session.userRoleId == 4)) ?(
                            <>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <span style={{display: 'inline-flex'}}><b>Auditor status</b></span>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={3}>
                                    <Grid.Column width={16}>
                                        <NameWithCheckBox label={'New application'} name={'auditorStatusIds'} id={'newApplication'}
                                                        value={state?.auditorStatusIds?.includes(1)}
                                            //eslint-disable-next-line
                                                        onChange={(e: any, data: any) => {
                                                            setState({
                                                                ...state,
                                                                auditorStatusIds: CheckArraySet(e.target.checked, 1, state?.auditorStatusIds)
                                                            });
                                                        }} disabled={!isEdit}/>
                                    </Grid.Column>
                                    <Grid.Column width={16} style={{marginRight: '7px'}}>
                                        <NameWithCheckBox label={'Application in review'} name={'auditorStatusIds'} id={'applicationInReview'}
                                                        value={state?.auditorStatusIds?.includes(2)}
                                            //eslint-disable-next-line
                                                        onChange={(e: any, data: any) => {
                                                            setState({
                                                                ...state,
                                                                auditorStatusIds: CheckArraySet(e.target.checked, 2, state?.auditorStatusIds)
                                                            });
                                                        }} disabled={!isEdit}/>
                                    </Grid.Column>
                                    <Grid.Column width={16}>
                                        <NameWithCheckBox label={'Application assessment'} name={'auditorStatusIds'} id={'applicationAssessment'}
                                                        value={state?.auditorStatusIds?.includes(7)}
                                            //eslint-disable-next-line
                                                        onChange={(e: any, data: any) => {
                                                            setState({
                                                                ...state,
                                                                auditorStatusIds: CheckArraySet(e.target.checked, 7, state?.auditorStatusIds)
                                                            });
                                                        }} disabled={!isEdit}/>
                                    </Grid.Column>
                                    <Grid.Column width={16}>
                                        <NameWithCheckBox label={'Active'} name={'auditorStatusIds'} id={'active'}
                                                        value={state?.auditorStatusIds?.includes(3)}
                                            //eslint-disable-next-line
                                                        onChange={(e: any, data: any) => {
                                                            setState({
                                                                ...state,
                                                                auditorStatusIds: CheckArraySet(e.target.checked, 3, state?.auditorStatusIds)
                                                            });
                                                        }} disabled={!isEdit}/>
                                    </Grid.Column>
                                    <Grid.Column width={16} style={{marginRight: '7px'}}>
                                        <NameWithCheckBox label={'Level review'} name={'auditorStatusIds'} id={'levelReview'}
                                                        value={state?.auditorStatusIds?.includes(4)}
                                            //eslint-disable-next-line
                                                        onChange={(e: any, data: any) => {
                                                            setState({
                                                                ...state,
                                                                auditorStatusIds: CheckArraySet(e.target.checked, 4, state?.auditorStatusIds)
                                                            });
                                                        }} disabled={!isEdit}/>
                                    </Grid.Column>
                                    <Grid.Column width={16}>
                                        <NameWithCheckBox label={'Elapsed'} name={'auditorStatusIds'} id={'elapsed'}
                                                        value={state?.auditorStatusIds?.includes(5)}
                                            //eslint-disable-next-line
                                                        onChange={(e: any, data: any) => {
                                                            setState({
                                                                ...state,
                                                                auditorStatusIds: CheckArraySet(e.target.checked, 5, state?.auditorStatusIds)
                                                            });
                                                        }} disabled={!isEdit}/>
                                    </Grid.Column>
                                    <Grid.Column width={16}>
                                        <NameWithCheckBox label={'Inactive'} name={'auditorStatusIds'} id={'inactive'}
                                                        value={state?.auditorStatusIds?.includes(6)}
                                            //eslint-disable-next-line
                                                        onChange={(e: any, data: any) => {
                                                            setState({
                                                                ...state,
                                                                auditorStatusIds: CheckArraySet(e.target.checked, 6, state?.auditorStatusIds)
                                                            });
                                                        }} disabled={!isEdit}/>
                                    </Grid.Column>
                                </Grid.Row>
                            </>

                        ):(null)
                    }

                    {/*#######################################################################################################*/}
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <span style={{display: 'inline-flex'}}><b>Location of auditor</b></span>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={16}>
                            <NameWithDropDown
                                selection={true}
                                labelText={'State'}
                                labelPosition={Position.Top}
                                id={'State'}
                                placeholder={'Enter State'}
                                options={stages}
                                required={false}
                                clearable={true}
                                value={state?.auditorLocationStateId ? state?.auditorLocationStateId.toString() : '0'}
                                //eslint-disable-next-line
                                onChange={(e: any, data: any) => {
                                    e.preventDefault();
                                    setState({...state, auditorLocationStateId: data.value});
                                }}
                                disabled={!isEdit}

                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={16}>
                            <NameWithDefault labelText={'Postcode'} labelPosition={Position.Top}
                                             id={'txtPostcode'} fluid={true} type={'text'}
                                             placeholder={'Enter Postcode'}
                                             required={false}
                                             value={state?.auditorLocationPostCode}
                                //onError={fnameError}
                                //eslint-disable-next-line
                                             onChange={(e: any) => {
                                                 setState({...state, auditorLocationPostCode: e.target.value});
                                             }}
                                             disabled={!isEdit}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={16}>
                            <NameWithDropDown
                                selection={true}
                                labelText={'Proximity'}
                                labelPosition={Position.Top}
                                id={'Proximity'}
                                placeholder={'Enter Proximity'}
                                options={[{key: 1, text: 'Within 0km', value: '1'},
                                    {key: 2, text: 'Within 5km', value: '2'},
                                    {key: 3, text: 'Within 10km', value: '3'},
                                    {key: 4, text: 'Within 20km', value: '4'},
                                    {key: 5, text: 'Within 50km', value: '5'},
                                    {key: 6, text: 'Within 100km', value: '6'},
                                    {key: 7, text: 'Within 250km', value: '7'},
                                    {key: 8, text: 'Within 500km', value: '8'},
                                ]}
                                required={false}
                                clearable={true}
                                value={state?.auditorLocationProximityInKm ? state?.auditorLocationProximityInKm.toString() : '0'}
                                //eslint-disable-next-line
                                onChange={(e: any, data: any) => {
                                    e.preventDefault();
                                    setState({...state, auditorLocationProximityInKm: data.value});
                                }}
                                disabled={!isEdit}

                            />
                        </Grid.Column>
                    </Grid.Row>
                    {/*#######################################################################################################*/}
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <span style={{display: 'inline-flex'}}><b>Region available to work in</b></span>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'New South Wales'} name={'state'} id={'nsw'} value={nsw}
                                              onChange={() => {
                                                  setNsw(!nsw);
                                              }} disabled={!isEdit}/>
                            <Grid.Row columns={1}>
                                <Grid.Column style={{paddingLeft: '20px'}}>
                                    <NameWithCheckBox label={'Greater Sydney'} name={'state'} id={'grate'}
                                                      value={state?.regionIdsAvailableToWorkIn?.includes(1)}
                                        //eslint-disable-next-line
                                                      onChange={(e: any, data: any) => {
                                                          setState({
                                                              ...state,
                                                              regionIdsAvailableToWorkIn: CheckArraySet(e.target.checked, 1, state?.regionIdsAvailableToWorkIn)
                                                          });
                                                      }} disabled={!isEdit}/>
                                </Grid.Column>
                                <Grid.Column style={{paddingLeft: '20px'}}>
                                    <NameWithCheckBox label={'North'} name={'state'} id={'north'}
                                                      value={state?.regionIdsAvailableToWorkIn?.includes(2)}
                                        //eslint-disable-next-line
                                                      onChange={(e: any, data: any) => {
                                                          setState({
                                                              ...state,
                                                              regionIdsAvailableToWorkIn: CheckArraySet(e.target.checked, 2, state?.regionIdsAvailableToWorkIn)
                                                          });
                                                      }} disabled={!isEdit}/>
                                </Grid.Column>
                                <Grid.Column style={{paddingLeft: '20px'}}>
                                    <NameWithCheckBox label={'South'} name={'state'} id={'south'}
                                                      value={state?.regionIdsAvailableToWorkIn?.includes(3)}
                                        //eslint-disable-next-line
                                                      onChange={(e: any, data: any) => {
                                                          setState({
                                                              ...state,
                                                              regionIdsAvailableToWorkIn: CheckArraySet(e.target.checked, 3, state?.regionIdsAvailableToWorkIn)
                                                          });
                                                      }} disabled={!isEdit}/>
                                </Grid.Column>
                                <Grid.Column style={{paddingLeft: '20px'}}>
                                    <NameWithCheckBox label={'West'} name={'state'} id={'west'}
                                                      value={state?.regionIdsAvailableToWorkIn?.includes(4)}
                                        //eslint-disable-next-line
                                                      onChange={(e: any, data: any) => {
                                                          setState({
                                                              ...state,
                                                              regionIdsAvailableToWorkIn: CheckArraySet(e.target.checked, 4, state?.regionIdsAvailableToWorkIn)
                                                          });
                                                      }} disabled={!isEdit}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Victoria'} name={'state'} id={'victoria'}
                                              value={state?.regionIdsAvailableToWorkIn?.includes(5)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      regionIdsAvailableToWorkIn: CheckArraySet(e.target.checked, 5, state?.regionIdsAvailableToWorkIn)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Western Australia'} name={'state'} id={'wa'}
                                              value={state?.regionIdsAvailableToWorkIn?.includes(6)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      regionIdsAvailableToWorkIn: CheckArraySet(e.target.checked, 6, state?.regionIdsAvailableToWorkIn)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                    {/* </Grid.Row>
                    <Grid.Row columns={3}> */}
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Australia Capital Region'} name={'state'} id={'act'}
                                              value={state?.regionIdsAvailableToWorkIn?.includes(7)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      regionIdsAvailableToWorkIn: CheckArraySet(e.target.checked, 7, state?.regionIdsAvailableToWorkIn)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Queensland'} name={'state'} id={'qld'}
                                              value={state?.regionIdsAvailableToWorkIn?.includes(8)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      regionIdsAvailableToWorkIn: CheckArraySet(e.target.checked, 8, state?.regionIdsAvailableToWorkIn)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'South Australia'} name={'state'} id={'sa'}
                                              value={state?.regionIdsAvailableToWorkIn?.includes(9)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      regionIdsAvailableToWorkIn: CheckArraySet(e.target.checked, 9, state?.regionIdsAvailableToWorkIn)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                    {/* </Grid.Row>
                    <Grid.Row columns={3}> */}
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Tasmania'} name={'state'} id={'tas'}
                                              value={state?.regionIdsAvailableToWorkIn?.includes(10)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      regionIdsAvailableToWorkIn: CheckArraySet(e.target.checked, 10, state?.regionIdsAvailableToWorkIn)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Northern Territory'} name={'state'} id={'nt'}
                                              value={state?.regionIdsAvailableToWorkIn?.includes(11)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      regionIdsAvailableToWorkIn: CheckArraySet(e.target.checked, 11, state?.regionIdsAvailableToWorkIn)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Overseas'} name={'state'} id={'os'}
                                              value={state?.regionIdsAvailableToWorkIn?.includes(12)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      regionIdsAvailableToWorkIn: CheckArraySet(e.target.checked, 12, state?.regionIdsAvailableToWorkIn)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                    </Grid.Row>
                    {/*#############################################################################################################################*/}
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <span style={{display: 'inline-flex'}}><b>Relevant professional experience</b></span>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column width={16}>
                            <NameWithDropDown
                                selection={true}
                                labelText={'Road Design'}
                                labelPosition={Position.Top}
                                id={'RoadDesign'}
                                placeholder={'Enter Road Design'}
                                options={experience}
                                required={false}
                                fluid={true}
                                clearable={true}
                                value={state?.roadDesign ? state?.roadDesign.toString() : '0'}
                                //eslint-disable-next-line
                                onChange={(e: any, data: any) => {
                                    e.preventDefault();
                                    setState({...state, roadDesign: data.value});
                                }}
                                disabled={!isEdit}

                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column width={16}>
                            <NameWithDropDown
                                selection={true}
                                labelText={'Road Safety Engineering'}
                                labelPosition={Position.Top}
                                id={'RoadSafetyEngineering'}
                                placeholder={'Enter Road Safety Engineering'}
                                options={experience}
                                required={false}
                                fluid={true}
                                clearable={true}
                                value={state?.roadSafetyEngineering ? state?.roadSafetyEngineering.toString() : '0'}
                                //eslint-disable-next-line
                                onChange={(e: any, data: any) => {
                                    e.preventDefault();
                                    setState({...state, roadSafetyEngineering: data.value});
                                }}
                                disabled={!isEdit}

                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column width={16}>
                            <NameWithDropDown
                                selection={true}
                                labelText={'Traffic Engineering'}
                                labelPosition={Position.Top}
                                id={'TrafficEngineering'}
                                placeholder={'Enter Traffic Engineering'}
                                options={experience}
                                required={false}
                                clearable={true}
                                fluid={true}
                                value={state?.trafficEngineering ? state?.trafficEngineering.toString() : '0'}
                                //eslint-disable-next-line
                                onChange={(e: any, data: any) => {
                                    e.preventDefault();
                                    setState({...state, trafficEngineering: data.value});
                                }}
                                disabled={!isEdit}

                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column width={16}>
                            <NameWithDropDown
                                selection={true}
                                labelText={'Behavioural Science'}
                                labelPosition={Position.Top}
                                id={'BehaviouralScience'}
                                placeholder={'Enter Behavioural Science'}
                                options={experience}
                                required={false}
                                fluid={true}
                                clearable={true}
                                value={state?.behaviouralScience ? state?.behaviouralScience.toString() : '0'}
                                //eslint-disable-next-line
                                onChange={(e: any, data: any) => {
                                    e.preventDefault();
                                    setState({...state, behaviouralScience: data.value});
                                }}
                                disabled={!isEdit}

                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column width={16}>
                            <NameWithDropDown
                                selection={true}
                                labelText={'Traffic/Transport Management'}
                                labelPosition={Position.Top}
                                id={'Traffic/TransportManagement'}
                                placeholder={'Enter Traffic/Transport Management'}
                                options={experience}
                                required={false}
                                fluid={true}
                                clearable={true}
                                value={state?.trafficTransportManagement ? state?.trafficTransportManagement.toString() : '0'}
                                //eslint-disable-next-line
                                onChange={(e: any, data: any) => {
                                    e.preventDefault();
                                    setState({...state, trafficTransportManagement: data.value});
                                }}
                                disabled={!isEdit}

                            />
                        </Grid.Column>
                        <Grid.Column width={16}>

                        </Grid.Column>
                    </Grid.Row>
                    {/*#############################################################################################################################*/}
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <span style={{display: 'inline-flex'}}><b>Specific expertise</b></span>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Construction Projects'} name={'state'} id={'constructionProject'}
                                              value={state?.specificExpertiseIds?.includes(1)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      specificExpertiseIds: CheckArraySet(e.target.checked, 1, state?.specificExpertiseIds)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Reconstruction Projects'} name={'state'}
                                              id={'reconstructionProjects'}
                                              value={state?.specificExpertiseIds?.includes(2)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      specificExpertiseIds: CheckArraySet(e.target.checked, 2, state?.specificExpertiseIds)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Intersection Projects'} name={'state'} id={'intersectionProjects'}
                                              value={state?.specificExpertiseIds?.includes(3)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      specificExpertiseIds: CheckArraySet(e.target.checked, 3, state?.specificExpertiseIds)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                    {/* </Grid.Row>
                    <Grid.Row columns={3}> */}
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Local Area Traffic Management Projects'} name={'state'}
                                              id={'localAreaTrafficManagementProjects'}
                                              value={state?.specificExpertiseIds?.includes(4)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      specificExpertiseIds: CheckArraySet(e.target.checked, 4, state?.specificExpertiseIds)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Traffic Signal Projects'} name={'state'}
                                              id={'trafficSignalProjects'}
                                              value={state?.specificExpertiseIds?.includes(6)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      specificExpertiseIds: CheckArraySet(e.target.checked, 6, state?.specificExpertiseIds)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Temporary Traffic Management'} name={'state'}
                                              id={'temporaryTrafficManagement'}
                                              value={state?.specificExpertiseIds?.includes(7)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      specificExpertiseIds: CheckArraySet(e.target.checked, 7, state?.specificExpertiseIds)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                    {/* </Grid.Row>
                    <Grid.Row columns={3}> */}
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Development Planning'} name={'state'} id={'developmentPlanning'}
                                              value={state?.specificExpertiseIds?.includes(8)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      specificExpertiseIds: CheckArraySet(e.target.checked, 8, state?.specificExpertiseIds)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Design & Construction Projects'} name={'state'}
                                              id={'designConstructionProjects'}
                                              value={state?.specificExpertiseIds?.includes(9)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      specificExpertiseIds: CheckArraySet(e.target.checked, 9, state?.specificExpertiseIds)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Crash Investigation and Analyses'} name={'state'}
                                              id={'crashInvestigation'}
                                              value={state?.specificExpertiseIds?.includes(10)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      specificExpertiseIds: CheckArraySet(e.target.checked, 10, state?.specificExpertiseIds)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Vulnerable Road User (Pedestrians, cyclists, motorcyclists)'}
                                              name={'state'} id={'vulnerableRoadUser'}
                                              value={state?.specificExpertiseIds?.includes(5)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      specificExpertiseIds: CheckArraySet(e.target.checked, 5, state?.specificExpertiseIds)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                        <Grid.Column width={16}>

                        </Grid.Column>
                        <Grid.Column width={16}>

                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                </Grid>
                <Grid.Row></Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column width={16} className='button-flex' style={{justifyContent: 'end'}}>
                        <OutLineButton id={'btnSearchBottom'} onClick={OnSearch} color='blue'
                                       className={"background-transaparent"} text='Apply'
                                       disabled={!isEdit}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </React.Fragment>
    );
}

interface SearchResultsProps {
    //eslint-disable-next-line
    setIsSearch: any,
    recordCount: number,
    //eslint-disable-next-line
    searchResults: AuditorSearchResRow[],
    state: SearchReq
}

const SearchResult = (props: SearchResultsProps) => {
    const Session = getSessionCookie();
    const [current, setCurrent] = useState(1);
    const [icon1, setIcon1] = useState('')
    const [icon2, setIcon2] = useState('')
    const [icon3, setIcon3] = useState('')
    const [icon4, setIcon4] = useState('')
    const [icon5, setIcon5] = useState('')
    const [icon6, setIcon6] = useState('')
    const [searchCol, setSearchCol] = useState(0)
    const [searchOrder, setSearchOrder] = useState(false)
    //eslint-disable-next-line
    const [data, setData] = useState<any>([]);


    useEffect(() => {
        if (props.searchResults) {
            SetDataTable(props.searchResults)
        }

        // if(props.searchResults.length > 20){
        //     setData([{auditorName:'Chathura Priyashad',auditorCompany:'ABC Company',level:'Level 1', auditorId:'1122' }]);
        // }
    }, [props.searchResults]);

    const SetDataTable = (rawData: AuditorSearchResRow[]) => {
        console.log(rawData)
        //eslint-disable-next-line
        let temSearch: { auditorName: string; auditorCompany: string; auditorStatus: string; level: number; auditorId: number; accreditationExpiryDate:string; lastUpdatedOnDate:string; }[] = []
        rawData.map((r) => {
            temSearch.push({
                auditorName: r.fullName,
                auditorCompany: r.orgName,
                auditorStatus: r.auditorStatus,
                auditorId: r.auditorId,
                level: r.auditorLevel,
                accreditationExpiryDate: moment(r.accreditationExpiryDate).format("MM-DD-YYYY hh:mm:ss A"),
                lastUpdatedOnDate: moment(r.lastUpdatedOnDate).format("MM-DD-YYYY hh:mm:ss A"),

            })
        })
        setData(temSearch);
    }

    useEffect(() => {
        if (data.length == 1) {
            if(Session != null && (Session.userRoleId == 1 || Session.userRoleId == 4)) {
                window.location.href = `/profile?get=${btoa(data[0].auditorId)}`
            } else {
                window.location.href = `/auditor?get=${btoa(data[0].auditorId)}`
            }
        }
    }, [data])
    const OnSearch = () => {
        props.setIsSearch(false);
    }

    const AuditorNameClick = () => {
        setIcon2('')
        setIcon3('')
        setSearchCol(2)
        if (icon1 == 'sort up') {
            setIcon1('sort down')
            OnSortClick(2, true);
            setSearchOrder(true)
        } else {
            setIcon1('sort up')
            OnSortClick(2, false);
            setSearchOrder(false)
        }
    }

    const AuditorCompanyClick = () => {
        setIcon1('')
        setIcon3('')
        setSearchCol(3)
        if (icon2 == 'sort up') {
            setIcon2('sort down')
            OnSortClick(3, true);
            setSearchOrder(true)
        } else {
            setIcon2('sort up')
            OnSortClick(3, false);
            setSearchOrder(false)
        }
    }

    const AuditorStatusClick = () => {
        setIcon1('')
        setIcon2('')
        setIcon4('')
        setIcon5('')
        setIcon6('')
        setIcon1('')
        setSearchCol(7)
        if (icon3 == 'sort up') {
            setIcon3('sort down')
            OnSortClick(7, true);
            setSearchOrder(true)
        } else {
            setIcon3('sort up')
            OnSortClick(7, false);
            setSearchOrder(false)
        }
    }

    const AuditorLevelClick = () => {
        setIcon1('')
        setIcon3('')
        setIcon2('')
        setIcon5('')
        setIcon6('')
        setSearchCol(4)
        if (icon4 == 'sort up') {
            setIcon4('sort down')
            OnSortClick(4, true);
            setSearchOrder(true)
        } else {
            setIcon4('sort up')
            OnSortClick(4, false);
            setSearchOrder(false)
        }
    }

    const AuditorCEDClick = () => {
        setIcon1('')
        setIcon3('')
        setIcon4('')
        setIcon2('')
        setIcon6('')
        setSearchCol(5)
        if (icon5 == 'sort up') {
            setIcon5('sort down')
            OnSortClick(5, true);
            setSearchOrder(true)
        } else {
            setIcon5('sort up')
            OnSortClick(5, false);
            setSearchOrder(false)
        }
    }

    const AuditorLastUpdateClick = () => {
        setIcon1('')
        setIcon3('')
        setIcon4('')
        setIcon5('')
        setIcon2('')
        setSearchCol(11)
        if (icon6 == 'sort up') {
            setIcon6('sort down')
            OnSortClick(11, true);
            setSearchOrder(true)
        } else {
            setIcon6('sort up')
            OnSortClick(11, false);
            setSearchOrder(false)
        }
    }


    const OnSortClick = (sortNumber: number, desc: boolean) => {
        if(Session != null && (Session.userRoleId == 1 || Session.userRoleId == 4)) {
            const searchData = SearchApiCall({...props.state, orderByField: sortNumber, isOrderByDesc: desc})
            Promise.resolve(searchData).then((value) => {
                if (value) {
                    SetDataTable(value.auditorSearchRess)
                }
                props.setIsSearch(true);
            })
        } else {
            const searchData = SearchApiCall({...props.state, orderByField: sortNumber, isOrderByDesc: desc})
            Promise.resolve(searchData).then((value) => {
                if (value) {
                    SetDataTable(value.auditorSearchRess)
                }
                props.setIsSearch(true);
            })
        }
    }

    const OnPageChange: PaginationProps['onChange'] = (Page) => {
        if(Session != null && (Session.userRoleId == 1 || Session.userRoleId == 4)) {
            const searchData = SearchApiCall({
                ...props.state,
                pageNumStartingZero: Page - 1,
                isOrderByDesc: searchOrder,
                orderByField: searchCol
            })
            Promise.resolve(searchData).then((value) => {
                if (value) {
                    SetDataTable(value.auditorSearchRess)
                    setCurrent(Page)
                }
                props.setIsSearch(true);
            })
        } else {
            const searchData = SearchApiCall({
                ...props.state,
                pageNumStartingZero: Page - 1,
                isOrderByDesc: searchOrder,
                orderByField: searchCol
            })
            Promise.resolve(searchData).then((value) => {
                if (value) {
                    SetDataTable(value.auditorSearchRess)
                    setCurrent(Page)
                }
                props.setIsSearch(true);
            })
        }

    }

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    };

    return (
        <Grid>
            <Grid.Row columns={2}>
                <Grid.Column width={15} widescreen={15} mobile={16} tablet={8} computer={15} largeScreen={15}>
                    <span
                        style={{display: 'inline-flex'}}><b>{`Search results : found ${props.recordCount} results`}</b></span>
                </Grid.Column>
                <Grid.Column width={1} className='button-flex' widescreen={1} mobile={16} tablet={8} computer={1}
                             largeScreen={1} style={{justifyContent: 'center'}}>
                    {/* <OutLineButton id={'btnSearchTop'} onClick={OnSearch} color='blue'
                                   className={"background-transaparent"} text='Refine Search'/> */}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={16}>
                    <span style={{display: 'inline-flex'}}><b>Auditor(s) details</b></span>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
                {data.length > 0 ? (<Grid.Column width={16} style={{Width: '1000px', overflow: 'auto'}}>
                    <Table celled>
                        <Table.Header>
                            <Table.Row style={{verticalAlign: 'baseline'}}>
                                <Table.HeaderCell style={{cursor: 'pointer', color: '#29599e'}}
                                                  onClick={AuditorNameClick}>Auditor&apos;s name<i
                                    className={`icon ${icon1}`}/></Table.HeaderCell>
                                <Table.HeaderCell style={{cursor: 'pointer', color: '#29599e'}}
                                                  onClick={AuditorCompanyClick}>Auditor&apos;s Company/Organisation <i
                                    className={`icon ${icon2}`}/></Table.HeaderCell>

                                {
                                    (Session != null && (Session.userRoleId == 1 || Session.userRoleId == 4)) ?(
                                        <Table.HeaderCell style={{cursor: 'pointer', color: '#29599e'}}
                                            onClick={AuditorStatusClick}>Auditor Status<i
                                            className={`icon ${icon3}`}/></Table.HeaderCell>
                                    ):(null)
                                }

                                <Table.HeaderCell style={{cursor: 'pointer', color: '#29599e'}}
                                                  onClick={AuditorLevelClick}>Level<i
                                    className={`icon ${icon4}`}/></Table.HeaderCell>
                                {(Session != null) ?(
                                    (Session.userRoleId == 1 || Session.userRoleId == 4) ?(
                                    <Table.HeaderCell style={{cursor: 'pointer', color: '#29599e'}}
                                                      onClick={AuditorCEDClick}>Accreditation Expiry<i
                                        className={`icon ${icon5}`}/></Table.HeaderCell>
                                ):null):null }
                                {(Session != null) ?(
                                    (Session.userRoleId == 1 || Session.userRoleId == 4) ?(
                                    <Table.HeaderCell style={{cursor: 'pointer', color: '#29599e'}}
                                                      onClick={AuditorLastUpdateClick}>Last updated<i
                                        className={`icon ${icon6}`}/></Table.HeaderCell>
                                ):null):null }
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {data.map((item: TableRowProps, index: number) => (
                                <TableRow
                                    key={index}
                                    auditorName={item.auditorName}
                                    auditorCompany={item.auditorCompany}
                                    auditorStatus={item.auditorStatus}
                                    level={item.level}
                                    auditorId={item.auditorId}
                                    accreditationExpiryDate={formatDate(item.accreditationExpiryDate)}
                                    lastUpdatedOnDate={formatDate(item.lastUpdatedOnDate)}
                                />
                            ))}
                        </Table.Body>

                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell></Table.HeaderCell>
                                {(Session != null) ?(
                                    (Session.userRoleId == 1 || Session.userRoleId == 4) ?(
                                    <>
                                    <Table.HeaderCell></Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                    </>
                                 ):null):null }
                                <Table.HeaderCell colSpan='2' textAlign={'right'}>
                                    <Pagination current={current} onChange={OnPageChange} total={props.recordCount - 1}
                                                pageSize={10} showSizeChanger={false}/>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </Grid.Column>) : (<Grid.Column width={16}>No Records found !</Grid.Column>)}
            </Grid.Row>
            <Grid.Row columns={2} style={{justifyContent: 'end'}}>
                <Grid.Column width={15} widescreen={15} mobile={16} tablet={8} computer={15} largeScreen={15}>
                </Grid.Column>
                <Grid.Column width={1} className='button-flex' widescreen={1} mobile={16} tablet={8} computer={1}
                             largeScreen={1}>
                    <OutLineButton id={'btnSearchBottom'} onClick={OnSearch} color='blue'
                                   className={"background-transaparent"} text='Revise Search'/>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

interface TableRowProps {
    auditorName: string,
    auditorCompany: string,
    level: string,
    auditorId: string,
    key: number,
    accreditationExpiryDate:string,
    lastUpdatedOnDate:string,
    auditorStatus: string
}

const TableRow = (props: TableRowProps) => {
    const profilelog = new URLSearchParams(location.search).get('profilelog');
    const name = new URLSearchParams(location.search).get('name');
    const Session = getSessionCookie();
    return (
        <Table.Row key={props.key}>
            <Table.Cell style={{cursor: 'pointer', color: '#29599e'}}><a
                href={Session != null && (Session.userRoleId == 1 || Session.userRoleId == 4) ? profilelog ? `/mis/profilelog?AuditorId=${props.auditorId}&name=${props.auditorName}` :  `/profile?get=${btoa(props.auditorId)}` : `/auditor?get=${btoa(props.auditorId)}`}>{props.auditorName}</a></Table.Cell>
            <Table.Cell>{props.auditorCompany}</Table.Cell>
            {
                (Session != null && (Session.userRoleId == 1 || Session.userRoleId == 4)) ?(
                    <Table.Cell>{props.auditorStatus}</Table.Cell>
                ):(null)
            }
            <Table.Cell>{props.level}</Table.Cell>
            {(Session != null) ?(
                                    (Session.userRoleId == 1 || Session.userRoleId == 4) ?(
                <Table.Cell textAlign={'center'}>{props.accreditationExpiryDate}</Table.Cell>
                ):null):null }
            {(Session != null) ?(
                (Session.userRoleId == 1 || Session.userRoleId == 4) ?(
                <Table.Cell textAlign={'center'}>{props.lastUpdatedOnDate}</Table.Cell>
                ):null):null }
        </Table.Row>
    );
}
