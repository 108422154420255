import React, { useState, useEffect } from "react";
import {Container, Grid} from "semantic-ui-react";
import Buttons from "../../framework/components/Buttons";
import ErrorToast from "../../framework/components/Toast/ErrorToast";
import { SearchApiCallForPageContent } from "../search/SearchController";
import { PageContentRow } from "../../models/apiResponce/mis/AuditPhaseRes";


const HomeSlider = () => {
    const [leftMsg, setLeftMsg] = useState('Search for a recognised road safety auditor to participate in a road safety audit for a road/traffic project.')
    const [rightMsg, setRightMsg] = useState('To be eligible for road safety auditor accreditation and be listed on this Register, specific criteria must be met.')
    const [content, setContent] = useState<PageContentRow>();

    useEffect(() => {
        try {
            const pageContents = SearchApiCallForPageContent();
            Promise.resolve(pageContents).then((value) => {
                if (value && value.pageContent) {
                    const homePageContent = value.pageContent.find(row => row.title === 'Home Page');
                    if (homePageContent !== undefined) {
                        setContent(homePageContent);
                    }
                }
            });
        } catch (error) {
            // Handle the error here
            ErrorToast('Error fetching page content results');
        }
        
    }, []);
    
    const onMouseEnter = (e:any) =>{
        const element = document.getElementById(e.target.id+'3')
        // if(element)
            // element.className = 'home-trapezoid-button_hover'
            // setTimeout(()=>{
            //     if(e.target.id == 'leftT')
            //         setLeftMsg('Search for a recognised road safety auditor to participate in a road safety audit for a road/traffic project.')
            //     if(e.target.id == 'rightT')
            //         setRightMsg('To be eligible for road safety auditor accreditation and be listed on this Register, specific criteria must be met.')
            // },300)
    }

    const onMouseExit = (e:any) =>{
        const element = document.getElementById(e.target.id+'3')

    }

    return (
        <React.Fragment>
            <Container fluid={true} className='hero-banner-container'>
                <div className='hero-banner'>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column mobile={16} tablet={16} computer={8}>
                                <p className='top-title-home'>Search for Road Safety Auditors or get accredited by <span style={{color:'#db2828', fontWeight:'bolder'}} className='top-title-home-red'>Transport for NSW</span></p>
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={16} computer={8}>
                                <div className='home-trapezoid'>
                                    <Grid centered={true}>
                                        <Grid.Row columns={2}>
                                            <Grid.Column mobile={16} tablet={8} computer={8}>
                                                <div className='left-trapezoid' id='leftT' onMouseEnter={onMouseEnter} onMouseLeave={onMouseExit}>
                                                    <p className='home-trapezoid-text-title' id='leftT0'>I’m a company</p>
                                                    <span className='home-trapezoid-text' id='leftT1'>looking for an auditor</span>
                                                    <p className='home-trapezoid-text-Details' id='leftT2'>{leftMsg}</p>
                                                    <div className='home-trapezoid-button' id='leftT31'>
                                                        <Buttons id='btnSearch' onClick={() => { window.location.href = '/search'; }} color='red' text='Search'
                                                            style={{ width: '200px', height: '50px', textTransform: 'uppercase', fontSize: '20px' }} className="background-transaparent-red" />
                                                    </div>
                                                </div>
                                            </Grid.Column>
                                            <Grid.Column mobile={16} tablet={8} computer={8}>
                                                <div className='right-trapezoid' id='rightT' onMouseEnter={onMouseEnter} onMouseLeave={onMouseExit}>
                                                    <p className='home-trapezoid-text-title-R' id='rightT0'>I’m an auditor</p>
                                                    <span className='home-trapezoid-text-R' id='rightT1'>looking for accreditation</span>
                                                    <p className='home-trapezoid-text-Details-R' id='rightT2'>{rightMsg}</p>
                                                    <div className='home-trapezoid-button-R' id='rightT3'>
                                                        <Buttons id='btnSearch' onClick={() => { window.location.href = '/registration'; }} color='red' text='Register'
                                                            style={{ width: '200px', height: '50px', textTransform: 'uppercase', fontSize: '20px' }} className="background-transaparent-red" />
                                                    </div>
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </Container>
            <Container>
                <Grid>
                    <Grid.Row>
                        <Grid.Column className="home-content">
                            {content ? (
                                <div dangerouslySetInnerHTML={{ __html: content.content || '' }} />
                            ) : (
                                <p>Loading...</p>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
            <br />
        </React.Fragment>
    );
}

export default HomeSlider;