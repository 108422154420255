/*
*#################################################################################
*Module Name                   	    : NameWithCheckBox.tsx         				 #
*Purpose of the Module         	    : To Show CheckBox element with text		 #
*Description of the Module    	    : This element  act like CheckBox     		 #
*Initial Author               	    : T A C Priyashad                            #
*Date                          	    : 11/30/2022                   		         #
*Copyright © 2022 ITworx Consulting.                                   	    	 #
*#################################################################################
*/

import React, { useState } from "react";

import CheckBoxProps from "../../types/CheckBoxProps";
import CheckBox from "../../components/CheckBox";

/**
 *
 * id = Is the identification of the element should be string   eg:- id={'btnAddCourse'}
 *
 * value = Is the value of the Checkbox
 *
 * name = group of the checkboxes widely use for radio buttons.
 *
 * label = If need to show text along with the checkbox. eg;- label={'Accept newsletters'}
 *
 * onChange = If you need to trigger event according to checkbox value change.
 *
 * onClick = If you need to trigger event according to mouse click on the element.
 *
 * onMouseDown = If you need to trigger event according to mouse button down.
 *
 * onMouseUp = If you need to trigger event according to mouse button up.
 */
const NameWithCheckBox = (props:CheckBoxProps) => {
    const [hover, setHover] = useState(false);

    const styleTicked = {
        color: 'rgb(0, 0, 0)',
        //borderColor: 'rgb(134, 120, 120)',
        backgroundColor: hover ? '#2196F3' : '#2196F3' // Adjust the background color on hover
    };

    const styleTickedWithoutBg = {
        color: 'rgb(0, 0, 0)',
        //borderColor: 'rgb(134, 120, 120)',
    };

    const styleUnticked = {
        color: 'rgb(134, 120, 120)',
        //borderColor: 'rgb(134, 120, 120)',
        backgroundColor: hover ? '#e5e5e5' : '#e5e5e5' // Adjust the background color on hover
    };

    const styleUntickedWithoutBg = {
        color: 'rgb(134, 120, 120)',
        //borderColor: 'rgb(134, 120, 120)',
    };

    return (
        // <CheckBox
        //      id={props.id}
        //      name={props.name}
        //      value={props.value}
        //      onChange={props.onChange}
        //      onClick={props.onClick}
        //      onMouseDown={props.onMouseDown}
        //      onMouseUp={props.onMouseUp}
        //      label={props.label}
        //      disabled={props.disabled}
        //      style={props.style}
        //      className={props.className}
        // />
        <>
            {
                props.isAddDisableStyle ? (
                    <label className="checkbox-container-gen" style={props.value ? styleTickedWithoutBg : styleUntickedWithoutBg}>{props.label}
                        <input id={props.id} type={'checkbox'} checked={props.value} onChange={props.onChange} disabled={props.disabled}/>
                        <span className="checkmark-gen" 
                            style={props.value ? styleTicked : styleUnticked}
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                        ></span>
                    </label>
                ):(
                    <label className="checkbox-container-gen">{props.label}
                        <input id={props.id} type={'checkbox'} checked={props.value} onChange={props.onChange} disabled={props.disabled}/>
                        <span 
                        className="checkmark-gen"></span>
                    </label>
                )
            }
        </>
    );
}

export default NameWithCheckBox