import React, { useEffect, useState} from "react";
import {Container, Grid} from "semantic-ui-react";
import InnerTitleBar from "../../../../framework/layouts/InnerTitleBar";
import {userRoleSelector} from "../../../../common/roleSelector";
import {getSessionCookie} from "../../../../framework/common/SessionHandler";
import OutLineButton from "../../../../framework/components/Buttons/OutlIneButton";
import RSATable from "../../../../framework/widgets/TableWithBackgrounds/RSATable";
import TableHeader from "../../../../framework/types/TableHeader";
import tags from "../../../../models/common/tag";
import { AssessorSearchReq } from "../../../../models/apiRequest/mis/LoginNameChangeReq";
import { SearchApiCallForAssessor } from "../../../search/SearchController";
import { AssessorSearchResRow } from "../../../../models/apiResponce/Auditor/SearchRes";
import { PaginationProps } from "antd/es/pagination";
import NameWithDefault from "../../../../framework/widgets/NameWithText/NameWithDefault";
import Position from "../../../../framework/types/Position";
import NameWithEmail from "../../../../framework/widgets/NameWithText/NameWithEmail";

const AssessorContainer = () =>{

    const session = getSessionCookie();
    const [current, setCurrent] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0)
    const [assessorId, setAssessorId] = useState('')
    const [assessorEmail, setAssessorEmail] = useState('')
    const [assessorName, setAssessorName] = useState('')
    const rsaAssessorChangeHead: TableHeader[] = [
        {
            text: "First name"
        },
        {
            text: "Middle name"
        },
        {
            text: "Last name"
        }
        ,
        {
            text: "Company/Organisation"
        },
        {
            text: "Active"
        },
        {
            text: ""
        }
    ];
    const [rsaAssessorChange, setrsaAssessorChange] = useState<object[]>([
    ])



    const SetValues = (data:AssessorSearchResRow[]) => {
        const TableRows: object[] = [];
        data.map((r:AssessorSearchResRow) => {
            TableRows.push([{
                    tag: tags.text,
                    text: r.firstName,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.middleName,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.lastName,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.orgName,
                    icon: "",
                    onclick: null
                },
                {
                    tag: 'icon-fa',
                    text: 'center',
                    icon: r.isActive == true ? "check" : "minus",
                    onclick: null
                },
                {
                    tag: "icon-fa",
                    text: "Select",
                    icon: "edit",
                    onclick:() => {
                        window.location.href = `/profile/assessorProfile?id=${r.userId}&type=update`
                    }
                }])
        })
        setrsaAssessorChange(TableRows);
    }

    useEffect(() => {
        const searchData = SearchApiCallForAssessor(assessorId, assessorName, assessorEmail, 1)
        Promise.resolve(searchData).then((value) => {
            if (value) {
                setTotalRecords(value.totalRows)
                SetValues(value.assessorSearchRess)
            }
        })
    }, []);

    const onChange: PaginationProps['onChange'] = (page) => {
        setCurrent(page);
        const searchData = SearchApiCallForAssessor(assessorId, assessorName, assessorEmail, page)
        Promise.resolve(searchData).then((value) => {
            if (value) {
                setTotalRecords(value.totalRows)
                SetValues(value.assessorSearchRess)
            }
        })
    };

    return(
        <Container fluid={true} style={{marginTop: '14px', marginBottom: '20px'}}>
            <InnerTitleBar
                title={"Search for Assessors"}
                usreName={session.userDisplayName != null ? session.userDisplayName : "  "}
                userRole={userRoleSelector(session.userRoleId.toString())}
                lastLogin={session.lastLogin.toString()}
                status={userRoleSelector(session.userRoleId.toString())}
            />
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                    
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                    
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                    
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>                       
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={12} mobile={16} computer={12}>
                                    <p><b> Search results : found {totalRecords} results </b></p>
                                </Grid.Column>
                                {
                                    session.userRoleId == 4 ? (
                                        <Grid.Column width={4} mobile={16} computer={4} className='button-flex'>
                                            <OutLineButton id={'btnAddAssessor'} className={"background-transaparent"}
                                                        style={{width: '200px', height:'36px'}} onClick={(e:any) => {
                                                        window.location.href = '/profile/assessorProfile?id=0&type=add'
                                            }} color={'blue'} text={'Add Assessor'}/>
                                        </Grid.Column>
                                    ) : (null)
                                }
                                
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2}>
                        
                    </Grid.Column>
                </Grid.Row>
                {rsaAssessorChange.length > 0 ? (<Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <div style={{ overflow: 'auto' }}>
                            <RSATable
                                head={rsaAssessorChangeHead}
                                body={rsaAssessorChange}
                                current={current}
                                pageCount={totalRecords/10}
                                onChange={onChange}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>):null}
            </Grid>
        </Container>
    );
}

export default AssessorContainer
