import React, { useState, useEffect } from "react";
import Header from "../../framework/layouts/Header";
import Footer from "../../framework/layouts/Footer";
import {Container, Grid, Image} from "semantic-ui-react";
import InnerTitleBar from "../../framework/layouts/InnerTitleBar";
import "../../assets/scss/theme.scss";
import ErrorToast from "../../framework/components/Toast/ErrorToast";
import { SearchApiCallForPageContent } from "../search/SearchController";
import { PageContentRow } from "../../models/apiResponce/mis/AuditPhaseRes";

const Discaimer =()=>{
    const [content, setContent] = useState<PageContentRow>();

    useEffect(() => {
        try {
            const pageContents = SearchApiCallForPageContent();
            Promise.resolve(pageContents).then((value) => {
                if (value && value.pageContent) {
                    const aboutUsContent = value.pageContent.find(row => row.title === 'Disclaimer Page');
                    if (aboutUsContent !== undefined) {
                        setContent(aboutUsContent);
                    }
                }
            });
        } catch (error) {
            // Handle the error here
            ErrorToast('Error fetching page content results');
        }
        
    }, []);

    return (
        <>
            <Header>
                <InnerTitleBar
                    title={'Disclaimer'}
                    usreName={''}
                    userRole={""}
                    lastLogin={''}
                    status={''}
                />
                <Container className='reg-thank' fluid={true} style={{marginBottom: '50px'}}>
                    <Grid>
                        <Grid.Row columns={3}>
                            <Grid.Column width={2}>

                            </Grid.Column>
                            <Grid.Column width={12}>
                                { content ? (
                                    <div dangerouslySetInnerHTML={{ __html: content.content || '' }} />
                                ) : (
                                    <p>Loading...</p>
                                )}
                            </Grid.Column>
                            <Grid.Column width={2}>

                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                </Container>
            </Header>
            <Footer/>

        </>
    )
}

export default Discaimer

// import React, { useState, useEffect } from "react";
// import "../../assets/scss/theme.scss";
// import ErrorToast from "../../framework/components/Toast/ErrorToast";
// import { SearchApiCallForPageContent } from "../search/SearchController";
// import { PageContentRow } from "../../models/apiResponce/mis/AuditPhaseRes";

// const Discaimer =()=>{
//     const [content, setContent] = useState<PageContentRow>();

//     useEffect(() => {
//         try {
//             const pageContents = SearchApiCallForPageContent();
//             Promise.resolve(pageContents).then((value) => {
//                 if (value && value.pageContent) {
//                     const aboutUsContent = value.pageContent.find(row => row.title === 'About Us Page');
//                     if (aboutUsContent !== undefined) {
//                         setContent(aboutUsContent);
//                     }
//                 }
//             });
//         } catch (error) {
//             // Handle the error here
//             ErrorToast('Error fetching page content results');
//         }
        
//     }, []);

//     // Render the fetched content as dangerouslySetInnerHTML if content is not null
//     return content ? (
//         <div dangerouslySetInnerHTML={{ __html: content.content || '' }} />
//     ) : (
//         <p>Loading...</p>
//     );
// }

// export default Discaimer