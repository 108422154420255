import React, {useEffect, useState} from "react";
import {Container, Grid, Table} from "semantic-ui-react";
import InnerTitleBar from "../../../../framework/layouts/InnerTitleBar";
import {userRoleSelector} from "../../../../common/roleSelector";
import {getSessionCookie} from "../../../../framework/common/SessionHandler";
import OutLineButton from "../../../../framework/components/Buttons/OutlIneButton";
import RSATable from "../../../../framework/widgets/TableWithBackgrounds/RSATable";
import TableHeader from "../../../../framework/types/TableHeader";
import tags from "../../../../models/common/tag";
import { SearchApiCallForPageContent, SavePageContent } from "../../../search/SearchController";
import { PageContentRow } from "../../../../models/apiResponce/mis/AuditPhaseRes";
import SuccessToast from "../../../../framework/components/Toast/SuccessToast";
import NameWithDefault from "../../../../framework/widgets/NameWithText/NameWithDefault";
import Position from "../../../../framework/types/Position";
import { PageContentReq } from "../../../../models/apiRequest/mis/AuditPhasesReq";
import ErrorToast from "../../../../framework/components/Toast/ErrorToast";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css'; // import styles
import CustomToolbar from "../../common/CustomToolbar";
import { Editor } from '@tinymce/tinymce-react';

const PageContentContainer = () =>{

    const [state, setState] = React.useState<PageContentReq>();
    const session = getSessionCookie();
    const [selectedPageContent, setSelectedPageContent] = useState(0)
    const [htmlContent, setHtmlContent] = useState('');
    const rsaPageContentHead: TableHeader[] = [
        {
            text: "ID"
        },
        {
            text: "Title"
        },
        {
            text: "Description"
        },
        {
            text: ""
        }
    ];
    const [rsaPageContent, setrsaPageContent] = useState<object[]>([
    ])

    const SetValues = (data:PageContentRow[]) => {
        const TableRows: object[] = [];
        data.map((r:PageContentRow) => {
            TableRows.push([{
                    tag: tags.text,
                    text: `${r.id}`,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: `${r.title}`,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: `${r.description}`,
                    icon: "",
                    onclick: null
                },
                {
                    tag: "icon-fa",
                    text: "",
                    icon: "edit",
                    onclick:() => {
                        setSelectedPageContent(r.id || 0)
                        setHtmlContent(r.content || '')
                        setState(r);
                    }
                }])
        })
        setrsaPageContent(TableRows);
    }

    useEffect(() => {
        try {
            const pageContents = SearchApiCallForPageContent();
            Promise.resolve(pageContents).then((value) => {
                if (value && value.pageContent) {
                    SetValues(value.pageContent);
                }
            });
        } catch (error) {
            // Handle the error here
            ErrorToast('Error fetching page content results');
        }
        
    }, []);

    const handleHtmlContentChange = (content:any) => {
        setHtmlContent(content);
    };

    const onCancelClick = () => {
        setHtmlContent('');
        setState(undefined); // Reset the state
    };

    const onSaveClick = async () => {
        try {
            // Update
            const updatedData: PageContentReq = {
                ...state!,
                content : htmlContent
            };
            const result = await SavePageContent(updatedData);
            if (result) {
                SuccessToast('Page content updated successfully');
                setTimeout(() => {
                    // Reload the page
                    window.location.reload();
                }, 2000); // 2000 milliseconds delay (2 seconds)
            }
        } catch (error) {
            // Handle the error here
            ErrorToast('Error updating page content');
        }
    };

    return(
        <Container fluid={true} style={{marginTop: '14px', marginBottom: '20px'}}>
            <InnerTitleBar
                title={"Edit Page Content"}
                usreName={session.userDisplayName != null ? session.userDisplayName : "  "}
                userRole={userRoleSelector(session.userRoleId.toString())}
                lastLogin={session.lastLogin.toString()}
                status={userRoleSelector(session.userRoleId.toString())}
            />
            <Grid>
                {rsaPageContent.length > 0 ? (<Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <div style={{ overflow: 'auto' }}>
                            <RSATable
                                head={rsaPageContentHead}
                                body={rsaPageContent}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>):null}
                {selectedPageContent != 0 ? (<>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                            <hr/>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={2}>
                                    <b><span style={{display:'inline-flex'}}>Page Content</span></b>
                                </Grid.Column>
                                <Grid.Column>
                                    
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>     
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={3}>
                                    <span style={{display:'inline-flex'}}>ID:</span>
                                </Grid.Column>
                                <Grid.Column>
                                    <span style={{display:'inline-flex'}}>{selectedPageContent}</span>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>     
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={8} widescreen={8} mobile={16} computer={8} tablet={16} largeScreen={16}>
                                <NameWithDefault 
                                    labelText={'Title:'} 
                                    labelPosition={Position.Top}
                                    id={'txtTitle'} 
                                    fluid={true} 
                                    type={'text'}
                                    placeholder={'Enter title'}
                                    required={true}
                                    value={state?.title}
                                    onChange={(e: any) => {
                                        setState({...state,title:e.target.value})
                                    }}
                                />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>    
                    </Grid.Column>
                </Grid.Row>
                

                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={8} widescreen={8} mobile={16} computer={8} tablet={16} largeScreen={16}>
                                    <span>Content:</span><br/>
                                    <Editor
                                        apiKey='3xxcpcel6tu6p7yqlreybrkhywmme7e0qxktbcs6831srrv5'
                                        init={{
                                            plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker code',
                                            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat code',
                                            tinycomments_mode: 'embedded',
                                            tinycomments_author: 'Author name',
                                            mergetags_list: [
                                            { value: 'First.Name', title: 'First Name' },
                                            { value: 'Email', title: 'Email' },
                                            ],
                                            ai_request: (request:any, respondWith:any) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
                                        }}
                                        value={htmlContent}
                                        onEditorChange={handleHtmlContentChange}
                                        />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>    
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={8} widescreen={8} mobile={16} computer={8} tablet={16} largeScreen={16}>
                                <NameWithDefault 
                                    labelText={'Description:'} 
                                    labelPosition={Position.Top}
                                    id={'txtDescription'} 
                                    fluid={true} 
                                    type={'text'}
                                    placeholder={'Enter Description'}
                                    required={true}
                                    value={state?.description}
                                    onChange={(e: any) => {
                                        setState({...state,description:e.target.value})
                                    }}
                                />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>    
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>     
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12} className={'button-flex'} style={{display:'flex', justifyContent:'right'}}>
                        <OutLineButton
                            id={'btnCancel'}
                            style={{ width: '150px', marginLeft: '10px' }}
                            onClick={onCancelClick}
                            color='red'
                            className={'background-transaparent'}
                            text={'Cancel'}
                            disabled={false}
                        />
                        <OutLineButton
                            id={'btnUpdate'}
                            style={{ width: '150px' }}
                            onClick={onSaveClick}
                            color='blue'
                            className={'background-transaparent'}
                            text={'Update'}
                            disabled={false}
                        />
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                </>):null}
            </Grid>
        </Container>
    );
}

export default PageContentContainer
