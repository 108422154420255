
import {processreportReq} from '../../../../models/apiRequest/mis/ProcessReportReq'

//Import url from config file
import ApiCallAxio from "../../../../framework/common/apiCallAxio";
import APICallMethods from "../../../../framework/types/APICallMethods";

import moment from "moment";

const baseUrl = process.env.REACT_APP_API_BASEURL;
const baseUrl2 = process.env.REACT_APP_API_BASEURL2;
const sublink = process.env.REACT_APP_API_SUBLINK;
const version = process.env.REACT_APP_API_VERSION;


const WhoCanApplyForLevelReview = async () => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/reports/whocanapplyforlevelreview`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objresult:string = result; //store the result in userTitles array
    return objresult;//return the array
}


const GetAEDRLevelThree = async (aedDate?:string) => {
    const param = aedDate  ? `?aedDate=${moment(aedDate).format("MM-DD-YYYY")}` : ''
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/reports/getaedlevelthree${param}`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objresult:string = result; //store the result in userTitles array
    return objresult;//return the array
}

const GetAEDRLevelTwo = async (aedDate?:string) => {
    const param = aedDate  ? `?aedDate=${moment(aedDate).format("MM-DD-YYYY")}` : ''
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/reports/getaedleveltwo${param}`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objresult:string = result; //store the result in userTitles array
    return objresult;//return the array
}

const GetAEDRLevelOne = async (aedDate?:string) => {
    const param = aedDate  ? `?aedDate=${moment(aedDate).format("MM-DD-YYYY")}` : ''
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/reports/getaedlevelone${param}`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objresult:string = result; //store the result in userTitles array
    return objresult;//return the array
}


export {GetAEDRLevelOne, GetAEDRLevelTwo, GetAEDRLevelThree, WhoCanApplyForLevelReview };