import React, {useEffect, useState} from 'react';
import {Grid, Icon, Popup, Radio, SemanticICONS} from "semantic-ui-react";
import NameWithMultiline from "../../../framework/widgets/NameWithText/NameWithMuliLine";
import Position from "../../../framework/types/Position";
import NameWithNumber from "../../../framework/widgets/NameWithText/NameWithNumber";
import MultilineTextBox from "../../../framework/components/TextBoxes/MultilineTextBox";
import MaxChars from "../../../framework/components/Supportive/MaxChars";
import Dropdowns from "../../../framework/components/DropDowns";
import NameWithCheckBox from "../../../framework/widgets/NameWithCheckBox/NameWithCheckBox";
import {QualificationErrorProps, QualificationValueProps} from "./DataModal";
import {UpdateMsgFromAdmin} from "./ProfileController";
import FileUplaodReq from "../../../models/common/fileUplaodReq";
import FileUploadingToServer from "../../../common/FileUploadingController";
import {getSessionCookie} from "../../../framework/common/SessionHandler";
import InfoToggle from "../../../framework/widgets/InfoToggle";
import MsgFromAdmin from "../../../models/apiResponce/Auditor/MsgFromAdmin";
import FileUploadPop from "../../../framework/components/FileUpload/FileUploadPop";
import Modal from "../../../framework/components/Modals";
import {fetchFiles} from "../../../framework/components/FileUpload/FileUploadController";
import RelevantExpBox from "../../../framework/widgets/RelevantExpBox";
import ErrorToast from "../../../framework/components/Toast/ErrorToast";

interface Props {
    auditor: any;
    focusMode: any;
    setFocusMode: any;
    setStateExperiance: any;
    Error: QualificationErrorProps,
    Level: string,
    setQE:any,
    qe:any
}

const QualificationsAndExperience = (props: Props) => {
    const [state, setState] = React.useState<QualificationValueProps>({roadDesign:'0',trafficEngineering:'0',behaviouralScience:'0',trafficTransportManagement:'0',totalExperience:'0',roadSafetyEngineering:'0'});
    const [stateExpExperties, setStateExpExperties] = React.useState<QualificationValueProps>({roadDesign:'0',trafficEngineering:'0',behaviouralScience:'0',trafficTransportManagement:'0',totalExperience:'0',roadSafetyEngineering:'0'});
    const [isEdit, setIsEdit] = React.useState<boolean>(true);
    //const [qe, setQE] = React.useState<MsgFromAdmin>({} as MsgFromAdmin);
    const [isConfirmQE, setIsConfirmQE] = React.useState(false)
    const [isConfirmQE2, setIsConfirmQE2] = React.useState(false)
    const [qualificationError, SetQualificationError] = React.useState('')
    const [wesError, SetWESError] = React.useState('')
    const [totalError, SetTotalError] = React.useState('')
    const [essError, SetEssError] = React.useState('')
    const [rimError, SetRIMError] = React.useState('')
    const [RDError, SetRDError] = React.useState('')
    const [TEError, SetTEError] = React.useState('')
    const [TTMError, SetTTMError] = React.useState('')
    const [RSEError, SetRSEError] = React.useState('')
    const [BSError, SetBSError] = React.useState('')
    const [ORError, SetORError] = React.useState('')
    const [WEDError, SetWEDError] = React.useState('')
    const session = getSessionCookie();
    const [level, SetLevel] = React.useState(false);
    const [open, setOpen] = useState(false);
    const [fileType, setFileType] = React.useState('CV')
    const [cvFileIcon, setCVFileIcon] = useState<SemanticICONS>('attach')
    const [ExpFileIcon, setEXPFileIcon] = useState<SemanticICONS>('attach')
    const [isCVEdit, setIsCVEdit] = React.useState<boolean>(false);
    const [isEXPEdit, setIsEXPEdit] = React.useState<boolean>(false);
    const encid = new URLSearchParams(location.search).get('get')
    const Session = getSessionCookie();
    const [inforToggleqae, setInforToggleqae] = useState(false);
    const [auditorStatus, SetAuditorStatus] = useState(0)
    //console.log("Props from QE Head", props)
    useEffect(() => {
        if (props.Level == '2' || props.Level == '3') {
            SetLevel(true)
        } else {
            SetLevel(false)
        }
    }, [props.Level]);

    useEffect(() => {
        Promise.resolve(props.auditor).then((value) => {
            //console.log(value)
            //eslint-disable-next-line
            //debugger;
            setState({...value.qualification })
            setStateExpExperties({...value.qualification });
            SetAuditorStatus(value.personalDetails?.AuditorsStateRes?.auditorStatusId?.toString())
            setIsEdit(encid != null && (Session.userRoleId == 1 || Session.userRoleId == 4) ? true : value.QE.isActivated ? value.QE.isActivated : value.Editable)
            props.setQE(value.QE)
            setInforToggleqae(props.auditor.QE.isActivated)
            setIsConfirmQE(value.QE.isConfirmedByAuditor)
            setIsConfirmQE2(value.QE.isConfirmedByAuditor2)
        })

        //console.log('props.auditor', props.auditor)
        const getfile = fetchFiles(props.auditor.personalDetails.txtEmail, 'CV')
        Promise.resolve(getfile).then((value) => {
            if (value.length > 0) {
                //eslint-disable-next-line
                //console.log('ccccccccccccccccccccccccccc')
                setCVFileIcon('file')
                setIsCVEdit(true)
            }
        })
        const getfileEx = fetchFiles(props.auditor.personalDetails.txtEmail, 'EXP')
        Promise.resolve(getfileEx).then((value) => {
            if (value.length > 0) {
                //eslint-disable-next-line
                //console.log('bbbbbbbbbbbbbbbbbbbbbbbbb')
                setEXPFileIcon('file')
                setIsEXPEdit(true)
            }
        })
    }, [props.auditor])

    const setFileLoad = (isOpen: boolean) => {
        setOpen(isOpen)
        if (fileType == 'CV') {
            const getfile = fetchFiles(props.auditor.personalDetails.txtEmail, 'CV')
            Promise.resolve(getfile).then((value) => {
                if (value.length > 0) {
                    //console.log('rrrrrrrrrrrrrrrrrrrrrrrrr')
                    //eslint-disable-next-line
                    setCVFileIcon('file')
                }
            })
        } else {
            const getfileEx = fetchFiles(props.auditor.personalDetails.txtEmail, 'EXP')
            Promise.resolve(getfileEx).then((value) => {
                if (value.length > 0) {
                    //console.log('fffffffffffffffffffffffffffffff')
                    //eslint-disable-next-line
                    setEXPFileIcon('file')
                }
            })
        }
    }

    useEffect(() => {
        SetQualificationError(props.Error?.qualifications ? props.Error.qualifications : '')
        SetWESError(props.Error?.workExperienceSummary ? props.Error.workExperienceSummary : '')
        SetTotalError(props.Error?.totalExperience ? props.Error.totalExperience : '')
        SetEssError(props.Error?.experienceSkillsSummary ? props.Error.experienceSkillsSummary : '')
        SetRIMError(props.Error?.mitigation ? props.Error.mitigation : '')
        SetRDError(props.Error?.roadDesign ? props.Error.roadDesign : '')
        SetTEError(props.Error?.trafficEngineering ? props.Error.trafficEngineering : '')
        SetTTMError(props.Error?.trafficTransportManagement ? props.Error.trafficTransportManagement : '')
        SetRSEError(props.Error?.roadSafetyEngineering ? props.Error.roadSafetyEngineering : '')
        SetBSError(props.Error?.behaviouralScience ? props.Error.behaviouralScience : '')
        SetORError(props.Error?.otherRelevantExperience ? props.Error.otherRelevantExperience : '')
        SetWEDError(props.Error?.eligibilityCriteria ? props.Error.eligibilityCriteria : '')
    }, [props.Error])

    const onLostFocusQualification = async () => {
        props.setFocusMode("qualification")
    }

    useEffect(() => {
        props.setStateExperiance(state);
    }, [state])

    const fileUplaodExp = (file: any) => {
        const fileData: FileUplaodReq = {
            file: file,
            auditorID: session?.auditorId.toString(),
            type: "EXP",
            refID: session?.auditorId.toString(),
            userID: session?.userId.toString(),
            email: session?.userName.toString()
        };
        const result = FileUploadingToServer(fileData)
    }
    const fileUplaodCV = (file: any) => {
        const fileData: FileUplaodReq = {
            file: file,
            auditorID: session?.auditorId.toString(),
            type: "CV",
            refID: session?.auditorId.toString(),
            userID: session?.userId.toString(),
            email: session?.userName.toString()
        };
        const result = FileUploadingToServer(fileData)
    }
    const onQEChange = async (id: number, e: any, data: any, sectionName: string) => {
        console.log(isConfirmQE)
        setIsConfirmQE(!isConfirmQE);
        await UpdateMsgFromAdmin(id, !isConfirmQE, sectionName)
    }

    const onQEChange2 = async (id: number, e: any, data: any, sectionName: string) => {
        setIsConfirmQE2(!isConfirmQE2);
        await UpdateMsgFromAdmin(id, !isConfirmQE2, sectionName)
    }

    const onQEAdminBoxChange = (e:any) => {
        props.setQE({...props.qe,
            isActivated:inforToggleqae,
            Type: props.qe.Type ? props.qe.Type : 'AdminInfoToggle',
            msgFromAdmin: e != '' ? e.target.value : '',
            auditorId:parseInt(atob(encid ? encid : '')) ,
        })
    }

    return (
        <React.Fragment>
            <div className="ui center aligned icon header red-title" id={'qande'}>
                {encid != null && (session.userRoleId == 1 || session.userRoleId == 4) && (auditorStatus == 1 || auditorStatus == 2 ) ? (
                    <Grid>
                        <Grid.Row columns={2}>
                            <Grid.Column width={14} mobile={12} tablet={13}>
                                <h1 className="ui center aligned icon header red-title">Qualifications And
                                    Experience</h1>
                            </Grid.Column>
                            <Grid.Column width={2} style={{paddingLeft: '4rem'}}>
                                <Popup
                                    flowing
                                    hoverable
                                    trigger={
                                        <Radio  toggle checked={inforToggleqae} onChange={() => {
                                            setInforToggleqae(!inforToggleqae)
                                            props.setQE({...props.qe,
                                                isActivated:!inforToggleqae,
                                                Type: props.qe.Type ? props.qe.Type : 'AdminInfoToggle',
                                                msgFromAdmin: props.qe.msgFromAdmin ? props.qe.msgFromAdmin : '',
                                                auditorId:parseInt(atob(encid)) ,
                                                // id: 0,
                                                // isConfirmedByAuditor: false,
                                                // sectionName: "RSC",
                                            })
                                        }}/>
                                    }
                                >
                                    <Popup.Header>{inforToggleqae ? 'Admin infotoggle is ON' : 'Admin infotoggle is OFF'}</Popup.Header>
                                    <Popup.Content>
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <p>{inforToggleqae ? `slide to save and close` : `slide to send message to auditor`}</p>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Popup.Content>
                                </Popup>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>) : (
                    <h1 className="ui center aligned icon header red-title">Qualifications And Experience</h1>)}
            </div>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        {props.qe.isActivated ? (<InfoToggle
                            type={props.qe.Type}
                            msg={props.qe.msgFromAdmin}
                            update={props.qe.update}
                            msg2={props.qe.msgFromAdmin2 ? props.qe.msgFromAdmin2 : ""}
                            update2={props.qe.update2}
                            id={'chkAdminConformQE'}
                            id2={'chkAdminConformQE2'}
                            value={isConfirmQE}
                            value2={isConfirmQE2}
                            onChange={(e: any, data: any) => {
                                onQEChange(props.qe.id, e, data, props.qe.sectionName)
                            }}
                            onChange2={(e: any, data: any) => {
                                onQEChange2(props.qe.id2 ? props.qe.id2 : 0, e, data, props.qe.sectionName)
                            }}
                            createDate={props.qe.createDate}
                            createDate2={props.qe.createDate2}
                            responceDate={props.qe.ResponceDate}
                            responceDate2={props.qe.ResponceDate2}
                            onMsgBoxChange={onQEAdminBoxChange}
                            isConfirmedByAdminOnDate={props.qe.isConfirmedByAdminOnDate}
                            createdBy={props.qe.assessorName}
                            createdBy2={props.qe.assessorName2}
                        />) : null}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <div>The following details are required to allow careful consideration of your experience and
                            capabilities and that they satisfy the Road Safety Auditor accreditation requirements as set
                            out in the NSW Centre for Road Safety and Transport for NSW Guidelines for Road Safety audit
                            Practices.
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column width={3} computer={3} mobile={8} widescreen={3} tablet={8}>
                        <span style={{display: 'inline-flex'}}><b>Resume</b></span>
                    </Grid.Column>
                    <Grid.Column width={12} computer={12} tablet={8} widescreen={12} mobile={8}>
                        <Icon id={'btnClick'} className={cvFileIcon} size={"big"} onClick={() => {
                            setOpen(true)
                            setFileType('CV')
                        }} style={{cursor: 'pointer'}} disabled={isCVEdit == true ? !isCVEdit : !isEdit}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column width={16}>
                        <span style={{display: 'inline-flex'}}><b>Qualifications</b>&nbsp;(maximum of 100 characters)</span>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <NameWithMultiline id={'txtQualifications'}
                                           labelText={'Provide a short summary of your qualifications'}
                                           type={'text'}
                                           wordCount={true}
                                           labelPosition={Position.Top}
                                           required={false}
                                           className={'other_exp'}
                                           value={stateExpExperties?.qualifications}
                                           onError={qualificationError}
                                           onChange={(e: any) => {
                                               SetQualificationError('');
                                               if(stateExpExperties?.qualifications != undefined && stateExpExperties?.qualifications?.length > 100){
                                                   ErrorToast(`Maximum of 100 characters and typed characters are ${stateExpExperties?.qualifications?.length} `)
                                                   setStateExpExperties({...stateExpExperties, qualifications: stateExpExperties?.qualifications.substr(0,100)})
                                               }else{
                                                setStateExpExperties({...stateExpExperties, qualifications: e.target.value})
                                               }

                                           }}
                                           onLostFocus={(e: any) => {

                                            if(state?.qualifications?.trim() !== stateExpExperties?.qualifications?.trim())
                                            {
                                                if(state?.qualifications != undefined && state?.qualifications?.length > 100){
                                                    ErrorToast(`Maximum of 100 characters and typed characters are ${state?.qualifications?.length} `)
                                                    setState({...state, qualifications: state?.qualifications.substr(0,100)})
                                                    setStateExpExperties({...stateExpExperties, qualifications : stateExpExperties?.qualifications?.substr(0,100)})
                                                }
                                                else
                                                {
                                                 setState({...state, qualifications: e.target.value})
                                                 setStateExpExperties({...stateExpExperties, qualifications: e.target.value})
                                                }
                                            }

                                               setTimeout(() => {
                                                   onLostFocusQualification()
                                               }, 500)
                                           }}
                                           disabled={!isEdit}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column width={16}>
                        <span style={{display: 'inline-flex'}}><b>Experience</b></span>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <NameWithNumber id={'txtTotalExp'}
                                        labelText={'Total years experience'}
                                        type={'text'}
                                        labelPosition={Position.Left}
                                        required={true}
                                        value={state?.totalExperience}
                                        onChange={(e: any) => {
                                            SetTotalError('')
                                            SetTotalError('')
                                            if(parseInt(e.target.value) >= 0){
                                                setState({...state, totalExperience: e.target.value})
                                            }

                                        }}
                                        onLostFocus={() => {
                                            setTimeout(() => {
                                                onLostFocusQualification()
                                            }, 500)
                                        }}
                                        disabled={!isEdit}
                                        onError={totalError}
                        />
                    </Grid.Column>
                </Grid.Row><br/><br/>
                <Grid.Row columns={1}>
                    <Grid.Column width={16}>
                        <span
                            style={{display: 'inline-flex'}}><b>Work experience summary</b>&nbsp;(maximum of 2000 characters)<p
                            style={{color: 'red'}}>{'*'}</p></span>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <p className='exp-instructions'>Include information on key positions you have held including
                            company/organisation, position
                            name, years in position and duties. Work experience listed is to be relevant to your role as
                            a road safety auditor in ability to identify and assess risks to road safety.</p>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <NameWithMultiline id={'txtWorkExperienceSummary'}
                                           labelText={''}
                                           type={'text'}
                                           className={'other_exp'}
                                           wordCount={true}
                                           labelPosition={Position.Top}
                                           required={true}
                                           value={stateExpExperties?.workExperienceSummary}
                                           onError={wesError}
                                        //    onChange={(e: any) => {
                                        //        SetWESError('')
                                        //        if(state?.workExperienceSummary != undefined && state?.workExperienceSummary?.length > 2000){
                                        //            ErrorToast(`Maximum of 2000 characters and typed characters are ${state?.workExperienceSummary?.length} `)
                                        //            setState({...state, workExperienceSummary: state?.workExperienceSummary.substr(0,2000)})
                                        //        }else{
                                        //            setState({...state, workExperienceSummary: e.target.value})
                                        //        }

                                        //    }}
                                        onChange={(e: any) => {
                                            SetWESError('')
                                            if(stateExpExperties?.workExperienceSummary != undefined && stateExpExperties?.workExperienceSummary?.length > 2000){
                                                ErrorToast(`Maximum of 2000 characters and typed characters are ${stateExpExperties?.workExperienceSummary?.length} `)
                                                setStateExpExperties({...stateExpExperties, workExperienceSummary: stateExpExperties?.workExperienceSummary.substr(0,2000)})
                                            }else{
                                                setStateExpExperties({...stateExpExperties, workExperienceSummary: e.target.value})
                                            }

                                        }}

                                        //    onLostFocus={() => {
                                        //        if(state?.workExperienceSummary != undefined && state?.workExperienceSummary?.length > 2000){
                                        //            ErrorToast(`Maximum of 2000 characters and typed characters are ${state?.workExperienceSummary?.length} `)
                                        //            setState({...state, workExperienceSummary: state?.workExperienceSummary.substr(0,2000)})
                                        //        }
                                        //        setTimeout(() => {
                                        //            onLostFocusQualification()
                                        //        }, 500)
                                        //    }}

                                        onLostFocus={(e: any) => {

                                            if(state?.workExperienceSummary?.trim() !== stateExpExperties?.workExperienceSummary?.trim())
                                            {
                                                if(state?.workExperienceSummary != undefined && state?.workExperienceSummary?.length > 2000){
                                                    ErrorToast(`Maximum of 2000 characters and typed characters are ${state?.workExperienceSummary?.length} `)
                                                    setState({...state, workExperienceSummary: state?.workExperienceSummary.substr(0,2000)})
                                                    setStateExpExperties({...stateExpExperties, workExperienceSummary : stateExpExperties?.workExperienceSummary?.substr(0,2000)})
                                                }
                                                else
                                                {
                                                 setState({...state, workExperienceSummary: e.target.value})
                                                 setStateExpExperties({...stateExpExperties, workExperienceSummary: e.target.value})
                                                }
                                            }

                                               setTimeout(() => {
                                                   onLostFocusQualification()
                                               }, 500)
                                           }}

                                           disabled={!isEdit}
                        />
                    </Grid.Column>
                    {/* <Grid.Column width={16}>
                        <MaxChars charCount={2000}/>
                    </Grid.Column> */}
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column width={16}>
                        <span style={{display: 'inline-flex'}}><b>Experience/skills summary</b>&nbsp;(maximum of 2000 characters)<p
                            style={{color: 'red'}}>{level ? '*' : ''}</p></span>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <p className='exp-instructions'> Include a brief break down of your relevant experience and
                            capabilities focusing on road
                            design, traffic engineering, traffic / transport management, road construction techniques,
                            road safety engineering, road-user behaviors or other closely related road safety
                            disciplines.</p>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <NameWithMultiline id={'txtExperienceSkillsSummary'}
                                           labelText={''}
                                           type={'text'}
                                           className={'other_exp'}
                                           wordCount={true}
                                           labelPosition={Position.Top}
                                           required={level}
                                           value={stateExpExperties?.experienceSkillsSummary}
                                           onError={essError}
                                           onChange={(e: any) => {
                                            SetEssError('')
                                            if(stateExpExperties?.experienceSkillsSummary != undefined && stateExpExperties?.experienceSkillsSummary?.length > 2000){
                                                ErrorToast(`Maximum of 2000 characters and typed characters are ${stateExpExperties?.experienceSkillsSummary?.length} `)
                                                setStateExpExperties({...stateExpExperties, experienceSkillsSummary: stateExpExperties?.experienceSkillsSummary.substr(0,2000)})
                                            }else{
                                                setStateExpExperties({...stateExpExperties, experienceSkillsSummary: e.target.value})
                                            }

                                        }}
                                        onLostFocus={(e: any) => {
                                            if(state?.experienceSkillsSummary?.trim() !== stateExpExperties?.experienceSkillsSummary?.trim())
                                            {
                                                if(state?.experienceSkillsSummary != undefined && state?.experienceSkillsSummary?.length > 2000){
                                                    ErrorToast(`Maximum of 2000 characters and typed characters are ${state?.experienceSkillsSummary?.length} `)
                                                    setState({...state, experienceSkillsSummary: state?.experienceSkillsSummary.substr(0,2000)})
                                                    setStateExpExperties({...stateExpExperties, experienceSkillsSummary : stateExpExperties?.experienceSkillsSummary?.substr(0,2000)})
                                                }
                                                else
                                                {
                                                 setState({...state, experienceSkillsSummary: e.target.value})
                                                 setStateExpExperties({...stateExpExperties, experienceSkillsSummary: e.target.value})
                                                }
                                            }

                                               setTimeout(() => {
                                                   onLostFocusQualification()
                                               }, 500)
                                           }}
                                        //    onChange={(e: any) => {
                                        //        SetEssError('')
                                        //        if(state?.experienceSkillsSummary != undefined && state?.experienceSkillsSummary?.length > 2000){
                                        //            ErrorToast(`Maximum of 2000 characters and typed characters are ${state?.experienceSkillsSummary?.length} `)
                                        //            setState({...state, experienceSkillsSummary: state?.experienceSkillsSummary.substr(0,2000)})
                                        //        }else{
                                        //            setState({...state, experienceSkillsSummary: e.target.value})
                                        //        }
                                        //    }}
                                        //    onLostFocus={() => {
                                        //        if(state?.experienceSkillsSummary != undefined && state?.experienceSkillsSummary?.length > 2000){
                                        //            ErrorToast(`Maximum of 2000 characters and typed characters are ${state?.experienceSkillsSummary?.length} `)
                                        //            setState({...state, experienceSkillsSummary: state?.experienceSkillsSummary.substr(0,2000)})
                                        //        }
                                        //        setTimeout(() => {
                                        //            onLostFocusQualification()
                                        //        }, 500)
                                        //    }}
                                           disabled={!isEdit}
                        />
                    </Grid.Column>

                    {/* <Grid.Column width={16}>
                        <MaxChars charCount={2000}/>
                    </Grid.Column> */}
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column width={16}>
                        <span style={{display: 'inline-flex'}}><b>Road Safety identification and mitigation</b>&nbsp;(maximum of 2000 characters)<p
                            style={{color: 'red'}}>{level ? '*' : ''}</p></span>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <p className='exp-instructions'>With an example, explain how your experience and skills listed
                            above give you the ability to
                            identify road safety issues using the Safe Systems approach. Your example should demonstrate
                            how you attempted to mitigate the exposure, likelihood and severity of potential crashes and
                            existing position. This could be completed through:</p>
                        <ul className='exp-instructions'>
                            <li>the assessment or consideration of engineering
                                drawings,
                            </li>
                            <li>site inspections of existing formations,</li>
                            <li>strategic and/or policy proposals, conference
                                presentations or similar
                            </li>
                        </ul>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <NameWithMultiline id={'txtMitigation'}
                                           labelText={''}
                                           type={'text'}
                                           className={'other_exp'}
                                           wordCount={true}
                                           labelPosition={Position.Top}
                                           required={level}
                                           value={stateExpExperties?.mitigation}
                                           onError={rimError}
                                           onChange={(e: any) => {
                                            SetRIMError('')
                                            if(stateExpExperties?.mitigation != undefined && stateExpExperties?.mitigation?.length > 2000){
                                                ErrorToast(`Maximum of 2000 characters and typed characters are ${stateExpExperties?.mitigation?.length} `)
                                                setStateExpExperties({...stateExpExperties, mitigation: stateExpExperties?.mitigation.substr(0,2000)})
                                            }else{
                                                setStateExpExperties({...stateExpExperties, mitigation: e.target.value})
                                            }

                                        }}
                                        onLostFocus={(e: any) => {
                                            if(state?.mitigation?.trim() !== stateExpExperties?.mitigation?.trim())
                                            {
                                                if(state?.mitigation != undefined && state?.mitigation?.length > 2000){
                                                    ErrorToast(`Maximum of 2000 characters and typed characters are ${state?.mitigation?.length} `)
                                                    setState({...state, mitigation: state?.mitigation.substr(0,2000)})
                                                    setStateExpExperties({...stateExpExperties, mitigation : stateExpExperties?.mitigation?.substr(0,2000)})
                                                }
                                                else
                                                {
                                                 setState({...state, mitigation: e.target.value})
                                                 setStateExpExperties({...stateExpExperties, mitigation: e.target.value})
                                                }
                                            }

                                               setTimeout(() => {
                                                   onLostFocusQualification()
                                               }, 500)
                                           }}
                                        //    onChange={(e: any) => {
                                        //        SetRIMError('')
                                        //        if(state?.mitigation != undefined && state?.mitigation?.length > 2000){
                                        //            ErrorToast(`Maximum of 2000 characters and typed characters are ${state?.mitigation?.length} `)
                                        //            setState({...state, mitigation: state?.mitigation.substr(0,2000)})
                                        //        }else {
                                        //            setState({...state, mitigation: e.target.value})
                                        //        }
                                        //    }}
                                        //    onLostFocus={() => {
                                        //        if(state?.mitigation != undefined && state?.mitigation?.length > 2000){
                                        //            ErrorToast(`Maximum of 2000 characters and typed characters are ${state?.mitigation?.length} `)
                                        //            setState({...state, mitigation: state?.mitigation.substr(0,2000)})
                                        //        }
                                        //        setTimeout(() => {
                                        //            onLostFocusQualification()
                                        //        }, 500)
                                        //    }}
                                           disabled={!isEdit}
                        />
                    </Grid.Column>

                    {/* <Grid.Column width={16}>
                        <MaxChars charCount={2000}/>
                    </Grid.Column> */}
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column width={3} computer={3} mobile={8} widescreen={3} tablet={8}>
                        <span style={{display: 'inline-flex'}}><b>Supporting documents</b></span>
                    </Grid.Column>
                    <Grid.Column width={12} computer={12} tablet={8} widescreen={12} mobile={8}>
                        <Icon id={'btnClick'} className={ExpFileIcon} size={"big"} onClick={() => {
                            setOpen(true)
                            setFileType('EXP')
                        }} style={{cursor: 'pointer'}} disabled={isEXPEdit == true ? !isEXPEdit : !isEdit}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column width={16}>
                        <span style={{display: 'inline-flex'}}><b>Relevant Professional Experience</b></span>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <p>Enter the years of employed experience in the areas where you have been involved at a
                            technical level and have a capacity in (note that management of people doing work in these
                            areas from outside these disciplines is not considered relevant professional experience)</p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row id={'dn'} columns={2}>
                                <Grid.Column  width={8} mobile={16} widescreen={8} computer={8} tablet={16}>
                                    <RelevantExpBox
                                        id={'txtRoadDesign'}
                                        label={'Road Design'}
                                        value={stateExpExperties?.rdSummary}
                                        valueYrs={state?.roadDesign}
                                        // onChange={(e: any) => {
                                        //     if(state?.rdSummary != undefined && state?.rdSummary?.length > 1000){
                                        //         ErrorToast(`Maximum of 1000 characters and typed characters are ${state?.rdSummary?.length} `)
                                        //         setState({...state, rdSummary: state?.rdSummary.substr(0,1000)})
                                        //     }else {
                                        //         setState({...state, rdSummary: e.target.value})
                                        //     }
                                        // }}
                                        onChange={(e: any) => {
                                            if(stateExpExperties?.rdSummary != undefined && stateExpExperties?.rdSummary?.length > 1000){
                                                ErrorToast(`Maximum of 1000 characters and typed characters are ${stateExpExperties?.rdSummary?.length} `)
                                                setStateExpExperties({...stateExpExperties, rdSummary: stateExpExperties?.rdSummary.substr(0,1000)})
                                            }else{
                                                setStateExpExperties({...stateExpExperties, rdSummary: e.target.value})
                                            }
                                        }}
                                        onChangeYrs={(e: any) => {
                                            SetRDError('')
                                            if((state?.rdSummary == undefined || state?.rdSummary.length == 0) && e.target.value.length == 0 ){
                                                setState({...state, roadDesign: '0'})
                                            }else{
                                                if(parseInt(e.target.value) >= 0) {
                                                    setState({...state, roadDesign: e.target.value})
                                                }
                                            }
                                        }}
                                        onError={props.Error.rdSummary}
                                        onErrorYrs={RDError}
                                        disabled={!isEdit}
                                        // onLostFocus={() => {
                                        //     setTimeout(() => {
                                        //         if(state?.rdSummary != undefined && state?.rdSummary?.length > 1000){
                                        //             ErrorToast(`Maximum of 1000 characters and typed characters are ${state?.rdSummary?.length} `)
                                        //             setState({...state, rdSummary: state?.rdSummary.substr(0,1000)})
                                        //         }
                                        //         onLostFocusQualification()
                                        //     }, 500)
                                        // }}
                                        onLostFocus={(e: any) => {
                                               setTimeout(() => {
                                                if(state?.rdSummary?.trim() !== stateExpExperties?.rdSummary?.trim())
                                                {
                                                    if(state?.rdSummary != undefined && state?.rdSummary?.length > 1000){
                                                        ErrorToast(`Maximum of 1000 characters and typed characters are ${state?.rdSummary?.length} `)
                                                        setState({...state, rdSummary: state?.rdSummary.substr(0,1000)})
                                                        setStateExpExperties({...stateExpExperties, rdSummary : stateExpExperties?.rdSummary?.substr(0,1000)})
                                                    }
                                                    else
                                                    {
                                                     setState({...state, rdSummary: e.target.value})
                                                     setStateExpExperties({...stateExpExperties, rdSummary: e.target.value})
                                                    }
                                                }
                                                   onLostFocusQualification()
                                               }, 500)
                                           }}
                                        onLostFocusYrs={() => {
                                            setTimeout(() => {
                                                onLostFocusQualification()
                                            }, 500)
                                        }}
                                    />
                                </Grid.Column>
                                <Grid.Column  width={8} mobile={16} widescreen={8} computer={8} tablet={16}>
                                    <RelevantExpBox
                                        id={'txtTrafficEngineering'}
                                        label={'Traffic Engineering'}
                                        value={stateExpExperties?.trSummary}
                                        valueYrs={state?.trafficEngineering}
                                        // onChange={(e: any) => {
                                        //     if(state?.trSummary != undefined && state?.trSummary?.length > 1000){
                                        //         ErrorToast(`Maximum of 1000 characters and typed characters are ${state?.trSummary?.length} `)
                                        //         setState({...state, trSummary: state?.trSummary.substr(0,1000)})
                                        //     }else {
                                        //         setState({...state, trSummary: e.target.value})
                                        //     }
                                        // }}
                                        onChange={(e: any) => {
                                            if(stateExpExperties?.trSummary != undefined && stateExpExperties?.trSummary?.length > 1000){
                                                ErrorToast(`Maximum of 1000 characters and typed characters are ${stateExpExperties?.trSummary?.length} `)
                                                setStateExpExperties({...stateExpExperties, trSummary: stateExpExperties?.trSummary.substr(0,1000)})
                                            }else{
                                                setStateExpExperties({...stateExpExperties, trSummary: e.target.value})
                                            }
                                        }}
                                        onChangeYrs={(e: any) => {
                                            SetTEError('')
                                            if((state?.trSummary == undefined || state?.trSummary.length == 0) && e.target.value.length == 0 ){
                                                setState({...state, trafficEngineering: '0'})
                                            }else{
                                                if(parseInt(e.target.value) >= 0) {
                                                    setState({...state, trafficEngineering: e.target.value})
                                                }
                                            }
                                        }}
                                        onError={props.Error.trSummary}
                                        onErrorYrs={TEError}
                                        disabled={!isEdit}
                                        // onLostFocus={() => {
                                        //     if(state?.trSummary != undefined && state?.trSummary?.length > 1000){
                                        //         ErrorToast(`Maximum of 1000 characters and typed characters are ${state?.trSummary?.length} `)
                                        //         setState({...state, trSummary: state?.trSummary.substr(0,1000)})
                                        //     }
                                        //     setTimeout(() => {
                                        //         onLostFocusQualification()
                                        //     }, 500)
                                        // }}
                                        onLostFocus={(e: any) => {
                                            if(state?.trSummary?.trim() !== stateExpExperties?.trSummary?.trim())
                                             {
                                                 if(state?.trSummary != undefined && state?.trSummary?.length > 1000){
                                                     ErrorToast(`Maximum of 1000 characters and typed characters are ${state?.trSummary?.length} `)
                                                     setState({...state, trSummary: state?.trSummary.substr(0,1000)})
                                                     setStateExpExperties({...stateExpExperties, trSummary : stateExpExperties?.trSummary?.substr(0,1000)})
                                                 }
                                                 else
                                                 {
                                                  setState({...state, trSummary: e.target.value})
                                                  setStateExpExperties({...stateExpExperties, trSummary: e.target.value})
                                                 }
                                             }
                                            setTimeout(() => {
                                                onLostFocusQualification()
                                            }, 500)
                                        }}
                                        onLostFocusYrs={() => {
                                            setTimeout(() => {
                                                onLostFocusQualification()
                                            }, 500)
                                        }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2}>
                                <Grid.Column  width={8} mobile={16} widescreen={8} computer={8} tablet={16}>
                                    <RelevantExpBox
                                        id={'txtTrafficTransportManagement'}
                                        label={'Traffic/Transport Management'}
                                        value={stateExpExperties?.ttmSummary}
                                        valueYrs={state?.trafficTransportManagement}
                                        // onChange={(e: any) => {
                                        //     if(state?.ttmSummary != undefined && state?.ttmSummary?.length > 1000){
                                        //         ErrorToast(`Maximum of 1000 characters and typed characters are ${state?.ttmSummary?.length} `)
                                        //         setState({...state, ttmSummary: state?.ttmSummary.substr(0,1000)})
                                        //     }else {
                                        //         setState({...state, ttmSummary: e.target.value})
                                        //     }
                                        // }}
                                        onChange={(e: any) => {
                                            if(stateExpExperties?.ttmSummary != undefined && stateExpExperties?.ttmSummary?.length > 1000){
                                                ErrorToast(`Maximum of 1000 characters and typed characters are ${stateExpExperties?.ttmSummary?.length} `)
                                                setStateExpExperties({...stateExpExperties, ttmSummary: stateExpExperties?.ttmSummary.substr(0,1000)})
                                            }else{
                                                setStateExpExperties({...stateExpExperties, ttmSummary: e.target.value})
                                            }
                                        }}
                                        onChangeYrs={(e: any) => {
                                            SetTTMError('')
                                            if((state?.ttmSummary == undefined || state?.ttmSummary.length == 0) && e.target.value.length == 0 ){
                                                setState({...state, trafficTransportManagement: '0'})
                                            }else{
                                                if(parseInt(e.target.value) >= 0) {
                                                    setState({...state, trafficTransportManagement: e.target.value})
                                                }
                                            }
                                        }}
                                        onError={props.Error.ttmSummary}
                                        onErrorYrs={TTMError}
                                        disabled={!isEdit}
                                        // onLostFocus={() => {
                                        //     if(state?.ttmSummary != undefined && state?.ttmSummary?.length > 1000){
                                        //         ErrorToast(`Maximum of 1000 characters and typed characters are ${state?.ttmSummary?.length} `)
                                        //         setState({...state, ttmSummary: state?.ttmSummary.substr(0,1000)})
                                        //     }
                                        //     setTimeout(() => {
                                        //         onLostFocusQualification()
                                        //     }, 500)
                                        // }}
                                        onLostFocus={(e: any) => {
                                            if(state?.ttmSummary?.trim() !== stateExpExperties?.ttmSummary?.trim())
                                             {
                                                 if(state?.ttmSummary != undefined && state?.ttmSummary?.length > 1000){
                                                     ErrorToast(`Maximum of 1000 characters and typed characters are ${state?.ttmSummary?.length} `)
                                                     setState({...state, ttmSummary: state?.ttmSummary.substr(0,1000)})
                                                     setStateExpExperties({...stateExpExperties, ttmSummary : stateExpExperties?.ttmSummary?.substr(0,1000)})
                                                 }
                                                 else
                                                 {
                                                  setState({...state, ttmSummary: e.target.value})
                                                  setStateExpExperties({...stateExpExperties, ttmSummary: e.target.value})
                                                 }
                                             }
                                            setTimeout(() => {
                                                onLostFocusQualification()
                                            }, 500)
                                        }}
                                        onLostFocusYrs={() => {
                                            setTimeout(() => {
                                                onLostFocusQualification()
                                            }, 500)
                                        }}
                                    />
                                </Grid.Column>
                                <Grid.Column  width={8} mobile={16} widescreen={8} computer={8} tablet={16}>
                                    <RelevantExpBox
                                        id={'txtRoadSafetyEngineering'}
                                        label={'Road Safety Engineering'}
                                        value={stateExpExperties?.rseSummary}
                                        valueYrs={state?.roadSafetyEngineering}
                                        // onChange={(e: any) => {
                                        //     if(state?.rseSummary != undefined && state?.rseSummary?.length > 1000){
                                        //         ErrorToast(`Maximum of 1000 characters and typed characters are ${state?.rseSummary?.length} `)
                                        //         setState({...state, rseSummary: state?.rseSummary.substr(0,1000)})
                                        //     }else {
                                        //         setState({...state, rseSummary: e.target.value})
                                        //     }
                                        // }}
                                        onChange={(e: any) => {
                                            if(stateExpExperties?.rseSummary != undefined && stateExpExperties?.rseSummary?.length > 1000){
                                                ErrorToast(`Maximum of 1000 characters and typed characters are ${stateExpExperties?.rseSummary?.length} `)
                                                setStateExpExperties({...stateExpExperties, rseSummary: stateExpExperties?.rseSummary.substr(0,1000)})
                                            }else{
                                                setStateExpExperties({...stateExpExperties, rseSummary: e.target.value})
                                            }
                                        }}
                                        onChangeYrs={(e: any) => {
                                            SetRSEError('')
                                            if((state?.rseSummary == undefined || state?.rseSummary.length == 0) && e.target.value.length == 0 ){
                                                setState({...state, roadSafetyEngineering: '0'})
                                            }else{
                                                if(parseInt(e.target.value) >= 0) {
                                                    setState({...state, roadSafetyEngineering: e.target.value})
                                                }
                                            }
                                        }}
                                        onError={props.Error.rseSummary}
                                        onErrorYrs={RSEError}
                                        disabled={!isEdit}
                                        // onLostFocus={() => {
                                        //     if(state?.rseSummary != undefined && state?.rseSummary?.length > 1000){
                                        //         ErrorToast(`Maximum of 1000 characters and typed characters are ${state?.rseSummary?.length} `)
                                        //         setState({...state, rseSummary: state?.rseSummary.substr(0,1000)})
                                        //     }
                                        //     setTimeout(() => {
                                        //         onLostFocusQualification()
                                        //     }, 500)
                                        // }}
                                        onLostFocus={(e: any) => {
                                            if(state?.rseSummary?.trim() !== stateExpExperties?.rseSummary?.trim())
                                             {
                                                 if(state?.rseSummary != undefined && state?.rseSummary?.length > 1000){
                                                     ErrorToast(`Maximum of 1000 characters and typed characters are ${state?.rseSummary?.length} `)
                                                     setState({...state, rseSummary: state?.rseSummary.substr(0,1000)})
                                                     setStateExpExperties({...stateExpExperties, rseSummary : stateExpExperties?.rseSummary?.substr(0,1000)})
                                                 }
                                                 else
                                                 {
                                                  setState({...state, rseSummary: e.target.value})
                                                  setStateExpExperties({...stateExpExperties, rseSummary: e.target.value})
                                                 }
                                             }
                                            setTimeout(() => {
                                                onLostFocusQualification()
                                            }, 500)
                                        }}
                                        onLostFocusYrs={() => {
                                            setTimeout(() => {
                                                onLostFocusQualification()
                                            }, 500)
                                        }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2}>
                                <Grid.Column  width={8} mobile={16} widescreen={8} computer={8} tablet={16}>
                                    <RelevantExpBox
                                        id={'txtBehaviouralScience'}
                                        label={'Behavioural Science'}
                                        value={stateExpExperties?.bsSummary}
                                        valueYrs={state?.behaviouralScience}
                                        // onChange={(e: any) => {
                                        //     if(state?.bsSummary != undefined && state?.bsSummary?.length > 1000){
                                        //         ErrorToast(`Maximum of 1000 characters and typed characters are ${state?.bsSummary?.length} `)
                                        //         setState({...state, bsSummary: state?.bsSummary.substr(0,1000)})
                                        //     }else {
                                        //         setState({...state, bsSummary: e.target.value})
                                        //     }
                                        // }}
                                        onChange={(e: any) => {
                                            if(stateExpExperties?.bsSummary != undefined && stateExpExperties?.bsSummary?.length > 1000){
                                                ErrorToast(`Maximum of 1000 characters and typed characters are ${stateExpExperties?.bsSummary?.length} `)
                                                setStateExpExperties({...stateExpExperties, bsSummary: stateExpExperties?.bsSummary.substr(0,1000)})
                                            }else{
                                                setStateExpExperties({...stateExpExperties, bsSummary: e.target.value})
                                            }
                                        }}
                                        onChangeYrs={(e: any) => {
                                            SetBSError('')
                                            if((state?.bsSummary == undefined || state?.bsSummary.length == 0) && e.target.value.length == 0 ){
                                                setState({...state, behaviouralScience: '0'})
                                            }else{
                                                if(parseInt(e.target.value) >= 0) {
                                                    setState({...state, behaviouralScience: e.target.value})
                                                }
                                            }
                                        }}
                                        onError={props.Error.bsSummary}
                                        onErrorYrs={BSError}
                                        disabled={!isEdit}
                                        // onLostFocus={() => {
                                        //     if(state?.bsSummary != undefined && state?.bsSummary?.length > 1000){
                                        //         ErrorToast(`Maximum of 1000 characters and typed characters are ${state?.bsSummary?.length} `)
                                        //         setState({...state, bsSummary: state?.bsSummary.substr(0,1000)})
                                        //     }
                                        //     setTimeout(() => {
                                        //         onLostFocusQualification()
                                        //     }, 500)
                                        // }}
                                        onLostFocus={(e: any) => {
                                            if(state?.bsSummary?.trim() !== stateExpExperties?.bsSummary?.trim())
                                             {
                                                 if(state?.bsSummary != undefined && state?.bsSummary?.length > 1000){
                                                     ErrorToast(`Maximum of 1000 characters and typed characters are ${state?.bsSummary?.length} `)
                                                     setState({...state, bsSummary: state?.bsSummary.substr(0,1000)})
                                                     setStateExpExperties({...stateExpExperties, bsSummary : stateExpExperties?.bsSummary?.substr(0,1000)})
                                                 }
                                                 else
                                                 {
                                                  setState({...state, bsSummary: e.target.value})
                                                  setStateExpExperties({...stateExpExperties, bsSummary: e.target.value})
                                                 }
                                             }
                                            setTimeout(() => {
                                                onLostFocusQualification()
                                            }, 500)
                                        }}
                                        onLostFocusYrs={() => {
                                            setTimeout(() => {
                                                onLostFocusQualification()
                                            }, 500)
                                        }}
                                    />
                                </Grid.Column>
                    <Grid.Column width={8} mobile={16} widescreen={8} computer={8} tablet={16}>
                        <span style={{display: 'inline-flex'}}><b>Other relevant experience:</b></span>
                        <p>Explain any other relevant experience that has contributed to your professional development
                            relating to road safety audits (maximum of 1000 characters)</p>
                        <MultilineTextBox id={'txtOre'}
                                          wordCount={true}
                                          className={'other_exp'}
                                          value={stateExpExperties?.otherRelevantExperience}
                                          onerror={ORError}
                                        //   onChange={(e: any) => {
                                        //       SetORError('')
                                        //       if(state?.otherRelevantExperience != undefined && state?.otherRelevantExperience?.length > 1000){
                                        //           ErrorToast(`Maximum of 1000 characters and typed characters are ${state?.bsSummary?.length} `)
                                        //           setState({...state, otherRelevantExperience: state?.otherRelevantExperience.substr(0,1000)})
                                        //       }else {
                                        //           setState({...state, otherRelevantExperience: e.target.value})
                                        //       }
                                        //   }}
                                        onChange={(e: any) => {
                                            SetORError('')
                                            if(stateExpExperties?.otherRelevantExperience != undefined && stateExpExperties?.otherRelevantExperience?.length > 1000){
                                                ErrorToast(`Maximum of 1000 characters and typed characters are ${stateExpExperties?.otherRelevantExperience?.length} `)
                                                setStateExpExperties({...stateExpExperties, otherRelevantExperience: stateExpExperties?.otherRelevantExperience.substr(0,1000)})
                                            }else{
                                                setStateExpExperties({...stateExpExperties, otherRelevantExperience: e.target.value})
                                            }

                                        }}
                                        //   onLostFocus={() => {
                                        //       if(state?.otherRelevantExperience != undefined && state?.otherRelevantExperience?.length > 1000){
                                        //           ErrorToast(`Maximum of 1000 characters and typed characters are ${state?.bsSummary?.length} `)
                                        //           setState({...state, otherRelevantExperience: state?.otherRelevantExperience.substr(0,1000)})
                                        //       }
                                        //       setTimeout(() => {
                                        //           onLostFocusQualification()
                                        //       }, 500)
                                        //   }}
                                        onLostFocus={(e: any) => {
                                            if(state?.otherRelevantExperience?.trim() !== stateExpExperties?.otherRelevantExperience?.trim())
                                            {
                                                if(state?.otherRelevantExperience != undefined && state?.otherRelevantExperience?.length > 1000){
                                                    ErrorToast(`Maximum of 1000 characters and typed characters are ${state?.otherRelevantExperience?.length} `)
                                                    setState({...state, otherRelevantExperience: state?.otherRelevantExperience.substr(0,1000)})
                                                    setStateExpExperties({...stateExpExperties, otherRelevantExperience : stateExpExperties?.otherRelevantExperience?.substr(0,1000)})
                                                }
                                                else
                                                {
                                                 setState({...state, otherRelevantExperience: e.target.value})
                                                 setStateExpExperties({...stateExpExperties, otherRelevantExperience: e.target.value})
                                                }
                                            }

                                               setTimeout(() => {
                                                   onLostFocusQualification()
                                               }, 500)
                                           }}
                                          disabled={!isEdit}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column width={16}>
                        <p>It is important that information provided clearly supports the claimed years of experience
                            below:</p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column width={16}>
                        <p><i>&quot;I declare that I have at least the following number of years of work experience and
                            capability, indicated as selected below, in road design, traffic engineering, transport
                            management, road safety engineering &/or behavioural&quot;</i></p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column width={4} mobile={16} computer={4} tablet={8} widescreen={4} largeScreen={4}>
                        <Dropdowns id={'ddSelect'} placeholder={'Select'}
                                   options={[
                                       {
                                           key: 0,
                                           text: 'Select',
                                           value: '0'
                                       },
                                       {
                                           key: 1,
                                           text: '0-4 Years',
                                           value: '1'
                                       },
                                       {
                                           key: 2,
                                           text: '5-6 Years',
                                           value: '2'
                                       },
                                       {
                                           key: 3,
                                           text: '7 or more Years',
                                           value: '3'
                                       }
                                   ]}
                                   fluid={true} selection={true}
                                   value={state?.eligibilityCriteria}
                                   onerror={WEDError}
                                   onChange={(e: any, data: any) => {
                                       SetWEDError('')
                                       setState({...state, eligibilityCriteria: data.value});
                                   }}
                                   disabled={!isEdit}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column width={16}>
                        <p>Select the areas where you have the expertise to identify risks to road safety in</p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={5} mobile={8} widescreen={5}>
                        <NameWithCheckBox label={'Construction Projects'} name={'state'} id={'constructionProject'}
                                          value={state?.constructionProject} onChange={(e: any, data: any) => {
                            setState({...state, constructionProject: !state.constructionProject});
                        }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} mobile={8} widescreen={5}>
                        <NameWithCheckBox label={'Reconstruction Projects'} name={'state'} id={'reconstructionProjects'}
                                          value={state?.reconstructionProjects} onChange={(e: any, data: any) => {
                            setState({...state, reconstructionProjects:  !state.reconstructionProjects});
                        }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} mobile={8} widescreen={5}>
                        <NameWithCheckBox label={'Intersection Projects'} name={'state'} id={'intersectionProjects'}
                                          value={state?.intersectionProjects} onChange={(e: any, data: any) => {
                            setState({...state, intersectionProjects:  !state.intersectionProjects});
                        }} disabled={!isEdit}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={5} mobile={8} widescreen={5}>
                        <NameWithCheckBox label={'Local Area Traffic Management Projects'} name={'state'}
                                          id={'localAreaTrafficManagementProjects'}
                                          value={state?.localAreaTrafficManagementProjects}
                                          onChange={(e: any, data: any) => {
                                              setState({...state, localAreaTrafficManagementProjects:  !state.localAreaTrafficManagementProjects});
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} mobile={8} widescreen={5}>
                        <NameWithCheckBox label={'Traffic Signal Projects'} name={'state'} id={'trafficSignalProjects'}
                                          value={state?.trafficSignalProjects} onChange={(e: any, data: any) => {
                            setState({...state, trafficSignalProjects:  !state.trafficSignalProjects});
                        }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} mobile={8} widescreen={5}>
                        <NameWithCheckBox label={'Temporary Traffic Management'} name={'state'}
                                          id={'temporaryTrafficManagement'} value={state?.temporaryTrafficManagement}
                                          onChange={(e: any, data: any) => {
                                              setState({...state, temporaryTrafficManagement:  !state.temporaryTrafficManagement});
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={5} mobile={8} widescreen={5}>
                        <NameWithCheckBox label={'Development Planning'} name={'state'} id={'developmentPlanning'}
                                          value={state?.developmentPlanning} onChange={(e: any, data: any) => {
                            setState({...state, developmentPlanning:  !state.developmentPlanning});
                        }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} mobile={8} widescreen={5}>
                        <NameWithCheckBox label={'Design & Construction Projects'} name={'state'}
                                          id={'designConstructionProjects'} value={state?.designConstructionProjects}
                                          onChange={(e: any, data: any) => {
                                              setState({...state, designConstructionProjects:  !state.designConstructionProjects});
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} mobile={8} widescreen={5}>
                        <NameWithCheckBox label={'Crash Investigation and Analyses'} name={'state'}
                                          id={'crashInvestigation'} value={state?.crashInvestigation}
                                          onChange={(e: any, data: any) => {
                                              setState({...state, crashInvestigation:  !state.crashInvestigation});
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={5} mobile={8} widescreen={5}>
                        <NameWithCheckBox label={'Vulnerable Road User (Pedestrians, cyclists, motorcyclists)'}
                                          name={'state'} id={'vulnerableRoadUser'} value={state?.vulnerableRoadUser}
                                          onChange={(e: any, data: any) => {
                                              setState({...state, vulnerableRoadUser:  !state.vulnerableRoadUser});
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5}>

                    </Grid.Column>
                    <Grid.Column width={5}>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <br/>
            <hr/>
            <Modal open={open ? open : false} modalAction={false} setOpen={setFileLoad}
                   title={fileType == 'CV' ? "Auditor Resume" : "Supporting Documents for Experience"}
                   ModalBody={() => (
                       <FileUploadPop
                           fileType={fileType}
                           auditorEmail={props.auditor.personalDetails.txtEmail}
                           message={fileType == 'CV' ? "Please note you can only have one resume stored and uploading a new file will replace the existing file" : "Please note you can upload as many documents as you can"}
                           auditorId={props.auditor.personalDetails.txtAuditorId}
                           editable={props.qe.isActivated}
                       />)}/>
        </React.Fragment>
    );
};

export default QualificationsAndExperience;
