import React, {useEffect, useState} from "react";
import {Container, Grid, Table} from "semantic-ui-react";
import InnerTitleBar from "../../../../framework/layouts/InnerTitleBar";
import {userRoleSelector} from "../../../../common/roleSelector";
import {getSessionCookie} from "../../../../framework/common/SessionHandler";
import OutLineButton from "../../../../framework/components/Buttons/OutlIneButton";
import RSATable from "../../../../framework/widgets/TableWithBackgrounds/RSATable";
import TableHeader from "../../../../framework/types/TableHeader";
import tags from "../../../../models/common/tag";
import { ApiCallForDeleteFaq, FetchFaqCategory, SaveFaqPageContent, SavePageContent, SearchApiCallForAdminFaqPageContent, UpdateOrderApiCallForFaqPageContent } from "../../../search/SearchController";
import { FaqPageContentRow } from "../../../../models/apiResponce/mis/AuditPhaseRes";
import SuccessToast from "../../../../framework/components/Toast/SuccessToast";
import NameWithDefault from "../../../../framework/widgets/NameWithText/NameWithDefault";
import Position from "../../../../framework/types/Position";
import { FaqPageContentReq } from "../../../../models/apiRequest/mis/AuditPhasesReq";
import ErrorToast from "../../../../framework/components/Toast/ErrorToast";
import ReactQuill from "react-quill";
import Modal from "../../../../framework/components/Modals";
import 'react-quill/dist/quill.snow.css'; // import styles
import DefaultButton from "../../../../framework/components/Buttons/DefaultButton";
import waringImage from "../../../../assets/images/icons/warning.png"
import NameWithDropDown from "../../../../framework/widgets/NameWithDropDown";
import CustomToolbar from "../../common/CustomToolbar";
import { PaginationProps } from "antd/es/pagination";
import { Editor } from "@tinymce/tinymce-react";

const FaqPageContentContainer = () =>{

    const [state, setState] = React.useState<FaqPageContentReq>();
    const [faqPageContentRes, setFaqPageContentRes] = React.useState<FaqPageContentRow[]>();
    const session = getSessionCookie();
    const [selectedFaqPageContent, setSelectedFaqPageContent] = useState(0)
    const [htmlContent, setHtmlContent] = useState('');
    const [open2, setOpen2] = useState(false);
    const [delFaqId, setDelFaqId] = useState(0); 
    const [deleteError, setDleteError] = React.useState('')
    const [btnText, setButtoText] = React.useState('')
    const [isAddingNew, setIsAddingNew] = useState(false);
    const [faqCategory, setFaqCategory] = useState<any>()
    const [formKey, setFormKey] = useState(0);
    const [current, setCurrent] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0)

    useEffect(() => {
        if(selectedFaqPageContent != 0){
            setButtoText('Update')
        }else if(isAddingNew){
            setButtoText('Add')
        }
    },[selectedFaqPageContent, isAddingNew])

    const rsaFaqPageContentHead: TableHeader[] = [

        {
            text: ""
        },
        {
            text: ""
        },
        {
            text: "Question"
        },
        
        {
            text: ""
        }
    ];
    const [rsaFaqPageContent, setrsaFaqPageContent] = useState<object[]>([
    ])

    const SetValues = (data:FaqPageContentRow[]) => {
        const TableRows: object[] = [];
        data.map((r:FaqPageContentRow, index:number) => {
            TableRows.push([{
                    tag: "icon-fa",
                    text: "",
                    icon: "arrow-up",
                    onclick: () => moveUp(index)
                },
                {
                    tag: "icon-fa",
                    text: "",
                    icon: "arrow-down",
                    onclick: () => moveDown(index)
                },
                {
                    tag: tags.text,
                    text: `[${r.categoryDesc}] ${r.question}`,
                    icon: "",
                    onclick: null
                },
                {
                    tag: "icon-fa",
                    text: "center",
                    icon: "edit",
                    onclick:() => {
                        setSelectedFaqPageContent(r.faqId || 0)
                        setHtmlContent(r.answer || '')
                        setState(prevState => ({
                            ...prevState,
                            ...r,
                            category: r.categoryId,
                        }));
                    }
                },
                {
                    tag: "icon-fa",
                    text: "center",
                    icon: "delete",
                    onclick: () => {
                        promptDeleteFaq(r.faqId || 0)
                    }
                }])
        })
        setrsaFaqPageContent(TableRows);
    }

    const moveUp = (index:any) => {
        if (index === 0) return; // Can't move up the first item
    
        setFaqPageContentRes(currentRes => {
            const newRes = [...(currentRes || [])];
    
            // Ensure there are items to swap
            if (newRes[index] && newRes[index - 1]) {
                // Swap the FAQ items
                [newRes[index], newRes[index - 1]] = [newRes[index - 1], newRes[index]];
    
                const tempSequence = newRes[index].sequence;
                newRes[index].sequence = newRes[index - 1].sequence;
                newRes[index - 1].sequence = tempSequence;
            }
    
            updateFaqOrderOnServer(newRes);
    
            return newRes;
        });
    };

    const moveDown = (index:any) => {
        setFaqPageContentRes(currentRes => {
            const newRes = [...(currentRes || [])];
    
            if (index >= newRes.length - 1) return newRes;
    
            // Ensure there are items to swap
            if (newRes[index] && newRes[index + 1]) {
                // Swap the FAQ items
                [newRes[index], newRes[index + 1]] = [newRes[index + 1], newRes[index]];
    
                const tempSequence = newRes[index].sequence;
                newRes[index].sequence = newRes[index + 1].sequence;
                newRes[index + 1].sequence = tempSequence;
            }
    
            updateFaqOrderOnServer(newRes);
    
            return newRes;
        });
    };
        
    const updateFaqOrderOnServer = async (updatedRes:FaqPageContentRow[]) => {
        try {
            const faqContentReqArray = updatedRes.map((row) => ({
                id: row.faqId,
                question: row.question,
                answer: row.answer,
                sequence: row.sequence,
                category: row.categoryId,
                countryStateId: row.countryStateId,
            }));
            
            const pageContents = UpdateOrderApiCallForFaqPageContent(faqContentReqArray);
            Promise.resolve(pageContents).then((value) => {
                if (value && value.faQs) {
                    SetValues(value.faQs);
                    setFaqPageContentRes(value.faQs);
                }
            });
        } catch (error) {
            // Handle the error here
            ErrorToast('Error updating faq page content sequence');
        }
    };
    
    const promptDeleteFaq = (faqId: number) => {
        console.log('delete faq', faqId)
        setDelFaqId(faqId);
        setOpen2(true); // Open the confirmation modal
    };

    const DeleteFaq = async () => {
        try {
            //Delete
            const result = await ApiCallForDeleteFaq(delFaqId);
            if (result.faQs) {
                SuccessToast('FAQ deleted successfully');

                // Set a timeout before navigating
                setTimeout(() => {
                    location.reload()
                }, 2000); // 2000 milliseconds delay (2 seconds)
            }
        } catch (error) {
            ErrorToast('Error deleting FAQ');
        } finally {
            setOpen2(false); // Close the modal
            setDelFaqId(0); // Reset the delete audit ID
        }
    };

    useEffect(() => {
        try {

            const faqCategoryArray = FetchFaqCategory();
            Promise.resolve(faqCategoryArray).then((value) => {
                console.log(value)
                setFaqCategory(value)
            })

            const pageContents = SearchApiCallForAdminFaqPageContent(0);
            Promise.resolve(pageContents).then((value) => {
                if (value && value.faQs) {
                    SetValues(value.faQs);
                    setFaqPageContentRes(value.faQs);
                    setTotalRecords(value.totalRows)
                }
            });
        } catch (error) {
            // Handle the error here
            ErrorToast('Error fetching faq page content results');
        }
        
    }, []);

    const handleHtmlContentChange = (content:any) => {
        setHtmlContent(content);
    };

    const onCancelClick = () => {
        location.reload();
    };

    const onSaveClick = async () => {
        try {
            // Update
            console.log(state)
            const updatedData: FaqPageContentReq = {
                ...state!,
                answer : htmlContent,
                id: selectedFaqPageContent
            };
            const result = await SaveFaqPageContent(updatedData);
            if (result) {
                SuccessToast('Faq page content saved successfully');
                setTimeout(() => {
                    // Reload the page
                    window.location.reload();
                }, 2000); // 2000 milliseconds delay (2 seconds)
            }
        } catch (error) {
            // Handle the error here
            ErrorToast('Error saving faq page content');
        }
    };

    const resetFormFields = () => {
        setHtmlContent('');
        setState(undefined); // Reset the state
        setSelectedFaqPageContent(0);
        setFormKey(prevKey => prevKey + 1);
    };

    const onAddNewClick = () => {
        resetFormFields();
        setIsAddingNew(true);
    };

    const onChange: PaginationProps['onChange'] = (page) => {
        setCurrent(page);
        const searchData = SearchApiCallForAdminFaqPageContent(page)
        Promise.resolve(searchData).then((value) => {
            if (value && value.faQs) {
                SetValues(value.faQs);
                setFaqPageContentRes(value.faQs);
                setTotalRecords(value.totalRows)
            }
        })
    };

    return(
        <React.Fragment>
        <Container fluid={true} style={{marginTop: '14px', marginBottom: '20px'}}>
            <InnerTitleBar
                title={"Add/Edit FAQs Page Content"}
                usreName={session.userDisplayName != null ? session.userDisplayName : "  "}
                userRole={userRoleSelector(session.userRoleId.toString())}
                lastLogin={session.lastLogin.toString()}
                status={userRoleSelector(session.userRoleId.toString())}
            />
            <Grid>
                {selectedFaqPageContent == 0 && !isAddingNew ? (
                    <Grid.Row columns={2}>
                        <Grid.Column width={2}>

                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column className={'button-flex'} style={{display:'flex', justifyContent:'right', paddingRight: '0px'}}>
                                        <OutLineButton
                                            id={'btnAddNew'}
                                            style={{ width: '300px' }}
                                            onClick={onAddNewClick}
                                            color='blue'
                                            className={'background-transaparent'}
                                            text={'Add a new FAQ question / answer'}
                                            disabled={false}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>     
                        </Grid.Column>
                </Grid.Row>
                ): null}
                {selectedFaqPageContent == 0 && !isAddingNew && rsaFaqPageContent.length > 0 ? (<Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <div style={{ overflow: 'auto' }}>
                            <RSATable
                                head={rsaFaqPageContentHead}
                                body={rsaFaqPageContent}
                                current={current}
                                pageCount={totalRecords/10}
                                onChange={onChange}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>):null}
                {selectedFaqPageContent != 0 || isAddingNew ? (<>
                {/* <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <b><span style={{display:'inline-flex'}}>FAQ Page Content</span></b>
                                </Grid.Column>
                                <Grid.Column>
                                    
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>     
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row> */}
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={3}>
                                    <span style={{display:'inline-flex'}}>ID:</span>
                                </Grid.Column>
                                <Grid.Column>
                                    <span style={{display:'inline-flex'}}>{selectedFaqPageContent}</span>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>     
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={8} widescreen={8} mobile={16} computer={8} tablet={16} largeScreen={16}>
                                    <NameWithDropDown
                                        key={formKey}
                                        selection={true}
                                        labelText={'Category:'}
                                        labelPosition={Position.Top}
                                        id={'ddCategory'}
                                        placeholder={'Select Category'}
                                        options={faqCategory}
                                        required={true}
                                        value={state?.category?.toString()}
                                        onChange={(e:any,data:any) => {
                                            console.log("New category value:", data.value); // Debug log
                                            setState((prevState) => ({ ...prevState, category: data.value }))
                                        }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>    
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={8} widescreen={8} mobile={16} computer={8} tablet={16} largeScreen={16}>
                                <NameWithDefault 
                                    labelText={'Question:'} 
                                    labelPosition={Position.Top}
                                    id={'txtQuestion'} 
                                    fluid={true} 
                                    type={'text'}
                                    placeholder={'Enter question'}
                                    required={true}
                                    value={state?.question || ''}
                                    onChange={(e: any) => {
                                        setState({...state,question:e.target.value})
                                    }}
                                />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>    
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={8} widescreen={8} mobile={16} computer={8} tablet={16} largeScreen={16}>
                                    <span>Content:</span><br/>
                                    <Editor
                                        apiKey='3xxcpcel6tu6p7yqlreybrkhywmme7e0qxktbcs6831srrv5'
                                        init={{
                                            plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker code',
                                            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat code',
                                            tinycomments_mode: 'embedded',
                                            tinycomments_author: 'Author name',
                                            mergetags_list: [
                                            { value: 'First.Name', title: 'First Name' },
                                            { value: 'Email', title: 'Email' },
                                            ],
                                            ai_request: (request:any, respondWith:any) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
                                        }}
                                        value={htmlContent}
                                        onEditorChange={handleHtmlContentChange}
                                        />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>    
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>     
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12} className={'button-flex'} style={{display:'flex', justifyContent:'right'}}>
                        <OutLineButton
                            id={'btnCancel'}
                            style={{ width: '150px', marginLeft: '10px' }}
                            onClick={onCancelClick}
                            color='red'
                            className={'background-transaparent'}
                            text={'Cancel'}
                            disabled={false}
                        />
                        <OutLineButton
                            id={'btnUpdate'}
                            style={{ width: '150px' }}
                            onClick={onSaveClick}
                            color='blue'
                            className={'background-transaparent'}
                            text={btnText}
                            disabled={false}
                        />
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                </>):null}
            </Grid>
        </Container>
        <Grid>
                <Modal
                        open={open2 ? open2 : false}
                        setOpen={setOpen2}
                        title={"Warning !"}
                        modalDescription={true}
                        descriptionHeader={'Are you sure you want to delete this faq?'}
                        descriptionBody={'This action cannot be undone.'}
                        imageUrl={waringImage}
                        imageSize='small'
                        modalAction={true}
                        ActionButtons={() => (
                            <ControllButtonReg
                                NoClick={() => {
                                    setOpen2(false)
                                    setDelFaqId(0)
                                }}
                                YesClick={() => DeleteFaq()}
                            />
                        )}
                    />
                <br/>
            </Grid>
        </React.Fragment>
    );
}

export default FaqPageContentContainer

interface ControllButtonProps {
    //eslint-disable-next-line
    NoClick: any;
    //eslint-disable-next-line
    YesClick: any;
    //eslint-disable-next-line
}

const ControllButtonReg = (props: ControllButtonProps) => {
    return (
        <React.Fragment>
            <OutLineButton id={'btnCancel'} style={{width: '150px'}} onClick={() => {
                props.NoClick()
            }} color={'red'} text={'No'}/>
            <DefaultButton id={'btnSave'} style={{width: '150px'}} onClick={() => {
                props.YesClick()
            }} color={'blue'}
                           text={'Yes'}/>
        </React.Fragment>
    )
}
