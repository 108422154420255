import React, {useEffect} from "react";
import Header from "../../../../framework/layouts/Header";
import Footer from "../../../../framework/layouts/Footer";
import NumberofAuditsContainer from "./NumberofAuditsContainer";


const Numberofauditors = () => {
     return(
         <React.Fragment>
             <Header>
                <NumberofAuditsContainer/>
             </Header>
             <Footer/>
         </React.Fragment>
     );
}

export default Numberofauditors