import React, {useEffect} from "react";
import Header from "../../../../framework/layouts/Header";
import Footer from "../../../../framework/layouts/Footer";
import AuditStagesContainer from "./AuditStagesContainer";


const AuditStages = () => {
    const mainUrl = window.location.pathname
    console.log(mainUrl)
    return(
        <React.Fragment>
            <Header>
                <AuditStagesContainer/>
            </Header>
            <Footer/>
        </React.Fragment>
    );
}

export default AuditStages