import React, {useEffect, useState} from "react";
import {Container, Grid, Table} from "semantic-ui-react";
import InnerTitleBar from "../../../../framework/layouts/InnerTitleBar";
import {userRoleSelector} from "../../../../common/roleSelector";
import {getSessionCookie} from "../../../../framework/common/SessionHandler";
import OutLineButton from "../../../../framework/components/Buttons/OutlIneButton";
import { GetAssessorChair, SaveApiCallForAssessorChair } from "../../../search/SearchController";
import SuccessToast from "../../../../framework/components/Toast/SuccessToast";
import ErrorToast from "../../../../framework/components/Toast/ErrorToast";
import NameWithPassword from "../../../../framework/widgets/NameWithText/NameWithPassword";
import Position from "../../../../framework/types/Position";
import { AssessorChairReq } from "../../../../models/apiRequest/mis/AssessorChairReq";

const AssessorChairContainer = () =>{

    const session = getSessionCookie();
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [selectEmail, setSelectEmail] = useState('')
    const [selectUserId, setSelectUserId] = useState(0)
    const [passwordError, setPasswordError] = React.useState('')

    useEffect(() => {
        GetAssessorChair().then((value) => {
            if (value) {
                console.log('value', value)
                console.log('userId', value.assessorChair.userId)
                console.log('workEmail', value.assessorChair.workEmail)
                setSelectUserId(value.assessorChair.userId)
                setSelectEmail(value.assessorChair.workEmail)
            }
        });
        
    }, []);

    useEffect(() => {
        console.log('selectEmail', selectEmail)
        console.log('selectUserId', selectUserId)
    },[selectEmail, selectUserId])

    const onSaveClick = () => {
        try {
            if (!Validating()) {
                return;
            }
    
            const newData: AssessorChairReq = {
                userId: selectUserId,
                workEmail: selectEmail || '',
                newPassword: newPassword,
                confirmPassword: confirmPassword
            };
    
            const searchData = SaveApiCallForAssessorChair(newData)
            Promise.resolve(searchData).then((value)=>{
                if(value){
                    SuccessToast('Assessor chair updated successfully')
                    setTimeout(() => {
                        // Reload the page
                        window.location.reload();
                    }, 2000); // 2000 milliseconds delay (2 seconds)
                }
            })
        } catch (error) {
            // Handle the error here
            ErrorToast('Error saving assessor chair');
        }
    }

    const Validating = () => {
        if (newPassword !== confirmPassword) {
            // Show an error message or handle the validation failure
            setPasswordError('New password and Confirm password must match');
            return false;
        }
        setPasswordError('');
        return true;
    };

    const [disableSave, setDisableSave] = React.useState(false);

useEffect(() => {
        DisableValidation();
    }, [newPassword, confirmPassword]);

    const DisableValidation = () =>{
        const isFormInvalid = (
            !newPassword ||
            !confirmPassword
        );
    
        setDisableSave(isFormInvalid);
    }

    return(
        <Container fluid={true} style={{marginTop: '14px', marginBottom: '20px'}}>
            <InnerTitleBar
                title={"Edit Assessor chair"}
                usreName={session.userDisplayName != null ? session.userDisplayName : "  "}
                userRole={userRoleSelector(session.userRoleId.toString())}
                lastLogin={session.lastLogin.toString()}
                status={userRoleSelector(session.userRoleId.toString())}
            />
            <Grid>
                {selectEmail.length > 0 ? (<>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                            <hr/>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <b><span style={{display:'inline-flex'}}>Edit Assessor chair</span></b>
                                </Grid.Column>
                                <Grid.Column>
                                    
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>     
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={3}>
                                    <span style={{display:'inline-flex'}}>Assessor chair user id:</span>
                                </Grid.Column>
                                <Grid.Column>
                                    <span style={{display:'inline-flex'}}>{selectUserId}</span>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>     
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={3}>
                                    <span style={{display:'inline-flex'}}>Email address:</span>
                                </Grid.Column>
                                <Grid.Column>
                                    <span style={{display:'inline-flex'}}>{selectEmail}</span>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>     
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2} widescreen={2} mobile={2} computer={2} tablet={2} largeScreen={2}>

                    </Grid.Column>
                    <Grid.Column width={12} widescreen={12} mobile={12} computer={12} tablet={12} largeScreen={12}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <NameWithPassword
                                        id={'txtPassword'}
                                        fluid={true}
                                        placeholder={'Enter New password'}
                                        value={newPassword}
                                        onChange={(e: any) => {
                                            setNewPassword(e.target.value);
                                        } } 
                                        labelText={"Password:"} 
                                        labelPosition={Position.Top} 
                                        type={"password"} 
                                        required={true} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>     
                    </Grid.Column>
                    <Grid.Column width={2} widescreen={2} mobile={2} computer={2} tablet={2} largeScreen={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <NameWithPassword
                                        id={'txtConfirmPassword'}
                                        fluid={true}
                                        placeholder={'Enter New confirm password'}
                                        value={confirmPassword}
                                        onChange={(e: any) => {
                                            setConfirmPassword(e.target.value);
                                        } } 
                                        labelText={"Confirm password:"} 
                                        labelPosition={Position.Top} 
                                        type={"password"}
                                        required={true} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>     
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <span style={{color:'red'}}>{passwordError}</span>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12} className={'button-flex'} style={{display:'flex', justifyContent:'right'}}>
                        <OutLineButton id={'btnSave'} 
                            style={{width: '150px'}} 
                            onClick={() => {
                                onSaveClick()
                            }} 
                            color='blue' 
                            className={"background-transaparent"}
                            text={'Save'}
                            disabled={disableSave}
                        />
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                </>):null}
            </Grid>
        </Container>
    );
}

export default AssessorChairContainer
