import React, {useEffect, useState} from "react";
import {Container, Grid} from "semantic-ui-react";
import InnerTitleBar from "../../../framework/layouts/InnerTitleBar";
import MessageCenter from "./MessageCenter";
import ApplyForLevelReview from "./ApplyForLevelReview";
import CurrentAvaiability from "./CurrentAvaiability";
import AuditSummery from "./AuditSummery";
import CourseSummery from "./CourseSummery";
import QESummary from "./Q&ESummary";
import {fetchAuditor, fetchAuditorAudit, fetchAuditorAuditAll} from "../profile/ProfileController";
import {
    AuditorRegionValueProps,
    PersonalDetailsValueProps,
    QualificationValueProps,
    RoadSafetyCoursesProps
} from "../profile/DataModal";
import {AuditorAuditRess} from "../../../models/apiResponce/Auditor/AuditorAuditRess";
import Modals from "../../../framework/components/Modals";



interface DashboardInterface {
    session:any,
}
const DashboardContainer = ({session}:DashboardInterface) =>{

    const [myReagion, setMyReagion] = useState<AuditorRegionValueProps>()
    const [qae,setQae] = useState<QualificationValueProps>()
    const [course,setCourses] = useState< RoadSafetyCoursesProps[]>()
    const [audits,setAudits] = useState< AuditorAuditRess[]>()
    const [auditor,seAuditor] = useState<PersonalDetailsValueProps>()
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
    
        const auditorPromise = fetchAuditor(session.auditorId, 0);
        Promise.resolve(auditorPromise).then((r) => {
            setMyReagion(r.auditorRegion);
            setQae(r.qualification);
            setCourses(r.roadSafetyCourses);
            seAuditor(r.personalDetails);
            // Optionally set loading to false here if you want it to stop as soon as data is fetched
        });
    
        const auditorAudits = fetchAuditorAuditAll(session.auditorId, 0);
        Promise.resolve(auditorAudits).then((r) => {
            setAudits(r);
            // Optionally set loading to false here if you want it to stop as soon as data is fetched
        });
    
        // Setting a timeout to automatically set loading to false after 5 seconds
        const timer = setTimeout(() => {
            setLoading(false);
        }, 3000);
    
        // Cleanup function to clear the timeout if the component unmounts
        return () => clearTimeout(timer);
    }, [session.auditorId]);
    
    //console.log('Dash Auditor',auditor)


    const userRoleSelector = (roleId:string)=>{
        switch(roleId){
            case "1":
                return "Admin";
            case "2":
                return "Assessor";
            case "3":
                return "Auditor";
            case "4":
                return "SuperAdmin";
            case "5":
                return "AssessorChair";
        }
    }

    return (
        <Container fluid={true} style={{/*height: '750vh',*/ marginTop: '14px', marginBottom: '20px'}}>
            <InnerTitleBar
                title={"Auditor's Dashboard"}
                usreName={session.userDisplayName}
                userRole={userRoleSelector(session.userRoleId.toString())}
                lastLogin={session.lastLogin}
                status={session.statusName == undefined || session.statusName == '' ? 'Saved as Draft' : session.statusName}
                level={session.auditorLevel}
                certExpDate={session.certExpDate}
                newId={session.auditorNewId}
            />
            <Grid>
                <Grid.Row>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <MessageCenter
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <ApplyForLevelReview
                            auditor={auditor}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <hr/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <CurrentAvaiability
                            myReagion={myReagion}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <hr/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <AuditSummery
                            audits={audits}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <hr/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <CourseSummery
                            courses={course}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <hr/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <QESummary
                            QandE={qae}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Modals
                  open={loading}
                  basicModal={true}
                  modalSize={'small'}
                  imageSize={'tiny'}
                  imageUrl={'https://images.emojiterra.com/google/noto-emoji/v2.034/128px/23f3.png'}
                  setOpen={setLoading}
                  modalDescription={true}
                  descriptionHeader={'Please wait ...!'}
                  descriptionBody={'Your data is loading ...'}
                  modalDimmer={"blurring"}
                  modalAction={false}
              />
        </Container>
    )
 }

 export default  DashboardContainer;