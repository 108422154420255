import React, {useEffect, useRef, useState} from "react";
import {Container, Grid, Table} from "semantic-ui-react";
import InnerTitleBar from "../../../../framework/layouts/InnerTitleBar";
import {userRoleSelector} from "../../../../common/roleSelector";
import {getSessionCookie} from "../../../../framework/common/SessionHandler";
import OutLineButton from "../../../../framework/components/Buttons/OutlIneButton";
import moment from "moment";
import RSATable from "../../../../framework/widgets/TableWithBackgrounds/RSATable";
import TableHeader from "../../../../framework/types/TableHeader";
import { PaginationProps } from "antd/es/pagination";
import tags from "../../../../models/common/tag";
import NameWithDefault from "../../../../framework/widgets/NameWithText/NameWithDefault";
import Position from "../../../../framework/types/Position";
import NameWithDropDown from "../../../../framework/widgets/NameWithDropDown";
import NameWithDate from "../../../../framework/widgets/NameWithText/NameWithDate";
import NameWithRadioButton from "../../../../framework/widgets/NameWithCheckBox/NameWithRadioButton";
import { fetchAllCourses, addCourse, fetchAllAuditorCourses } from "./CourseManagementController";
import { CourseClassification, CourseManagementRes } from "../../../../models/apiResponce/Courses/CourseManagementRes";
import SuccessToast from "../../../../framework/components/Toast/SuccessToast";
import { Color } from "@antv/attr";
import MultilineTextBox from "../../../../framework/components/TextBoxes/MultilineTextBox";
import { AuditorCourseRess } from "../../../../models/apiResponce/Auditor/AuditorCourseRess";
import DefaultButton from "../../../../framework/components/Buttons/DefaultButton";
import Modal from "../../../../framework/components/Modals";
import waringImage from "../../../../assets/images/icons/warning.png";

const CoursesClassificationContainer = () =>{
    const session = getSessionCookie();
    const CourseId = new URLSearchParams(location.search).get('id');
    const [title, setTtitle] = useState('Add Course')
    useEffect(() => {
        if(CourseId){
            setTtitle('Edit Course')
        }else{
            setTtitle('Add Course')
        }
    },[])

    return(
        <Container fluid={true} style={{marginTop: '14px', marginBottom: '20px'}}>
            <InnerTitleBar
                title={title}
                usreName={session.userDisplayName != null ? session.userDisplayName : "  "}
                userRole={userRoleSelector(session.userRoleId.toString())}
                lastLogin={session.lastLogin.toString()}
                status={userRoleSelector(session.userRoleId.toString())}
            />
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <AddEditCourse/>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
}

export default CoursesClassificationContainer


const AddEditCourse = () => {
    const [entries, setEntries] = useState<CourseClassification[]>([])
    const [removedEntries, setRemovedEntries] = useState<CourseClassification[]>([]);
    const [courseClassificationDet, setCourseClassificationDet] = useState<object[]>([])
    const [addClassificationButtonText, setAddClassificationButtonText] = useState('Add Classification');
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const [courseClassificationError, setCourseClassificationError] = useState('')
    const [courserror, setCourserror] = useState('')
    const [courseShortDesc, setDescriptionText] = useState('')
    const CourseId = new URLSearchParams(location.search).get('id');
    const [state, SetState] = useState<CourseManagementRes>();
    const [auditorCoursesList, setauditorCoursesList] = useState<AuditorCourseRess[]>([])
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [testDesc, setTestDesc] = useState("");
    const [buttonText, setButtoText] = useState('Add Course')
    const [delCourseClassificationIndex, setDelCourseClassificationIndex] = useState(0); 
    const [open2, setOpen2] = useState(false);
    const session = getSessionCookie();
    useEffect(() => {
        if(CourseId){
            setButtoText('Update Course')
        }else{
            setButtoText('Add Course')
        }
    },[])

    const courseClassificationHead: TableHeader[] = [
        {
            text: "Classification"
        },
        {
            text: "From date"
        },
        {
            text: "To date"
        },
        {
            text: ""
        },
        {
            text: ""
        }
    ];

    const SetValuesHistory = (CourseClassification: CourseClassification[]) => {
        const TableRows: object[] = [];
        CourseClassification.map((raw: CourseClassification, index: number) => {
            TableRows.push([               
                {
                    tag: "text",
                    text: raw.classification,
                    icon: "",
                    onclick: null
                },
                {
                    tag: "text",
                    text: raw.fromDate,
                    icon: "",
                    onclick: null
                },
                {
                    tag: "text",
                    text: raw.toDate,
                    icon: "",
                    onclick: null
                },
                (session.userRoleId == 4) ? {
                    tag: "icon-fa",
                    text: "center",
                    icon: "edit",
                    onclick: () => {
                        editCourseClassification(index)
                    }
                }:null,
                (session.userRoleId == 4 && raw.courseId != raw.courseGroup) ? {
                    tag: "icon-fa",
                    text: "center",
                    icon: "delete",
                    onclick: () => {
                        promptDeleteCourseClassification(index)
                    }
                }:null
            ])})
            setCourseClassificationDet(TableRows);
    }

    const promptDeleteCourseClassification = async (index: number) => {
        try {
            const itemToRemove = entriesRef.current[index];
            // Fetch the latest auditor courses
            const currentAuditorCoursesList = await fetchAllAuditorCourses(itemToRemove.courseId);
            if (currentAuditorCoursesList.length > 0) {
                setErrorMessage("Cannot remove this course as it's linked with auditor courses.");
                return; // Exit the function early
            } else {
                setDelCourseClassificationIndex(index);
                setOpen2(true);
            }
        } catch (error) {
            console.error("Error in removeCourseClassification:", error);
            // Handle error appropriately
        }
    };

    const editCourseClassification = (index: number) => {
        setEntries((currentEntries) => {
            const editedEntry = currentEntries[index];

            SetState((prevState) => ({
                ...prevState,
                flag: editedEntry.classification,
                courseStartDate: editedEntry.fromDate,
                courseEndDate: editedEntry.toDate
            }));

            // Change the button text to "Update Classification"
            setAddClassificationButtonText("Update Classification");
            
            // Set the index of the clicked row as the activeIndex for update
            setActiveIndex(index);

            return currentEntries; // Return currentEntries to complete the functional update
        });
    }

    const auditorCoursesListRef = useRef(auditorCoursesList);

    useEffect(() => {
        auditorCoursesListRef.current = auditorCoursesList;
    }, [auditorCoursesList]);

    const entriesRef = useRef(entries);

    useEffect(() => {
        entriesRef.current = entries;
    }, [entries]);
    
    const removeCourseClassification = async (index: number) => {
        try {
            const itemToRemove = entriesRef.current[index];
            setEntries((prevEntries) => {
                const updatedEntries = [...prevEntries];
                updatedEntries.splice(index, 1);
                setErrorMessage(null);
                SetValuesHistory(updatedEntries);
                setRemovedEntries(prevRemovedEntries => [...prevRemovedEntries, itemToRemove]);
                return updatedEntries;
            });
            setOpen2(false)
        } catch (error) {
            console.error("Error in removeCourseClassification:", error);
            // Handle error appropriately
        }
    };

    const validateDateRanges = (newEntry: CourseClassification, existingEntries: CourseClassification[]) => {
        const fromDate1 = moment(newEntry.fromDate, 'DD/MM/YYYY');
        const toDate1 = moment(newEntry.toDate, 'DD/MM/YYYY');
    
        for (const existingEntry of existingEntries) {
            const fromDate2 = moment(existingEntry.fromDate, 'DD/MM/YYYY');
            const toDate2 = moment(existingEntry.toDate, 'DD/MM/YYYY');
            
            // Check if the new date range is entirely before or after the existing date range
            if (!(fromDate1.isBetween(fromDate2, toDate2, null, '[]')) && !(toDate1.isBetween(fromDate2, toDate2, null, '[]'))) {
                continue; // No overlap, continue to the next entry
            }
    
            return false; // Date ranges overlap
        }
    
        return true; // Date ranges do not overlap
    };

    const handleAddEntry = () => {
        // Validate required fields
        if (!state?.flag || !state?.courseStartDate || !state?.courseEndDate) {
            setCourseClassificationError("Please fill in all required fields.");
        } else {
            if (activeIndex !== null) {
                const updatedEntry = {
                    classification: state?.flag ? state?.flag : "",
                    fromDate: state?.courseStartDate ? state?.courseStartDate : "",
                    toDate: state?.courseEndDate ? state?.courseEndDate : "",
                    courseGroup: entries[activeIndex].courseGroup,
                    courseId: entries[activeIndex].courseId,
                    
                };

                // Determine entries to validate against depending on add or update
                const entriesToValidateAgainst = activeIndex !== null 
                ? entries.filter((_, index) => index !== activeIndex) 
                : entries;

                const isDateRangeValid = validateDateRanges(updatedEntry, entriesToValidateAgainst);

                if (!isDateRangeValid) {
                    setCourseClassificationError("Date ranges cannot overlap with existing classifications.");
                } else {
                    const updatedEntries = [...entries];
                    updatedEntries[activeIndex] = updatedEntry;
                    setEntries(updatedEntries);
                    SetValuesHistory(updatedEntries);

                    // Clear the form fields and reset the button text
                    SetState({
                        ...state,
                        flag: "",
                        courseStartDate: "",
                        courseEndDate: ""
                    });
                    setAddClassificationButtonText("Add Classification");
                    setActiveIndex(null);
                    setCourseClassificationError("");
                }
            } else {
                // Create a new entry object with default values or values from the state
                const newEntry: CourseClassification = {
                    courseGroup: state?.courseGroup ? state?.courseGroup : 0,
                    courseId: 0,
                    classification: state?.flag ? state?.flag : "",
                    fromDate: state?.courseStartDate ? state?.courseStartDate : "",
                    toDate: state?.courseEndDate ? state?.courseEndDate : ""
                };
                        
                // Validate the new entry against existing entries
                const isDateRangeValid = validateDateRanges(newEntry, entries);

                if (!isDateRangeValid) {
                    setCourseClassificationError("Date ranges cannot overlap with existing classifications.");
                } else {
                    const newLogs: CourseClassification[] = [...entries, newEntry];
                    setEntries(newLogs);
                    // Add the new entry to the entries list
                    SetValuesHistory(newLogs);
                    setCourseClassificationError("");
                }
            }
        }
    };

    useEffect(() => {
        const loadData = async () => {
            if (CourseId) {
                try {
                    // Fetch courses
                    const coursesValue = await fetchAllCourses(parseInt(CourseId), -1, 0);
                    if (coursesValue) {
                        const course = coursesValue.courseManagementRess.find((r) => r.courseId === parseInt(CourseId));
                        console.log(course);
                        if (course) {
                            // Set the state with the formatted dates
                            SetState({
                                ...course,
                                flag: "",
                                courseStartDate: "",
                                courseEndDate: "",
                            });

                            setDescriptionText(course.courseShortDesc ? course.courseShortDesc : "");

                            if (course.courseClassifications) {
                                const formattedClassifications = course.courseClassifications.map((classification) => ({
                                    ...classification,
                                    fromDate: moment(classification.fromDate, "YYYY-MM-DD").format('DD/MM/YYYY'),
                                    toDate: moment(classification.toDate, "YYYY-MM-DD").format('DD/MM/YYYY'),
                                }));
                                SetValuesHistory(formattedClassifications);
                                setEntries(formattedClassifications);
                            }
                        }
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                    // Handle error
                }
            }
        };
    
        loadData();
    }, [CourseId]);
    

    const getAllCourses = async () => {
        try {
            const response = await fetchAllCourses(-1,-1, 0)
            Promise.resolve(response).then((value) => {
                if(value){
                    return value.courseManagementRess.map((r) => r.courseReferenceId).filter((id): id is string => typeof id === 'string');
                } else {
                throw new Error("Failed to fetch courses");
                }
            });
            
        } catch (error) {
            console.error("Error fetching courses: ", error);
            return [];
        }
    };

    const removedEntriesRef = useRef(removedEntries);

    useEffect(() => {
        removedEntriesRef.current = removedEntries;
    }, [removedEntries]);

    const AddCourse = async () => {
        const existingCourseReferenceIds: string[] = (await getAllCourses()) || [];
        const courseReferenceId = state?.courseReferenceId ?? '';

        // Check if the new courseReferenceId already exists in the list
        if (existingCourseReferenceIds?.includes(courseReferenceId)) {
            // Show an error message indicating that the courseReferenceId is not unique
            setCourserror('Course reference ID already exists. Please review existing course')
            return;
        } else {
            setCourserror('')
            
            if(CourseId) {
                if(state){
                    const updatedState: CourseManagementRes  = {
                        ...state,
                        courseShortDesc,
                        courseClassifications: entries.map((classification) => ({
                            ...classification,
                            fromDate: moment(classification.fromDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
                            toDate: moment(classification.toDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
                        })),
                        removedCourseClassifications: removedEntriesRef.current.map((classification) => ({
                            ...classification,
                            fromDate: moment(classification.fromDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
                            toDate: moment(classification.toDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
                        })),
                    };

                    const addedCourse = addCourse(updatedState)
                    Promise.resolve(addedCourse).then((value)=>{
                        if(value){
                            SuccessToast('Course updated successfully')
                            // Set a timeout before navigating
                            setTimeout(() => {
                                location.reload()
                            }, 2000); // 2000 milliseconds delay (2 seconds)
                        }
                    })
                }
            } else {
                if(state){
                    const newState: CourseManagementRes = {
                        ...state,
                        courseShortDesc
                    }
                    const addedCourse = addCourse(newState)
                    Promise.resolve(addedCourse).then((value)=>{
                        if(value){
                            SuccessToast('Course added successfully')
                            // Set a timeout before navigating
                            setTimeout(() => {
                                location.reload()
                            }, 2000); // 2000 milliseconds delay (2 seconds)
                        }
                    })
                }
            }
        }
    };

    const [disableSave, setDisableSave] = React.useState(false);

    useEffect(() => {
        DisableValidation();
    }, [state]);

    const DisableValidation = () =>{
        if(!CourseId) {
            const isFormInvalid = (
                !state?.providerName ||
                !state?.courseReferenceId ||
                !state?.courseName ||
                !state?.flag ||
                !state?.courseStartDate ||
                !state?.courseEndDate
            );
        
            setDisableSave(isFormInvalid);
        }
    }

    return(
        <>
        <Grid>
            <Grid.Row columns={3}>
                <Grid.Column width={8} mobile={16} widescreen={8} tablet={8} computer={8}>
                    <NameWithDefault 
                        labelText={'Course provider'} 
                        labelPosition={Position.Top}
                        id={'txtCourseprovider'} 
                        fluid={true} 
                        type={'text'}
                        placeholder={'Enter Course provider'}
                        required={true}
                        value={state?.providerName}
                        disabled={!(session.userRoleId == 4)}
                        onChange={(e:any)=>{
                            SetState({...state, providerName:e.target.value})
                        }}
                    />
                </Grid.Column>
                <Grid.Column width={8} mobile={16} widescreen={8} tablet={8} computer={8}>
                    <NameWithDefault 
                        labelText={'Course reference ID'} 
                        labelPosition={Position.Top}
                        id={'txtCoursereferenceID'} 
                        fluid={true} 
                        type={'text'}
                        placeholder={'Enter Course reference ID'}
                        required={true}
                        value={state?.courseReferenceId}
                        disabled={!(session.userRoleId == 4)}
                        onChange={(e:any)=>{
                            SetState({...state, courseReferenceId:e.target.value})
                        }}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
                <Grid.Column width={8} mobile={16} widescreen={8} tablet={8} computer={8}>
                    <NameWithDefault 
                        labelText={'Course name'} 
                        labelPosition={Position.Top}
                        id={'txtCoursename'} 
                        fluid={true} 
                        type={'text'}
                        placeholder={'Enter Course name'}
                        required={true}
                        value={state?.courseName}
                        disabled={!(session.userRoleId == 4)}
                        onChange={(e:any)=>{
                            SetState({...state, courseName:e.target.value})
                        }}
                    />
                </Grid.Column>
                <Grid.Column width={8} mobile={16} widescreen={8} tablet={8} computer={8}>
                    
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
                <Grid.Column width={16} mobile={16} widescreen={16} tablet={16} computer={16}>
                    <NameWithDefault 
                        labelText={'Course link'} 
                        labelPosition={Position.Top}
                        id={'txtCourselink'} 
                        fluid={true} 
                        type={'text'}
                        placeholder={'Enter Course link'}
                        value={state?.courseLink}
                        disabled={!(session.userRoleId == 4)}
                        onChange={(e:any)=>{
                            SetState({...state, courseLink:e.target.value})
                        }}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
                <Grid.Column width={16} mobile={16} widescreen={16} tablet={16} computer={16}>
                    <span>Course short description</span><br></br>
                    <MultilineTextBox
                        wordCount={true}
                        fluid={true}
                        className={'course_desc'}
                        id={'txtDescriptionTexts'}
                        placeholder={'Course short description'}
                        value={courseShortDesc}
                        disabled={!(session.userRoleId == 4)}
                        onChange={(e: any) => {
                            setDescriptionText(e.target.value)
                        }}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
                <Grid.Column>
                    <p><b>Classifications:</b></p>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
                <Grid.Column width={8} mobile={16} widescreen={8} tablet={8} computer={8}>
                    <NameWithDropDown 
                        clearable={true} 
                        selection={true} 
                        labelText={'Classification'}
                        labelPosition={Position.Top} 
                        id={'ddClassification'}
                        placeholder={'Select Classification'}
                        required={true}
                        options={[
                            { key: 1, text: 'Auditor', value: 'Auditor' },
                            { key: 2, text: 'Leader', value: 'Leader' },
                            { key: 3, text: 'Pre-requisite', value: 'Pre-requisite' },
                            { key: 4, text: 'Update', value: 'Update' }
                        ]}
                        value={state?.flag}
                        onChange={(e:any,data:any)=>{
                            SetState({...state, flag:data.value})
                        }}
                    />
                </Grid.Column>
                <Grid.Column width={8} mobile={16} widescreen={8} tablet={8} computer={8}>
                    <NameWithDate
                        labelText={'From Date'}
                        fluid={true}
                        labelPosition={Position.Top}
                        id={'txtFDate'}
                        type={'date'}
                        required={true}
                        value={state?.courseStartDate}//moment(state?.courseStartDate).format('DD/MM/YYYY')}
                        onChange={(e: any, data: any) => {/*e.preventDefault();*/
                            SetState({...state, courseStartDate: data.value})
                        }}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
                <Grid.Column width={8} mobile={16} widescreen={8} tablet={8} computer={8}>
                <NameWithDate
                        labelText={'To Date'}
                        fluid={true}
                        labelPosition={Position.Top}
                        id={'txtTDate'}
                        type={'date'}
                        required={true}
                        value={state?.courseEndDate}//moment(state?.courseEndDate).format('DD/MM/YYYY')}
                        onChange={(e: any, data: any) => {/*e.preventDefault();*/
                            SetState({...state, courseEndDate: data.value})
                        }}
                    />
                </Grid.Column>
                <Grid.Column width={8} mobile={16} widescreen={8} tablet={8} computer={8}>
                    
                </Grid.Column>
            </Grid.Row>
            { CourseId ? (
                <>
                    <Grid.Row columns={3}>
                        <Grid.Column>
                            <div className={'button-flex'} style={{justifyContent:'start'}}>
                                <OutLineButton id={'btnAddClassification'} style={{width: '170px'}} onClick={() => {
                                    handleAddEntry()
                                }} color='blue' className={"background-transaparent"} text={addClassificationButtonText}  disabled={false}/>
                            </div>
                            <div style={{color: 'red'}}>{courseClassificationError}</div>
                            <div style={{color: 'red'}}>{errorMessage}</div>
                        </Grid.Column>
                        <Grid.Column>

                        </Grid.Column>
                        <Grid.Column>

                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            {courseClassificationDet.length > 0 ?  (<div style={{overflow: 'auto'}}>
                                <RSATable
                                    head={courseClassificationHead}
                                    body={courseClassificationDet}
                                    //pageCount={totalPages/5}
                                    //onChange={onChange}
                                    //current={currentPage}
                                />
                            </div>): "No Courses"}
                        </Grid.Column>
                    </Grid.Row>
                </>
            ) : (null)}
            <Grid.Row columns={3}>
                <Grid.Column width={16}>
                    <span style={{display: 'inline-flex'}}>Course status</span>
                </Grid.Column>
                <Grid.Column width={16}>&nbsp;</Grid.Column>
                <Grid.Column width={1} mobile={4}>
                    <NameWithRadioButton
                        id={'rbYes'}
                        label={'Active'}
                        name={'rbAudit'}
                        value={state?.isActivated}
                        disabled={!(session.userRoleId == 4)}
                        onChange={() => {
                            SetState({...state, isActivated: true})
                        }}
                        // disabled={isEditCourse}
                    />
                </Grid.Column>
                <Grid.Column width={1} mobile={4}>
                    <NameWithRadioButton
                        id={'rbNo'}
                        label={'Inactive'}
                        name={'rbAudit'}
                        value={!state?.isActivated}
                        disabled={!(session.userRoleId == 4)}
                        onChange={() => {
                            SetState({...state, isActivated : false})
                        }}
                        // disabled={isEditCourse}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row centered={true}>
                    <Grid.Column mobile={16} tablet={8} widescreen={12} largeScreen={12} computer={12}>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={5} widescreen={4} largeScreen={4}
                                 style={{justifyContent: 'center', display: 'flex'}}>
                        <ControllButtonReg
                            AddCourse={() => { AddCourse(); }}
                            Back={()=>{window.location.href = '/misc/coursemanagement'}}
                            ButtonText={buttonText}
                            DisableSave={disableSave}
                        />
                    </Grid.Column>
                </Grid.Row>
        </Grid>
        <Grid>
            <Modal
                    open={open2 ? open2 : false}
                    setOpen={setOpen2}
                    title={"Warning !"}
                    modalDescription={true}
                    descriptionHeader={'Are you sure you want to delete this course classification?'}
                    descriptionBody={'This action cannot be undone.'}
                    imageUrl={waringImage}
                    imageSize='small'
                    modalAction={true}
                    ActionButtons={() => (
                        <ControllButtonRegModal
                            NoClick={() => {
                                setOpen2(false)
                                setDelCourseClassificationIndex(0)
                            }}
                            YesClick={() => removeCourseClassification(delCourseClassificationIndex)}
                        />
                    )}
                />
            <br/>
            </Grid>
       </> 
    );
}

interface ControllButtonRegProps {
    //eslint-disable-next-line
    AddCourse: any;
    //eslint-disable-next-line
    Back: any;
    ButtonText: string;
    DisableSave: boolean;
}

const ControllButtonReg = (props: ControllButtonRegProps) => {
    return (
        <div className={'button-flex'}>
            <OutLineButton id={'btnCancel'} style={{width: '150px'}} onClick={() => {
               props.Back()
            }} color={'red'} className={"background-transaparent-red"}
                            text={'Back'}/>
            <OutLineButton id={'btnSave'} style={{width: '150px'}} onClick={() => {
                  props.AddCourse()
            }} color='blue' className={"background-transaparent"}
                        text={props.ButtonText}
                        disabled={props.DisableSave}/>
        </div>
    )
}

interface ControllButtonProps {
    //eslint-disable-next-line
    NoClick: any;
    //eslint-disable-next-line
    YesClick: any;
    //eslint-disable-next-line
}

const ControllButtonRegModal = (props: ControllButtonProps) => {
    return (
        <React.Fragment>
            <OutLineButton id={'btnCancel'} style={{width: '150px'}} onClick={() => {
                props.NoClick()
            }} color={'red'} text={'No'}/>
            <DefaultButton id={'btnSave'} style={{width: '150px'}} onClick={() => {
                props.YesClick()
            }} color={'blue'}
                           text={'Yes'}/>
        </React.Fragment>
    )
}