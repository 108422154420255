import React, {useEffect, useRef, useState} from "react";
import {Container, Grid, Table} from "semantic-ui-react";
import InnerTitleBar from "../../../../framework/layouts/InnerTitleBar";
import {userRoleSelector} from "../../../../common/roleSelector";
import {getSessionCookie} from "../../../../framework/common/SessionHandler";
import OutLineButton from "../../../../framework/components/Buttons/OutlIneButton";
import RSATable from "../../../../framework/widgets/TableWithBackgrounds/RSATable";
import TableHeader from "../../../../framework/types/TableHeader";
import tags from "../../../../models/common/tag";
import { SearchApiCallForAuditPhases, SaveAuditPhases, DeleteAuditPhases, GetAllAuditStagesByAuditPhaseId } from "../../../search/SearchController";
import { AuditPhases } from "../../../../models/apiResponce/mis/AuditPhaseRes";
import SuccessToast from "../../../../framework/components/Toast/SuccessToast";
import NameWithDefault from "../../../../framework/widgets/NameWithText/NameWithDefault";
import Position from "../../../../framework/types/Position";
import { AuditPhasesReq } from "../../../../models/apiRequest/mis/AuditPhasesReq";
import MultilineTextBox from "../../../../framework/components/TextBoxes/MultilineTextBox";
import ErrorToast from "../../../../framework/components/Toast/ErrorToast";
import Modal from "../../../../framework/components/Modals";
import waringImage from "../../../../assets/images/icons/warning.png";
import DefaultButton from "../../../../framework/components/Buttons/DefaultButton";

const AuditPhasesContainer = () =>{

    const [state, setState] = React.useState<AuditPhasesReq>();
    const session = getSessionCookie();
    const [auditPhaseName, setPhaseName] = useState('')
    const [auditPhaseDesc, setDescriptionText] = useState('')
    const [selectedAuditPhase, setSelectAuditPhase] = useState(0)
    const [isAddingNew, setIsAddingNew] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const contentRef = useRef<HTMLDivElement>(null);
    const rsaPhaseChangeHead: TableHeader[] = [
        {
            text: "Phase name"
        },
        {
            text: "Description text"
        },
        {
            text: ""
        }
    ];
    const [rsaPhaseChange, setrsaPhaseChange] = useState<object[]>([
    ])



    const SetValues = (data:AuditPhases[]) => {
        const TableRows: object[] = [];
        data.map((r:AuditPhases) => {
            TableRows.push([{
                    tag: tags.text,
                    text: `${r.auditPhaseName}`,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: `${r.auditPhaseDesc}`,
                    icon: "",
                    onclick: null
                },
                {
                    tag: "icon-fa",
                    text: "Select",
                    icon: "view",
                    onclick:() => {
                        setSelectAuditPhase(r.auditPhaseId)
                        setPhaseName(r.auditPhaseName || '');
                        setDescriptionText(r.auditPhaseDesc || '');
                        setState(r);
                        setIsAddingNew(false);
                        contentRef.current?.scrollIntoView({ behavior: 'smooth' });
                    }
                }])
        })
        setrsaPhaseChange(TableRows);
    }

    useEffect(() => {
        try {
            const auditPhases = SearchApiCallForAuditPhases();
            Promise.resolve(auditPhases).then((value) => {
                if (value && value.auditPhases) {
                    SetValues(value.auditPhases);
                }
            });
        } catch (error) {
            // Handle the error here
            ErrorToast('Error fetching audit results');
        }
        
    }, []);

    const resetFormFields = () => {
        setPhaseName('');
        setDescriptionText('');
        setState(undefined); // Reset the state
    };

    const onAddNewClick = () => {
        resetFormFields();
        setIsAddingNew(true);
    };

    const onSaveClick = async () => {
        try {
            if (isAddingNew) {
                // Add new
                const newData: AuditPhasesReq = {
                    auditPhaseId: 0,
                    auditPhaseName: auditPhaseName || '',
                    auditPhaseDesc: auditPhaseDesc || '',
                };
                const result = await SaveAuditPhases(newData);
                if (result) {
                    SuccessToast('Audit phase added successfully');
                    setTimeout(() => {
                        // Reload the page
                        window.location.reload();
                    }, 2000); // 2000 milliseconds delay (2 seconds)
                }
            } else {
                // Update
                const updatedData: AuditPhasesReq = {
                    ...state!,
                    auditPhaseId: selectedAuditPhase,
                    auditPhaseName: auditPhaseName || '',
                    auditPhaseDesc: auditPhaseDesc || '',
                };
                const result = await SaveAuditPhases(updatedData);
                if (result) {
                    SuccessToast('Audit phase updated successfully');
                    setTimeout(() => {
                        // Reload the page
                        window.location.reload();
                    }, 2000); // 2000 milliseconds delay (2 seconds)
                }
            }
        } catch (error) {
            // Handle the error here
            ErrorToast('Error saving audit phase');
        }
    };

    const promtDeleteClick = async () => {
        try {
            // Fetch the latest audit phases
            const auditStagesRes = await GetAllAuditStagesByAuditPhaseId(selectedAuditPhase);
            if (auditStagesRes.auditStages.length > 0) {
                setErrorMessage("There is at least one audit stage using this audit phase");
                return; // Exit the function early
            } else {
                setOpen2(true);
            }
        } catch (error) {
            console.error("Error in remove audit phase:", error);
            // Handle error appropriately
        }
    };

    const onDeleteClick = async () => {
        try{
            if (isAddingNew) {
                resetFormFields();
            }else{
                // Delete
                const result = await DeleteAuditPhases(selectedAuditPhase);
                if (result) {
                    SuccessToast('Audit phase deleted successfully');
                    // Reload the page
                    window.location.reload();
                }
            }
        } catch (error) {
            ErrorToast('Error deleting audit phase');
        }
        setOpen2(false)
    };

    const [disableSave, setDisableSave] = React.useState(false);

useEffect(() => {
        DisableValidation();
    }, [auditPhaseName]);

    const DisableValidation = () =>{
        const isFormInvalid = (
            !auditPhaseName
        );
    
        setDisableSave(isFormInvalid);
    }

    return(
        <Container fluid={true} style={{marginTop: '14px', marginBottom: '20px'}}>
            <InnerTitleBar
                title={"Edit/Del Audit Phases"}
                usreName={session.userDisplayName != null ? session.userDisplayName : "  "}
                userRole={userRoleSelector(session.userRoleId.toString())}
                lastLogin={session.lastLogin.toString()}
                status={userRoleSelector(session.userRoleId.toString())}
            />
            <Grid>
            <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                    
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                    
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                    
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                    
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={12} className={'button-flex'} style={{display:'flex', justifyContent:'right', paddingRight: '0px'}}>
                                <OutLineButton
                                    id={'btnAddNew'}
                                    style={{ width: '150px' }}
                                    onClick={onAddNewClick}
                                    color='blue'
                                    className={'background-transaparent'}
                                    text={'+ Add New'}
                                    disabled={false}
                                />
                                </Grid.Column>
                                <Grid.Column>
                                
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>     
                    </Grid.Column>
                </Grid.Row>
                {rsaPhaseChange.length > 0 ? (<Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <div style={{ overflow: 'auto' }}>
                            <RSATable
                                head={rsaPhaseChangeHead}
                                body={rsaPhaseChange}
                            />
                        </div>    
                        
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>):null}
                {selectedAuditPhase != 0 || isAddingNew ? (<>
                    <div ref={contentRef}>
                        <Grid.Row columns={3}>
                            <Grid.Column width={2}>
                            </Grid.Column>
                            <Grid.Column width={12}>
                            </Grid.Column>
                            <Grid.Column width={2}>
                            </Grid.Column>
                        </Grid.Row>
                    </div>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                            <hr/>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={12}>
                                    <b><span style={{display:'inline-flex'}}>Audit Phases</span></b>
                                </Grid.Column>
                                <Grid.Column>
                                    
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>     
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={8} widescreen={8} mobile={16} computer={8} tablet={16} largeScreen={16}>
                                <NameWithDefault 
                                    labelText={'Phase name'} 
                                    labelPosition={Position.Top}
                                    id={'txtPhasename'} 
                                    fluid={true} 
                                    type={'text'}
                                    placeholder={'Enter Phase name'}
                                    required={true}
                                    value={auditPhaseName}
                                    onChange={(e: any) => {
                                        setPhaseName(e.target.value)
                                    }}
                                />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>    
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={8} widescreen={8} mobile={16} computer={8} tablet={16} largeScreen={16}>
                                <span>Description</span><br></br>
                                <MultilineTextBox
                                    wordCount={true}
                                    fluid={true}
                                    className={'other_exp'}
                                    id={'txtDescriptionTexts'}
                                    placeholder={''}
                                    value={auditPhaseDesc}
                                    onChange={(e: any) => {
                                        setDescriptionText(e.target.value)
                                    }}
                                />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>    
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={8} widescreen={8} mobile={16} computer={8} tablet={16} largeScreen={16}>
                                    <div style={{color: 'red'}}>{errorMessage}</div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>    
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>     
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>
                    
                    </Grid.Column>
                    <Grid.Column width={12} className={'button-flex'} style={{display:'flex', justifyContent:'right'}}>
                                <OutLineButton
                                    id={'btnUpdate'}
                                    style={{ width: '150px' }}
                                    onClick={onSaveClick}
                                    color='blue'
                                    className={'background-transaparent'}
                                    text={isAddingNew ? 'Save' : 'Update'}
                                    disabled={false}
                                />
                                {
                                    !isAddingNew ? (
                                        <OutLineButton
                                            id={'btnDelete'}
                                            style={{ width: '150px', marginLeft: '10px' }}
                                            onClick={promtDeleteClick}
                                            color='red'
                                            className={'background-transaparent'}
                                            text={isAddingNew ? 'Clear' : 'Delete'}
                                            disabled={disableSave}
                                        />
                                    ):(null)
                                }
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                </>):null}
            </Grid>
            <Modal
                    open={open2 ? open2 : false}
                    setOpen={setOpen2}
                    title={"Warning !"}
                    modalDescription={true}
                    descriptionHeader={'Are you sure you want to delete this audit phase?'}
                    descriptionBody={'This action cannot be undone.'}
                    imageUrl={waringImage}
                    imageSize='small'
                    modalAction={true}
                    ActionButtons={() => (
                        <ControllButtonRegModal
                            NoClick={() => {
                                setOpen2(false)
                            }}
                            YesClick={onDeleteClick}
                        />
                    )}
                />
                <br/>
        </Container>
    );
}

export default AuditPhasesContainer

interface ControllButtonProps {
    //eslint-disable-next-line
    NoClick: any;
    //eslint-disable-next-line
    YesClick: any;
    //eslint-disable-next-line
}

const ControllButtonRegModal = (props: ControllButtonProps) => {
    return (
        <React.Fragment>
            <OutLineButton id={'btnCancel'} style={{width: '150px'}} onClick={() => {
                props.NoClick()
            }} color={'red'} text={'No'}/>
            <DefaultButton id={'btnSave'} style={{width: '150px'}} onClick={() => {
                props.YesClick()
            }} color={'blue'}
                           text={'Yes'}/>
        </React.Fragment>
    )
}
