import React, {useEffect, useState} from "react";
import {Container, Grid, Table} from "semantic-ui-react";
import InnerTitleBar from "../../../../framework/layouts/InnerTitleBar";
import {userRoleSelector} from "../../../../common/roleSelector";
import {getSessionCookie} from "../../../../framework/common/SessionHandler";
import OutLineButton from "../../../../framework/components/Buttons/OutlIneButton";
import DateRangeBox from "../../../../framework/components/TextBoxes/DateRangeBox";
import { CSVLink, CSVDownload } from "react-csv";
import ReactDOM from 'react-dom/client';
import { OtherAuditorsperRegionReportExcel, OtherAuditorsperRegionReportPdf, RMSAuditorsperRegionReportExcel, RMSAuditorsperRegionReportPdf } from "./RegionReportController";

const RegionReportContainer = () =>{
    const session = getSessionCookie();
    const AuditorId = new URLSearchParams(location.search).get('AuditorId');
    const name = new URLSearchParams(location.search).get('name');
    const[txtName, setTxtName] = useState('')
    const [current, setCurrent] = useState('');
    const [data, setData] = useState<any>([]);
    const [recoord, setRecord] = useState(0)
    const [dateRange, setDateRange] = useState('')

    const RMSAuditorsRegionReportPdf = async() => {
    
        const report = await RMSAuditorsperRegionReportPdf()
        const linkSource = `data:application/pdf;base64,${report}`;
        const downloadLink = document.createElement("a");
        const fileName = "RMSRegionReport.pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    const RMSAuditorsRegionReportExcel = async() => {
    
        const report = await RMSAuditorsperRegionReportExcel()
        //const linkSource = `data:application/vnd.ms-excel,${report}`;
        const url = window.URL.createObjectURL(new Blob([report])) 
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', "RMSRegionReport.csv")
        document.body.appendChild(link)
        link.click()
        link.remove()

    }

    const OtherAuditorsRegionReportExcel = async() => {
    
        const report = await OtherAuditorsperRegionReportExcel()
        //const linkSource = `data:application/vnd.ms-excel,${report}`;
        const url = window.URL.createObjectURL(new Blob([report])) 
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', "OtherRegionReport.csv")
        document.body.appendChild(link)
        link.click()
        link.remove()

    }

    const OtherAuditorsRegionReportPdf = async() => {
    
        const report = await OtherAuditorsperRegionReportPdf()
        const linkSource = `data:application/pdf;base64,${report}`;
        const downloadLink = document.createElement("a");
        const fileName = "OtherRegionReport.pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    return(
        <Container fluid={true} style={{marginTop: '14px', marginBottom: '20px'}}>
            <InnerTitleBar
                title={"Auditors Per Region Report"}
                usreName={session.userDisplayName != null ? session.userDisplayName : "  "}
                userRole={userRoleSelector(session.userRoleId.toString())}
                lastLogin={session.lastLogin.toString()}
                status={userRoleSelector(session.userRoleId.toString())}
            />
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <p><b>Region Report:</b></p>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={3}>
                                <Grid.Column width={10} mobile={16} computer={10}>
                                    RMS Auditors per Region Report
                                </Grid.Column>
                                <Grid.Column width={2} mobile={16} computer={3} className='button-flex'>
                                    <OutLineButton id={'btnDownloadTallyReport'} className={"background-transaparent"}
                                                   style={{width: '250px'}} onClick={(e:any) => {
                                                    RMSAuditorsRegionReportExcel()
                                    }} color={'blue'} text={'Download to Excel'}/>
                                </Grid.Column>
                                <Grid.Column width={3} mobile={16} computer={3} className='button-flex'>
                                    <OutLineButton id={'btnDownloadTallyReport'} className={"background-transaparent"}
                                                   style={{width: '250px'}} onClick={(e:any) => {
                                                    RMSAuditorsRegionReportPdf()
                                    }} color={'blue'} text={'Download to PDF'}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <hr/>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={3}>
                                <Grid.Column width={10} mobile={16} computer={10}>
                                    Other Auditors per Region Report
                                </Grid.Column>
                                <Grid.Column width={2} mobile={16} computer={3} className='button-flex'>
                                    <OutLineButton id={'btnDownloadTallyReport'} className={"background-transaparent"}
                                                   style={{width: '250px'}} onClick={(e:any) => {
                                                    OtherAuditorsRegionReportExcel()
                                    }} color={'blue'} text={'Download to Excel'}/>
                                </Grid.Column>
                                <Grid.Column width={3} mobile={16} computer={3} className='button-flex'>
                                    <OutLineButton id={'btnDownloadTallyReport'} className={"background-transaparent"}
                                                   style={{width: '250px'}} onClick={(e:any) => {
                                                    OtherAuditorsRegionReportPdf()
                                    }} color={'blue'} text={'Download to PDF'}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <hr/>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={3}>
                                <Grid.Column width={10} mobile={16} computer={10}>
                                </Grid.Column>
                                <Grid.Column width={2} mobile={16} computer={3} className='button-flex'>
                                </Grid.Column>
                                <Grid.Column width={3} mobile={16} computer={3} className='button-flex'>
                                    <OutLineButton id={'btnDownloadTallyReport'} className={"background-transaparent"}
                                                   style={{width: '250px'}} onClick={(e:any) => {
                                        window.location.href = `/search?profilelog=true&name=${txtName}`
                                    }} disabled color={'blue'} text={'Region Report'}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>

                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>

                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
}

export default RegionReportContainer
