import React, {useEffect, useState} from "react";
import {Accordion, Grid, Icon, Popup, Radio} from "semantic-ui-react";
import NameWithDropDown from "../../../framework/widgets/NameWithDropDown";
import Position from "../../../framework/types/Position";
import NameWithDefault from "../../../framework/widgets/NameWithText/NameWithDefault";
import NameWithEmail from "../../../framework/widgets/NameWithText/NameWithEmail";
import NameWithPassword from "../../../framework/widgets/NameWithText/NameWithPassword";
import NameWithPhone from "../../../framework/widgets/NameWithText/NameWithPhone";
import NameWithCheckBox from "../../../framework/widgets/NameWithCheckBox/NameWithCheckBox";
import DropDownOptions from "../../../framework/types/DropDownOptions";
import {
    PersonalDetailsValueProps,
    BusinessDetailsValueProps,
    AuditorRegionValueProps,
    PersonalDetailsErrorProps, BusinessDetailsErrorProps
} from "./DataModal";
import InfoToggle from "../../../framework/widgets/InfoToggle";
import MsgFromAdmin from "../../../models/apiResponce/Auditor/MsgFromAdmin";
import {UpdateMsgFromAdmin} from "./ProfileController";
import Availablity from "./Availablity";
import {getSessionCookie} from "../../../framework/common/SessionHandler";
import ErrorToast from "../../../framework/components/Toast/ErrorToast";
import AccreditationDetails from "./AccreditationDetails";
import personalDetails from "../registration/PersonalDetails";
import AccreditationAssessment from "./AccreditationAssessment";

interface Props {
    title: any;
    country: any;
    company:any;
    auditor: any;
    states: any,
    contactMethods: any,
    focusMode: any;
    setFocusMode: any;
    setStateAuditor: any;
    setStateBusiness: any;
    setStateRegion: any;
    stateAuditor:any;
    profile:boolean
    Session:any;
    setConfirmPW:any;
    PersoanlError:PersonalDetailsErrorProps
    BusinessError:BusinessDetailsErrorProps
    bd:any
    setBD:any
    ad:any
    setAD:any
    ava:any
    setAVA:any
    Status:any
    SetStatus:any
    setAuditor:any
}

const ContactDetails = (props: Props) => {
    const [activeIndex, setActiveIndex] = useState([0, 1, 2, 3, 4])
    const [inforTogglePersonal, setInforTogglePersonal] = useState(false);
    const [inforToggleBusiness, setInforToggleBusiness] = useState(false);
    const [inforToggleAvailability, setInforToggleAvailability] = useState(false);
    const [auditorStatus, SetAuditorStatus] = useState(0)
    const pathName = window.location.pathname;
    const session = getSessionCookie();
    const encid = new URLSearchParams(location.search).get('get')


    useEffect(()=>{
        if(session.status != 3 && session.status != 4 ){
            if(pathName == '/accreditation'){
                window.location.href = '/profile';
            }
        }
    },[])

    useEffect(() => {
        Promise.resolve(props.auditor).then((value) => {
            if(value != undefined){
                SetAuditorStatus(value.personalDetails?.AuditorsStateRes?.auditorStatusId?.toString())
                setInforTogglePersonal(value.AD.isActivated)
                setInforToggleBusiness(value.BD.isActivated)
                setInforToggleAvailability(value.AVA.isActivated)
            }
        })
    },[props.auditor])
    const handleClick = (index: number) => {
        const newIndex = activeIndex;

        const currentIndexPosition = activeIndex.indexOf(index);
        if (currentIndexPosition > -1) {
            newIndex.splice(currentIndexPosition, 1);
        } else {
            newIndex.push(index);
        }
        setActiveIndex(newIndex)
    }
    // const [focusMode,setFocusMode] = useState('pending');

    const showingMsg = (status:number, lineNo:number) =>{
        let retunLine = ''
        switch (lineNo) {
            case 1:
                switch (status) {
                    case 1:
                        retunLine = 'Your auditor registration is pending assessment.';
                        break;
                    case 2:
                        retunLine = 'Your auditor registration is currently being assessed.';
                        break;
                    case 3:
                        retunLine = pathName == '/profile' ? '' : 'Your auditor accreditation has been assessed and you are currently a accredited auditor on the Register.';
                        break;
                    case 4:
                        retunLine = pathName == '/profile' ? '' : 'Your auditor profile is currently being reassessed, however you are currently a accredited auditor on the Register.';
                        break;
                    case 5:
                        retunLine = 'Your current level of accreditation has expired.';
                        break;
                    case 6:
                        retunLine = 'Your current level of accreditation has expired.';
                        break;
                    case 7:
                        retunLine = 'Your auditor registration is currently being assessed.';
                        break;
                }
                break;
            case 2:
                switch (status) {
                    case 1:
                        retunLine = 'You can continue to update your profile with information to meet the eligibility criteria for the level of accreditation that you are applying for.';
                        break;
                    case 2:
                        retunLine = 'If you have any queries regarding your assessment process, please contact the Register for further information.';
                        break;
                    case 3:
                        retunLine = pathName == '/profile' ? '' : 'If your profile has been updated with information that may impact your level of accreditation, you can request for a reassessment of your accreditation level by contacting the Register.';
                        break;
                    case 4:
                        retunLine = pathName == '/profile' ? '' : 'If you have any queries regarding your reassessment process, please contact the Register for further information.';
                        break;
                    case 5:
                        retunLine = 'If your profile has been updated with information that may impact your level of accreditation, or your current level of accreditation has expired, you can request for a reassessment of your accreditation level by contacting the Register.';
                        break;
                    case 6:
                        retunLine = 'If your profile has been updated with information that may impact your level of accreditation, or your current level of accreditation has expired, you can request for a reassessment of your accreditation level by contacting the Register.';
                        break;
                    case 7:
                        retunLine = 'If you have any queries regarding your assessment process, please contact the Register for further information.';
                        break;
                }
                break;
        }
        return retunLine;
    }

    const updateAuditorInfo = (updatedAuditor:any) => {
        console.log('updatedAuditor', updatedAuditor)
        props.setAuditor(updatedAuditor);
    };

    return (
        <React.Fragment>
            <Grid style={{ textAlign: 'left'}}>
                <Grid.Row>
                    <hr/>
                    {props.Session.status == 8 ? (<Grid.Column width={16}>
                        <p className={'profile-message'} style={{marginBottom:'3px'}}>Your email address has now been confirmed. Please continue filling the registration form to become a Road Safety Auditor</p>
                        <p className={'profile-message'} style={{color:'#CD123D'}}>Please note that the minimum accreditation for a Road Safety Auditor on the Transport for NSW Register of Road Safety Auditors is level 1. <br/> To meet the minimum accreditation, you are required to have completed at least one Transport for NSW recognised Road Safety Audit training course and at least one road safety audit that meets either <a href='https://austroads.com.au/publications/road-safety/agrs06' target="_blank" rel="noopener noreferrer">Austroads</a> or <a href="https://www.transport.nsw.gov.au/system/files/media/documents/2023/Guidelines%20for%20Road%20Safety%20Audit%20Practices.pdf" target="_blank" rel="noopener noreferrer">Transport for NSW Guidelines</a>. Check <a href='/faqs' target="_blank" rel="noopener noreferrer">FAQ here</a>.</p>
                    </Grid.Column>):(
                        <Grid.Column width={16}>
                            <p className={'profile-message'} style={{marginBottom:'3px'}}>{showingMsg(props.Session.status,1)}</p>
                            <p className={'profile-message'}>{showingMsg(props.Session.status,2)}</p>
                        </Grid.Column>
                    )}
                </Grid.Row>
            </Grid>
            <h1 className="ui center aligned icon header red-title" style={{marginTop: '20px'}}>
                {pathName == '/accreditation' ? 'States and Regions' : encid != null && (props.Session.userRoleId == 1 || props.Session.userRoleId == 4) ? "Auditor's Details" : 'Contact Details'}
            </h1>
            <Accordion fluid>
                {encid != null && (props.Session.userRoleId == 1 || props.Session.userRoleId == 4) ? (<><Accordion.Title
                    active={activeIndex.includes(3)}
                    index={3}
                    onClick={() => {
                        handleClick(3)
                    }}
                >
                    <Grid>
                        <Grid.Row columns={2}>
                            <Grid.Column className={'accrdian-title'} width={15} mobile={12} tablet={13}>
                                Accreditation Assessment
                            </Grid.Column>
                            <Grid.Column width={1}>
                                {/* <Icon name='angle down'/> */}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Accordion.Title>
                    <Accordion.Content active={activeIndex.includes(3)}>
                        <AccreditationAssessment
                            updateAuditorInfo = {updateAuditorInfo}
                        />
                    </Accordion.Content></>) : null}
                {encid != null && (props.Session.userRoleId == 1 || props.Session.userRoleId == 4) ? (<><Accordion.Title
                    active={activeIndex.includes(4)}
                    index={4}
                    onClick={() => {
                        handleClick(4)
                    }}
                >
                    <Grid>
                        <Grid.Row columns={2}>
                            <Grid.Column className={'accrdian-title'} width={15} mobile={12} tablet={13}>
                                Accreditation Details
                            </Grid.Column>
                            <Grid.Column width={1}>
                                {/* <Icon name='angle down'/> */}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Accordion.Title>
                    <Accordion.Content active={activeIndex.includes(4)}>
                       <AccreditationDetails
                           auditor={props.auditor}
                           Status={props.Status}
                           SetStatus={props.SetStatus}
                       />
                    </Accordion.Content></>) : null}
                {pathName == '/accreditation' ? null : (<><Accordion.Title
                    active={activeIndex.includes(0)}
                    index={0}
                >
                    <Grid>
                        <Grid.Row columns={3}>
                            <Grid.Column className={'accrdian-title'} width={14} mobile={12} tablet={13}>
                                Personal Details
                            </Grid.Column>
                            <Grid.Column width={2} style={{textAlign:'right'}}>
                                {encid != null && (props.Session.userRoleId == 1 || props.Session.userRoleId == 4) && (auditorStatus == 1 || auditorStatus == 2 ) ? (<Popup
                                    flowing
                                    hoverable
                                    trigger={
                                        <Radio toggle checked={inforTogglePersonal} onChange={() => {
                                            setInforTogglePersonal(!inforTogglePersonal)
                                            props.setAD({...props.ad,
                                                isActivated:!inforTogglePersonal,
                                                Type: props.ad.Type ? props.ad.Type : 'AdminInfoToggle',
                                                msgFromAdmin: props.ad.msgFromAdmin ? props.ad.msgFromAdmin : '',
                                                auditorId:parseInt(atob(encid)) ,
                                                // id: 0,
                                                // isConfirmedByAuditor: false,
                                                // sectionName: "RSC",
                                            })
                                        }}/>
                                    }
                                >
                                    <Popup.Header>{inforTogglePersonal ? 'Admin infotoggle is ON' : `Admin infotoggle is OFF` }</Popup.Header>
                                    <Popup.Content >
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <p>{inforTogglePersonal ? 'slide to save and close' : `slide to send message to auditor` }</p>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Popup.Content>
                                </Popup>) : (null)}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Accordion.Title>
                <Accordion.Content active={activeIndex.includes(0)}>
                    <PersonalDetails
                        title={props.title}
                        focusMode={props.focusMode}
                        setFocusMode={props.setFocusMode}
                        auditor={props.auditor}
                        setStateAuditor={props.setStateAuditor}
                        setConfirmPW={props.setConfirmPW}
                        Error={props.PersoanlError}
                        ad={props.ad}
                        setAD={props.setAD}
                        inforTogglePersonal={inforTogglePersonal}
                        setInforTogglePersonal={setInforTogglePersonal}
                    />
                </Accordion.Content></>)}

                {pathName == '/accreditation' ? null : props.profile ? (<><Accordion.Title
                    active={props.profile ? activeIndex.includes(1) : false}
                    index={1}


                >
                    <Grid>
                        <Grid.Row columns={2}>
                            <Grid.Column className={'accrdian-title'} width={14} mobile={12} tablet={13}>
                                Business Details
                            </Grid.Column>
                            <Grid.Column width={2} style={{textAlign:'right'}}>
                                {encid != null && (props.Session.userRoleId == 1 || props.Session.userRoleId == 4) && (auditorStatus == 1 || auditorStatus == 2 ) ? (<Popup
                                    flowing
                                    hoverable
                                    trigger={
                                        <Radio toggle checked={inforToggleBusiness} onChange={() => {
                                            setInforToggleBusiness(!inforToggleBusiness)
                                            props.setBD({...props.bd,
                                                isActivated:!inforToggleBusiness,
                                                Type: props.bd.Type ? props.bd.Type : 'AdminInfoToggle',
                                                msgFromAdmin: props.bd.msgFromAdmin ? props.bd.msgFromAdmin : '',
                                                auditorId:parseInt(atob(encid)) ,
                                                // id: 0,
                                                // isConfirmedByAuditor: false,
                                                // sectionName: "RSC",
                                            })
                                        }}/>
                                    }
                                >
                                    <Popup.Header>{inforToggleBusiness ? 'Admin infotoggle is ON' : 'Admin infotoggle is OFF'}</Popup.Header>
                                    <Popup.Content >
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <p>{inforToggleBusiness ? `slide to save and close` : `slide to send message to auditor` }</p>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Popup.Content>
                                </Popup>) : (null)}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Accordion.Title>
                <Accordion.Content active={props.profile ? activeIndex.includes(1) : false}>
                    <BusinessDetails
                        country={props.country}
                        focusMode={props.focusMode}
                        setFocusMode={props.setFocusMode}
                        auditor={props.auditor}
                        states={props.states}
                        setStateBusiness={props.setStateBusiness}
                        contactMethods={props.contactMethods}
                        company={props.company}
                        Error={props.BusinessError}
                        inforToggleBusiness={inforToggleBusiness}
                        setInforToggleBusiness={setInforToggleBusiness}
                        bd={props.bd}
                        setBD={props.setBD}
                    />
                </Accordion.Content></>) : null }

                {(props.Session.status == 3 || props.Session.status == 4) && pathName == '/profile'? null : props.profile ? (<><Accordion.Title
                    active={props.profile ? activeIndex.includes(2) : false}
                    index={2}
                    id={'availability'}
                >
                    <Grid>
                        <Grid.Row columns={2}>
                            <Grid.Column className={'accrdian-title'} width={14} mobile={12} tablet={13}>
                                Availability
                            </Grid.Column>
                            <Grid.Column width={2} style={{textAlign:'right'}}>
                                {encid != null && (props.Session.userRoleId == 1 || props.Session.userRoleId == 4) && (auditorStatus == 1 || auditorStatus == 2 ) ? (<Popup
                                    flowing
                                    hoverable
                                    trigger={
                                        <Radio toggle checked={inforToggleAvailability} onChange={() => {
                                            setInforToggleAvailability(!inforToggleAvailability)
                                            props.setAVA({...props.ava,
                                                isActivated:!inforToggleAvailability,
                                                Type: props.ava.Type ? props.ava.Type : 'AdminInfoToggle',
                                                msgFromAdmin: props.ava.msgFromAdmin ? props.ava.msgFromAdmin : '',
                                                auditorId:parseInt(atob(encid)) ,
                                                // id: 0,
                                                // isConfirmedByAuditor: false,
                                                // sectionName: "RSC",
                                            })
                                        }}/>
                                    }
                                >
                                    <Popup.Header>{inforToggleAvailability ? 'Admin infotoggle is ON' : 'Admin infotoggle is OFF'}</Popup.Header>
                                    <Popup.Content >
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <p>{inforToggleAvailability ? `slide to save and close` : `slide to send message to auditor` }</p>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Popup.Content>
                                </Popup>) : (null)}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Accordion.Title>
                <Accordion.Content active={props.profile ? activeIndex.includes(2) : false}>
                    <Availablity
                        auditor={props.auditor}
                        focusMode={props.focusMode}
                        setFocusMode={props.setFocusMode}
                        setStateRegion={props.setStateRegion}
                        ava={props.ava}
                        inforToggleAvailability={inforToggleAvailability}
                        setAVA={props.setAVA}
                        setInforToggleAvailability={setInforToggleAvailability}
                    />
                </Accordion.Content></>) : null}
            </Accordion>
        </React.Fragment>
    );
};

export default ContactDetails;


interface PersonalDetailsProps {
    title: any;
    setFocusMode: any
    focusMode: string
    auditor: any,
    setStateAuditor: any
    setConfirmPW:any
    Error:PersonalDetailsErrorProps,
    ad:any,
    setAD:any,
    setInforTogglePersonal:any,
    inforTogglePersonal:any
}


const PersonalDetails = (props: PersonalDetailsProps) => {
    const [title, setTitle] = React.useState<DropDownOptions[]>([]);
    const [state, setState] = React.useState<PersonalDetailsValueProps>();
    const [isEdit, setIsEdit] = React.useState<boolean>(true);
    const [confirmPassword, setConfirmPassword] = React.useState<string>("");
    //const [ad, setAD] = useState <MsgFromAdmin>({} as MsgFromAdmin)
    const [emailError, setEmailError] = React.useState('')
    const [fnameError, setFnameError] = React.useState('')
    const [lnameError, setLnameError] = React.useState('')
    const [pwdError, setPwdError] = React.useState('')
    const [pwdCError, setPwdCError] = React.useState('')
    const [isConfirmAD, setIsConfirmAD] = React.useState(false)
    const [isConfirmAD2, setIsConfirmAD2] = React.useState(false)
    const [titleError, setTitleError] = React.useState('')
    const [appError, setAppError] = React.useState('')
    const Session = getSessionCookie();
    const encid = new URLSearchParams(location.search).get('get')

    useEffect(() => {
        Promise.resolve(props.title).then((value) => {
            setTitle(value)
        })
    }, [props.title])

    useEffect(()=>{
        setTitleError(props.Error?.txtTitle? props.Error.txtTitle : '')
        setEmailError(props.Error?.txtEmail? props.Error.txtEmail : '')
        setPwdCError(props.Error?.txtConfirmPW? props.Error.txtConfirmPW : '')
        setPwdError(props.Error?.txtPassword? props.Error.txtPassword : '')
        setLnameError(props.Error?.txtLastName? props.Error.txtLastName : '')
        setFnameError(props.Error?.txtFirstName? props.Error.txtFirstName : '')
        setAppError(props.Error?.txtApplyLevel? props.Error.txtApplyLevel : '')
    },[props.Error])

    useEffect(() => {
        //eslint-disable-next-line
        //debugger
        Promise.resolve(props.auditor).then((value) => {
            //console.log(value)
            if(value != undefined){
                if(value.personalDetails.txtPassword == "" && sessionStorage.getItem('tempUser')){
                    setState(value.personalDetails)
                    setIsEdit(encid != null && (Session.userRoleId == 1 || Session.userRoleId == 4) ? true : value.Editable)
                }else if(value.personalDetails.txtPassword != ""){
                    setState(value.personalDetails)
                    setConfirmPassword(value.personalDetails.txtPassword)
                    setIsEdit(encid != null && (Session.userRoleId == 1 || Session.userRoleId == 4) ? true : value.AD.isActivated ? value.AD.isActivated : value.Editable)
                    props.setAD(value.AD);
                    props.setInforTogglePersonal(props.auditor.AD.isActivated)
                    setIsConfirmAD(value.AD.isConfirmedByAuditor)
                    setIsConfirmAD2(value.AD.isConfirmedByAuditor2)
                }
            }
        })
    }, [props.auditor])

    useEffect(() => {
        //eslint-disable-next-line
        //debugger;
        if(state != undefined && state.txtPassword == confirmPassword) {
            props.setStateAuditor(state);
            setPwdCError('')
            props.setFocusMode("personal2")
        }else if(state != undefined && state.txtPassword == 'password'){
            props.setStateAuditor(state);
            setPwdCError('')
            props.setFocusMode("personal3")
        }else if(state == undefined || state.txtPassword == ''){
            setPwdCError('')
        }else{
            setPwdCError('Password not match !')
        }

    }, [state,confirmPassword])
    const onLostFocusPersonalDetails = async () => {
        props.setFocusMode("personal")
    }

    const onADChange = async (id: number, newValue: boolean, sectionName: string) => {
        console.log('Updating with value:', newValue);
        await UpdateMsgFromAdmin(id, newValue, sectionName);
    };

    const onADChange2 = async(id:number, e:any, data:any, sectionName:string) => {
        setIsConfirmAD2(!isConfirmAD2);
        await UpdateMsgFromAdmin(id,!isConfirmAD2, sectionName)
    }

    const onADAdminBoxChange = (e:any) => {

        props.setAD({...props.ad,
            isActivated:props.inforTogglePersonal,
            Type: props.ad.Type ? props.ad.Type : 'AdminInfoToggle',
            msgFromAdmin: e != '' ? e.target.value : '',
            auditorId:parseInt(atob(encid ? encid : '')) ,
        })
    }

    return (
        <React.Fragment>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        {props.ad.isActivated && props.inforTogglePersonal ? (<InfoToggle
                            type={props.ad.Type}
                            msg={props.ad.msgFromAdmin}
                            update={props.ad.update}
                            msg2={props.ad.msgFromAdmin2? props.ad.msgFromAdmin2 : ""}
                            update2={props.ad.update2}
                            id={'chkAdminConformAD'}
                            id2={'chkAdminConformAD2'}
                            value={isConfirmAD}
                            value2={isConfirmAD2}
                            onChange={(e:any, data:any) => {
                                const newValue = !isConfirmAD;  // Calculate the new value based on current state
                                setIsConfirmAD(newValue);       // Update local state immediately
                                onADChange(props.ad.id, newValue, props.ad.sectionName);  // Pass new value to function
                            }}
                            onChange2={(e: any, data: any)=>{
                                onADChange2(props.ad.id2 ? props.ad.id2 : 0 ,e,data, props.ad.sectionName)
                            }}
                            createDate={props.ad.createDate}
                            createDate2={props.ad.createDate2}
                            responceDate={props.ad.ResponceDate}
                            responceDate2={props.ad.ResponceDate2}
                            onMsgBoxChange={onADAdminBoxChange}
                            isConfirmedByAdminOnDate={props.ad.isConfirmedByAdminOnDate}
                        />):null}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3} >
                    <Grid.Column width={4} mobile={16} widescreen={4} tablet={4} computer={4}>
                        <NameWithDropDown clearable={false} selection={true} labelText={'Title'}
                                          labelPosition={Position.Top} id={'ddTitle'}
                                          placeholder={'Select Title'}
                                          required={true}
                                          options={title}
                                          value={state?.txtTitle}
                                          onChange={(e: any, data: any) => {
                                              setTitleError('')
                                              setState({...state, txtTitle: data.value});
                                              setTimeout(() => {
                                                  onLostFocusPersonalDetails()
                                              }, 500)
                                          }}
                                          disabled={!isEdit}
                                          onerror={titleError}

                        />
                    </Grid.Column>
                    <Grid.Column width={6} mobile={16} widescreen={6} tablet={6} computer={6}>
                        <NameWithDefault labelText={'First name'} labelPosition={Position.Top}
                                         id={'txtFName'} fluid={true} type={'text'}
                                         placeholder={'Enter First Name'}
                                         required={true}
                                         value={state?.txtFirstName}
                                         onError={fnameError}
                                         onChange={(e: any) => {
                                             setFnameError('')
                                             setState({...state, txtFirstName: e.target.value});
                                         }}
                                         onLostFocus={() => {
                                             setTimeout(() => {
                                                 onLostFocusPersonalDetails()
                                             }, 500)
                                         }}
                                         disabled={!isEdit}
                        />
                    </Grid.Column>
                    <Grid.Column width={6} mobile={16} widescreen={6} tablet={6} computer={6}>
                        <NameWithDefault labelText={'Middle name'} labelPosition={Position.Top}
                                         id={'txtMName'} fluid={true} type={'text'}
                                         placeholder={'Enter Middle Name'}
                                         required={false}
                                         value={state?.txtMiddleName}
                                         onError={lnameError}
                                         onChange={(e: any) => {
                                             setLnameError('')
                                             setState({...state, txtMiddleName: e.target.value});
                                         }}
                                         onLostFocus={() => {
                                             setTimeout(() => {
                                                 onLostFocusPersonalDetails()
                                             }, 500)
                                         }}
                                         disabled={!isEdit}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={8} mobile={16} widescreen={8} tablet={8} computer={8}>
                        <NameWithDefault labelText={'Last name'} labelPosition={Position.Top}
                                         id={'txtLName'} fluid={true} type={'text'}
                                         placeholder={'Enter Last Name'}
                                         required={true}
                                         value={state?.txtLastName}
                                         onError={lnameError}
                                         onChange={(e: any) => {
                                             setLnameError('')
                                             setState({...state, txtLastName: e.target.value});
                                         }}
                                         onLostFocus={() => {
                                             setTimeout(() => {
                                                 onLostFocusPersonalDetails()
                                             }, 500)
                                         }}
                                         disabled={!isEdit}
                        />
                    </Grid.Column>
                    <Grid.Column width={8} mobile={16} widescreen={8} tablet={8} computer={8}>
                        <NameWithEmail labelText={'Email address (Username)'} labelPosition={Position.Top}
                                       id={'txtEmail'} fluid={true} type={'text'}
                                       placeholder={'Enter Email Address'}
                                       required={false}
                                       onError={emailError}
                                       value={state?.txtEmail}
                                       onChange={(e: any) => {
                                           setEmailError('')
                                           setState({...state, txtEmail: e.target.value});
                                       }}
                                       onLostFocus={() => {
                                           setTimeout(() => {
                                               onLostFocusPersonalDetails()
                                           }, 500)
                                       }}
                                       disabled={true}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column width={8} mobile={16} widescreen={8} tablet={16} computer={8}>
                        <NameWithPassword labelText={'Password'} labelPosition={Position.Top}
                                          id={'txtPassword'} fluid={true} type={'password'}
                                          placeholder={'Enter Password'}
                                          required={true}
                                          onError={pwdError}
                                          value={state?.txtPassword}
                                          onChange={(e: any) => {
                                              setPwdError('')
                                              setState({...state, txtPassword: e.target.value});
                                          }}
                                          onLostFocus={() => {
                                              setTimeout(() => {
                                                  onLostFocusPersonalDetails()
                                              }, 500)
                                          }}
                                          disabled={!isEdit}
                        />
                    </Grid.Column>
                    <Grid.Column width={8} mobile={16} widescreen={8} tablet={16} computer={8}>
                        <NameWithPassword labelText={'Confirm password'} labelPosition={Position.Top}
                                          id={'txtCPassword'} fluid={true} type={'password'}
                                          placeholder={'Confirm Password'}
                                          required={true}
                                          onError={pwdCError}
                                          value={confirmPassword}
                                          onChange={(e: any) => {
                                              setConfirmPassword(e.target.value);
                                              props.setConfirmPW(e.target.value)
                                          }}
                                          disabled={!isEdit}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    {Session.status == 8 || Session.status == 1 || (encid != null && (Session.userRoleId == 1 || Session.userRoleId == 4))  ? ( <Grid.Column width={16}>
                        { state?.AuditorsStateRes?.auditorStatusId == 3 ? (
                            <NameWithDropDown  clearable={true} selection={true} labelText={'Level Applying for'}
                                labelPosition={Position.Top} id={'ddApplyLevel'}
                                placeholder={'Select level applying for'}
                                required={true}
                                options={[{key: 1, text: 'Auditor (Level 1)', value: '1'}, {
                                    key: 2,
                                    text: 'Senior Auditor (Level 2)',
                                    value: '2'
                                }, {key: 3, text: 'Leader (Level 3)', value: '3'}]}
                                onChange={(e: any, data: any) => {
                                    setState({...state, txtApplyLevel: data.value})
                                    setTimeout(() => {
                                        onLostFocusPersonalDetails()
                                    }, 500)
                                    setAppError('')
                                }}
                                onLostFocus={onLostFocusPersonalDetails}
                                disabled={!isEdit}
                                onerror={appError}
                            />
                        ) : (
                            <NameWithDropDown  clearable={true} selection={true} labelText={'Level Applying for'}
                                labelPosition={Position.Top} id={'ddApplyLevel'}
                                placeholder={'Select level applying for'}
                                required={true}
                                options={[{key: 1, text: 'Auditor (Level 1)', value: '1'}, {
                                    key: 2,
                                    text: 'Senior Auditor (Level 2)',
                                    value: '2'
                                }, {key: 3, text: 'Leader (Level 3)', value: '3'}]}
                                value={state?.txtApplyLevel}
                                onChange={(e: any, data: any) => {
                                    setState({...state, txtApplyLevel: data.value})
                                    setTimeout(() => {
                                        onLostFocusPersonalDetails()
                                    }, 500)
                                    setAppError('')
                                }}
                                onLostFocus={onLostFocusPersonalDetails}
                                disabled={!isEdit}
                                onerror={appError}
                            />
                        )

                        }
                        <p style={{fontStyle: 'normal', marginTop: '1rem', marginBottom: '1rem'}}>Eligibility Level criteria, <a href={'/faqs'}>please refer to FAQs.</a></p>
                    </Grid.Column>) : null}
                </Grid.Row>
            </Grid>
        </React.Fragment>
    );
};

interface BusinessProps {
    country: any
    company:any
    setFocusMode: any
    focusMode: string
    auditor: any
    states: any
    contactMethods: any
    setStateBusiness: any
    Error:BusinessDetailsErrorProps,
    bd:any,
    setBD:any,
    setInforToggleBusiness:any,
    inforToggleBusiness:any
}

const BusinessDetails = (props: BusinessProps) => {
    const [state, setState] = React.useState<BusinessDetailsValueProps>();
    const [isEdit, setIsEdit] = React.useState<boolean>(true);
    const [country, setCountry] = React.useState<DropDownOptions[]>([]);
    const [companies, setComapnies] = React.useState<DropDownOptions[]>([])
    const [stateList, setStateList] = useState<DropDownOptions[]>([]);
    const [contactMethod, setContactMethod] = useState<DropDownOptions[]>([]);

    const [companyError, setCompanyError] = React.useState('')
    const [branchError, setBranchError] = React.useState('')
    const [positionError, setPosisionError] = React.useState('')
    const [officePhoneError, setOfficePhoneError] = React.useState('')
    const [mobileError, setMobileError] = React.useState('')
    const [homePhoneError, setHomePhoneError] = React.useState('')
    const [offficeEmailError, setOfficeEmailError] = React.useState('')
    const [personalEmailError, setPersonalEmailError] = React.useState('')
    const [prefferContactError, setPrefferContactError] = React.useState('')
    const [workAddressError, setWorkAddressError] = React.useState('')
    const [suburbError, setSuburbError] = React.useState('')
    const [stateError, setStateError] = React.useState('')
    const [postalCodeError, setPostalCodeError] = React.useState('')
    const [countryError, setCountryError] = React.useState('')
    //const [bd, setBD] = useState <MsgFromAdmin>({} as MsgFromAdmin)
    const [isConfirmBD, setIsConfirmBD] = React.useState(false)
    const [isConfirmBD2, setIsConfirmBD2] = React.useState(false)
    const encid = new URLSearchParams(location.search).get('get')
    const Session = getSessionCookie();

    useEffect(()=>{
        setCompanyError(props.Error?.txtCompanyName? props.Error.txtCompanyName : '')
        setOfficePhoneError(props.Error?.txtOfficePhone? props.Error.txtOfficePhone : '')
        setMobileError(props.Error?.txtMobilePhone? props.Error.txtMobilePhone : '')
        setHomePhoneError(props.Error?.txtHomePhone? props.Error.txtHomePhone : '')
        setOfficeEmailError(props.Error?.txtWorkEmail? props.Error.txtWorkEmail : '')
        setPersonalEmailError(props.Error?.txtPersonalEmail? props.Error.txtPersonalEmail : '')
        setPrefferContactError(props.Error?.txtPreferredContact? props.Error.txtPreferredContact : '')
        setWorkAddressError(props.Error?.txtOfficeAddress? props.Error.txtOfficeAddress : '')
        setSuburbError(props.Error?.txtSuburb? props.Error.txtSuburb : '')
        setStateError(props.Error?.txtState? props.Error.txtState : '')
        setPostalCodeError(props.Error?.txtPostcode? props.Error.txtPostcode : '')
        setCountryError(props.Error?.txtCountry? props.Error.txtCountry : '')
        setPosisionError(props.Error?.txtDesignation? props.Error.txtDesignation : '')
    },[props.Error])

    useEffect(() => {
        Promise.resolve(props.country).then((value) => {
            //console.log(value)
            setCountry(value)
        })
    }, [props.country])

    useEffect(() => {
        Promise.resolve(props.company).then((value) => {
            //console.log(value)
            setComapnies(value)
        })
    }, [props.company])

    useEffect(() => {
        Promise.resolve(props.states).then((value) => {
            setStateList(value)
        })
    }, [props.states])

    useEffect(() => {
        Promise.resolve(props.contactMethods).then((value) => {
            setContactMethod(value)
        })
    }, [props.contactMethods])

    useEffect(() => {
        Promise.resolve(props.auditor).then((value) => {
            //console.log(value)
            //eslint-disable-next-line
            //debugger;
            if(value){
                if(!state){
                    setState({...value.businessDetails,txtState : value.businessDetails.txtState ? value.businessDetails.txtState : '1', txtCountry :  value.businessDetails.txtCountry ? value.businessDetails.txtCountry : '1' })
                    setIsEdit(encid != null && (Session.userRoleId == 1 || Session.userRoleId == 4) ? true : value.BD.isActivated ? value.BD.isActivated : value.Editable)
                    props.setBD(value.BD);
                    props. setInforToggleBusiness(props.auditor.BD.isActivated)
                    setIsConfirmBD(value.BD.isConfirmedByAuditor)
                    setIsConfirmBD2(value.BD.isConfirmedByAuditor2)
                }
            }

        })
    }, [props.auditor])

    useEffect(() => {
        props.setStateBusiness(state);
        if(!companies.find(x => x.text == state?.txtCompanyName)){
            setComapnies([{ key:9999999 , text: state?.txtCompanyName ? state?.txtCompanyName : '', value:state?.txtCompanyName ? state?.txtCompanyName : '' }, ...companies])
        }
    }, [state])

    const onBDChange = async(id:number, e:any, data:any, sectionName:string) => {
        console.log(isConfirmBD)
        setIsConfirmBD(!isConfirmBD);
        await UpdateMsgFromAdmin(id,!isConfirmBD, sectionName)
    }

    const onBDChange2 = async(id:number, e:any, data:any, sectionName:string) => {
        setIsConfirmBD2(!isConfirmBD2);
        await UpdateMsgFromAdmin(id,!isConfirmBD2, sectionName)
    }

    const onLostFocusBusinesslDetails = async () => {
        //eslint-disable-next-line
        //debugger;
        props.setStateBusiness(state);
        props.setFocusMode("business")
    }

    const onBDAdminBoxChange = (e:any) => {
        props.setBD({...props.bd,
            isActivated:props.inforToggleBusiness,
            Type: props.bd.Type ? props.bd.Type : 'AdminInfoToggle',
            msgFromAdmin: e != '' ? e.target.value : '',
            auditorId:parseInt(atob(encid ? encid : '')) ,
        })
    }

    return (
        <React.Fragment>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        {props.bd.isActivated && props.inforToggleBusiness ? (<InfoToggle
                            type={props.bd.Type}
                            msg={props.bd.msgFromAdmin}
                            update={props.bd.update}
                            msg2={props.bd.msgFromAdmin2 ? props.bd.msgFromAdmin2 : ""}
                            update2={props.bd.update2}
                            id={'chkAdminConformBD'}
                            id2={'chkAdminConformBD2'}
                            value={isConfirmBD}
                            value2={isConfirmBD2}
                            onChange={(e: any, data: any)=>{
                                onBDChange(props.bd.id,e,data, props.bd.sectionName)
                            }}
                            onChange2={(e: any, data: any)=>{
                                onBDChange2(props.bd.id2 ? props.bd.id2 : 0,e,data, props.bd.sectionName)
                            }}
                            createDate={props.bd.createDate}
                            createDate2={props.bd.createDate2}
                            responceDate={props.bd.ResponceDate}
                            responceDate2={props.bd.ResponceDate2}
                            onMsgBoxChange={onBDAdminBoxChange}
                            isConfirmedByAdminOnDate={props.bd.isConfirmedByAdminOnDate}
                        />):null}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column width={8} mobile={16} widescreen={8} tablet={16} computer={8}>
                        {/*<NameWithDefault labelText={'Company/Organisation'} labelPosition={Position.Top}
                                         id={'txtCompany'} fluid={true} type={'text'}
                                         placeholder={'Enter Company/Organisation'}
                                         required={true}
                                         value={state?.txtCompanyName}
                                         onError={companyError}
                                         onChange={(e: any) => {
                                             setState({...state, txtCompanyName: e.target.value})
                                         }}
                                         onLostFocus={() => {
                                             setTimeout(() => {
                                                 onLostFocusBusinesslDetails()
                                             }, 500)
                                         }}
                                         disabled={!isEdit}
                        />*/}
                        <NameWithDropDown selection={true}
                                          labelText={'Company/Organisation'}
                                          labelPosition={Position.Top} id={'ddCompany'}
                                          placeholder={'Enter Company/Organisation'}
                                          required={true}
                                          multiple={false}
                                          allowAdditions={true}
                                          onAddItem={(e : any,{value}:any) => {
                                              console.log(value)
                                              setComapnies([{ key:value , text: value, value }, ...companies])
                                          }}
                                          search={true}
                                          options={companies}
                                          value={state?.txtCompanyName}
                                            // onError={personalEmailError}
                                          onChange={(e: any, data: any) => {
                                              setCompanyError('')
                                              setState({...state, txtCompanyName: data.value});
                                            //   setTimeout(() => {
                                            //       onLostFocusBusinesslDetails()
                                            //   }, 500)
                                          }}
                                          disabled={!isEdit}
                                          onerror={companyError}

                        />
                    </Grid.Column>
                    <Grid.Column width={8} mobile={16} widescreen={8} tablet={16} computer={8}>
                        <NameWithDefault labelText={'Branch/Section'} labelPosition={Position.Top}
                                         id={'txtBranch'} fluid={true} type={'text'}
                                         placeholder={'Enter Branch/Section'}
                                         required={false}
                                         value={state?.txtBranchName}
                                         onError={branchError}
                                         onChange={(e: any) => {
                                             setBranchError('')
                                             setState({...state, txtBranchName: e.target.value})
                                         }}
                                         onLostFocus={() => {
                                             setTimeout(() => {
                                                 onLostFocusBusinesslDetails()
                                             }, 500)
                                         }}
                                         disabled={!isEdit}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column width={8} mobile={16} widescreen={8} tablet={16} computer={8}>
                        <NameWithDefault labelText={'Position'} labelPosition={Position.Top}
                                         id={'txtPosition'} fluid={true} type={'text'}
                                         placeholder={'Enter Position'}
                                         required={true}
                                         value={state?.txtDesignation}
                                         onError={positionError}
                                         onChange={(e: any) => {
                                             setPosisionError('')
                                             setState({...state, txtDesignation: e.target.value})
                                         }}
                                         onLostFocus={() => {
                                             setTimeout(() => {
                                                 onLostFocusBusinesslDetails()
                                             }, 500)
                                         }}
                                         disabled={!isEdit}
                        />
                    </Grid.Column>
                    <Grid.Column width={8}></Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column width={8} mobile={16} widescreen={8} tablet={16} computer={8}>
                        <NameWithPhone labelText={'Work telephone no.'} labelPosition={Position.Top}
                                       id={'txtOfficePhone'} fluid={true} type={'phone'}
                                       placeholder={'Enter Telephone Phone No.'}
                                       required={true}
                                       value={state?.txtOfficePhone}
                                       onError={officePhoneError}
                                       onChange={(e: any) => {
                                           setOfficePhoneError('')
                                           setState({...state, txtOfficePhone: e.target.value})
                                           setOfficePhoneError('')
                                       }}
                                       onLostFocus={() => {
                                           setTimeout(() => {
                                               onLostFocusBusinesslDetails()
                                           }, 500)
                                       }}
                                       disabled={!isEdit}
                        />
                    </Grid.Column>
                    <Grid.Column width={8} mobile={16} widescreen={8} tablet={16} computer={8}>
                        <NameWithPhone labelText={'Mobile phone no.'} labelPosition={Position.Top}
                                       id={'txtMobilePhone'} fluid={true} type={'phone'}
                                       placeholder={'Enter Mobile Phone No.'}
                                       required={true}
                                       value={state?.txtMobilePhone}
                                       onError={mobileError}
                                       onChange={(e: any) => {
                                           setMobileError('')
                                           setState({...state, txtMobilePhone: e.target.value})
                                           setMobileError('')
                                       }}
                                       onLostFocus={() => {
                                           setTimeout(() => {
                                               onLostFocusBusinesslDetails()
                                           }, 500)
                                       }}
                                       disabled={!isEdit}
                        />
                        <p>Required as an alternate contact in the event your work telephone number changes. Number to
                            be treated confidential with privacy respected.</p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column width={8} mobile={16} widescreen={8} tablet={16} computer={8}>
                        <NameWithPhone labelText={'Home phone no.'} labelPosition={Position.Top}
                                       id={'txtHomePhone'} fluid={true} type={'phone'}
                                       placeholder={'Enter Home Phone No.'}
                                       required={false}
                                       value={state?.txtHomePhone}
                                       onError={homePhoneError}
                                       onChange={(e: any) => {
                                           setHomePhoneError('')
                                           setState({...state, txtHomePhone: e.target.value})
                                           setHomePhoneError('')
                                       }}
                                       onLostFocus={() => {
                                           setTimeout(() => {
                                               onLostFocusBusinesslDetails()
                                           }, 500)
                                       }}
                                       disabled={!isEdit}
                        />
                        {''}
                    </Grid.Column>
                    <Grid.Column width={8} mobile={16} widescreen={8} tablet={16} computer={8}>
                        {/*<NameWithDefault labelText={'Skype name'} labelPosition={Position.Top}*/}
                        {/*                 id={'txtSkype'} fluid={true} type={'text'}*/}
                        {/*                 placeholder={'Enter Skype Name'}*/}
                        {/*                 required={false}*/}
                        {/*                 value={state?.txtSkypeId}*/}
                        {/*    //onError={homePhoneError}*/}
                        {/*                 onChange={(e: any) => {*/}
                        {/*                     setState({...state, txtSkypeId: e.target.value})*/}
                        {/*                 }}*/}
                        {/*                 onLostFocus={() => {*/}
                        {/*                     setTimeout(() => {*/}
                        {/*                         onLostFocusBusinesslDetails()*/}
                        {/*                     }, 500)*/}
                        {/*                 }}*/}
                        {/*                 disabled={!isEdit}*/}
                        {/*/>*/}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column width={8} mobile={16} widescreen={8} tablet={16} computer={8}>
                        <NameWithEmail labelText={'Work email'} labelPosition={Position.Top}
                                       id={'txtWEmail'} fluid={true} type={'email'}
                                       placeholder={'Enter Work Email'}
                                       required={true}
                                       value={state?.txtWorkEmail}
                                       onError={offficeEmailError}
                                       onChange={(e: any) => {
                                           setOfficeEmailError('')
                                           setState({...state, txtWorkEmail: e.target.value})
                                       }}
                                       onLostFocus={() => {
                                           setTimeout(() => {
                                               onLostFocusBusinesslDetails()
                                           }, 500)
                                       }}
                                       disabled={!isEdit}
                        />
                    </Grid.Column>
                    <Grid.Column width={8} mobile={16} widescreen={8} tablet={16} computer={8}>
                        <NameWithEmail labelText={'Personal email'} labelPosition={Position.Top}
                                       id={'txtEmail'} fluid={true} type={'email'}
                                       placeholder={'Enter Personal Email'}
                                       required={false}
                                       value={state?.txtPersonalEmail}
                                       onError={personalEmailError}
                                       onChange={(e: any) => {
                                           setPersonalEmailError('')
                                           setState({...state, txtPersonalEmail: e.target.value})
                                       }}
                                       onLostFocus={() => {
                                           setTimeout(() => {
                                               onLostFocusBusinesslDetails()
                                           }, 500)
                                       }}
                                       disabled={!isEdit}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <NameWithDropDown selection={true}
                                          labelText={'Select at least one of the contact options to tell us how you would prefer to be contacted'}
                                          labelPosition={Position.Top} id={'ddContactOption'}
                                          placeholder={'Select Default contact option'}
                                          required={true}
                                          multiple={true}
                                          options={contactMethod}
                                          value={state?.txtPreferredContact}
                                          onerror={prefferContactError}
                                          onChange={(e: any, data: any) => {
                                              if((e.target.innerText == 'mobile' && state?.txtMobilePhone != undefined && state?.txtMobilePhone.length > 5)
                                              || (e.target.innerText == 'home phone' && state?.txtHomePhone != undefined && state?.txtHomePhone.length > 5)
                                              || (e.target.innerText == 'work email' && state?.txtWorkEmail != undefined && state?.txtWorkEmail.length > 5)
                                              || (e.target.innerText == 'personal email' && state?.txtPersonalEmail != undefined && state?.txtPersonalEmail.length > 5)
                                              || (e.target.innerText == 'work phone' && state?.txtOfficePhone != undefined && state?.txtOfficePhone.length > 5)
                                              || (e.target.innerText == '')){
                                                  setState({...state, txtPreferredContact: data.value});
                                                  setPrefferContactError(``);
                                              }else{
                                                  ErrorToast(`Please fill ${e.target.innerText} before select !`);
                                                  //setPrefferContactError(`Please fill ${e.target.innerText} before select !`);
                                                  return;
                                              }

                                              //eslint-disable-next-line
                                              //debugger
                                          }}
                                          disabled={!isEdit}
                                          onLostFocus={onLostFocusBusinesslDetails}

                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <span style={{display: 'inline-flex'}}>Business Address</span>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column width={8} mobile={16} widescreen={8} tablet={16} computer={8}>
                        <NameWithDefault labelText={'Work postal address'} labelPosition={Position.Top}
                                         id={'txtOfficeAddress'} fluid={true} type={'text'}
                                         placeholder={'Enter Work Postal Address'}
                                         required={true}
                                         value={state?.txtOfficeAddress}
                                         onError={workAddressError}
                                         onChange={(e: any) => {
                                             setWorkAddressError('')
                                             setState({...state, txtOfficeAddress: e.target.value})
                                         }}
                                         onLostFocus={() => {
                                             setTimeout(() => {
                                                 onLostFocusBusinesslDetails()
                                             }, 500)
                                         }}
                                         disabled={!isEdit}
                        />
                    </Grid.Column>
                    <Grid.Column width={8} mobile={16} widescreen={8} tablet={16} computer={8}>
                        <NameWithDefault labelText={'Suburb'} labelPosition={Position.Top}
                                         id={'txtSuburb'} fluid={true} type={'text'}
                                         placeholder={'Enter Suburb'}
                                         required={true}
                                         value={state?.txtSuburb}
                                         onError={suburbError}
                                         onChange={(e: any) => {
                                             setSuburbError('')
                                             setState({...state, txtSuburb: e.target.value})
                                         }}
                                         onLostFocus={() => {
                                             setTimeout(() => {
                                                 onLostFocusBusinesslDetails()
                                             }, 500)
                                         }}
                                         disabled={!isEdit}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column width={8} mobile={16} widescreen={8} tablet={16} computer={8}>
                        <NameWithDropDown
                            selection={true}
                            labelText={'State'}
                            labelPosition={Position.Top}
                            id={'ddState'}
                            placeholder={'Select State'}
                            options={stateList}
                            required={true}
                            value={state?.txtState ? state?.txtState : '1'}
                            onChange={(e: any, data: any) => {
                                setStateError('')
                                setState({...state, txtState: data.value});
                                // setTimeout(() => {
                                //     onLostFocusBusinesslDetails()
                                // }, 500)
                            }}
                            disabled={!isEdit}
                            onerror={stateError}
                        />
                    </Grid.Column>
                    <Grid.Column width={8} mobile={16} widescreen={8} tablet={16} computer={8}>
                        <NameWithDefault labelText={'Postcode'} labelPosition={Position.Top}
                                         id={'txtPostcode'} fluid={true} type={'text'}
                                         placeholder={'Enter Postcode'}
                                         required={true}
                                         value={state?.txtPostcode}
                                         onError={postalCodeError}
                                         onChange={(e: any) => {
                                             setPostalCodeError('')
                                             setState({...state, txtPostcode: e.target.value})
                                         }}
                                         onLostFocus={() => {
                                             setTimeout(() => {
                                                 onLostFocusBusinesslDetails()
                                             }, 500)
                                         }}
                                         disabled={!isEdit}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <NameWithDropDown selection={true} labelText={'Country'}
                                          labelPosition={Position.Top} id={'ddTitle'}
                                          placeholder={'Select Country'}
                                          required={true}
                                          search={true}
                                          multiple={false}
                                          clearable={true}
                                          options={country}
                                          value={state?.txtCountry}
                                          onerror={countryError}
                                          onChange={(e: any, data: any) => {
                                              setCountryError('')
                                              setState({...state, txtCountry: data.value});
                                          }}
                                          disabled={!isEdit}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </React.Fragment>
    );
};



