import React, {useEffect, useState} from "react";
import {Container, Grid, Table} from "semantic-ui-react";
import InnerTitleBar from "../../../../framework/layouts/InnerTitleBar";
import {userRoleSelector} from "../../../../common/roleSelector";
import {getSessionCookie} from "../../../../framework/common/SessionHandler";
import OutLineButton from "../../../../framework/components/Buttons/OutlIneButton";
import RSATable from "../../../../framework/widgets/TableWithBackgrounds/RSATable";
import TableHeader from "../../../../framework/types/TableHeader";
import tags from "../../../../models/common/tag";
import { SearchApiCallForAuditorCompany, SaveAuditorCompany, DeleteAuditorCompany } from "../../../search/SearchController";
import { AuditorCompanies } from "../../../../models/apiResponce/mis/AuditPhaseRes";
import SuccessToast from "../../../../framework/components/Toast/SuccessToast";
import NameWithDefault from "../../../../framework/widgets/NameWithText/NameWithDefault";
import Position from "../../../../framework/types/Position";
import { AuditorCompanyReq } from "../../../../models/apiRequest/mis/AuditPhasesReq";
import ErrorToast from "../../../../framework/components/Toast/ErrorToast";
import { PaginationProps } from "antd/es/pagination";

const AuditorCompanyContainer = () =>{

    const [state, setState] = React.useState<AuditorCompanyReq>();
    const session = getSessionCookie();
    const [auditorCompanyName, setCompanyName] = useState('')
    const [selectedauditorCompany, setSelectauditorCompany] = useState(0)
    const [isAddingNew, setIsAddingNew] = useState(false);
    const [current, setCurrent] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0)
    const rsaCompanyChangeHead: TableHeader[] = [
        {
            text: "Company / Organisation name"
        },
        {
            text: ""
        }
    ];
    const [rsaCompayChange, setrsaCompanyChange] = useState<object[]>([
    ])



    const SetValues = (data:AuditorCompanies[]) => {
        const TableRows: object[] = [];
        data.map((r:AuditorCompanies) => {
            TableRows.push([{
                    tag: tags.text,
                    text: `${r.companyName}`,
                    icon: "",
                    onclick: null
                },
                {
                    tag: "icon-fa",
                    text: "Select",
                    icon: "view",
                    onclick:() => {
                        setSelectauditorCompany(r.companyId)
                        setCompanyName(r.companyName || '');
                        setState(r);
                    }
                }])
        })
        setrsaCompanyChange(TableRows);
    }

    useEffect(() => {
        try {
            const auditorCompanys = SearchApiCallForAuditorCompany(1);
            Promise.resolve(auditorCompanys).then((value) => {
                if (value) {
                    SetValues(value.companies);
                    setTotalRecords(value.totalRows)
                }
            });
        } catch (error) {
            // Handle the error here
            ErrorToast('Error fetching audit results');
        }
        
    }, []);

    const resetFormFields = () => {
        setCompanyName('');
        setState(undefined); // Reset the state
    };

    const onAddNewClick = () => {
        resetFormFields();
        setIsAddingNew(true);
    };

    const onSaveClick = async () => {
        try {
            if (isAddingNew) {
                // Add new
                const newData: AuditorCompanyReq = {
                    companyId: 0,
                    companyName: auditorCompanyName || '',
                };
                const result = await SaveAuditorCompany(newData);
                if (result) {
                    SuccessToast('Auditor company added successfully');
                    setTimeout(() => {
                        // Reload the page
                        window.location.reload();
                    }, 2000); // 2000 milliseconds delay (2 seconds)
                }
            } else {
                // Update
                const updatedData: AuditorCompanyReq = {
                    ...state!,
                    companyId: selectedauditorCompany,
                    companyName: auditorCompanyName || '',
                };
                const result = await SaveAuditorCompany(updatedData);
                if (result) {
                    SuccessToast('Auditor company updated successfully');
                    setTimeout(() => {
                        // Reload the page
                        window.location.reload();
                    }, 2000); // 2000 milliseconds delay (2 seconds)
                }
            }
        } catch (error) {
            // Handle the error here
            ErrorToast('Error saving auditor company');
        }
    };

    const onDeleteClick = async () => {
        try{
            if (isAddingNew) {
                resetFormFields();
            }else{
                // Delete
                const result = await DeleteAuditorCompany(selectedauditorCompany);
                if (result) {
                    SuccessToast('Auditor company deleted successfully');
                    // Reload the page
                    window.location.reload();
                }
            }
        } catch (error) {
            ErrorToast('Error deleting auditor company');
        }
    };

    const onChange: PaginationProps['onChange'] = (page) => {
        // console.log(page);
        
        setCurrent(page);
        // const fetchCourses = fetchAllCourses(-1,-1, page)
        const auditorCompanys = SearchApiCallForAuditorCompany(page);
        Promise.resolve(auditorCompanys).then((value) => {
            if(value){
                SetValues(value.companies)
                setTotalRecords(value.totalRows)
            }
        });
    };

    const [disableSave, setDisableSave] = React.useState(false);

useEffect(() => {
        DisableValidation();
    }, [auditorCompanyName]);

    const DisableValidation = () =>{
        const isFormInvalid = (
            !auditorCompanyName
        );
    
        setDisableSave(isFormInvalid);
    }

    return(
        <Container fluid={true} style={{marginTop: '14px', marginBottom: '20px'}}>
            <InnerTitleBar
                title={"Add/Edit Auditor's company"}
                usreName={session.userDisplayName != null ? session.userDisplayName : "  "}
                userRole={userRoleSelector(session.userRoleId.toString())}
                lastLogin={session.lastLogin.toString()}
                status={userRoleSelector(session.userRoleId.toString())}
            />
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={12} mobile={12} computer={12}>
                                    <p><b> Search results : found {totalRecords} results </b></p>
                                </Grid.Column>
                                <Grid.Column width={4} mobile={16} computer={4} className='button-flex'>
                                    <OutLineButton
                                        id={'btnAddNew'}
                                        style={{ width: '150px' }}
                                        onClick={onAddNewClick}
                                        color='blue'
                                        className={'background-transaparent'}
                                        text={'+ Add New'}
                                        disabled={false}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>     
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                {rsaCompayChange.length > 0 ? (<Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <div style={{ overflow: 'auto' }}>
                            <RSATable
                                head={rsaCompanyChangeHead}
                                body={rsaCompayChange}
                                current={current}
                                pageCount={totalRecords/10}
                                onChange={onChange}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>):null}
                {selectedauditorCompany != 0 || isAddingNew ? (<>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                            <hr/>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={2}>
                                    <b><span style={{display:'inline-flex'}}>Auditor Company</span></b>
                                </Grid.Column>
                                <Grid.Column>
                                    
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>     
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={8} widescreen={8} mobile={16} computer={8} tablet={16} largeScreen={16}>
                                <NameWithDefault 
                                    labelText={'Company / Organisation:'} 
                                    labelPosition={Position.Top}
                                    id={'txtCompanyame'} 
                                    fluid={true} 
                                    type={'text'}
                                    placeholder={'Enter company / organisation: name'}
                                    required={true}
                                    value={auditorCompanyName}
                                    onChange={(e: any) => {
                                        setCompanyName(e.target.value)
                                    }}
                                />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>    
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>     
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12} className={'button-flex'} style={{display:'flex', justifyContent:'right'}}>
                                <OutLineButton
                                    id={'btnUpdate'}
                                    style={{ width: '150px' }}
                                    onClick={onSaveClick}
                                    color='blue'
                                    className={'background-transaparent'}
                                    text={isAddingNew ? 'Save' : 'Update'}
                                    disabled={false}
                                />
                                <OutLineButton
                                    id={'btnDelete'}
                                    style={{ width: '150px', marginLeft: '10px' }}
                                    onClick={onDeleteClick}
                                    color='red'
                                    className={'background-transaparent'}
                                    text={isAddingNew ? 'Clear' : 'Delete'}
                                    disabled={false}
                                />
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                </>):null}
            </Grid>
        </Container>
    );
}

export default AuditorCompanyContainer
