import React, {useEffect, useState} from "react";
import {AuditorRegionValueProps, LeaveExtend} from "./DataModal";
import MsgFromAdmin from "../../../models/apiResponce/Auditor/MsgFromAdmin";
import {UpdateMsgFromAdmin} from "./ProfileController";
import {Grid} from "semantic-ui-react";
import InfoToggle from "../../../framework/widgets/InfoToggle";
import NameWithCheckBox from "../../../framework/widgets/NameWithCheckBox/NameWithCheckBox";
import AvailabiltyProps from "../../../models/apiResponce/Common/AvailablityProps";
import DateTimeBox from "../../../framework/components/TextBoxes/DateTimeBox";
import DefaultButton from "../../../framework/components/Buttons/DefaultButton";
import IconTextOutlineButton from "../../../framework/components/Buttons/IconTextOutlineButton";
import {DatesRangeInput} from "semantic-ui-calendar-react";
import moment from "moment";
import DateRangeBox from "../../../framework/components/TextBoxes/DateRangeBox";
import {getSessionCookie} from "../../../framework/common/SessionHandler";
import ErrorToast from "../../../framework/components/Toast/ErrorToast";

const Availablity = (props: AvailabiltyProps) => {
    const [state, setState] = React.useState<AuditorRegionValueProps>();
    const [nsw, setNsw] = React.useState<boolean>(false);
    const [isEdit, setIsEdit] = React.useState<boolean>(true);
    //const [ava, setAVA] = useState <MsgFromAdmin>({} as MsgFromAdmin)
    const [isConfirmAVA, setIsConfirmAVA] = React.useState(false)
    const [isConfirmAVA2, setIsConfirmAVA2] = React.useState(false)
    const [dateRange, setDateRange] = useState('')
    const [lstLeave, setLstLeave] = useState<LeaveExtend[]>()
    const session = getSessionCookie();
    const encid = new URLSearchParams(location.search).get('get')

    useEffect(() => {
        //eslint-disable-next-line
        //debugger
        Promise.resolve(props.auditor).then((value) => {
            //console.log(value)
            setState(value.auditorRegion)
            setIsEdit(encid != null && (session.userRoleId == 1 || session.userRoleId == 4) ? true : value.AVA.isActivated ? value.AVA.isActivated : value.Editable)
            props.setAVA(value.AVA);
            props. setInforToggleAvailability(props.auditor.AVA.isActivated)
            setIsConfirmAVA(value.AVA.isConfirmedByAuditor)
            setIsConfirmAVA2(value.AVA.isConfirmedByAuditor2)
        })
    }, [props.auditor])

    useEffect(() => {
        props.setStateRegion(state);
        if(state != undefined && (state.greateSyndey == true || state.west == true || state.south == true || state.north == true)){
            setNsw(true)
        }else{
            setNsw(false)
        }
    }, [state])

    const onAVAChange = async(id:number, e:any, data:any, sectionName:string) => {
        console.log(isConfirmAVA)
        setIsConfirmAVA(!isConfirmAVA);
        await UpdateMsgFromAdmin(id,!isConfirmAVA, sectionName)
    }

    const onAVAChange2 = async(id:number, e:any, data:any, sectionName:string) => {
        setIsConfirmAVA2(!isConfirmAVA2);
        await UpdateMsgFromAdmin(id,!isConfirmAVA2, sectionName)
    }

    const onLostFocusAvailablity = async () => {
        props.setFocusMode("availablity")
    }

    const onAVAAdminBoxChange = (e:any) => {
        props.setAVA({...props.ava,
            isActivated:props.inforToggleAvailability,
            Type: props.ava.Type ? props.ava.Type : 'AdminInfoToggle',
            msgFromAdmin: e != '' ? e.target.value : '',
            auditorId:parseInt(atob(encid ? encid : '')) ,
        })
    }

    return (
        <React.Fragment>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        {props.ava.isActivated && props.inforToggleAvailability ? (<InfoToggle
                            type={props.ava.Type}
                            msg={props.ava.msgFromAdmin}
                            update={props.ava.update}
                            msg2={props.ava.msgFromAdmin2 ? props.ava.msgFromAdmin2 : ""}
                            update2={props.ava.update2}
                            id={'chkAdminConformAVA'}
                            id2={'chkAdminConformAVA2'}
                            value={isConfirmAVA}
                            value2={isConfirmAVA2}
                            onChange={(e: any, data: any)=>{
                                onAVAChange(props.ava.id,e,data, props.ava.sectionName )
                            }}
                            onChange2={(e: any, data: any)=>{
                                onAVAChange2(props.ava.id2 ? props.ava.id2 : 0,e,data,props.ava.sectionName )
                            }}
                            createDate={props.ava.createDate}
                            createDate2={props.ava.createDate2}
                            responceDate={props.ava.ResponceDate}
                            responceDate2={props.ava.ResponceDate2}
                            onMsgBoxChange={onAVAAdminBoxChange}
                            isConfirmedByAdminOnDate={props.ava.isConfirmedByAdminOnDate}
                        />):null}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <span style={{display: 'inline-flex'}} ><b>Regions Available</b></span>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <p style={{display: 'inline-flex'}}>Select relevant region where you are available to perform
                            Road Safety Audits</p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={5} mobile={8} widescreen={5}>
                        <NameWithCheckBox label={'New South Wales'} name={'state'} id={'nsw'} value={nsw}
                                          onChange={() => {
                                              setNsw(!nsw);
                                              setState({...state, greateSyndey: !nsw, west: !nsw, south: !nsw, north: !nsw});
                                              onLostFocusAvailablity()
                                          }} disabled={!isEdit}/>
                        <Grid.Row columns={1}>
                            <Grid.Column style={{paddingLeft: '50px'}}>
                                <NameWithCheckBox label={'Greater Sydney'} name={'state'} id={'grate'}
                                                  value={state?.greateSyndey} onChange={(e: any, data: any) => {
                                    setState({...state, greateSyndey: !state?.greateSyndey});
                                    onLostFocusAvailablity()
                                }} disabled={!isEdit}/>
                            </Grid.Column>
                            <Grid.Column style={{paddingLeft: '50px'}}>
                                <NameWithCheckBox label={'North'} name={'state'} id={'north'} value={state?.north}
                                                  onChange={(e: any, data: any) => {
                                                      setState({...state, north: !state?.north});
                                                      onLostFocusAvailablity()
                                                  }} disabled={!isEdit}/>
                            </Grid.Column>
                            <Grid.Column style={{paddingLeft: '50px'}}>
                                <NameWithCheckBox label={'South'} name={'state'} id={'south'} value={state?.south}
                                                  onChange={(e: any, data: any) => {
                                                      setState({...state, south: !state?.south});
                                                      onLostFocusAvailablity()
                                                  }} disabled={!isEdit}/>
                            </Grid.Column>
                            <Grid.Column style={{paddingLeft: '50px'}}>
                                <NameWithCheckBox label={'West'} name={'state'} id={'west'} value={state?.west}
                                                  onChange={(e: any, data: any) => {
                                                      setState({...state, west: !state?.west});
                                                      onLostFocusAvailablity()
                                                  }} disabled={!isEdit}/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={5} mobile={8} widescreen={5}>
                        <NameWithCheckBox label={'Victoria'} name={'state'} id={'victoria'} value={state?.vic}
                                          onChange={(e: any, data: any) => {
                                              setState({...state, vic: !state?.vic});
                                              onLostFocusAvailablity()
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} mobile={8} widescreen={5}>
                        <NameWithCheckBox label={'Western Australia'} name={'state'} id={'wa'} value={state?.wa}
                                          onChange={(e: any, data: any) => {
                                              setState({...state, wa: !state?.wa});
                                              onLostFocusAvailablity()
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={5} mobile={8} widescreen={5}>
                        <NameWithCheckBox label={'Australia Capital Region'} name={'state'} id={'act'}
                                          value={state?.act} onChange={(e: any, data: any) => {
                            setState({...state, act: !state?.act});
                            onLostFocusAvailablity()
                        }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} mobile={8} widescreen={5}>
                        <NameWithCheckBox label={'Queensland'} name={'state'} id={'qld'} value={state?.qld}
                                          onChange={(e: any, data: any) => {
                                              setState({...state, qld: !state?.qld});
                                              onLostFocusAvailablity()
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} mobile={8} widescreen={5}>
                        <NameWithCheckBox label={'South Australia'} name={'state'} id={'sa'} value={state?.sa}
                                          onChange={(e: any, data: any) => {
                                              setState({...state, sa: !state?.sa});
                                              onLostFocusAvailablity()
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={5} mobile={8} widescreen={5}>
                        <NameWithCheckBox label={'Tasmania'} name={'state'} id={'tas'} value={state?.tas}
                                          onChange={(e: any, data: any) => {
                                              setState({...state, tas: !state?.tas});
                                              onLostFocusAvailablity()
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} mobile={8} widescreen={5}>
                        <NameWithCheckBox label={'Northern Territory'} name={'state'} id={'nt'} value={state?.nt}
                                          onChange={(e: any, data: any) => {
                                              setState({...state, nt: !state?.nt});
                                              onLostFocusAvailablity()
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} mobile={8} widescreen={5}>
                        <NameWithCheckBox label={'Overseas'} name={'state'} id={'os'} value={state?.overseas}
                                          onChange={(e: any, data: any) => {
                                              console.log(data);
                                              setState({...state, overseas: !state?.overseas});
                                              onLostFocusAvailablity()
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                </Grid.Row>
                {(session.status == 3 || session.status == 4) || (session.userRoleId == 1 || session.userRoleId == 4)  ? (<><Grid.Row>
                    <Grid.Column width={16}>
                        <span style={{display: 'inline-flex'}}><b>Extended Leave</b></span>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <p style={{display: 'inline-flex'}}>Enter the dates for any extended period that you are
                            unavailable to perform Road Safety Audits (e.g. long service leave, maternity leave,
                            etc.)</p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <DateRangeBox
                            id={'txtFromDate'}
                            fluid={true}
                            placeholder={'From Date'}
                            onChange={(e:any,data:any) => {/*e.preventDefault();*/console.log(data.value);setDateRange(data.value)}}
                            value={dateRange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <DefaultButton id={'btnAddDate'} onClick={() => {
                            //eslint-disable-next-line
                            let tempDateRage = state?.leaveExtend
                            const dates = dateRange.split('-')
                            if(moment(dates[0],'DD/MM/YYYY').isBefore()) {
                                ErrorToast('This date must be in future !')
                                return;
                            }
                            if(moment(dates[1],'DD/MM/YYYY').isBefore(moment(dates[0],'DD/MM/YYYY'))) {
                                ErrorToast('End of your holiday date must be later than the start of your holiday date !')
                                return;
                            }
                            tempDateRage?.push({
                                leaveStateDate:moment(dates[0], "DD/MM/YYYY").toString(),
                                leaveEndDate:moment(dates[1], "DD/MM/YYYY").toString()
                            })
                            setDateRange('')
                            setLstLeave(tempDateRage)
                            setState({...state,leaveExtend:tempDateRage})
                        }} color={'black'} text={'Add Leave'}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    {
                        state?.leaveExtend?.map((r,index)=> (
                            <Grid.Column key={index}>
                                <p>{`This auditor will be unavailable from ${moment(r.leaveStateDate).format("DD/MM/YYYY")} to ${moment(r.leaveEndDate).format("DD/MM/YYYY")}`}</p>
                            </Grid.Column>
                        ))
                    }
                </Grid.Row></>):null}
                <Grid.Row></Grid.Row>
            </Grid>
            <hr/>
        </React.Fragment>
    );
}

export default Availablity