import React, {useEffect, useRef, useState} from "react";
import {Container, Grid, Table} from "semantic-ui-react";
import InnerTitleBar from "../../../../framework/layouts/InnerTitleBar";
import {userRoleSelector} from "../../../../common/roleSelector";
import {getSessionCookie} from "../../../../framework/common/SessionHandler";
import OutLineButton from "../../../../framework/components/Buttons/OutlIneButton";
import RSATable from "../../../../framework/widgets/TableWithBackgrounds/RSATable";
import TableHeader from "../../../../framework/types/TableHeader";
import tags from "../../../../models/common/tag";
import { SearchApiCallForAuditStages, SaveAuditStages, DeleteAuditStages, GetAllAuditsByAuditStageId } from "../../../search/SearchController";
import { AuditStages } from "../../../../models/apiResponce/mis/AuditStagesRes";
import SuccessToast from "../../../../framework/components/Toast/SuccessToast";
import NameWithDropDown from "../../../../framework/widgets/NameWithDropDown";
import NameWithDefault from "../../../../framework/widgets/NameWithText/NameWithDefault";
import Position from "../../../../framework/types/Position";
import { AuditStagesReq } from "../../../../models/apiRequest/mis/AuditStagesReq";
import MultilineTextBox from "../../../../framework/components/TextBoxes/MultilineTextBox";
import ErrorToast from "../../../../framework/components/Toast/ErrorToast";
import { fetchAuditPhases } from "../../../auditor/profile/ProfileController";
import DefaultButton from "../../../../framework/components/Buttons/DefaultButton";
import Modal from "../../../../framework/components/Modals";
import waringImage from "../../../../assets/images/icons/warning.png";

const AuditStagesContainer = () =>{

    const [state, setState] = React.useState<AuditStagesReq>();
    const session = getSessionCookie();
    const [auditPhaseId, setProjectPhase] = useState('0');
    const [auditStageName, setStageName] = useState('')
    const [auditDisplayName, setDisplayText] = useState('')
    const [auditStageDesc, setDescriptionText] = useState('')
    const [selectedAuditStage, setSelectAuditStage] = useState(0)
    const [isAddingNew, setIsAddingNew] = useState(false);
    const [auditPhases, setAuditPhases] = useState<any>()
    const [delAuditStage, setDelAuditStage] = useState(0); 
    const [open2, setOpen2] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const contentRef = useRef<HTMLDivElement>(null);
    const rsaEmailChangeHead: TableHeader[] = [
        {
            text: "Stage name"
        },
        {
            text: "Display text"
        },
        {
            text: ""
        }
    ];
    const [rsaEmailChange, setRSAEmailChange] = useState<object[]>([
    ])



    const SetValues = (data:AuditStages[]) => {
        const TableRows: object[] = [];
        data.map((r:AuditStages) => {
            TableRows.push([{
                    tag: tags.text,
                    text: `${r.auditStageName}`,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: `${r.auditDisplayName}`,
                    icon: "",
                    onclick: null
                },
                {
                    tag: "icon-fa",
                    text: "Select",
                    icon: "view",
                    onclick:() => {
                        setSelectAuditStage(r.auditStageId);
                        setProjectPhase(r.auditPhaseId?.toString() || '');
                        setStageName(r.auditStageName || '');
                        setDisplayText(r.auditDisplayName || '');
                        setDescriptionText(r.auditStageDesc || '');
                        setState(r);
                        setIsAddingNew(false)
                        contentRef.current?.scrollIntoView({ behavior: 'smooth' });
                    }
                }])
        })
        setRSAEmailChange(TableRows);
    }

    useEffect(() => {
        try {
            const auditPhasesArray = fetchAuditPhases();
            Promise.resolve(auditPhasesArray).then((value) => {
                console.log(value)
                setAuditPhases(value)
            })

            const auditStages = SearchApiCallForAuditStages();
            Promise.resolve(auditStages).then((value) => {
                if (value && value.auditStages) {
                    SetValues(value.auditStages);
                }
            });
        } catch (error) {
            // Handle the error here
            ErrorToast('Error fetching audit results');
        }
        
    }, []);

    const resetFormFields = () => {
        setProjectPhase('0');
        setStageName('');
        setDisplayText('');
        setDescriptionText('');
        setSelectAuditStage(0);
        setState(undefined); // Reset the state
    };

    const onAddNewClick = () => {
        resetFormFields();
        setIsAddingNew(true);
        setErrorMessage('');
    };

    const onSaveClick = async () => {
        try {
            if (isAddingNew) {
                // Add new
                const newData: AuditStagesReq = {
                    auditStageId: 0,
                    auditPhaseId: parseInt(auditPhaseId) || 0,
                    auditStageName: auditStageName || '',
                    auditDisplayName: auditDisplayName || '',
                    auditStageDesc: auditStageDesc || '',
                };
                const result = await SaveAuditStages(newData);
                if (result) {
                    SuccessToast('Audit stage added successfully');
                    setTimeout(() => {
                        // Reload the page
                        window.location.reload();
                    }, 2000); // 2000 milliseconds delay (2 seconds)
                }
            } else {
                // Update
                const updatedData: AuditStagesReq = {
                    ...state!,
                    auditPhaseId: parseInt(auditPhaseId) || 0,
                    auditStageName: auditStageName || '',
                    auditDisplayName: auditDisplayName || '',
                    auditStageDesc: auditStageDesc || '',
                };
                const result = await SaveAuditStages(updatedData);
                if (result) {
                    SuccessToast('Audit stage updated successfully');
                    setTimeout(() => {
                        // Reload the page
                        window.location.reload();
                    }, 2000); // 2000 milliseconds delay (2 seconds)
                }
            }
        } catch (error) {
            // Handle the error here
            ErrorToast('Error saving audit stage');
        }
    };

    const onDeleteClick = async () => {
        try {
            // Fetch the latest audit stages
            const auditStagesRes = await GetAllAuditsByAuditStageId(selectedAuditStage);
            if (auditStagesRes.length > 0) {
                setErrorMessage("There is at least one audit using this audit stage");
                return; // Exit the function early
            } else {
                setOpen2(true);
            }
        } catch (error) {
            console.error("Error in remove audit stage:", error);
            // Handle error appropriately
        }
    };

    const deleteAuditStage = async () => {
        try{
            if (isAddingNew) {
                resetFormFields();
            }else{
                // Delete
                const result = await DeleteAuditStages(selectedAuditStage);
                if (result) {
                    SuccessToast('Audit stage deleted successfully');
                    // Reload the page
                    window.location.reload();
                }
            }
        } catch (error) {
            ErrorToast('Error deleting audit stage');
        }
        
        setOpen2(false)
    };

    const [disableSave, setDisableSave] = React.useState(false);

useEffect(() => {
        DisableValidation();
    }, [auditPhaseId, auditStageName, auditDisplayName]);

    const DisableValidation = () =>{
        const isFormInvalid = (
            !auditPhaseId ||
            !auditStageName ||
            !auditDisplayName
        );
    
        setDisableSave(isFormInvalid);
    }

    return(
        <Container fluid={true} style={{marginTop: '14px', marginBottom: '20px'}}>
            <InnerTitleBar
                title={"Edit/Del Audit Stages"}
                usreName={session.userDisplayName != null ? session.userDisplayName : "  "}
                userRole={userRoleSelector(session.userRoleId.toString())}
                lastLogin={session.lastLogin.toString()}
                status={userRoleSelector(session.userRoleId.toString())}
            />
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={12} className={'button-flex'} style={{display:'flex', justifyContent:'right', paddingRight: '0px'}}>
                                <OutLineButton
                                    id={'btnAddNew'}
                                    style={{ width: '150px' }}
                                    onClick={onAddNewClick}
                                    color='blue'
                                    className={'background-transaparent'}
                                    text={'+ Add New'}
                                    disabled={false}
                                />
                                </Grid.Column>
                                <Grid.Column>
                                
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>     
                    </Grid.Column>
                </Grid.Row>
                {rsaEmailChange.length > 0 ? (<Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <div style={{ overflow: 'auto' }}>
                            <RSATable
                                head={rsaEmailChangeHead}
                                body={rsaEmailChange}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>):null}
                {selectedAuditStage != 0 || isAddingNew ? (<>
                    <div ref={contentRef}>
                        <Grid.Row columns={3}>
                            <Grid.Column width={2}>
                            </Grid.Column>
                            <Grid.Column width={12}>
                            </Grid.Column>
                            <Grid.Column width={2}>
                            </Grid.Column>
                        </Grid.Row>
                    </div>
                    <Grid.Row columns={3}>
                            <Grid.Column width={2}>
                            </Grid.Column>
                            <Grid.Column width={12}>
                            </Grid.Column>
                            <Grid.Column width={2}>
                            </Grid.Column>
                        </Grid.Row>
                    <Grid.Row columns={3}>
                            <Grid.Column width={2}>
                            </Grid.Column>
                            <Grid.Column width={12}>
                                <hr />
                            </Grid.Column>
                            <Grid.Column width={2}>
                            </Grid.Column>
                        </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={2}>
                                    <b><span style={{display:'inline-flex'}}>Audit Stages</span></b>
                                </Grid.Column>
                                <Grid.Column>
                                    
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>     
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={8} widescreen={8} mobile={16} computer={8} tablet={16} largeScreen={16}>
                                    <NameWithDropDown
                                        selection={true}
                                        labelText={'Project phase'}
                                        labelPosition={Position.Top}
                                        id={'ddProjectPhase'}
                                        placeholder={'Select Project phase'}
                                        options={auditPhases}
                                        required={true}
                                        value={auditPhaseId?.toString()}
                                        onChange={(e:any,data:any) => {
                                            setProjectPhase(data.value)
                                        }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid> 
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={8} widescreen={8} mobile={16} computer={8} tablet={16} largeScreen={16}>
                                <NameWithDefault 
                                    labelText={'Stage name'} 
                                    labelPosition={Position.Top}
                                    id={'txtStagename'} 
                                    fluid={true} 
                                    type={'text'}
                                    placeholder={'Enter Stage name'}
                                    required={true}
                                    value={auditStageName}
                                    onChange={(e: any) => {
                                        setStageName(e.target.value)
                                    }}
                                />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>    
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={8} widescreen={8} mobile={16} computer={8} tablet={16} largeScreen={16}>
                                <NameWithDefault 
                                    labelText={'Display text'} 
                                    labelPosition={Position.Top}
                                    id={'txtisplayText'} 
                                    fluid={true} 
                                    type={'text'}
                                    placeholder={'Enter display text'}
                                    required={true}
                                    value={auditDisplayName}
                                    onChange={(e: any) => {
                                        setDisplayText(e.target.value)
                                    }}
                                />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>    
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={8} widescreen={8} mobile={16} computer={8} tablet={16} largeScreen={16}>
                                    <span>Stage Description</span>
                                <MultilineTextBox
                                    wordCount={true}
                                    fluid={true}
                                    className={'other_exp'}
                                    id={'txtDescriptionTexts'}
                                    placeholder={''}
                                    value={auditStageDesc}
                                    onChange={(e: any) => {
                                        setDescriptionText(e.target.value)
                                    }}
                                />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>    
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={8} widescreen={8} mobile={16} computer={8} tablet={16} largeScreen={16}>
                                    <div style={{color: 'red'}}>{errorMessage}</div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>    
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>     
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12} className={'button-flex'} style={{display:'flex', justifyContent:'right'}}>
                                <OutLineButton
                                    id={'btnUpdate'}
                                    style={{ width: '150px' }}
                                    onClick={onSaveClick}
                                    color='blue'
                                    className={'background-transaparent'}
                                    text={isAddingNew ? 'Save' : 'Update'}
                                    disabled={disableSave}
                                />
                                {
                                    !isAddingNew ? (
                                        <OutLineButton
                                        id={'btnDelete'}
                                        style={{ width: '150px', marginLeft: '10px' }}
                                        onClick={onDeleteClick}
                                        color='red'
                                        className={'background-transaparent'}
                                        text={isAddingNew ? 'Clear' : 'Delete'}
                                        disabled={false}
                                />
                                    ):(null)
                                }
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                </>):null}
            </Grid>
            <Modal
                    open={open2 ? open2 : false}
                    setOpen={setOpen2}
                    title={"Warning !"}
                    modalDescription={true}
                    descriptionHeader={'Are you sure you want to delete this audit stage?'}
                    descriptionBody={'This action cannot be undone.'}
                    imageUrl={waringImage}
                    imageSize='small'
                    modalAction={true}
                    ActionButtons={() => (
                        <ControllButtonRegModal
                            NoClick={() => {
                                setOpen2(false)
                                setDelAuditStage(0)
                            }}
                            YesClick={deleteAuditStage}
                        />
                    )}
                />
        </Container>
    );
}

export default AuditStagesContainer

interface ControllButtonProps {
    //eslint-disable-next-line
    NoClick: any;
    //eslint-disable-next-line
    YesClick: any;
    //eslint-disable-next-line
}

const ControllButtonRegModal = (props: ControllButtonProps) => {
    return (
        <React.Fragment>
            <OutLineButton id={'btnCancel'} style={{width: '150px'}} onClick={() => {
                props.NoClick()
            }} color={'red'} text={'No'}/>
            <DefaultButton id={'btnSave'} style={{width: '150px'}} onClick={() => {
                props.YesClick()
            }} color={'blue'}
                           text={'Yes'}/>
        </React.Fragment>
    )
}
