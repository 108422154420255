import React, {useEffect, useState} from 'react'

//Import assets
import Maintenance_bg from '../../assets/images/maintenance_bg.jpg'
import Maintenance_1 from '../../assets/images/Maintenance_1.png'
import logo from '../../assets/images/logos/logo.png'

//UI Framework
import {Grid, Image} from "semantic-ui-react";

//import Common modules
import {convertMsToTime} from '../../framework/common/SupportMobules'

interface TimeProps {
    days:number,
    hours:number,
    minutes:number,
    seconds:number
}

const Maintenance = ()=>{
    const config = window.config;
    const defineTime:string = process.env.REACT_APP_UPTIME ? process.env.REACT_APP_UPTIME: config.Uptime ? config.Uptime : '1900/01/01';
    const [time, setTime] = useState<TimeProps>({days:0,hours:0,minutes:0,seconds:0})



    setTimeout(()=>{
        //Delaration variables
        const dtDefine = new Date(defineTime)
        const dtNow = new Date();

        //Calculation
        const diff:number = dtDefine.getTime() - dtNow.getTime();

        //Set State
        setTime({
            days:convertMsToTime(diff).days,
            hours:convertMsToTime(diff).hours,
            minutes:convertMsToTime(diff).minutes,
            seconds:convertMsToTime(diff).seconds
        })
    },1000)


    return(
        <React.Fragment>
            <div className={'overlay'}>
                <Image src={logo} className={'logo'}/>
                <Image src={Maintenance_1} className={ 'main-img'}/>
                <h1 className={'textBlock'}>Coming Soon</h1>
                <p className={'subtext'}>Our website is currently under maintenance. If you have any questions regarding the Register, please contact us at: <a href="register@roadsafetyregister.com.au" target="_blank" rel="noopener noreferrer">register@roadsafetyregister.com.au</a></p>
                <Grid centered>
                    <Grid.Row centered columns={4}>
                        <Grid.Column>
                            <div className={'time-cicle'}>
                                <span className={'time-value'}>{time.days}</span>
                                <span className={'time-label'}>Days</span>
                            </div>
                        </Grid.Column>
                        <Grid.Column>
                            <div className={'time-cicle'}>
                                <span className={'time-value'}>{time.hours}</span>
                                <span className={'time-label'}>Hours</span>
                            </div>
                        </Grid.Column>
                        <Grid.Column>
                            <div className={'time-cicle'}>
                                <span className={'time-value'}>{time.minutes}</span>
                                <span className={'time-label'}>Minutes</span>
                            </div>
                        </Grid.Column>
                        <Grid.Column>
                            <div className={'time-cicle'}>
                                <span className={'time-value'}>{time.seconds}</span>
                                <span className={'time-label'}>Seconds</span>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
            <Image src={Maintenance_bg} alt={'Background Image'} className={'maintenance-bg'}/>
        </React.Fragment>
    )
}

export default Maintenance