import React, { useState} from "react";
import {Container, Grid} from "semantic-ui-react";
import InnerTitleBar from "../../../../framework/layouts/InnerTitleBar";
import {userRoleSelector} from "../../../../common/roleSelector";
import {getSessionCookie} from "../../../../framework/common/SessionHandler";
import OutLineButton from "../../../../framework/components/Buttons/OutlIneButton";
import RSATable from "../../../../framework/widgets/TableWithBackgrounds/RSATable";
import TableHeader from "../../../../framework/types/TableHeader";
import tags from "../../../../models/common/tag";
import { SearchApiCallForAdmin } from "../../../search/SearchController";
import { AdminSearchResRow } from "../../../../models/apiResponce/Auditor/SearchRes";
import { PaginationProps } from "antd/es/pagination";
import NameWithDefault from "../../../../framework/widgets/NameWithText/NameWithDefault";
import Position from "../../../../framework/types/Position";
import NameWithEmail from "../../../../framework/widgets/NameWithText/NameWithEmail";

const AdminContainer = () =>{

    const session = getSessionCookie();
    const [current, setCurrent] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0)
    const [AdminId, setAdminId] = useState('')
    const [AdminEmail, setAdminEmail] = useState('')
    const [AdminName, setAdminName] = useState('')
    const rsaAdminChangeHead: TableHeader[] = [
        {
            text: "First name"
        },
        {
            text: "Middle name"
        },
        {
            text: "Last name"
        }
        ,
        {
            text: "Company/Organisation"
        },
        {
            text: "Active"
        },
        {
            text: ""
        }
    ];
    const [rsaAdminChange, setrsaAdminChange] = useState<object[]>([
    ])



    const SetValues = (data:AdminSearchResRow[]) => {
        const TableRows: object[] = [];
        data.map((r:AdminSearchResRow) => {
            TableRows.push([{
                    tag: tags.text,
                    text: r.firstName,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.middleName,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.lastName,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: r.orgName,
                    icon: "",
                    onclick: null
                },
                {
                    tag: 'icon-fa',
                    text: 'center',
                    icon: r.isActive == true ? "check" : "minus",
                    onclick: null
                },
                {
                    tag: "icon-fa",
                    text: "Select",
                    icon: "edit",
                    onclick:() => {
                        window.location.href = `/profile/adminProfile?id=${r.userId}&type=update`
                    }
                }])
        })
        setrsaAdminChange(TableRows);
    }

    const onSearchClick = () => {
        const searchData = SearchApiCallForAdmin(AdminId, AdminName, AdminEmail, 1)
        Promise.resolve(searchData).then((value) => {
            if (value) {
                setTotalRecords(value.totalRows)
                SetValues(value.adminSearchRess)
            }
        })
    }

    const onChange: PaginationProps['onChange'] = (page) => {
        setCurrent(page);
        const searchData = SearchApiCallForAdmin(AdminId, AdminName, AdminEmail, page)
        Promise.resolve(searchData).then((value) => {
            if (value) {
                setTotalRecords(value.totalRows)
                SetValues(value.adminSearchRess)
            }
        })
    };

    return(
        <Container fluid={true} style={{marginTop: '14px', marginBottom: '20px'}}>
            <InnerTitleBar
                title={"Search for Admins"}
                usreName={session.userDisplayName != null ? session.userDisplayName : "  "}
                userRole={userRoleSelector(session.userRoleId.toString())}
                lastLogin={session.lastLogin.toString()}
                status={userRoleSelector(session.userRoleId.toString())}
            />
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>                       
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={12} mobile={16} computer={12}>
                                    <p><b> Search results : found {totalRecords} results </b></p>
                                </Grid.Column>
                                {
                                    session.userRoleId == 4 ? (
                                        <Grid.Column width={4} mobile={16} computer={4} className='button-flex'>
                                            <OutLineButton id={'btnAddAdmin'} className={"background-transaparent"}
                                                        style={{width: '200px', height:'36px'}} onClick={(e:any) => {
                                                        window.location.href = '/profile/adminProfile?id=0&type=add'
                                            }} color={'blue'} text={'Add Admin'}/>
                                        </Grid.Column>
                                    ) : (null)
                                }
                                
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2}>
                        
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <NameWithDefault
                                        labelText={'Admin ID:'}
                                        fluid={true}
                                        labelPosition={Position.Top}
                                        id={'txtAdminID'}
                                        type={'text'}
                                        required={false}
                                        placeholder={'Enter Admin ID'}
                                        value={AdminId}
                                        onChange={(e: any) => {
                                            setAdminId(e.target.value)
                                        }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>  
                    </Grid.Column>
                    <Grid.Column width={6}>  
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <NameWithEmail
                                        id={'txtEmail'}
                                        fluid={true}
                                        placeholder={'Enter Email address'}
                                        value={AdminEmail}
                                        onChange={(e: any) => {
                                            setAdminEmail(e.target.value);
                                        } } 
                                        labelText={"Email Address:"} 
                                        labelPosition={Position.Top} 
                                        type={"email"}                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>                       
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <NameWithDefault
                                        id={'txtName'}
                                        fluid={true}
                                        placeholder={'Enter Name'}
                                        value={AdminName}
                                        onChange={(e: any) => {
                                            setAdminName(e.target.value);
                                        } } 
                                        labelText={"Name:"} 
                                        labelPosition={Position.Top} 
                                        type={"text"}                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>                       
                    </Grid.Column>
                    <Grid.Column width={6}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12} className={'button-flex'} style={{display:'flex', justifyContent:'right'}}>
                        <OutLineButton id={'btnSave'} 
                            style={{width: '150px'}} 
                            onClick={() => {
                                onSearchClick()
                            }} 
                            color='blue' 
                            className={"background-transaparent"}
                            text={'Search'}
                            disabled={false}
                        />
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                {rsaAdminChange.length > 0 ? (<Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <div style={{ overflow: 'auto' }}>
                            <RSATable
                                head={rsaAdminChangeHead}
                                body={rsaAdminChange}
                                current={current}
                                pageCount={totalRecords/10}
                                onChange={onChange}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>):null}
            </Grid>
        </Container>
    );
}

export default AdminContainer
