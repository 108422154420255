import React, {useEffect} from "react";
import Header from "../../../../framework/layouts/Header";
import Footer from "../../../../framework/layouts/Footer";
import LoginActivityReportContainer from "./LoginActivityReportContainer";


const LoginActivity = () => {
    return(
        <React.Fragment>
            <Header>
                <LoginActivityReportContainer/>
            </Header>
            <Footer/>
        </React.Fragment>
    );
}

export default LoginActivity