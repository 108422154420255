import React, {useEffect, useState} from "react";
import {Container, Grid, Table} from "semantic-ui-react";
import InnerTitleBar from "../../../../framework/layouts/InnerTitleBar";
import {userRoleSelector} from "../../../../common/roleSelector";
import {getSessionCookie} from "../../../../framework/common/SessionHandler";
import OutLineButton from "../../../../framework/components/Buttons/OutlIneButton";
import RSATable from "../../../../framework/widgets/TableWithBackgrounds/RSATable";
import TableHeader from "../../../../framework/types/TableHeader";
import tags from "../../../../models/common/tag";
import { SearchApiCallForAuditStages, SaveAuditStages, DeleteAuditStages, GetAllAuditsByAuditStageId, SearchApiCallForAuditorProfEnablingLevelStates, SaveAuditorProfileEnablingLevelStates } from "../../../search/SearchController";
import { AuditStages } from "../../../../models/apiResponce/mis/AuditStagesRes";
import SuccessToast from "../../../../framework/components/Toast/SuccessToast";
import NameWithDropDown from "../../../../framework/widgets/NameWithDropDown";
import NameWithDefault from "../../../../framework/widgets/NameWithText/NameWithDefault";
import Position from "../../../../framework/types/Position";
import { AuditStagesReq, AuditorProfEnablingLevelReq } from "../../../../models/apiRequest/mis/AuditStagesReq";
import MultilineTextBox from "../../../../framework/components/TextBoxes/MultilineTextBox";
import ErrorToast from "../../../../framework/components/Toast/ErrorToast";
import { fetchAuditPhases, fetchAuditorProfileEnablingLevels } from "../../../auditor/profile/ProfileController";
import DefaultButton from "../../../../framework/components/Buttons/DefaultButton";
import Modal from "../../../../framework/components/Modals";
import waringImage from "../../../../assets/images/icons/warning.png";
import Dropdowns from "../../../../framework/components/DropDowns";

const UnlockAuditorProfileContainer = () =>{

    const [auditorUnlockStates, setAuditorUnlockStates] = React.useState<AuditorProfEnablingLevelReq[]>();
    const session = getSessionCookie();
    const [auditorProfileEnablingLevels, setAuditorProfileEnablingLevels] = useState<any>()
    const [auditorProfileEnablingLevelsDesc, setAuditorProfileEnablingLevelsDesc] = useState<any>()

    useEffect(() => {
        try {
            const auditPhasesArray = fetchAuditorProfileEnablingLevels();
            Promise.resolve(auditPhasesArray).then((value) => {
                console.log(value)
                setAuditorProfileEnablingLevels(value.auditorProfileEnablingLevelArray)
                setAuditorProfileEnablingLevelsDesc(value.objAuditorProfEnablingLevels)
            })

            const result = SearchApiCallForAuditorProfEnablingLevelStates();
            Promise.resolve(result).then((value) => {
                if (value && value.auditorUnlockStates) {
                    setAuditorUnlockStates(value.auditorUnlockStates)
                }
            });
        } catch (error) {
            // Handle the error here
            ErrorToast('Error fetching audit results');
        }
        
    }, []);

    const handleDropdownChange = (index:any, value:any) => {
        const updatedStates = [...(auditorUnlockStates || [])];
        updatedStates[index].enablingLevelId = value;
        setAuditorUnlockStates(updatedStates);
    };

    const onSaveClick = async () => {
        if (!auditorUnlockStates || auditorUnlockStates.length === 0) {
            ErrorToast('No auditor profile states to save.');
            return;
        }        

        const updatePromises = auditorUnlockStates?.map(async (state) => {
            const updateData = {
                id: state.id,
                countryStateId: state.countryStateId,
                enablingLevelId: state.enablingLevelId,
                displayOrder: state.displayOrder
            };
    
            try {
                await SaveAuditorProfileEnablingLevelStates(updateData);
            } catch (error) {
                console.error('Failed to update auditor profile state', state.id, error);
                throw error; // Re-throw the error to be caught by the Promise.allSettled
            }
            
        });
        try {
            const results = await Promise.allSettled(updatePromises);
            const failedUpdates = results.filter(result => result.status === 'rejected');

            if (failedUpdates.length > 0) {
                ErrorToast(`Failed to update ${failedUpdates.length} auditor profile state(s).`);
            } else {
                SuccessToast('All auditor profile states updated successfully.');
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            }

        } catch (error) {
            ErrorToast('An error occurred while saving updates.');
            console.error('Error saving auditor profile states:', error);
        }
            
    };

    return(
        <Container fluid={true} style={{marginTop: '14px', marginBottom: '20px'}}>
            <InnerTitleBar
                title={"Unlock Auditor Profile"}
                usreName={session.userDisplayName != null ? session.userDisplayName : "  "}
                userRole={userRoleSelector(session.userRoleId.toString())}
                lastLogin={session.lastLogin.toString()}
                status={userRoleSelector(session.userRoleId.toString())}
            />
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                            <hr/>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={12}>
                                    <h2>Options and Descriptions:</h2>
                                    {auditorProfileEnablingLevelsDesc?.map((data:any, index:any) => (
                                        <div key={data.auditprofileenablingLevelId}>{data.auditprofileenablingLevelName} - {data.auditprofileenablingLevelDesc}</div>
                                    ))}
                                </Grid.Column>
                                <Grid.Column>
                                    
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>     
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={6}>

                                </Grid.Column>
                                <Grid.Column>
                                    
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>     
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={6}>

                                </Grid.Column>
                                <Grid.Column>
                                    
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>     
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        {auditorUnlockStates?.map((state, index) => (
                            <Grid key={state.id}>
                                <Grid.Row columns={2}>
                                    <Grid.Column width={2} widescreen={2} mobile={2} computer={2} tablet={2} largeScreen={2}>

                                    </Grid.Column>
                                    <Grid.Column width={4} widescreen={4} mobile={4} computer={4} tablet={4} largeScreen={4}>
                                        <label>{state.countryState}</label>
                                    </Grid.Column>
                                    <Grid.Column width={6} widescreen={6} mobile={6} computer={6} tablet={6} largeScreen={6}>
                                        <Dropdowns   
                                            selection={true}
                                            fluid={true}
                                            clearable={true}
                                            id={'ddEnablngLevel'}
                                            options={auditorProfileEnablingLevels}
                                            value={state.enablingLevelId.toString()}
                                            onChange={(e: any, data: any) => {
                                                handleDropdownChange(index, data.value)
                                            }}
                                            placeholder={'Select Auditor Profile Enabling Level'}                          
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={2} widescreen={2} mobile={2} computer={2} tablet={2} largeScreen={2}>

                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        ))}
                    </Grid.Column>
                </Grid.Row>           
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12} className={'button-flex'} style={{display:'flex', justifyContent:'right'}}>
                                <OutLineButton
                                    id={'btnUpdate'}
                                    style={{ width: '150px' }}
                                    onClick={onSaveClick}
                                    color='blue'
                                    className={'background-transaparent'}
                                    text={'Update'}
                                    disabled={false}
                                />
                                <OutLineButton
                                    id={'btnDelete'}
                                    style={{ width: '150px', marginLeft: '10px' }}
                                    onClick={null}
                                    color='red'
                                    className={'background-transaparent'}
                                    text={'Cancel'}
                                    disabled={false}
                                />
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
}

export default UnlockAuditorProfileContainer

interface ControllButtonProps {
    //eslint-disable-next-line
    NoClick: any;
    //eslint-disable-next-line
    YesClick: any;
    //eslint-disable-next-line
}

const ControllButtonRegModal = (props: ControllButtonProps) => {
    return (
        <React.Fragment>
            <OutLineButton id={'btnCancel'} style={{width: '150px'}} onClick={() => {
                props.NoClick()
            }} color={'red'} text={'No'}/>
            <DefaultButton id={'btnSave'} style={{width: '150px'}} onClick={() => {
                props.YesClick()
            }} color={'blue'}
                           text={'Yes'}/>
        </React.Fragment>
    )
}
