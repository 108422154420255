import {Grid, Icon, Popup} from "semantic-ui-react";
import React, {useEffect, useState} from "react";
import DropDownOptions from "../../../framework/types/DropDownOptions";
import OutLineButton from "../../../framework/components/Buttons/OutlIneButton";
import Dropdowns from "../../../framework/components/DropDowns";
import MultilineTextBox from "../../../framework/components/TextBoxes/MultilineTextBox";
import {
    AddCommentToAssessior,
    AddOrUpdateAssessorToAuditor, deleteAssignAssessors, fetchAdminAddComment,
    fetchAssessors,
    fetchAssignAssessors, fetchAuditor
} from "./ProfileController";
import RSATable from "../../../framework/widgets/TableWithBackgrounds/RSATable";
import TableHeader from "../../../framework/types/TableHeader";
import {
    AdminAddCommenttoAssessorReq, AdminAddedCommenttoAssessorRes,
    AssessorAssignReq,
    AssignAssessors
} from "../../../models/apiResponce/Auditor/AssignAssessors";
import {getSessionCookie} from "../../../framework/common/SessionHandler";
import {userRoleSelector} from "../../../common/roleSelector";
import moment from "moment";
import {PaginationProps} from "antd";
import eligibilityCheck, {IEligibilityCheck} from "../../../common/eligibilityCheck";
import Modal from "../../../framework/components/Modals";
import waringImage from "../../../assets/images/icons/warning.png";
import DefaultButton from "../../../framework/components/Buttons/DefaultButton";
import SuccessToast from "../../../framework/components/Toast/SuccessToast";
import ErrorToast from "../../../framework/components/Toast/ErrorToast";
import { DeleteAuditStages } from "../../search/SearchController";

const AccreditationAssessment = ({ updateAuditorInfo }: { updateAuditorInfo: (auditor: any) => void }) => {
    const encid = new URLSearchParams(location.search).get('get')
    const [assessor, setAssessors] = React.useState<DropDownOptions[]>([]);
    const [assessorValue, setAssessorValue] = React.useState('');
    const [assignAssessor, setAssignAssessors] = React.useState<DropDownOptions[]>([]);
    const [assignAssessorValue, setAssignAssessorValue] = React.useState('');
    const [AssessorDet, setAssessorDet] = useState<object[]>([])
    const [AssessomentDet, setAssessomentDet] = useState<object[]>([])
    const [txtComment, setTxtComment] = useState('')
    const [totalPages,setTotalPages] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const AddAssessorHead: TableHeader[] = [
        {
            text: "Assessors"
        },
        {
            text: "Level"
        },
        {
            text: "Status"
        },
        {
            text: " "
        }
    ];
    const AssessmentHistoryHead: TableHeader[] = [
        {
            text: "Date/Time"
        },
        {
            text: "Sent by"
        },
        {
            text: "Sent to"
        },
        {
            text: "Role"
        },
        {
            text: "Level"
        },
        {
            text: "Comments"
        }
    ];
    // const getAuditor:any = null;
    const [auditor, setAuditor] = useState<any>();
    const [isDisable, SetIsDisable] = useState(false)
    const [eligibility, setEligibility]= useState<IEligibilityCheck>({CourseSuccess:false, AuditSuccess:false,experianceSuccess:false})
    const [error, setError] = useState('')
    const [open2, setOpen2] = useState(false);
    const [delAssignAssessorId, setDelAssignAssessorId] = useState(0); 

    const Session = getSessionCookie();

    const SetValuesAssessmentHistory = (auditor:any, Assesment:AdminAddedCommenttoAssessorRes[]) => {
        const TableRows: object[] = [];
        Assesment.map((raw: AdminAddedCommenttoAssessorRes) => {
            TableRows.push([
                {
                    tag: "text",
                    text: moment(raw.createDate).format("DD-MM-YYYY hh:mm:ss A"),
                    icon: "",
                    onclick: null
                },
                {
                    tag: "text",
                    text: raw.sentBy,
                    icon: "",
                    onclick: null
                },
                {
                    tag: "text",
                    text: raw.sentTo,
                    icon: "",
                    onclick: null
                },
                {
                    tag: "text",
                    text: userRoleSelector(raw.role.toString()),
                    icon: "",
                    onclick: null
                },
                {
                    tag: "text",
                    text: raw.proposedLevel,
                    icon: "",
                    onclick: null
                },
                {
                    tag: "text",
                    text: raw.commentDesc,
                    icon: "",
                    onclick: null
                }
            ])})
        console.log(TableRows)
        setAssessomentDet(TableRows);
    }

    useEffect(() => {
        console.log(AssessomentDet); // This will log the updated state after `AssessomentDet` changes.
        console.log(Array.isArray(AssessomentDet), AssessomentDet.length);

    }, [AssessomentDet]); // Adding `AssessomentDet` as a dependency, so the effect runs whenever it changes.
    

    const SetValues = (Assessors:AssignAssessors[]) => {
        const TableRows: object[] = [];
        Assessors.map((raw: AssignAssessors) => {
            TableRows.push([
                {
                    tag: "text",
                    text: raw.assessor,
                    icon: "",
                    onclick: null
                },
                {
                    tag: "text",
                    text: raw.proposedLevel > 0 ? raw.proposedLevel : '',
                    icon: "",
                    onclick: null
                },
                {
                    tag: "text",
                    text: getAssessorAssessmentStatus(raw.statusId),//raw.assessorAssessmentStatus,
                    icon: "",
                    onclick: null
                },
                raw.statusId != 200 ?
                {
                    tag: "icon-fa",
                    text: "Remove",
                    icon: "delete",
                    onclick: () => {promptDeleteAssignAssessor(raw.id)}
                }:{
                    tag: "icon-fa",
                    text: "center",
                    icon: "",
                    onclick: null
                }
            ])})
        setAssessorDet(TableRows);
    }

    const promptDeleteAssignAssessor = async (id: number) => {
        setDelAssignAssessorId(id);
        setOpen2(true);
    };

    useEffect(() => {
        fetchAssessorsFromDb();
        if (encid != null) {
            const id = atob(encid);
            fetchAuditor(parseInt(id), 0)
                .then((auditor: any) => {
                    setAuditor(auditor);
                    if (auditor.AD && auditor.AD.isActivated ||
                        auditor.BD && auditor.BD.isActivated ||
                        auditor.AVA && auditor.AVA.isActivated ||
                        auditor.QE && auditor.QE.isActivated ||
                        auditor.RSA && auditor.RSA.isActivated ||
                        auditor.RSC && auditor.RSC.isActivated) {
                        SetIsDisable(true);
                    }
                    console.log(auditor)
                    // Call fetchAdminAdd only after fetchAuditor has completed successfully
                    fetchAdminAdd(auditor);
                })
                .catch((error) => {
                    // Handle any errors that occur during the fetchAuditor call
                    console.error("Failed to fetch auditor data:", error);
                });
        }
    }, [encid]);

    const fetchAdminAdd = (auditor:any, pageNo?:number) => {
        console.log(auditor)
        if(encid) {
            const id = atob(encid)
            const getAssesmentHistry = fetchAdminAddComment(parseInt(id), pageNo ? pageNo : 1)
            console.log(getAssesmentHistry)
            Promise.resolve(getAssesmentHistry).then((r) => {
                setTotalPages(r.totalRecords)
                SetValuesAssessmentHistory(auditor, r.objAssessors)
            })
        }
    }

    const fetchAssessorsFromDb = () => {
        const getAssessors = fetchAssessors();
        Promise.resolve(getAssessors).then((getAssessorsvalue)=>{
            if(getAssessorsvalue){
                setAssessors(getAssessorsvalue)
                if(encid){
                    const id = atob(encid)
                    const getAssignAssessors = fetchAssignAssessors(parseInt(id))
                    console.group('getAssignAssessors', getAssignAssessors)
                    Promise.resolve(getAssignAssessors).then((value)=>{
                        if(value.length > 0){
                            SetValues(value)
                            const ddAssignAssessors: DropDownOptions[] = []; //array to store the titles list [{"key": 1,"value": "text"}, etc]
                            //loop through the result and push the titleId and titleName to the array according to dropdown option format

                            ddAssignAssessors.push({key: 0, value: "0", text:"Select All Assign Assessors"})
                            let tempAssessors = getAssessorsvalue
                            value.map((item: AssignAssessors) => {
                                tempAssessors = tempAssessors.filter(p => p.text != item.assessor)
                                ddAssignAssessors.push({key: item.assessorUserId, value: item.assessorUserId.toString(), text: item.assessor})
                            })
                            setAssessors(tempAssessors)
                            setAssignAssessors(ddAssignAssessors)
                        }
                    })
                }
            }
        })
    }

    const getAssessorAssessmentStatus = ( StatusId:number ) => {
            switch(StatusId){
                case 10:
                    return 'Assigned to assessor';
                    break;
                case 20:
                    return 'Assigned to assessor';
                    break;
                case 30:
                    return 'In progress';
                    break;
                case 40:
                    return 'Flagged';
                    break;
                case 200:
                    return 'Completed';
                    break;
                default:
                    return '';
                    break;
            }
    }

    const assignAssessors = async(isSingle:boolean) => {
        const id = atob(encid ? encid : '0')
        const isEligible = await eligibilityCheck( auditor.personalDetails.txtApplyLevel,id)
        if (!isEligible.CourseSuccess || !isEligible.AuditSuccess || !isEligible.experianceSuccess) {
            setEligibility(isEligible)
            setError('Eligibility not met for the requested Level, <a href="/faqs" target="_blank" style="text-decoration: underline; cursor: pointer; color: red;">please refer to FAQs.</a>')
        } else {
            setError('')
            setEligibility(isEligible)
            const assessor:AssessorAssignReq = {
                assessorId : isSingle ? parseInt(assessorValue) : 0,
                assignByUserId : Session.userId,
                countryStateId : 1,
                auditorId : encid ? parseInt(atob(encid)) : 0
            }
            const assign = AddOrUpdateAssessorToAuditor(assessor)
            Promise.resolve(assign).then(()=>{
                callApi();
                fetchAssessorsFromDb();
            })
        }


    }
    const callApi = () => {
        const reload = fetchAuditor(encid ? parseInt(atob(encid)) : 0, 0)
        Promise.resolve(reload).then((value: any) => {
            console.log(value)
            updateAuditorInfo(value)

        })
    }

    const addCommentToAssessorfromAdmin = async () => {
        
        if(assignAssessorValue != '0'){
            console.log('assignAssessorValue', assignAssessorValue)
            const data: AdminAddCommenttoAssessorReq = {
                userId :  Session.userId,
                assessorId : parseInt(assignAssessorValue),
                commentDesc : txtComment,
                auditorId : encid ? parseInt(atob(encid)) : 0,
                assessmentHistoryId : 0
            }
            const addComment = await AddCommentToAssessior(data)
        }else{
            for (const a of assignAssessor) {
                if(a.value != '0') {
                    const data: AdminAddCommenttoAssessorReq = {
                        userId: Session.userId,
                        assessorId: parseInt(a.value),
                        commentDesc: txtComment,
                        auditorId: encid ? parseInt(atob(encid)) : 0,
                        assessmentHistoryId: 0
                    }
                    const addComment = await AddCommentToAssessior(data)
                }
            }
        }
        setTxtComment('')

    }

    const onChange: PaginationProps['onChange'] = (page) => {
        fetchAdminAdd(auditor, page)
        setCurrentPage(page);
    };

    const removeAssignedAssessor = async (assignedAssessorId: any) => {
        try{
            // Delete
            if(encid){
                const id = atob(encid)
                const result = await deleteAssignAssessors(parseInt(id), parseInt(assignedAssessorId));
                if (result) {
                    SuccessToast('Assigned assessor deleted successfully');
                    fetchAssessorsFromDb();
                    SetIsDisable(false);
                    // Reload the page
                    //window.location.reload();
                }
            }
        } catch (error) {
            ErrorToast('Error deleting audit assigned assesor');
        }
        setOpen2(false)
    }

    return(
        <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row columns={2}>
                <Grid.Column>
                    <Grid>
                        { auditor && auditor.personalDetails.AuditorsStateRes?.auditorStatusId != 3 ? (
                            <Grid.Row columns={3} >
                            <Grid.Column width={8} mobile={16} widescreen={8} tablet={16} computer={8}>
                                <Dropdowns   selection={true}
                                                fluid={true}
                                                    id={'ddAssessor'}
                                                    options={assessor}
                                                    value={assessorValue}
                                                    onChange={(e: any, data: any) => {
                                                        setAssessorValue(data.value)
                                                    }}
                                                    placeholder={'Select Assessor'}
                                             disabled={assessor.length <= 0 ? true : isDisable}
                                />
                            </Grid.Column>
                            <Grid.Column width={4} mobile={16} widescreen={4} tablet={8} computer={4}>
                                <div className={'button-flex'}>
                                    <OutLineButton id={'btnAssignAll'} style={{width: '150px'}} onClick={() => {
                                        assignAssessors(false)
                                    }} color='blue' className={"background-transaparent"} text={'Assign All'} disabled={assessor.length <= 0 ? true : isDisable} />
                                </div>
                            </Grid.Column>
                            <Grid.Column width={4} mobile={16} widescreen={4} tablet={8} computer={4}>
                                <div className={'button-flex'}>
                                    <OutLineButton id={'btnAssign'} style={{width: '150px'}} onClick={() => {
                                        assignAssessors(true)
                                    }} color='blue' className={"background-transaparent"} text={'Assign'} disabled={assessor.length <= 0 ? true : isDisable} />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        ) : null}
                        
                        {error && error.length > 0 ? (<Grid.Row>
                            <Grid.Column width={16} tablet={16} computer={16} mobile={16}>
                                <Popup
                                    flowing
                                    hoverable
                                    trigger={
                                        <p className='email-validation'>Eligibility not met for the requested Level, <a href="/faqs" target="_blank" style={{textDecoration: 'underline', cursor: 'pointer', color: 'red'}}>please refer to FAQs.</a></p>
                                    }
                                >
                                    <Popup.Header>Eligibility Details</Popup.Header>
                                    <Popup.Content>
                                        <Grid>
                                            <Grid.Row columns={2}>
                                            </Grid.Row>
                                            <Grid.Row columns={2}>
                                                <Grid.Column>
                                                    <p>Courses</p>
                                                </Grid.Column>
                                                <Grid.Column>
                                                    {eligibility.CourseSuccess ? <Icon color={"green"}  name='check circle' /> : <Icon color={"red"}  name='times circle' />}
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row columns={2}>
                                                <Grid.Column>
                                                    <p>Audits</p>
                                                </Grid.Column>
                                                <Grid.Column>
                                                    {eligibility.AuditSuccess ? <Icon color={"green"}  name='check circle' /> : <Icon color={"red"}  name='times circle' />}
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row columns={2}>
                                                <Grid.Column>
                                                    <p>Experiance</p>
                                                </Grid.Column>
                                                <Grid.Column>
                                                    {eligibility.experianceSuccess ? <Icon color={"green"}  name='check circle' /> : <Icon color={"red"}  name='times circle' />}
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Popup.Content>
                                </Popup>
                            </Grid.Column>

                        </Grid.Row>) : null }
                    </Grid>
                </Grid.Column>
                <Grid.Column></Grid.Column>
            </Grid.Row>
            <Grid.Row>
               <Grid.Column>
                   {AssessorDet.length > 0 ?  (<div style={{overflow: 'auto'}}>
                       <RSATable
                           head={AddAssessorHead}
                           body={AssessorDet}
                       />
                   </div>): "No assessors assigned"}
               </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <h2 style={{display: 'inline-flex'}}>Message Assessors</h2>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
                <Grid.Column>
                    <Dropdowns   selection={true}
                                 fluid={true}
                                 id={'ddMsgAssessor'}
                                 options={assignAssessor}
                                 value={assignAssessorValue}
                                 onChange={(e: any, data: any) => {
                                     setAssignAssessorValue(data.value)
                                 }}
                                 placeholder={'Select Assign Assessors'}
                    />
                </Grid.Column>
                <Grid.Column></Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
                <Grid.Column>
                    <MultilineTextBox
                        wordCount={true}
                         fluid={true}
                        className={'other_exp'}
                         id={'txtMessageToAssessors'}
                         placeholder={''}
                        value={txtComment}
                        onChange={(e:any)=>{
                            setTxtComment(e.target.value)
                        }}
                    />
                </Grid.Column>
                <Grid.Column></Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
                <Grid.Column>
                    <Grid>
                        <Grid.Row columns={3} >
                            <Grid.Column width={8} mobile={16} widescreen={8} tablet={8} computer={8}>

                            </Grid.Column>
                            <Grid.Column width={4} mobile={16} widescreen={4} tablet={4} computer={4}>

                            </Grid.Column>
                            <Grid.Column width={4} mobile={16} widescreen={4} tablet={4} computer={4}>
                                <div className={'button-flex'}>
                                    <OutLineButton id={'btnSend'} style={{width: '150px'}} onClick={() => {
                                        addCommentToAssessorfromAdmin()
                                    }} color='blue' className={"background-transaparent"} text={'Send'} />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>
                <Grid.Column></Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <h2 style={{display: 'inline-flex'}}>Assessment History</h2>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    {AssessomentDet.length > 0 ?  (<div style={{overflow: 'auto'}}>
                        <RSATable
                            head={AssessmentHistoryHead}
                            body={AssessomentDet}
                            pageCount={totalPages/5}
                            onChange={onChange}
                            current={currentPage}
                        />
                    </div>): "No Admin Communication"}

                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                </Grid.Column>
            </Grid.Row>
            <Modal
                    open={open2 ? open2 : false}
                    setOpen={setOpen2}
                    title={"Warning !"}
                    modalDescription={true}
                    descriptionHeader={'Are you sure you want to delete this assigned assessor?'}
                    descriptionBody={'This action cannot be undone.'}
                    imageUrl={waringImage}
                    imageSize='small'
                    modalAction={true}
                    ActionButtons={() => (
                        <ControllButtonRegModal
                            NoClick={() => {
                                setOpen2(false)
                                setDelAssignAssessorId(0)
                            }}
                            YesClick={() => removeAssignedAssessor(delAssignAssessorId)}
                        />
                    )}
                />
            <br/>
        </Grid>
    )

}

export default AccreditationAssessment

interface ControllButtonProps {
    //eslint-disable-next-line
    NoClick: any;
    //eslint-disable-next-line
    YesClick: any;
    //eslint-disable-next-line
}

const ControllButtonRegModal = (props: ControllButtonProps) => {
    return (
        <React.Fragment>
            <OutLineButton id={'btnCancel'} style={{width: '150px'}} onClick={() => {
                props.NoClick()
            }} color={'red'} text={'No'}/>
            <DefaultButton id={'btnSave'} style={{width: '150px'}} onClick={() => {
                props.YesClick()
            }} color={'blue'}
                           text={'Yes'}/>
        </React.Fragment>
    )
}